import { API } from 'aws-amplify';
import * as mutations from '../../../../../graphql/mutations';
import { GamifyToast } from '../../../../common/CustomToasts';
import { isValid } from 'date-fns';

/**
 * Formats a date to ISO string format
 * @param dateStr Date or string to format
 * @returns ISO formatted date string
 */
export const newLocalToISODateFormat = (dateStr?: Date | string): string => {
  if (!dateStr || dateStr === 'undefined' || dateStr === '') {
    return '';
  }

  try {
    const date = typeof dateStr === 'string' ? new Date(dateStr) : dateStr;

    if (!isValid(date)) {
      return '';
    }

    return date.toISOString();
  } catch (error) {
    console.error('Error formatting date:', error);
    return '';
  }
};

/**
 * Marks an appointment with a specific action (e.g., 'cancelled', 'completed')
 * @param appointmentID ID of the appointment to mark
 * @param action Action to perform on the appointment
 * @param notes Optional notes to include
 * @param onSuccess Optional callback function on success
 * @returns Promise resolving to a boolean indicating success
 */
export const markAppointment = async (
  appointmentID: string,
  action: string,
  notes: string = '',
  onSuccess?: (result: boolean) => void
): Promise<boolean> => {
  console.debug('markAppointment:', appointmentID, action, notes);

  try {
    const request = {
      action,
      appointmentID,
      notes,
      statusRole: 'setter',
      actionTmst: newLocalToISODateFormat(new Date()),
    };
    console.debug('request:', request);

    const res = await API.graphql({
      query: mutations.bookCalendarSlot,
      variables: {
        request: JSON.stringify(request),
      },
    });
    console.debug('mark appointment res:', res);

    GamifyToast.success(`Appointment ${action}`);
    if (onSuccess) onSuccess(true);
    return true;
  } catch (err) {
    console.error('markAppointment Error:', JSON.stringify(err));
    GamifyToast.error(`Error while trying to mark appointment ${action}`);
    return false;
  }
};
