import React, { Suspense } from 'react';
import CustomThemeProvider from './configuration/CustomThemeProvider';
import RouteConfiguration from './routes/RouteConfiguration';
import { Box, Typography } from '@mui/material';
import { Authenticator, View } from '@aws-amplify/ui-react';
import store from './store/Store';
import { Provider } from 'react-redux';
import { PermissionsProvider } from './context/PermissionsContext/PermissionsContext';
import './configuration/AmplifyConfiguration';
import '@aws-amplify/ui-react/styles.css';
import './Application.css';
import ApplicationStateBootstrap from './configuration/ApplicationStateBootstrap';
import './Internationalization';
import CenteredProgress from './components/CenteredProgress';
import { ToastContainer, toast } from 'react-toastify';
import styled from '@emotion/styled';
import 'react-toastify/dist/ReactToastify.minimal.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const StyledToastContainer = styled(ToastContainer)`
  // https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity
  &&&.Toastify__toast-container {
    background-color: transparent;
    box-shadow: none;
    padding: 0;
  }
  .Toastify__toast {
    background-color: transparent;
    box-shadow: none;
    padding: 0;
  }
  .Toastify__toast-body {
    background-color: transparent;
    box-shadow: none;
    padding: 0;
  }
  .Toastify__progress-bar {
    margin: 0px;
    padding: 0px;
    border-radius: 0px;
  }
`;

const components = {
  Header() {
    return (
      <View style={{ justifyContent: 'center' }}>
        <View
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            background: 'transparent',
            marginLeft: '32px',
            marginTop: '32px',
          }}
        >
          <img src="/images/paiv-logo.png" alt="Gamify" />
        </View>
        <View style={{ paddingTop: 22 }}>
          <Typography
            sx={{
              color: '#222428',
              textAlign: 'left',
              fontSize: 32,
              fontFamily: 'Manrope',
              fontWeight: 'bold',
              marginLeft: '32px',
            }}
          >
            Welcome back
          </Typography>
          <Typography
            sx={{
              textAlign: 'left',
              fontSize: 16,
              fontFamily: 'Manrope',
              marginLeft: '32px',
            }}
          >
            Log in to your account
          </Typography>
        </View>
      </View>
    );
  },
};

global.toast = toast;
const queryClient = new QueryClient();

class Application extends React.Component {
  constructor(props) {
    super(props);
    // NOTE: this.state.isLogged is set in ApplicationStateBootstrap which is why this is passed to that component as a prop. This state variable is needed to dynamically change the app's styliing for when a user isn't logged in (log in screen) vs. when a user is logged in (rest of the app)
    this.state = {
      isLoggedIn: !!global.me, // Initial state based on global.me
    };
  }

  render() {
    // console.log(
    //   'LOG IN STYLING this.state?.isLoggedIn: ',
    //   this.state?.isLoggedIn
    // );

    return (
      <Box
        style={
          this.state?.isLoggedIn
            ? {}
            : {
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }
        }
      >
        <QueryClientProvider client={queryClient}>
          <Box
            style={
              this.state?.isLoggedIn
                ? {}
                : {
                    border: '1px solid #D7D9D6',
                    borderRadius: 16,
                    width: 'fit-content',
                  }
            }
          >
            <Authenticator
              components={components}
              loginMechanisms={['email']}
              hideSignUp={true}
            >
              <PermissionsProvider>
                <Provider store={store}>
                  <CustomThemeProvider>
                    <Suspense fallback={<CenteredProgress />}>
                      <ApplicationStateBootstrap this={this}>
                        <Box>
                          <RouteConfiguration />
                        </Box>
                        <StyledToastContainer />
                      </ApplicationStateBootstrap>
                    </Suspense>
                  </CustomThemeProvider>
                </Provider>
              </PermissionsProvider>
            </Authenticator>
          </Box>
        </QueryClientProvider>
      </Box>
    );
  }
}

export default Application;
