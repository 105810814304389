import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Drawer,
  IconButton,
  Grid,
  Paper,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { addMinutes, format, roundToNearestMinutes } from 'date-fns';
import { DateRangePicker } from '../../../../components/DateRangePicker/DateRangePicker';
import { GamifyIcon } from '../../../../components/GamifyIcon';
import Fuse from 'fuse.js';
import { GamifyToast } from '../../../../common/CustomToasts';
import { markAppointment } from '../api/markAppointment';
import { Deal } from '../api/useDealsQuery';
import AutorenewIcon from '@mui/icons-material/Autorenew';

const combineDateAndTime = (date: Date, time: Date): Date => {
  const result = new Date(date);
  result.setHours(
    time.getHours(),
    time.getMinutes(),
    time.getSeconds(),
    time.getMilliseconds()
  );
  return result;
};

interface AppointmentEditFormProps {
  open: boolean;
  onClose: () => void;
  appointment?: {
    id: string;
    title: string;
    start: Date;
    end: Date;
    admin_id: string;
    dealId?: string;
    extendedProps?: {
      displayFields?: {
        customerName?: string;
        fullAddress?: string;
        phoneNumber?: string;
        notes?: string;
        otherAssignees?: {
          id: string;
          name: string;
          avatar?: string;
        };
      };
      setter?: {
        name: string;
        avatar?: string;
        initials?: string;
      };
    };
  };
  onSave: (appointmentData: any) => Promise<void>;
  onDelete?: (appointmentId: string) => Promise<void>;
  availableUsers: Array<{
    id: string;
    name: string;
    avatar?: string;
    role?: string;
    closerWeight?: number;
  }>;
  isCreating?: boolean;
  initialDeal?: Deal | null;
  hasRoundRobin?: boolean;
}

const AppointmentEditForm: React.FC<AppointmentEditFormProps> = ({
  open,
  onClose,
  appointment,
  onSave,
  onDelete,
  availableUsers = [],
  isCreating = false,
  initialDeal = null,
  hasRoundRobin = true,
}) => {
  const [formData, setFormData] = useState({
    customerName: '',
    date: new Date(),
    startTime: new Date(),
    endTime: addMinutes(new Date(), 45),
    address: '',
    phoneNumber: '',
    assignedUsers: [] as string[],
    dealId: '',
    notes: '',
    sendNotification: true,
    isRoundRobin: false,
  });

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showUserPicker, setShowUserPicker] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [errors, setErrors] = useState({
    customerName: '',
    date: '',
    startTime: '',
    endTime: '',
    assignedUsers: '',
  });

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const [setterInfo, setSetterInfo] = useState<{
    name: string;
    avatar?: string;
    initials?: string;
  } | null>(null);

  const usersFuse = useMemo(
    () =>
      new Fuse(availableUsers, {
        keys: ['name', 'role'],
        threshold: 0.2,
        shouldSort: true,
      }),
    [availableUsers]
  );

  const filteredUsers = useMemo(() => {
    if (!searchQuery) return availableUsers;
    const results = usersFuse.search(searchQuery);
    return results.map((result) => result.item);
  }, [searchQuery, usersFuse, availableUsers]);

  const [showRoundRobinOption, setShowRoundRobinOption] =
    useState(hasRoundRobin);

  useEffect(() => {
    console.log('AppointmentEditForm - initialDeal:', initialDeal);
    console.log('🚀 ~ useEffect ~ appointment:', appointment);

    setShowRoundRobinOption(hasRoundRobin);

    if (appointment) {
      setFormData({
        customerName:
          appointment.extendedProps?.displayFields?.customerName || '',
        date: appointment.start,
        startTime: appointment.start,
        endTime: appointment.end,
        address: appointment.extendedProps?.displayFields?.fullAddress || '',
        phoneNumber:
          appointment.extendedProps?.displayFields?.phoneNumber || '',
        assignedUsers: [appointment.admin_id],
        dealId: appointment.dealId || '',
        notes: appointment.extendedProps?.displayFields?.notes || '',
        sendNotification: true,
        isRoundRobin: appointment.admin_id === 'RoundRobin',
      });
    } else if (initialDeal) {
      console.log('Pre-filling form with deal data:', initialDeal);

      const now = new Date();
      const roundedNow = roundToNearestMinutes(now, { nearestTo: 15 });
      const endTime = addMinutes(roundedNow, 45);

      let addressText = '';
      if (initialDeal.description) {
        addressText = initialDeal.description;
      } else if (initialDeal.formattedAddress) {
        addressText = initialDeal.formattedAddress;
      } else if (initialDeal.address) {
        addressText = initialDeal.address;
      } else if (initialDeal.pinAddress) {
        const pin = initialDeal.pinAddress;
        if (typeof pin === 'object') {
          const parts = [];
          if (pin.street) parts.push(pin.street);
          if (pin.address) parts.push(pin.address);
          if (pin.city) parts.push(pin.city);
          if (pin.region) parts.push(pin.region);
          if (pin.postalCode) parts.push(pin.postalCode);
          if (parts.length > 0) {
            addressText = parts.join(', ');
          }
        }
      }

      const initialAssignedUsers = [];

      if (initialDeal.closer?.id) {
        console.log('Using closer as assigned user:', initialDeal.closer);
        initialAssignedUsers.push(initialDeal.closer.id);
      } else if (initialDeal.setter?.id) {
        console.log(
          'No closer available, using setter as assigned user:',
          initialDeal.setter
        );
        initialAssignedUsers.push(initialDeal.setter.id);
      } else {
        console.log(
          'No closer or setter available, user will need to select an assignee'
        );
      }

      console.log('Initial assigned users:', initialAssignedUsers);

      setFormData({
        customerName: initialDeal.name || initialDeal.customerName || '',
        date: roundedNow,
        startTime: roundedNow,
        endTime,
        address: addressText,
        phoneNumber: initialDeal.phoneNumber || '',
        assignedUsers: initialAssignedUsers,
        dealId: initialDeal.id,
        notes: initialDeal.notes || '',
        sendNotification: true,
        isRoundRobin: false,
      });
    } else {
      setFormData({
        customerName: '',
        date: new Date(),
        startTime: new Date(),
        endTime: addMinutes(new Date(), 45),
        address: '',
        phoneNumber: '',
        assignedUsers: [],
        dealId: '',
        notes: '',
        sendNotification: true,
        isRoundRobin: false,
      });
    }
  }, [appointment, initialDeal, hasRoundRobin]);

  useEffect(() => {
    if (initialDeal?.setter) {
      setSetterInfo({
        name: initialDeal.setter.name,
        avatar: initialDeal.setter.profilePicture || '',
        initials:
          initialDeal.setter.initials || initialDeal.setter.name.charAt(0),
      });
    } else {
      setSetterInfo(null);
    }
  }, [initialDeal]);

  useEffect(() => {
    if (!showUserPicker) {
      setSearchQuery('');
    }
  }, [showUserPicker]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    if (errors[name as keyof typeof errors]) {
      setErrors((prev) => ({ ...prev, [name]: '' }));
    }
  };

  const handleTimeSlotSelect = (startTime: Date, endTime: Date) => {
    setFormData((prev) => ({ ...prev, startTime, endTime }));
  };

  const handleDateSelect = (date: Date) => {
    const newStartTime = new Date(date);
    newStartTime.setHours(
      formData.startTime.getHours(),
      formData.startTime.getMinutes(),
      0,
      0
    );

    const newEndTime = new Date(date);
    newEndTime.setHours(
      formData.endTime.getHours(),
      formData.endTime.getMinutes(),
      0,
      0
    );

    setFormData((prev) => ({
      ...prev,
      date,
      startTime: newStartTime,
      endTime: newEndTime,
    }));

    setShowDatePicker(false);
  };

  const handleUserSelect = (userId: string) => {
    setFormData((prev) => ({
      ...prev,
      assignedUsers: [userId],
      isRoundRobin: false,
    }));
    setShowUserPicker(false);
  };

  const handleRoundRobinSelect = () => {
    setFormData((prev) => ({
      ...prev,
      assignedUsers: ['RoundRobin'],
      isRoundRobin: true,
    }));
    setShowUserPicker(false);
  };

  const validateForm = () => {
    const newErrors = {
      customerName: '',
      date: '',
      startTime: '',
      endTime: '',
      assignedUsers: '',
    };

    let isValid = true;

    if (!formData.customerName.trim()) {
      newErrors.customerName = 'Closer name is required';
      isValid = false;
    }

    if (!formData.date) {
      newErrors.date = 'Valid date is required';
      isValid = false;
    }

    if (!formData.startTime) {
      newErrors.startTime = 'Valid start time is required';
      isValid = false;
    }

    if (!formData.endTime) {
      newErrors.endTime = 'Valid end time is required';
      isValid = false;
    }

    if (formData.assignedUsers.length === 0) {
      newErrors.assignedUsers = 'At least one assigned user is required';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    setIsSubmitting(true);

    try {
      let finalAssignedUser = formData.assignedUsers[0];

      if (formData.isRoundRobin || finalAssignedUser === 'RoundRobin') {
        const availableClosers = availableUsers.filter(
          (user) => user.closerWeight && user.closerWeight > 0
        );

        if (availableClosers.length > 0) {
          finalAssignedUser = availableClosers[0].id;
          console.log('Round Robin selected, assigning to:', finalAssignedUser);
        } else {
          GamifyToast.error('No available closers for Round Robin assignment');
          setIsSubmitting(false);
          return;
        }
      }

      const appointmentData: any = {
        id: appointment?.id,
        title: formData.customerName,
        start: combineDateAndTime(formData.date, formData.startTime),
        end: combineDateAndTime(formData.date, formData.endTime),
        admin_id: finalAssignedUser,
        dealId: formData.dealId,
        extendedProps: {
          displayFields: {
            customerName: formData.customerName,
            fullAddress: formData.address,
            phoneNumber: formData.phoneNumber,
            notes: formData.notes,
          },
          isRoundRobin: formData.isRoundRobin,
        },
      };

      if (finalAssignedUser && finalAssignedUser !== 'RoundRobin') {
        const assignedUser = availableUsers.find(
          (user) => user.id === finalAssignedUser
        );

        if (assignedUser) {
          console.log(
            'Adding assigned user to appointment data:',
            assignedUser
          );

          appointmentData.extendedProps.displayFields.assignedUser = {
            id: assignedUser.id,
            name: assignedUser.name,
            avatar: assignedUser.avatar,
          };
        }
      }

      if (appointment) {
        appointmentData.id = appointment.id;
      }

      console.log('Final appointment data being saved:', appointmentData);
      await onSave(appointmentData);
      onClose();
      GamifyToast.success('Appointment saved successfully');
    } catch (error) {
      console.error('Error saving appointment:', error);
      GamifyToast.error('Failed to save appointment. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = async () => {
    if (appointment?.id && onDelete && !isSubmitting) {
      setConfirmDialogOpen(true);
    }
  };

  const handleConfirmDialogClose = (confirmed: boolean) => {
    setConfirmDialogOpen(false);

    if (confirmed && appointment?.id && !isSubmitting) {
      setIsSubmitting(true);

      if (onDelete) {
        onDelete(appointment.id)
          .then(() => {
            onClose();
            GamifyToast.success('Appointment cancelled');
          })
          .catch((error) => {
            console.error('Error deleting appointment:', error);
            GamifyToast.error('There was a problem deleting the appointment');
          })
          .finally(() => {
            setIsSubmitting(false);
          });
      } else {
        markAppointment(
          appointment.id,
          'cancelled',
          formData.notes,
          (result) => {
            if (result) {
              onClose();
              GamifyToast.success('Appointment cancelled');
            }
            setIsSubmitting(false);
          }
        ).catch((error) => {
          console.error('Error deleting appointment:', error);
          GamifyToast.error('There was a problem deleting the appointment');
          setIsSubmitting(false);
        });
      }
    }
  };

  const generateTimeSlots = () => {
    const slots = [];
    const startHour = 8;
    const endHour = 19;
    const date = formData.date;

    for (let hour = startHour; hour <= endHour; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const startTime = new Date(date);
        startTime.setHours(hour, minute, 0, 0);

        const endTime = new Date(startTime);
        endTime.setMinutes(endTime.getMinutes() + 30);

        const now = new Date();
        if (
          date.getDate() === now.getDate() &&
          date.getMonth() === now.getMonth() &&
          date.getFullYear() === now.getFullYear() &&
          startTime < now
        ) {
          continue;
        }

        slots.push({
          startTime,
          endTime,
          label: format(startTime, 'h:mm a'),
          category: hour < 12 ? 'Morning' : hour < 17 ? 'Afternoon' : 'Evening',
        });
      }
    }

    return slots;
  };

  const timeSlotsByCategory = () => {
    const slots = generateTimeSlots();
    const grouped: Record<string, typeof slots> = {
      Morning: [],
      Afternoon: [],
      Evening: [],
    };

    slots.forEach((slot) => {
      grouped[slot.category].push(slot);
    });

    return grouped;
  };

  const renderAssignedUser = () => {
    if (formData.isRoundRobin) {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            sx={{ width: 24, height: 24, mr: 1.5, bgcolor: 'primary.light' }}
          >
            <AutorenewIcon sx={{ width: 16, height: 16 }} />
          </Avatar>
          <Typography
            variant="body2"
            sx={{
              color: 'text.primary',
              fontSize: '13px',
            }}
          >
            Round Robin
          </Typography>
        </Box>
      );
    }

    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar
          src={
            formData.assignedUsers.length > 0
              ? availableUsers.find(
                  (user) => user.id === formData.assignedUsers[0]
                )?.avatar
              : undefined
          }
          sx={{ width: 24, height: 24, mr: 1.5 }}
        >
          {formData.assignedUsers.length > 0
            ? availableUsers
                .find((user) => user.id === formData.assignedUsers[0])
                ?.name.charAt(0)
            : ''}
        </Avatar>
        <Typography
          variant="body2"
          sx={{
            color:
              formData.assignedUsers.length > 0
                ? 'text.primary'
                : 'text.secondary',
            fontSize: '13px',
          }}
        >
          {formData.assignedUsers.length > 0
            ? availableUsers.find(
                (user) => user.id === formData.assignedUsers[0]
              )?.name
            : 'Select a closer'}
        </Typography>
      </Box>
    );
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => !isSubmitting && onClose()}
        style={{
          zIndex: 99999,
        }}
        PaperProps={{
          sx: {
            width: { xs: '100%', sm: 400 },
            padding: 0,
            overflow: 'hidden',
            marginTop: '64px',
            height: 'calc(100% - 64px)',
            bgcolor: 'white',
            boxShadow: '-4px 0px 10px rgba(0, 0, 0, 0.1)',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          {/* Header with closer name and close/delete buttons */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              p: 2,
              borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
            }}
          >
            <TextField
              fullWidth
              placeholder="Closer Name"
              name="customerName"
              value={formData.customerName}
              onChange={handleInputChange}
              error={!!errors.customerName}
              helperText={errors.customerName}
              variant="standard"
              InputProps={{
                disableUnderline: true,
                style: { fontSize: '18px', fontWeight: 'bold' },
              }}
              sx={{ mr: 2 }}
              disabled={isSubmitting}
            />
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {!isCreating && onDelete && (
                <IconButton
                  color="error"
                  onClick={handleDelete}
                  sx={{ mr: 1 }}
                  disabled={isSubmitting}
                >
                  <GamifyIcon
                    icon={'trash'}
                    color={isSubmitting ? '#ccc' : 'red'}
                    backgroundColor={'#fff'}
                    height={24}
                    width={24}
                  />
                </IconButton>
              )}
              <IconButton onClick={onClose} disabled={isSubmitting}>
                <CloseIcon sx={{ color: isSubmitting ? '#ccc' : 'inherit' }} />
              </IconButton>
            </Box>
          </Box>

          {/* Main Content */}
          <Box sx={{ flex: 1, overflow: 'auto' }}>
            {/* Date and Time Display */}
            <Box sx={{ p: 2, pb: 0 }}>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ fontSize: '13px' }}
              >
                {`${format(formData.date, 'EEEE, MMMM d')}  ${format(
                  formData.startTime,
                  'h:mm'
                )} - ${format(formData.endTime, 'h:mm a')}`}
              </Typography>
            </Box>

            {/* Address */}
            <Box sx={{ p: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                <Box sx={{ mr: 1.5 }}>
                  <GamifyIcon
                    icon={'pin'}
                    height={20}
                    width={20}
                    color="#666"
                  />
                </Box>
                <Typography
                  variant="body2"
                  sx={{
                    color: formData.address ? 'text.primary' : 'text.secondary',
                    fontSize: '13px',
                  }}
                >
                  {formData.address || 'Address'}
                </Typography>
              </Box>
            </Box>

            {/* Phone Number */}
            {formData.phoneNumber && (
              <Box sx={{ p: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                  <Box sx={{ mr: 1.5 }}>
                    <GamifyIcon
                      icon={'phone'}
                      height={20}
                      width={20}
                      color="#666"
                    />
                  </Box>
                  <Typography
                    variant="body2"
                    sx={{
                      color: formData.phoneNumber
                        ? 'text.primary'
                        : 'text.secondary',
                      fontSize: '13px',
                    }}
                  >
                    {formData.phoneNumber || 'Phone number'}
                  </Typography>
                </Box>
              </Box>
            )}

            {/* User Info (not editable) */}
            <Box sx={{ p: 2, py: '12px' }}>
              {/* People row - side by side */}
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {/* Setter - First */}
                <Box sx={{ display: 'flex', alignItems: 'center', mr: 3 }}>
                  <Box sx={{ mr: 1 }}>
                    <GamifyIcon
                      icon={'users'}
                      color={'#000000'}
                      width={20}
                      height={20}
                    />
                  </Box>
                  <Box sx={{ mr: 1 }}>
                    <Avatar
                      sx={{
                        width: 24,
                        height: 24,
                        backgroundColor: '#FFE0CC',
                      }}
                    >
                      {setterInfo?.initials ||
                        (setterInfo?.name ? setterInfo.name.charAt(0) : '')}
                    </Avatar>
                  </Box>
                  <Typography
                    variant="body2"
                    sx={{
                      color: setterInfo?.name
                        ? 'text.primary'
                        : 'text.secondary',
                      fontSize: '13px',
                    }}
                  >
                    {setterInfo?.name || 'Setter'}
                  </Typography>
                </Box>

                {/* Closer - Second */}
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ mr: 1 }}>
                    <Avatar
                      sx={{
                        width: 24,
                        height: 24,
                        backgroundColor: '#E3F2FD',
                      }}
                    >
                      {formData.assignedUsers.length > 0
                        ? availableUsers
                            .find(
                              (user) => user.id === formData.assignedUsers[0]
                            )
                            ?.name.charAt(0)
                        : ''}
                    </Avatar>
                  </Box>
                  <Typography
                    variant="body2"
                    sx={{
                      color:
                        formData.assignedUsers.length > 0
                          ? 'text.primary'
                          : 'text.secondary',
                      fontSize: '13px',
                    }}
                  >
                    {formData.assignedUsers.length > 0
                      ? availableUsers.find(
                          (user) => user.id === formData.assignedUsers[0]
                        )?.name
                      : 'Closer'}
                  </Typography>
                </Box>
              </Box>
            </Box>

            {/* Divider before Assigned Closer */}
            <Box sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.08)' }} />

            {/* Date Picker Button */}
            <Box sx={{ p: 2, py: '18px' }}>
              <Button
                fullWidth
                onClick={() =>
                  !isSubmitting && setShowDatePicker(!showDatePicker)
                }
                disabled={isSubmitting}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  bgcolor: 'white',
                  color: 'text.primary',
                  textTransform: 'none',
                  borderRadius: 2,
                  py: 1.5,
                  px: 2,
                  '&:hover': {
                    bgcolor: 'rgba(0, 0, 0, 0.02)',
                  },
                  border: '1px solid rgba(0, 0, 0, 0.12)',
                  boxShadow: 'none',
                  opacity: isSubmitting ? 0.7 : 1,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box
                      sx={{
                        mr: 1.5,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '50%',
                        backgroundColor: '#E3F2F6',
                        width: 36,
                        height: 36,
                        flexShrink: 0,
                      }}
                    >
                      <GamifyIcon
                        icon={'calendar'}
                        color={'#4A6572'}
                        backgroundColor="transparent"
                        width={20}
                        height={20}
                      />
                    </Box>
                    <Typography sx={{ fontWeight: 500, fontSize: '13px' }}>
                      {format(formData.date, 'EEEE d, MMMM')}
                    </Typography>
                  </Box>
                  <ArrowDropDownIcon />
                </Box>
              </Button>

              {/* Date Picker Popup */}
              {showDatePicker && (
                <Paper
                  elevation={3}
                  sx={{
                    position: 'absolute',
                    zIndex: 1300,
                    width: '100%',
                    maxWidth: 300,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    mt: 1,
                    borderRadius: 2,
                    overflow: 'hidden',
                  }}
                >
                  <Box sx={{ p: 1, display: 'flex', justifyContent: 'center' }}>
                    <DateRangePicker
                      selectedDates={[formData.date]}
                      onDateSelect={handleDateSelect}
                      singleDateMode={true}
                    />
                  </Box>
                </Paper>
              )}
            </Box>

            {/* Time Slots */}
            <Box sx={{ p: 2, pt: '18px' }}>
              {(() => {
                const timeSlots = timeSlotsByCategory();
                const hasTimeSlots = Object.values(timeSlots).some(
                  (slots) => slots.length > 0
                );

                if (!hasTimeSlots) {
                  return (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100px',
                        border: '1px dashed rgba(0, 0, 0, 0.12)',
                        borderRadius: '8px',
                        backgroundColor: 'rgba(0, 0, 0, 0.02)',
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          color: 'text.secondary',
                          fontStyle: 'italic',
                        }}
                      >
                        No available timeslots for this date
                      </Typography>
                    </Box>
                  );
                }

                return Object.entries(timeSlots).map(
                  ([category, slots]) =>
                    slots.length > 0 && (
                      <Box key={category} sx={{ mb: 3 }}>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            fontWeight: 'bold',
                            mb: 1,
                            fontSize: '13px',
                          }}
                        >
                          {category}
                        </Typography>
                        <Grid container spacing={1}>
                          {slots.map((slot, index) => (
                            <Grid item xs={3} key={index}>
                              <Button
                                fullWidth
                                variant="outlined"
                                onClick={() =>
                                  !isSubmitting &&
                                  handleTimeSlotSelect(
                                    slot.startTime,
                                    slot.endTime
                                  )
                                }
                                disabled={isSubmitting}
                                sx={{
                                  borderRadius: '6px',
                                  textTransform: 'none',
                                  width: '82px',
                                  height: '38px',
                                  bgcolor:
                                    format(slot.startTime, 'h:mm a') ===
                                    format(formData.startTime, 'h:mm a')
                                      ? 'rgba(25, 118, 210, 0.1)'
                                      : 'white',
                                  color: isSubmitting
                                    ? 'rgba(0, 0, 0, 0.38)'
                                    : 'text.primary',
                                  border:
                                    format(slot.startTime, 'h:mm a') ===
                                    format(formData.startTime, 'h:mm a')
                                      ? '1px solid #1976d2'
                                      : '1px solid rgba(0, 0, 0, 0.12)',
                                  '&:hover': {
                                    bgcolor:
                                      format(slot.startTime, 'h:mm a') ===
                                      format(formData.startTime, 'h:mm a')
                                        ? 'rgba(25, 118, 210, 0.15)'
                                        : 'rgba(0, 0, 0, 0.04)',
                                  },
                                  padding: '8px 12px',
                                  fontSize: '0.75rem',
                                  opacity: isSubmitting ? 0.7 : 1,
                                }}
                              >
                                {slot.label}
                              </Button>
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    )
                );
              })()}
            </Box>

            {/* Assigned Closer (editable) */}
            <Box sx={{ p: 2, paddingTop: 0 }}>
              <Typography
                variant="body2"
                sx={{
                  color: 'text.secondary',
                  fontSize: '12px',
                  fontWeight: 500,
                  mb: 1,
                }}
              >
                Assigned Closer
              </Typography>
              <Box
                onClick={() => !isSubmitting && setShowUserPicker(true)}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  p: 1.5,
                  border: '1px solid rgba(0, 0, 0, 0.12)',
                  borderRadius: '8px',
                  cursor: isSubmitting ? 'default' : 'pointer',
                  '&:hover': {
                    backgroundColor: isSubmitting
                      ? 'transparent'
                      : 'rgba(0, 0, 0, 0.04)',
                  },
                  opacity: isSubmitting ? 0.7 : 1,
                }}
              >
                {renderAssignedUser()}
                <ArrowDropDownIcon />
              </Box>
            </Box>

            {/* Divider before Date Picker */}
            <Box sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.08)' }} />

            {/* User Picker Popup - Positioned to the left of the appointment form drawer */}
            {showUserPicker && (
              <>
                {/* Backdrop */}
                <Box
                  onClick={() => setShowUserPicker(false)}
                  sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    bgcolor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 9998,
                  }}
                />
                {/* Picker Panel */}
                <Box
                  sx={{
                    position: 'fixed',
                    top: 64,
                    right: { xs: 0, sm: 416 },
                    width: { xs: '100%', sm: 400 },
                    height: { xs: '100%', sm: 'auto' },
                    minHeight: { sm: '700px' },
                    maxHeight: { sm: 'calc(100% - 256px)' },
                    margin: { sm: '16px 0' },
                    bgcolor: 'white',
                    boxShadow: '-2px 0px 8px rgba(0, 0, 0, 0.1)',
                    zIndex: 9999,
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: { xs: 0, sm: '8px' },
                    overflow: 'hidden',
                  }}
                >
                  <Box
                    sx={{
                      p: 2,
                      borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Typography variant="h6" sx={{ mb: 2, fontSize: '16px' }}>
                      Re-assign to
                    </Typography>

                    {/* Search Box */}
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        p: 1,
                        border: '1px solid rgba(0, 0, 0, 0.12)',
                        borderRadius: 2,
                        bgcolor: 'rgba(0, 0, 0, 0.02)',
                      }}
                    >
                      <Box sx={{ mr: 1, color: 'text.secondary' }}>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                            stroke="#666666"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M21 21L16.65 16.65"
                            stroke="#666666"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </Box>
                      <input
                        type="text"
                        placeholder="Search people..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        style={{
                          border: 'none',
                          outline: 'none',
                          background: 'transparent',
                          width: '100%',
                          fontSize: '13px',
                        }}
                      />
                    </Box>
                  </Box>

                  {/* User List */}
                  <Box sx={{ flex: 1, overflow: 'auto' }}>
                    {/* Round Robin Option */}
                    {showRoundRobinOption && (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          p: 2,
                          borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
                          '&:hover': {
                            bgcolor: 'rgba(0, 0, 0, 0.02)',
                          },
                          cursor: 'pointer',
                          backgroundColor: formData.isRoundRobin
                            ? 'rgba(230, 247, 255, 0.5)'
                            : 'transparent',
                        }}
                        onClick={handleRoundRobinSelect}
                      >
                        <Box
                          sx={{
                            width: 36,
                            height: 36,
                            borderRadius: '50%',
                            bgcolor: '#E3F2F6',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            mr: 2,
                          }}
                        >
                          <AutorenewIcon color="primary" />
                        </Box>
                        <Box>
                          <Typography
                            sx={{ fontWeight: 'bold', fontSize: '13px' }}
                          >
                            Round Robin
                          </Typography>
                          <Typography
                            sx={{ color: 'text.secondary', fontSize: '12px' }}
                          >
                            Auto-assign
                          </Typography>
                        </Box>
                        {formData.isRoundRobin && (
                          <Box sx={{ ml: 'auto', color: '#0088CC' }}>
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M20 6L9 17L4 12"
                                stroke="#0088CC"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </Box>
                        )}
                      </Box>
                    )}

                    {/* User Items */}
                    {filteredUsers.length === 0 ? (
                      <Box
                        sx={{
                          p: 2,
                          textAlign: 'center',
                          color: 'text.secondary',
                        }}
                      >
                        <Typography variant="body2">No users found</Typography>
                      </Box>
                    ) : (
                      filteredUsers.map((user) => (
                        <Box
                          key={user.id}
                          onClick={() => handleUserSelect(user.id)}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            p: 2,
                            borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
                            bgcolor: formData.assignedUsers.includes(user.id)
                              ? 'rgba(230, 247, 255, 0.5)'
                              : 'transparent',
                            '&:hover': {
                              bgcolor: 'rgba(0, 0, 0, 0.02)',
                            },
                            cursor: 'pointer',
                          }}
                        >
                          <Avatar
                            src={user.avatar}
                            sx={{ width: 36, height: 36, mr: 2 }}
                          >
                            {user.name.charAt(0)}
                          </Avatar>
                          <Box>
                            <Typography
                              sx={{ fontWeight: 'bold', fontSize: '13px' }}
                            >
                              {user.name}
                            </Typography>
                            <Typography
                              sx={{ color: 'text.secondary', fontSize: '12px' }}
                            >
                              {user.role || 'Team Member'}
                            </Typography>
                          </Box>
                          {formData.assignedUsers.includes(user.id) && (
                            <Box sx={{ ml: 'auto', color: '#0088CC' }}>
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M20 6L9 17L4 12"
                                  stroke="#0088CC"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </Box>
                          )}
                        </Box>
                      ))
                    )}
                  </Box>
                </Box>
              </>
            )}

            {/* Notes Section */}
            <Box sx={{ px: 2, py: '18px' }}>
              <Typography
                variant="subtitle2"
                sx={{
                  fontWeight: 'bold',
                  mb: 1,
                  fontSize: '13px',
                }}
              >
                Notes about this appointment
              </Typography>
              <TextField
                fullWidth
                placeholder="Tap to add notes"
                name="notes"
                value={formData.notes}
                onChange={handleInputChange}
                variant="outlined"
                multiline
                rows={3}
                disabled={isSubmitting}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    bgcolor: 'rgba(0, 0, 0, 0.02)',
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                      borderWidth: '1px',
                    },
                  },
                  '& .MuiInputBase-input': {
                    fontSize: '13px',
                    color: 'rgba(0, 0, 0, 0.7)',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                    borderRadius: '8px',
                  },
                }}
              />
            </Box>
          </Box>

          {/* Footer with Save Button */}
          <Box
            sx={{
              p: 2,
              borderTop: '1px solid rgba(0, 0, 0, 0.08)',
            }}
          >
            <Button
              fullWidth
              variant="contained"
              onClick={handleSubmit}
              sx={{
                textTransform: 'none',
                bgcolor: '#FF6A00',
                '&:hover': { bgcolor: '#E05E00' },
                borderRadius: '8px',
                py: 1.5,
              }}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <CircularProgress size={20} color="inherit" />
                </Box>
              ) : (
                'Save changes'
              )}
            </Button>
          </Box>
        </Box>
      </Drawer>

      {/* Confirmation Dialog */}
      <Dialog
        open={confirmDialogOpen}
        onClose={() => !isSubmitting && handleConfirmDialogClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Cancel Appointment</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to cancel this appointment?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleConfirmDialogClose(false)}
            color="primary"
            disabled={isSubmitting}
          >
            No
          </Button>
          <Button
            onClick={() => handleConfirmDialogClose(true)}
            color="primary"
            autoFocus
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <CircularProgress size={20} sx={{ mr: 1 }} />
                Cancelling...
              </Box>
            ) : (
              'Yes'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AppointmentEditForm;
