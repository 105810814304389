export function camelCaseToTitleCase(str: string): string {
  return str
    .replace(/([A-Z])/g, ' $1') // Insert space before each uppercase letter
    .replace(/^./, (match) => match.toUpperCase()) // Capitalize the first letter of the string
    .trim(); // Remove any leading or trailing spaces
}

export function toCamelCase(str: string): string {
  return str
    .replace(/[^a-zA-Z0-9]+(.)/g, (match, chr) => chr.toUpperCase()) // Capitalize letters after non-alphanumeric characters
    .replace(/^[A-Z]/, (match) => match.toLowerCase())
    .trim(); // Ensure the first letter is lowercase
}
