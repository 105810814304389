import { useState, useEffect, useCallback } from 'react';
import { API } from 'aws-amplify';
import * as queries from '../../../../../graphql/queries';

export interface Deal {
  id: string;
  name: string;
  customerName?: string;
  phoneNumber?: string;
  email?: string;
  address?: string;
  formattedAddress?: string;
  description?: string;
  status?: string;
  state?: string;
  badgeID?: string;
  notes?: string;
  flexAttributes?: Record<string, any>;
  updatedAt: Date;
  createdAt: Date | null;
  stageId?: string;
  pinAddress?: {
    location?: {
      lon: number;
      lat: number;
    };
    street?: string;
    address?: string;
    city?: string;
    region?: string;
    country?: string;
    postalCode?: string;
  };
  attrs?: any[];
  setter?: {
    id: string;
    name: string;
    profilePicture?: string;
    imageName?: string;
    initials?: string;
  };
  closer?: {
    id: string;
    name: string;
    profilePicture?: string;
    imageName?: string;
    initials?: string;
  };
}

interface UseDealsQueryProps {
  searchString?: string;
  limit?: number;
  categoryId?: string;
  stageId?: string;
  assigneeId?: string;
  sortOrder?: string;
}

interface UseDealsQueryResult {
  deals: Deal[];
  isLoading: boolean;
  error: Error | null;
  fetchDeals: (searchString?: string) => Promise<void>;
  hasMore: boolean;
  loadMore: () => Promise<void>;
}

const getUserAvatar = async (
  userId: string,
  imageName?: string
): Promise<string> => {
  if (!imageName) return '';

  try {
    // Implementation would go here
    return '';
  } catch (error) {
    console.error('Error fetching user avatar:', error);
    return '';
  }
};

export const useDealsQuery = ({
  searchString = '',
  limit = 25,
  categoryId = 'abf7340d-0816-4c23-8f64-d30fa0ed6b24',
  stageId = '',
  assigneeId = '',
  sortOrder = 'DESC',
}: UseDealsQueryProps = {}): UseDealsQueryResult => {
  const [deals, setDeals] = useState<Deal[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);
  const [offset, setOffset] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [currentSearchString, setCurrentSearchString] =
    useState<string>(searchString);

  const fetchDealsData = useCallback(
    async (search: string = currentSearchString, currentOffset: number = 0) => {
      setIsLoading(true);
      setError(null);

      try {
        // These are common display fields that should work for most categories
        const displayFields = [
          'First Name',
          'Last Name',
          'Email Address',
          'Phone Number',
          'Address',
          'City',
          'Plan',
          'Description',
        ];

        // Use the provided categoryId or try to get a default one
        if (!categoryId) {
          console.error('No category ID provided');
          setError(new Error('No category ID provided'));
          setIsLoading(false);
          return;
        }

        console.log('Fetching deals with categoryID:', categoryId);

        const requestPayload = {
          categoryID: categoryId,
          stageID: stageId,
          assigneeID: assigneeId,
          searchString: search,
          limit,
          offset: currentOffset,
          displayFields,
          sortOrder,
        };

        console.log(
          `Fetching deals with offset: ${currentOffset}, limit: ${limit}`
        );
        console.log(
          'Deal fetch request payload:',
          JSON.stringify(requestPayload)
        );
        console.log('Using GraphQL query:', queries.listAllDeals);

        const response = await API.graphql({
          query: queries.listAllDeals,
          variables: {
            request: JSON.stringify(requestPayload),
          },
        });

        // @ts-ignore
        const rawResponse = response.data.listAllDeals;
        console.log('Raw API response:', rawResponse);

        const parsedData = JSON.parse(rawResponse);
        console.log('Parsed deals data:', parsedData);

        const fetchedDeals = parsedData.deals || [];
        const canLoadMore = parsedData.canLoadMore || false;

        console.log(
          `Fetched ${fetchedDeals.length} deals, canLoadMore: ${canLoadMore}`
        );
        console.log('Raw canLoadMore value:', parsedData.canLoadMore);

        // Log the first deal to see its structure
        if (fetchedDeals.length > 0) {
          console.log(
            'First deal structure:',
            JSON.stringify(fetchedDeals[0], null, 2)
          );
          console.log('Deal properties:', Object.keys(fetchedDeals[0]));

          // Check if status property exists
          const hasStatus = fetchedDeals.every(
            (deal: any) => deal.status !== undefined
          );
          console.log('All deals have status property:', hasStatus);

          // Check if customerName property exists
          const hasCustomerName = fetchedDeals.every(
            (deal: any) => deal.customerName !== undefined
          );
          console.log('All deals have customerName property:', hasCustomerName);
        }

        // Process deals to add profile pictures
        const processedDeals = await Promise.all(
          fetchedDeals.map(async (deal: any) => {
            // Add profile pictures for setter and closer if available
            if (deal.setter) {
              deal.setter.profilePicture = await getUserAvatar(
                deal.setter.id,
                deal.setter.imageName
              );
            }

            if (deal.closer) {
              deal.closer.profilePicture = await getUserAvatar(
                deal.closer.id,
                deal.closer.imageName
              );
            }

            // Format dates
            deal.updatedAt = new Date(deal.updatedAt);
            deal.createdAt = new Date(deal.createdAt);

            return deal;
          })
        );

        if (currentOffset === 0) {
          setDeals(processedDeals);
        } else {
          setDeals((prevDeals) => [...prevDeals, ...processedDeals]);
        }

        setHasMore(canLoadMore);
        setOffset(currentOffset + fetchedDeals.length);
      } catch (err) {
        console.error('Error fetching deals:', err);
        setError(
          err instanceof Error ? err : new Error('Failed to fetch deals')
        );
      } finally {
        setIsLoading(false);
      }
    },
    [currentSearchString, limit, categoryId, stageId, assigneeId, sortOrder]
  );

  const fetchDeals = useCallback(
    async (search?: string) => {
      console.log(`fetchDeals called with search: "${search}"`);

      // If search is provided, use it; otherwise use the current search string
      const newSearchString =
        search !== undefined ? search : currentSearchString;

      // Only update the state if the search string has changed
      if (newSearchString !== currentSearchString) {
        console.log(
          `Updating search string from "${currentSearchString}" to "${newSearchString}"`
        );
        setCurrentSearchString(newSearchString);
      }

      // Reset offset and fetch data with the new search string
      setOffset(0);
      await fetchDealsData(newSearchString, 0);
    },
    [currentSearchString, fetchDealsData]
  );

  const loadMore = useCallback(async () => {
    if (!isLoading && hasMore) {
      console.log('Loading more deals from offset:', offset);
      await fetchDealsData(currentSearchString, offset);
    } else {
      console.log('Cannot load more:', { isLoading, hasMore, offset });
    }
  }, [isLoading, hasMore, currentSearchString, offset, fetchDealsData]);

  useEffect(() => {
    if (categoryId) {
      fetchDeals();
    }
  }, [categoryId, fetchDeals]);

  return {
    deals,
    isLoading,
    error,
    fetchDeals,
    hasMore,
    loadMore,
  };
};
