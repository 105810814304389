import {
  FormControl,
  FormHelperText,
  ListSubheader,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { camelCaseToTitleCase } from '../../utils/stringConversion';
import React from 'react';

interface BaseFlexField {
  name: string;
  label: string;
  type: string;
}

interface ContactFlexField extends BaseFlexField {
  type: 'Contact';
  contactFields: { [key: string]: string };
}

type FlexField = BaseFlexField | ContactFlexField;

type FlexFields = FlexField[];

interface DealMappingProps {
  formName: string;
  workingDealForms: {
    [key: string]: {
      dealMappingsDescription: string;
      dealMappingsName: string;
      flexFields: FlexFields;
    };
  };
  fieldName: 'dealMappingsName' | 'dealMappingsDescription';
  disableFormSave: string;
  invalidMappingField: string;
  handleMappingChange: (
    formName: string,
    fieldName: string,
    value: unknown
  ) => void;
  convertDealMappings: (mapping: string, flexFields: FlexFields) => string[];
}

const DealMapping: React.FC<DealMappingProps> = ({
  formName,
  workingDealForms,
  fieldName,
  disableFormSave,
  invalidMappingField,
  handleMappingChange,
  convertDealMappings,
}) => {
  return (
    <>
      <Typography style={{ fontSize: 'medium', fontWeight: 600 }}>
        {fieldName === 'dealMappingsName' ? 'Deal Name' : 'Deal Description'}
      </Typography>
      <FormControl fullWidth margin="normal">
        <Select
          multiple
          value={convertDealMappings(
            workingDealForms[formName][fieldName],
            workingDealForms[formName].flexFields
          )}
          onChange={(e) =>
            handleMappingChange(formName, fieldName, e.target.value)
          }
          fullWidth
          renderValue={(selected) => {
            const labels = selected.map((value) => {
              if (value.includes('.')) {
                const [fieldName, contactField] = value.split('.');
                const field = workingDealForms[formName].flexFields.find(
                  (f) => f.name === fieldName
                );
                if (field) {
                  return camelCaseToTitleCase(contactField);
                }
              } else {
                const field = workingDealForms[formName].flexFields.find(
                  (f) => f.name === value
                );
                if (field) {
                  return field.label || value;
                }
              }
              return value;
            });
            return labels.join(', ');
          }}
          //   margin="normal"
          className="square-select"
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200,
                backgroundColor: 'white',
              },
            },
          }}
        >
          {workingDealForms[formName].flexFields &&
            workingDealForms[formName].flexFields.map((field: FlexField) => {
              // Need to use the grouping for the contact fields
              if (field.type === 'Contact') {
                return [
                  <ListSubheader
                    key={`header-${field.name}`}
                    component={'div'}
                    disableSticky={true}
                    style={{
                      backgroundColor: '#f0f0f0',
                      fontWeight: 'bold',
                      padding: '8px 16px',
                    }}
                  >
                    {field.label}
                  </ListSubheader>,
                  ...Object.keys((field as ContactFlexField).contactFields).map(
                    (contactField) => (
                      <MenuItem
                        key={`${field.name}.${contactField}`}
                        value={`${field.name}.${contactField}`}
                        style={{ paddingLeft: '30px' }}
                      >
                        {camelCaseToTitleCase(contactField)}
                      </MenuItem>
                    )
                  ),
                ];
              }
              return (
                <MenuItem key={field.name} value={field.name}>
                  {field.label}
                </MenuItem>
              );
            })}
        </Select>
        {disableFormSave === fieldName && (
          <FormHelperText>
            Invalid Mapping Name Field: {invalidMappingField}
          </FormHelperText>
        )}
      </FormControl>
    </>
  );
};

export default DealMapping;
