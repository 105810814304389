import React, { useEffect, useState } from 'react';
import { jsPDF as JsPDF } from 'jspdf';
import { defaults, Chart, registerables } from 'chart.js/auto';
import { Line, Bar } from 'react-chartjs-2';
import { View, Text } from '@aws-amplify/ui-react';
import DownloadIcon from '@mui/icons-material/Download';
import { Box, Menu } from '@mui/material';
import './reports.css';
import { StyledMenuItem } from './StyledInputs';
import BulkDownloadModal from './BulkDownloadModal';

Chart.register(...registerables);
const verticalLinePlugin = {
  id: 'verticalLine',
  afterDraw: (chart, args, options) => {
    const { ctx, scales, chartArea } = chart;
    if (options.lineAt) {
      const x = scales.x.getPixelForValue(options.lineAt);
      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x, chartArea.top);
      ctx.lineTo(x, chartArea.bottom);
      ctx.lineWidth = options.lineWidth || 1;
      ctx.strokeStyle = options.lineColor || 'black';
      ctx.stroke();
      ctx.restore();
    }
  },
};

// Register the plugin
Chart.register(verticalLinePlugin);
defaults.maintainAspectRatio = false;
defaults.responsive = true;

defaults.plugins.title.display = true;
defaults.plugins.title.align = 'center';
defaults.plugins.title.font.size = 25;
defaults.plugins.title.color = 'black';

const colorArray = [
  '#551AC9',
  '#00C572',
  '#EF3D5D',
  '#438EF7',
  '#FFCA54',
  '#703DDD',
  '#3AC6E5',
  '#B41632',
  '#0A157B',
  '#FF9F43',
  '#17D870',
  '#D42645',
  '#01A579',
  '#350A7B',
  '#F98A27',
  '#13DCA6',
  '#173FA9',
  '#9B0620',
  '#2E6EEA',
  '#A267EE',
  '#00D2D3',
  '#9b59b6',
  '#3498db',
  '#2ecc71',
  '#f1c40f',
  '#e74c3c',
  '#34495e',
  '#95a5a6',
  '#d35400',
  '#16a085',
];

const optionsObject = (title, label) => ({
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        callback: function (val) {
          if (val % 1 === 0) return val;
        },
      },
    },
  },
  plugins: {
    title: {
      text: title,
    },
    verticalLine: {
      lineAt: label,
      lineColor: 'red',
      lineWidth: 2,
    },
  },
  elements: {
    line: {
      tension: 0.2,
    },
  },
  spanGaps: true,
});

export default function ReportsCharts(props) {
  const { reports, filters } = props;
  const [reportsList, setReportsList] = useState(null);
  const [teamColorMap, setTeamColorMap] = useState({});
  const [previousReportType, setPreviousReportType] = useState(null);
  const [bulkDownloadModalOpen, setBulkDownloadModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeReportId, setActiveReportId] = useState(null);

  console.log('REPORTS LIST: ', reportsList);
  useEffect(() => {
    // Check if report type has changed
    if (
      reports &&
      previousReportType &&
      reports.report_type !== previousReportType
    ) {
      // Clear the reports list when report type changes
      setReportsList(null);
      return;
    }

    // Update previous report type
    if (reports) {
      setPreviousReportType(reports.report_type);
    }

    if (reports) {
      const reportsData = Object.values(reports.reports);
      setReportsList(reportsData);

      // Create a consistent color mapping for all teams
      const allTeams = new Set();
      reportsData.forEach((report) => {
        report.data_sets.forEach((dataset) => {
          allTeams.add(dataset.label);
        });
      });

      // Sort team names alphabetically to ensure consistent ordering
      const sortedTeams = Array.from(allTeams).sort();

      // Create and store the color mapping
      const colorMapping = {};
      sortedTeams.forEach((teamName, index) => {
        colorMapping[teamName] = colorArray[index % colorArray.length];
      });

      setTeamColorMap(colorMapping);
    }
  }, [reports, previousReportType]);

  const createExportName = (report, format) => {
    let fileName = report.report_info.title.split(' ').join('_') + '_';
    if (reports.report_type !== 'hourly') {
      fileName += `${report.labels[0].split(' ')[1]}-${
        report.labels[report.labels.length - 1].split(' ')[1]
      }_`;
    }
    fileName += `report_${reports.org_id}.${format}`;
    return fileName;
  };

  // Add helper function to create a PDF chart image
  const createChartImage = async (
    canvas,
    chartType,
    chartData,
    chartOptions
  ) => {
    const ctx = canvas.getContext('2d');

    const chart = new Chart(ctx, {
      type: chartType,
      data: chartData,
      options: chartOptions,
    });

    // DO NOT REMOVE THIS LINE - the chart is generated and needs to render essentially we are taking a picture of the chart but if you don't wait then it won't look right
    await new Promise((resolve) => setTimeout(resolve, 700));

    const imageUrl = canvas.toDataURL('image/png');

    // Clean up
    chart.destroy();

    return {
      imageUrl,
      chart,
    };
  };

  // Add a helper function to add report metadata to PDFs
  const addReportMetadata = (pdf, margin) => {
    pdf.setFontSize(10);
    pdf.setTextColor(100, 100, 100);
    const today = new Date();
    const dateStr = today.toLocaleDateString();
    const timeStr = today.toLocaleTimeString();
    pdf.text(`Generated on ${dateStr} at ${timeStr}`, margin, margin);
    return margin + 10;
  };

  // Add this function to download a single chart as PDF
  const downloadChartAsPDF = async (report) => {
    console.log('Downloading chart as PDF:', report.report_info.title);

    try {
      // Create PDF document with single chart
      const pdf = new JsPDF();
      const pageWidth = pdf.internal.pageSize.getWidth();
      const margin = 10;

      // Create a div container for the charts
      const tempContainer = document.createElement('div');
      tempContainer.style.position = 'absolute';
      tempContainer.style.visibility = 'hidden';
      document.body.appendChild(tempContainer);

      // Add metadata and timestamp to PDF
      const newMargin = addReportMetadata(pdf, margin);

      // Add title
      pdf.setFontSize(18);
      pdf.text(report.report_info.title, pageWidth / 2, newMargin + 15, {
        align: 'center',
      });

      // Create canvas for chart
      const canvas = document.createElement('canvas');
      canvas.width = 800;
      canvas.height = 400;
      tempContainer.appendChild(canvas);

      // Get chart type from report info if available
      let chartType = 'line';
      if (
        report.report_info.chart_type &&
        report.report_info.chart_type === 'bar'
      ) {
        chartType = 'bar';
      }

      // Create datasets for the chart with proper colors
      const datasets = report.data_sets.map((dataset, index) => {
        return {
          ...dataset,
          backgroundColor:
            teamColorMap[dataset.label] ||
            colorArray[index % colorArray.length],
          borderColor:
            teamColorMap[dataset.label] ||
            colorArray[index % colorArray.length],
        };
      });

      // Create chart data
      const chartData = {
        labels: report.labels,
        datasets,
      };

      // IMPORTANT: Use await here to make sure we get the image data
      const { imageUrl } = await createChartImage(
        canvas,
        chartType,
        chartData,
        optionsObject(report.report_info.title, report.report_info.today_label)
      );

      // Add chart to PDF
      pdf.addImage(
        imageUrl,
        'PNG',
        margin,
        newMargin + 30,
        pageWidth - margin * 2,
        (pageWidth - margin * 2) * 0.6
      );

      // Save the PDF with a meaningful filename
      const filename = createExportName(report, 'pdf');
      pdf.save(filename);

      // Clean up
      tempContainer.remove();
    } catch (error) {
      console.error('Error generating PDF for single chart:', error);
    }
  };

  // Add a function to handle single CSV export (seems to be working but included for completeness)
  const downloadSingleCSV = (report) => {
    console.log('Downloading single report as CSV:', report.report_info.title);

    // Create CSV header
    let csvString = 'Date,Team,Value\r\n';

    // Add data for each team
    report.data_sets.forEach((dataset) => {
      const teamName = dataset.label;

      // Add each data point
      dataset.data.forEach((value, index) => {
        if (value !== null && value !== undefined) {
          const date = report.labels[index];
          csvString += `"${date}","${teamName}",${value}\r\n`;
        }
      });
    });

    // Create and download the CSV file
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', createExportName(report, 'csv'));
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const MyChartComponent = ({ report }) => {
    const chartRef = React.useRef(null);

    const chartData = {
      labels: report.labels,
      datasets: report.data_sets
        .sort((a, b) => a.label.localeCompare(b.label))
        .map((ds) => ({
          ...ds,
          backgroundColor: teamColorMap[ds.label] || colorArray[0],
          borderColor: teamColorMap[ds.label] || colorArray[0],
        })),
    };

    const chartOptions = optionsObject(`${report.report_info.title} Report`);
    const ChartComponent = report.labels.length > 1 ? Line : Bar;

    return (
      <ChartComponent
        id={report.report_info.id}
        ref={chartRef}
        data={chartData}
        options={chartOptions}
      />
    );
  };

  const handleClick = (e, reportId) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setActiveReportId(reportId);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setActiveReportId(null);
  };

  // Update the downloadAllChartsAsPDF function to handle comparison charts

  const downloadAllChartsAsPDF = async (
    customReports = reports,
    customColorMap = teamColorMap,
    customFilename = null
  ) => {
    const reportData = customReports || reports;
    const colorMap = customColorMap || teamColorMap;

    console.log('PDF Generation - Report data:', reportData);

    // Create a loading indicator element
    const loadingElement = document.createElement('div');
    loadingElement.style.position = 'fixed';
    loadingElement.style.top = '0';
    loadingElement.style.left = '0';
    loadingElement.style.width = '100%';
    loadingElement.style.height = '100%';
    loadingElement.style.backgroundColor = 'rgba(0,0,0,0.5)';
    loadingElement.style.zIndex = '9999';
    loadingElement.style.display = 'flex';
    loadingElement.style.justifyContent = 'center';
    loadingElement.style.alignItems = 'center';
    loadingElement.style.flexDirection = 'column';
    loadingElement.innerHTML = `
      <div style="width: 50px; height: 50px; border: 5px solid #FF6A00; border-top-color: transparent; border-radius: 50%; animation: spin 1s linear infinite;"></div>
      <div style="color: white; margin-top: 20px; font-size: 20px;">Generating PDF...</div>
    `;
    document.body.appendChild(loadingElement);

    // Add animation style
    const styleElement = document.createElement('style');
    styleElement.innerHTML = `
      @keyframes spin {
        to { transform: rotate(360deg); }
      }
    `;
    document.head.appendChild(styleElement);

    const pdf = new JsPDF();
    const pageWidth = pdf.internal.pageSize.getWidth();
    const margin = 10;

    // Create a div container for the charts
    const tempContainer = document.createElement('div');
    tempContainer.style.position = 'absolute';
    tempContainer.style.visibility = 'hidden';
    document.body.appendChild(tempContainer);

    // Safe text rendering function for the PDF
    const safeRenderText = (pdf, text, x, y, options = {}) => {
      try {
        if (text && typeof text === 'string') {
          pdf.text(text, x, y, options);
        } else if (text) {
          pdf.text(String(text), x, y, options);
        } else {
          console.warn('Attempted to render null or undefined text in PDF');
        }
      } catch (error) {
        console.error('Error rendering text in PDF:', error, {
          text,
          x,
          y,
          options,
        });
      }
    };

    // Helper function to add metadata and timestamp to PDF
    const addReportMetadata = (pdf, margin) => {
      pdf.setFontSize(10);
      pdf.setTextColor(100, 100, 100);
      const today = new Date();
      const dateStr = today.toLocaleDateString();
      const timeStr = today.toLocaleTimeString();
      safeRenderText(
        pdf,
        `Generated on ${dateStr} at ${timeStr}`,
        margin,
        margin
      );
      return margin + 10;
    };

    (async () => {
      try {
        if (reportData.report_type === 'team') {
          // Add timestamp and report info
          const newMargin = addReportMetadata(pdf, margin);
          pdf.setFontSize(22);
          safeRenderText(
            pdf,
            'Team KPI Reports',
            pageWidth / 2,
            newMargin + 10,
            {
              align: 'center',
            }
          );

          // Extract all unique teams from the filtered datasets
          // Only include non-comparison teams
          const allTeams = new Set();
          reportData.reports.forEach((report) => {
            report.data_sets.forEach((dataset) => {
              const teamName = dataset.label;
              // Don't add comparison labels to the regular team list
              if (
                !teamName.includes(' vs ') &&
                !teamName.includes(' vs. ') &&
                !teamName.includes(' versus ')
              ) {
                allTeams.add(teamName);
              }
            });
          });

          // Sort team names alphabetically for consistent ordering
          const sortedTeams = Array.from(allTeams).sort();
          console.log('Teams for individual charts:', sortedTeams);

          // PART 1: GENERATE COMPARISON CHARTS FIRST
          let hasComparisonCharts = false;
          let pageCount = 0;

          // Check if we have any comparison metadata in the reports
          for (const report of reportData.reports) {
            if (report.comparisons && report.comparisons.length > 0) {
              hasComparisonCharts = true;
              break;
            }
          }

          if (hasComparisonCharts) {
            // Add header for comparison section
            pdf.setFontSize(18);
            safeRenderText(
              pdf,
              'Team Comparisons',
              pageWidth / 2,
              margin + 30,
              {
                align: 'center',
              }
            );
            pageCount++;

            // Process each report to create multi-team comparison charts
            for (const report of reportData.reports) {
              if (!report.comparisons || report.comparisons.length === 0)
                continue;

              // Add a new page except for the first chart
              if (pageCount > 1) {
                pdf.addPage();
                addReportMetadata(pdf, margin);
              }
              pageCount++;

              // Get comparison metadata
              const comparison = report.comparisons[0]; // We only have one comparison per KPI now

              // Create title for this comparison
              pdf.setFontSize(16);
              safeRenderText(
                pdf,
                `${report.report_info.title} - All Teams Comparison`,
                pageWidth / 2,
                margin + 50,
                {
                  align: 'center',
                }
              );

              // Create canvas for the chart
              const canvas = document.createElement('canvas');
              canvas.width = 800;
              canvas.height = 400;
              tempContainer.appendChild(canvas);

              // Create datasets for all teams in this comparison
              const datasets = comparison.teams
                .map((teamName) => {
                  const dataset = report.data_sets.find(
                    (ds) => ds.label === teamName
                  );
                  if (!dataset) return null;

                  return {
                    ...dataset,
                    backgroundColor: colorMap[teamName] || colorArray[0],
                    borderColor: colorMap[teamName] || colorArray[0],
                  };
                })
                .filter(Boolean); // Remove any null entries

              // Create chart with all team datasets
              const chartData = {
                labels: report.labels,
                datasets,
              };

              // Use the helper instead of direct chart creation
              const { imageUrl } = await createChartImage(
                canvas,
                'line',
                chartData,
                optionsObject(
                  report.report_info.title,
                  report.report_info.today_label
                )
              );

              // Add chart to PDF
              pdf.addImage(
                imageUrl,
                'PNG',
                margin,
                margin + 50,
                pageWidth - margin * 2,
                (pageWidth - margin * 2) * 0.6
              );

              // Canvas is cleaned up in the helper function
              canvas.remove();
            }

            // Add a page break between comparison and individual charts
            pdf.addPage();
            addReportMetadata(pdf, margin);
            pdf.setFontSize(18);
            safeRenderText(
              pdf,
              'Individual Team Reports',
              pageWidth / 2,
              margin + 30,
              {
                align: 'center',
              }
            );
            pageCount = 0; // Reset page count for individual team section
          }

          // PART 2: GENERATE INDIVIDUAL TEAM CHARTS
          for (const teamName of sortedTeams) {
            // Add a new page except for the first team
            if (pageCount > 0) {
              pdf.addPage();
              addReportMetadata(pdf, margin);
            }
            pageCount++;

            // Add team title
            pdf.setFontSize(16);
            safeRenderText(
              pdf,
              `Team: ${teamName}`,
              pageWidth / 2,
              margin + 50,
              {
                align: 'center',
              }
            );

            // Track progress on the page
            let yPosition = margin + 50;
            let chartsAdded = 0;

            // Add each KPI chart for this team
            for (const report of reportData.reports) {
              // Find this team's data in the report
              const teamDataset = report.data_sets.find(
                (ds) => ds.label === teamName
              );

              // Skip if this team doesn't have data for this KPI
              if (!teamDataset) continue;

              // Add a new page if needed
              if (
                chartsAdded > 0 &&
                yPosition + 180 > pdf.internal.pageSize.getHeight()
              ) {
                pdf.addPage();
                addReportMetadata(pdf, margin);
                yPosition = margin + 30;
              }

              // Create canvas for the chart
              const canvas = document.createElement('canvas');
              canvas.width = 800;
              canvas.height = 400;
              tempContainer.appendChild(canvas);

              // Get chart type from report info if available
              let chartType = 'line';
              if (
                report.report_info.chart_type &&
                report.report_info.chart_type === 'bar'
              ) {
                chartType = 'bar';
              }

              // Create chart data for this team only
              const chartData = {
                labels: report.labels,
                datasets: [
                  {
                    ...teamDataset,
                    backgroundColor: colorMap[teamName] || colorArray[0],
                    borderColor: colorMap[teamName] || colorArray[0],
                  },
                ],
              };

              // Use the helper instead of direct chart creation
              const { imageUrl } = await createChartImage(
                canvas,
                chartType,
                chartData,
                optionsObject(
                  report.report_info.title,
                  report.report_info.today_label
                )
              );

              // Add chart to PDF
              pdf.addImage(
                imageUrl,
                'PNG',
                margin,
                yPosition + 10,
                pageWidth - margin * 2,
                (pageWidth - margin * 2) * 0.5
              );

              // Canvas is cleaned up in the helper function
              canvas.remove();

              // Update position for next chart
              yPosition += (pageWidth - margin * 2) * 0.5 + 40;
              chartsAdded++;
            }

            // Add message if no KPIs were found for this team
            if (chartsAdded === 0) {
              pdf.setFontSize(14);
              safeRenderText(
                pdf,
                'No KPI data available for this team',
                pageWidth / 2,
                yPosition,
                {
                  align: 'center',
                }
              );
            }
          }

          // Save the PDF
          const filename =
            customFilename || `Team_KPI_Reports_${reportData.org_id}.pdf`;
          pdf.save(filename);
        } else {
          // Org charts handling (unchanged)
        }
      } catch (error) {
        console.error('Error generating PDF:', error);
      } finally {
        if (tempContainer) {
          tempContainer.remove();
        }
        // Remove loading element
        document.body.removeChild(loadingElement);
        document.head.removeChild(styleElement);
      }
    })();
  };

  const downloadAllOrgChartsAsPDF = async (
    customReports = reports,
    customFilename = null
  ) => {
    const reportData = customReports || reports;

    // Create a loading indicator element
    const loadingElement = document.createElement('div');
    loadingElement.style.position = 'fixed';
    loadingElement.style.top = '0';
    loadingElement.style.left = '0';
    loadingElement.style.width = '100%';
    loadingElement.style.height = '100%';
    loadingElement.style.backgroundColor = 'rgba(0,0,0,0.5)';
    loadingElement.style.zIndex = '9999';
    loadingElement.style.display = 'flex';
    loadingElement.style.justifyContent = 'center';
    loadingElement.style.alignItems = 'center';
    loadingElement.style.flexDirection = 'column';
    loadingElement.innerHTML = `
      <div style="width: 50px; height: 50px; border: 5px solid #FF6A00; border-top-color: transparent; border-radius: 50%; animation: spin 1s linear infinite;"></div>
      <div style="color: white; margin-top: 20px; font-size: 20px;">Generating PDF...</div>
    `;
    document.body.appendChild(loadingElement);

    // Add animation style
    const styleElement = document.createElement('style');
    styleElement.innerHTML = `
      @keyframes spin {
        to { transform: rotate(360deg); }
      }
    `;
    document.head.appendChild(styleElement);

    const pdf = new JsPDF();
    const pageWidth = pdf.internal.pageSize.getWidth();
    const imgWidth = pageWidth - 40; // Adjusted for better margin
    const imgHeight = imgWidth * 0.6; // Maintain aspect ratio
    const margin = 20;

    // Create a div container for the charts
    const tempContainer = document.createElement('div');
    tempContainer.style.position = 'absolute';
    tempContainer.style.visibility = 'hidden';
    document.body.appendChild(tempContainer);

    try {
      // Add title and metadata
      addReportMetadata(pdf, margin);
      pdf.setFontSize(18);
      pdf.text('Organization KPI Reports', pageWidth / 2, margin + 15, {
        align: 'center',
      });

      // Process each report
      for (let i = 0; i < reportData.reports.length; i++) {
        const report = reportData.reports[i];

        // Add a new page for charts after the first one
        if (i > 0) {
          pdf.addPage();
          addReportMetadata(pdf, margin);
        }

        // Add chart title
        pdf.setFontSize(14);
        pdf.text(report.report_info.title, pageWidth / 2, margin + 25, {
          align: 'center',
        });

        // Create canvas for chart
        const canvas = document.createElement('canvas');
        canvas.width = 800;
        canvas.height = 400;
        tempContainer.appendChild(canvas);

        // Determine chart type from report
        let chartType = 'line';
        if (
          report.report_info.chart_type &&
          report.report_info.chart_type === 'bar'
        ) {
          chartType = 'bar';
        }

        // Prepare chart data
        const chartData = {
          labels: report.labels,
          datasets: report.data_sets.map((dataset, index) => ({
            ...dataset,
            backgroundColor: colorArray[index % colorArray.length],
            borderColor: colorArray[index % colorArray.length],
          })),
        };

        // Generate chart image
        const { imageUrl } = await createChartImage(
          canvas,
          chartType,
          chartData,
          optionsObject(
            report.report_info.title,
            report.report_info.today_label
          )
        );

        // Add image to PDF
        pdf.addImage(imageUrl, 'PNG', margin, margin + 35, imgWidth, imgHeight);

        // Clean up
        canvas.remove();
      }

      // Save the PDF
      const filename =
        customFilename || `Organization_KPI_Reports_${reportData.org_id}.pdf`;
      pdf.save(filename);
    } catch (error) {
      console.error('Error generating organization PDF:', error);
    } finally {
      // Clean up
      if (tempContainer) {
        tempContainer.remove();
      }
      // Remove loading element
      document.body.removeChild(loadingElement);
      document.head.removeChild(styleElement);
    }
  };

  if (!reportsList || reportsList.length === 0)
    return (
      <View style={{ width: '100%' }}>
        <Box
          style={{
            width: '100%',
            height: '30dvh',
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '5rem',
          }}
        >
          <Text style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>
            {'No data is available for this report'}
          </Text>
        </Box>
      </View>
    );
  return (
    <View style={{ width: '100%' }}>
      {reports &&
        (reports.report_type === 'team' || reports.report_type === 'org') &&
        reportsList &&
        reportsList.length > 0 && (
          <Box
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              margin: '10px 5px',
            }}
          >
            {/* Bulk Download button */}
            <Box
              className="download-all-button hov"
              onClick={() => setBulkDownloadModalOpen(true)}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '8px 16px',
                backgroundColor: '#FF6A00',
                color: 'white',
                borderRadius: '8px',
                fontWeight: 'bold',
                cursor: 'pointer',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                width: 'fit-content',
              }}
            >
              <DownloadIcon style={{ marginRight: '8px' }} />
              Bulk Download
            </Box>
          </Box>
        )}

      {/* Replace the CSV export modal with the bulk download modal */}
      {bulkDownloadModalOpen && (
        <BulkDownloadModal
          open={bulkDownloadModalOpen}
          onClose={() => setBulkDownloadModalOpen(false)}
          reports={reports}
          teamColorMap={teamColorMap}
          downloadAllChartsAsPDF={downloadAllChartsAsPDF}
          downloadAllOrgChartsAsPDF={downloadAllOrgChartsAsPDF}
          filters={filters}
        />
      )}

      <Box className="reports-container">
        {reportsList &&
          reportsList.map((report, i) => {
            return (
              <Box key={i} className="single-chart-container">
                <Box
                  onClick={(e) => handleClick(e, report.report_info.id)}
                  className="download-report-button hov"
                >
                  <DownloadIcon />
                  <Menu
                    id={`menu-${report.report_info.id}`}
                    anchorEl={
                      report.report_info.id === activeReportId ? anchorEl : null
                    }
                    open={report.report_info.id === activeReportId}
                    onClose={handleClose}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <StyledMenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        downloadSingleCSV(report);
                        handleClose();
                      }}
                    >
                      Export as CSV
                    </StyledMenuItem>
                    <StyledMenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        downloadChartAsPDF(report);
                        handleClose();
                      }}
                    >
                      Export as PDF
                    </StyledMenuItem>
                  </Menu>
                </Box>
                <MyChartComponent report={report} />
              </Box>
            );
          })}
      </Box>
    </View>
  );
}
