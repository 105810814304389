export const newFieldTemp = {
  name: '',
  default: '',
  minLength: 0,
  maxLength: 4192,
  minValue: 0,
  maxValue: 100000000,
  required: false,
  requiredDispositions: [],
  label: '',
  corectValue: '',
  showTogether: [],
  type: 'String',
  heading: '',
  values: [],
  displayField: true,
  pickerDisplayField: false,
  pinMappingField: '',
  hideLabel: false,
};

export const newFormTemplate = {
  availabiltyDisposition: '',
  availabiltyStage: '',
  dealMappingsDescription: 'Address',
  dealMappingsName: 'First Name, Last Name',
  displayFields: [],
  flexFields: [],
  label: 'Customer',
  pickerDisplayFields: [],
};

export const typeSelectOptions = [
  { label: 'String', value: 'String' },
  { label: 'Notes', value: 'Notes' },
  { label: 'Number', value: 'Number' },
  { label: 'Date', value: 'Date' },
  { label: 'Yes/No', value: 'Boolean' },
  { label: 'List', value: 'List' },
  { label: 'Money', value: 'Money' },
  { label: 'Power', value: 'Power' },
  { label: 'Order', value: 'Order Lines' },
  { label: 'Contact', value: 'Contact' },
  { label: 'Phone', value: 'Phone' },
  { label: 'Email', value: 'Email' },
  { label: 'Images', value: 'ImageList' },
  { label: 'Read Only', value: 'Read Only' },
];

export const doorFieldDataOptions = [
  { label: 'None', value: 'none' },
  { label: 'Name', value: 'name' },
  { label: 'First name', value: 'firstName' },
  { label: 'Last name', value: 'lastName' },
  { label: 'Address', value: 'address' },
  { label: 'Region (State)', value: 'region' },
  { label: 'City', value: 'city' },
  { label: 'Zip (postal Code)', value: 'postalCode' },
  { label: 'Email', value: 'email' },
  { label: 'Phone Number', value: 'phone' },
  { label: 'Cell Phone', value: 'cellPhone' },
];

export const flexAttributesTemp = {
  config: {
    deal: {
      label: 'Customer',
      flexFields: [],
      displayFields: [],
      pickerDisplayFields: [],
      dealMappingsName: '',
      dealMappingsDescription: '',
      availabiltyStage: '',
      availabiltyDisposition: '',
      dealMappingsValues: '',
    },
  },
};
export const newCRMTemp = (orgID, flexAttributes) => ({
  orgID,
  title: '',
  iconName: '',
  iconType: '',
  iconColor: '',
  flexAttributes: JSON.stringify(flexAttributes),
  isDefault: false,
  isDeleted: false,
  enablePindrop: true,
  enableDeal: true,
});

export const calendarAppointmentStatusOptions = [
  'scheduled',
  'rescheduled',
  'cancelled',
  'completed',
  'pending',
];

export const newDealStageTemp = (orgID, categoryID, newID, sequence = 1) => ({
  id: newID,
  orgID,
  title: '',
  description: '',
  userSelectable: true,
  changeToDispositionID: null,
  action: '',
  actionPastTense: '',
  measure: '',
  measurePlural: '',
  valueUnit: '',
  valueUnitPlural: '',
  countUnit: '',
  countUnitPlural: '',
  iconName: '',
  iconType: '',
  iconColor: '',
  flexAttributes: null,
  sequence, // Assuming sequence starts from 1
  winProbability: 0,
  pinColor: '',
  icon: '',
  isMapDefault: false,
  isDisplay: false,
  isActive: false,
  isNoDealDefault: false,
  requireCloser: false,
  sendToCRM: false,
  calendarAppointmentStatus: '',
  categoryID,
  imageName: '',
  imageType: '',
  createdBy: '',
  lastUpdatedBy: '',
  createdAt: null,
  updatedAt: null,
  isDeleted: false,
});

export const getPinsDict = () => {
  // const pinsList = [
  //   'Seed-Appointment',
  //   'Seed-Call-Back',
  //   'Seed-Come-Back',
  //   'Seed-Credit-Failed',
  //   'Seed-Current-Customer',
  //   'Seed-DNK2',
  //   'Seed-Doesnt-Qualify',
  //   'Seed-Follow-Up-UPS2',
  //   'Seed-Full-Pitch-Completed',
  //   'Seed-Go-Back',
  //   'Seed-Good-Look',
  //   'Seed-HQ-Lead2',
  //   'Seed-Half-Pitch-Completed',
  //   'Seed-Has-Solar',
  //   'Seed-Home-Owner',
  //   'Seed-Interested',
  //   'Seed-Language-Barrier',
  //   'Seed-Mild-Interested',
  //   'Seed-Moving',
  //   'Seed-New-Referral',
  //   'Seed-No-Alarm',
  //   'Seed-No-Appointment',
  //   'Seed-No-Soliciting',
  //   'Seed-Not-At-Home',
  //   'Seed-Not-Home-AM',
  //   'Seed-Not-Home-Eve',
  //   'Seed-Not-Home-PM',
  //   'Seed-Not-Home-UPS2',
  //   'Seed-Not-Home',
  //   'Seed-Not-Interested-PM-UPS2',
  //   'Seed-Not-Interested',
  //   'Seed-Not-interested',
  //   'Seed-Old-Referral',
  //   'Seed-Old',
  //   'Seed-One-Legged-Presentation',
  //   'Seed-Referral',
  //   'Seed-Renter',
  //   'Seed-Shading',
  //   'Seed-Solar-Home-Competitor',
  //   'Seed-Sold',
  //   'Seed-Talk-Completed',
  //   'Seed-Vacant-Lot',
  //   'Seed-doesnt-qualify',
  //   // new addition
  //   'Seed-Call-Back-2',
  //   'Seed-Not-Home-4',
  //   'Seed-Not-Home-3',
  //   'Seed-Renter-2',
  //   'Seed-Matt-E-Leads',
  //   'Seed-Other',
  //   'Seed-Not-Home-2',
  //   'Seed-Go-Back-2',
  //   'Seed-Not-Interested-Full-Pitch',
  //   'Seed-Not-Interested',
  //   'Seed-Not-Interested-No pitch',
  //   'Seed-Not-Home-1',
  //   'Seed-Moving-2',
  //   'Seed-No-Knock-List',
  //   'Seed-Bad-Roof',
  //   'Seed-Appointment-Set',
  //   'Seed-Perfect-Packet',
  //   'Seed-No-Soliciting-2',
  //   'Seed-Other-Solar-Customer',
  //   'Seed-Previous-Set-Appointment',
  //   'Seed-Failed-Credit2',
  // ];
  const pinsList = [
    '$',
    'Appointment',
    'BSS-App-Set',
    'Bad-Roof',
    'Call-Back',
    'Canceled',
    'Consultation',
    'Contract-Signed',
    'Current-Customer',
    'DNK',
    'DQ',
    'Do-Not-Knock',
    "Doesn't-Qualify",
    'Failed-Credit',
    'Follow-Up',
    'For-Sale',
    'Go-Back',
    'Gold-Pin',
    'Good-Look',
    'HQ-Lead',
    'Has-Solar',
    'Has-Solar-And-Battery',
    'Home-Owner',
    'Interested',
    'Language-Barrier',
    'Mild-Interested',
    'Moving',
    'New-Not-Interested',
    'No-Alarm',
    'No-Answer',
    'No-Appointment',
    'No-Show',
    'No-Soliciting',
    'Not-At-Home',
    'Not-Interested',
    'Old',
    'One-Legged-Presentation',
    'Perfect-Packet',
    'Previous-Set-Appointment',
    'Question',
    'Referral',
    'Renter',
    'SS-Appt',
    // 'Seed-transparent',
    'Shading',
    'Solar-Home-Competitor',
    'Sold',
    'Sold-2',
    'Task-Completed',
    'Thumbs-Down',
    'Thumbs-Up',
    'Vacant-Lot',
  ];
  const s3Prefix =
    'https://lyferize-public-statics.s3.us-west-2.amazonaws.com/mapmarkers/paiv/';

  // return pinsList.reduce((acc, pin) => {
  //   acc[pin] = `${s3Prefix}${pin}-button.png`;
  //   return acc;
  // }, {});
  return pinsList.reduce((acc, pin) => {
    const sanitizedPin = pin.replace(/'/g, '%E2%80%99');
    acc[pin] = `${s3Prefix}${sanitizedPin}-button.png`;
    return acc;
  }, {});
};
// %E2%80%99

export const newEmptyPin = (newID, orgID, categoryID) => ({
  id: newID,
  orgID,
  categoryID,
  title: '',
  formName: '',
  stageID: '',
  isSetupDeal: false,
  requireDate: false,
  deleteType: 'always',
  order: '',
  iconName: '',
  iconType: '',
  iconColor: '',
  createdBy: '',
  lastUpdatedBy: '',
  createdAt: '',
  updatedAt: '',
  isDeleted: false,
});
