import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import NavBarView from './NavBar.view';
import { useSelector, useDispatch } from 'react-redux';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { resetUsersStateAction } from '../../store/reducers/users';
import { resetRewardsStateAction } from '../../store/reducers/rewards';
import { resetDealsStateAction } from '../../store/reducers/deals';
import { useCurrentUserStore } from '../../store/zustand/useCurrentUserStore';
import { useQueryClient } from '@tanstack/react-query';

const NavBarContainer: React.FC = () => {
  const { signOut } = useAuthenticator((context) => [context.signOut]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryClient = useQueryClient();
  const avatar = useSelector((state: any) => state.avatar.value);
  const userProfileAvatar = avatar || '/images/default-avatar.png';
  // const [isProfileDropdownOpen, setProfileDropdownOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>(location.pathname);

  // const handleProfileClick = () => {
  //   setProfileDropdownOpen(!isProfileDropdownOpen);
  // };

  const { resetStore } = useCurrentUserStore();

  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleNavigate = (path: string) => {
    if (path === '/signout') {
      signOut();
      return;
    }

    setActiveTab(path);
    // setProfileDropdownOpen(false);
    setAnchorEl(null);
    setIsMobileMenuOpen(false);

    navigate(path);
  };

  const handleSignout = () => {
    signOut();
    dispatch(resetUsersStateAction());
    dispatch(resetRewardsStateAction());
    dispatch(resetDealsStateAction());
    // current user store needs to be reset on sign out for keeping permissions updated
    // Invalidate users and teams queries on logout
    queryClient.invalidateQueries({ queryKey: ['users'] });
    queryClient.invalidateQueries({ queryKey: ['teams'] });
    resetStore();
    navigate('/');
  };

  useEffect(() => {
    setActiveTab(location.pathname);
  }, [location.pathname]);

  return (
    <NavBarView
      activeTab={activeTab}
      userProfileAvatar={userProfileAvatar}
      // isProfileDropdownOpen={isProfileDropdownOpen}
      // handleProfileClick={handleProfileClick}
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
      handleNavigate={handleNavigate}
      // handleLeaderboardsMenuToggle={handleLeaderboardsMenuToggle}
      handleMobileMenuToggle={handleMobileMenuToggle}
      handleSignout={handleSignout}
      isMobileMenuOpen={isMobileMenuOpen}
    />
  );
};

export default NavBarContainer;
