import React, { useEffect, useState } from 'react';
import { View, Text } from '@aws-amplify/ui-react';
import {
  Box,
  List,
  CircularProgress,
  ListItem,
  Snackbar,
  Button,
  Typography,
  Switch,
} from '@mui/material';
import { API } from 'aws-amplify';
import gql from 'graphql-tag';
import './kpis.css';
import CreateGoalDefinition from './CreateGoalDefinition';

export default function KpisConfig(props) {
  const { orgID } = props;
  const [KPIRules, setKPIRules] = useState([]);
  const [rulesProp, setRulesProp] = useState(null);
  const [orgRuleIds, setOrgRuleIds] = useState(null);
  const [snackbarObj, setSnackbarObj] = useState({ open: false, message: '' });
  const [currentRuleID, setCurrentRuleID] = useState(null);
  const [openEditor, setOpenEditor] = useState(false);
  const [invalidRuleError, setInvalidRuleError] = useState('');

  async function fetchKPIGoals() {
    const myQuery = gql`
      query MyQuery($orgID: ID!, $nextToken: String = null) {
        listKPIRules(
          filter: { orgID: { eq: $orgID }, isDeleted: { eq: false } }
          limit: 10000
          nextToken: $nextToken
        ) {
          nextToken
          items {
            id
            title
            abbreviation
            order
            action
            sourceType
            triggerID
            format
            formula
            isHidden
            categoryID
            dimensions
            isHidden
            stage {
              title
            }
            disposition {
              title
            }
            goals {
              items {
                id
              }
            }
          }
        }
      }
    `;
    let nextToken = null;
    const KPIRules = [];
    do {
      const listKPIRulesRet = await API.graphql({
        query: myQuery,
        variables: { orgID, nextToken },
      });
      KPIRules.push(...listKPIRulesRet.data.listKPIRules.items);
      nextToken = listKPIRulesRet.data.listKPIRules.nextToken;
    } while (nextToken !== null);

    const myCategoryQuery = gql`
      query MyQuery($orgID: ID!) {
        listStageCategorys(
          filter: { orgID: { eq: $orgID }, isDeleted: { eq: false } }
          limit: 10000
        ) {
          items {
            id
            title
            flexAttributes
          }
        }
      }
    `;
    const listStageCategorysRet = await API.graphql({
      query: myCategoryQuery,
      variables: { orgID },
    });
    const categories = listStageCategorysRet.data.listStageCategorys.items;
    const flexAttTemp = {};
    const fields = {};
    for (const category of categories) {
      if (!category.doNotShow) {
        flexAttTemp[category.id] = category.title;
        fields[category.id] = JSON.parse(
          category.flexAttributes
        ).config.deal.flexFields;
      }
    }

    const sortedKPIRules = KPIRules.sort((a, b) => {
      if (a.sourceType.toLowerCase() < b.sourceType.toLowerCase()) return -1;
      if (a.sourceType.toLowerCase() > b.sourceType.toLowerCase()) return 1;
      return 0;
    });
    setRulesProp(() => sortedKPIRules);
    const KPIRuleIds = sortedKPIRules.map((KPIRule) => KPIRule.id);
    setOrgRuleIds(KPIRuleIds);
    let lastSourceType = '';
    const groupedKPIRules = [];
    for (const KPIRule of sortedKPIRules) {
      if (KPIRule.sourceType !== lastSourceType) {
        let groupTitle;
        if (KPIRule.sourceType === 'contact_type') {
          groupTitle = 'Contact Type';
        } else if (KPIRule.sourceType === 'disposition') {
          groupTitle = 'Disposition';
        } else if (KPIRule.sourceType === 'stage') {
          groupTitle = 'Deal Stage';
        } else if (KPIRule.sourceType === 'flexfield') {
          groupTitle = 'Fields';
        } else if (KPIRule.sourceType === 'manual') {
          groupTitle = 'Manual';
        } else if (KPIRule.sourceType === 'rule') {
          groupTitle = 'Rule';
        }
        let ff = null;
        let field = null;
        if (KPIRule.sourceType === 'flexfield') {
          ff = flexAttTemp[KPIRule.categoryID];
          field = fields[KPIRule.categoryID][0].label;
          console.log(KPIRule, field);
        }
        groupedKPIRules[groupedKPIRules.length] = {
          title: groupTitle,
          abbreviation: KPIRule.abbreviation,
          count: KPIRule.goals.items.length,
          rules: [],
          category: ff,
          field,
        };
        lastSourceType = KPIRule.sourceType;
      }
      groupedKPIRules[groupedKPIRules.length - 1].rules[
        groupedKPIRules[groupedKPIRules.length - 1].rules.length
      ] = KPIRule;
    }
    setKPIRules(groupedKPIRules);
  }

  const editRule = (e) => {
    e.stopPropagation();
    setCurrentRuleID(e.currentTarget.getAttribute('name'));
    setOpenEditor(true);
  };
  const createNew = () => {
    setCurrentRuleID(() => null);
    setOpenEditor(true);
  };

  const snackbarMessage = (message) => {
    setSnackbarObj(() => ({
      open: true,
      message,
    }));
    setTimeout(() => {
      setSnackbarObj(() => ({
        open: false,
        message: '',
      }));
    }, 3000);
  };

  useEffect(() => {
    fetchKPIGoals();
  }, []);

  const displayFormula = (formula) => {
    let invalidFormula = false;
    const ruleRegex = /@rule\(([0-9a-fA-F-]{36})\)/g;
    const readableFormula = formula.replace(ruleRegex, (match, uuid) => {
      const rule = rulesProp.find((el) => el.id === uuid);
      if (!rule) invalidFormula = true;
      const title = rule ? rule.title : 'unknown';

      return `@rule(${title})`;
    });

    return (
      <Text style={{ color: invalidFormula ? 'red' : 'gray', margin: '2px 0' }}>
        {readableFormula}
      </Text>
    );
  };

  if (!KPIRules.length) return <CircularProgress color="inherit" />;

  return (
    <View>
      {openEditor && (
        <CreateGoalDefinition
          open={openEditor}
          closer={setOpenEditor}
          orgID={orgID}
          ruleID={currentRuleID}
          reloadKPIs={fetchKPIGoals}
          snackbarMessage={snackbarMessage}
          rulesProp={rulesProp}
          orgRuleIds={orgRuleIds}
          invalidRuleError={invalidRuleError}
          setInvalidRuleError={setInvalidRuleError}
        />
      )}
      <Box id="title-box">
        <Text
          style={{
            fontWeight: 'bold',
            padding: '0px',
            fontSize: '2rem',
          }}
        >{`KPI's Configuration`}</Text>
        <Button variant="contained" onClick={createNew}>
          Create
        </Button>
      </Box>
      <List id="kpi-list">
        {KPIRules.map((el, i) => {
          // Sort the rules by each field by order (if no order set then it will appear at the top):
          el.rules = el.rules.sort((ruleA, ruleB) => ruleA.order - ruleB.order);
          console.log(el);
          return (
            <Box key={i}>
              <ListItem
                key={`${el.title}-${i}`}
                style={
                  i === 0
                    ? {
                        borderTopLeftRadius: '15px',
                        borderTopRightRadius: '15px',
                      }
                    : {}
                }
                className="label-list-item"
              >
                <Text
                  style={{
                    fontWeight: 'bold',
                    fontSize: '1.5rem',
                    width: '100%',
                    margin: '0',
                  }}
                >
                  {el.title}
                </Text>
              </ListItem>
              {el.rules.map((rule) => {
                // Check if the rule is in the orgRuleIds array so user can be notified if there's a rule that isn't associated with the org
                const isNonOrgRule = !orgRuleIds.includes(rule.id);
                return (
                  <ListItem
                    key={rule.id}
                    name={rule.id}
                    // style={{ borderBottom: '1px solid lightgray' }}
                    className="rule-list-item"
                    onClick={editRule}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      gap={5}
                      width={'100%'}
                      flexWrap={'wrap'}
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        gap={1}
                        style={{ width: '350px' }}
                      >
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-between"
                        >
                          <Text
                            style={{
                              color:
                                isNonOrgRule || invalidRuleError !== ''
                                  ? 'red'
                                  : 'black',
                              fontWeight: 'bold',
                              fontSize: '1.2rem',
                              marginBottom: '3px',
                              marginRight: '8px',
                            }}
                          >
                            {`${rule.title} (${rule.abbreviation})`}
                          </Text>
                          <Box
                            display="flex"
                            flexDirection="row"
                            style={{ minWidth: '75px' }}
                          >
                            {rule.order ? (
                              <Text color="gray">{`order: ${rule.order}`}</Text>
                            ) : (
                              <Text color="lightgray">order not set</Text>
                            )}
                          </Box>
                        </Box>

                        {isNonOrgRule && (
                          <Box display="flex" gap={1}>
                            <Typography style={{ color: 'red' }}>
                              {`ERROR: This rule is not properly associated with this org,
                        please delete it and recreate it!`}
                            </Typography>
                          </Box>
                        )}
                        {invalidRuleError !== '' && (
                          <Box display="flex" gap={1}>
                            <Typography style={{ color: 'red' }}>
                              ERROR: {invalidRuleError}
                            </Typography>
                          </Box>
                        )}

                        {displayFormula(rule.formula)}
                      </Box>
                      {/* Trigger */}
                      <Box display="flex">
                        <Typography
                          style={{
                            fontSize: 'medium',
                            color: 'gray',
                            fontWeight: 'bold',
                            display: 'inline',
                          }}
                        >
                          Trigger:
                        </Typography>
                        <Typography
                          style={{
                            fontSize: 'medium',
                            color: 'gray',
                            fontWeight: 'normal',
                            marginLeft: '4px',
                          }}
                        >
                          {el.title}
                        </Typography>
                      </Box>
                      {rule.sourceType === 'disposition' && (
                        <Box display="flex" gap={1}>
                          <Typography
                            style={{
                              fontSize: 'medium',
                              color: 'gray',
                              fontWeight: 'bold',
                            }}
                          >
                            Disposition:
                          </Typography>
                          <Typography
                            component="span"
                            style={{
                              fontSize: 'medium',
                              color: 'gray',
                              marginLeft: '4px',
                              fontWeight: 'normal',
                            }}
                          >
                            {rule.disposition?.title}
                          </Typography>
                        </Box>
                      )}
                      {rule.sourceType === 'flexfield' && (
                        <>
                          <Box display="flex" gap={1}>
                            <Typography
                              style={{
                                fontSize: 'medium',
                                color: 'gray',
                                fontWeight: 'bold',
                              }}
                            >
                              CRM Category:
                            </Typography>
                            <Typography
                              component="span"
                              style={{
                                fontSize: 'medium',
                                color: 'gray',
                                marginLeft: '4px',
                                fontWeight: 'normal',
                              }}
                            >
                              {el.category}
                            </Typography>
                          </Box>
                          <Box display="flex" gap={1}>
                            <Typography
                              style={{
                                fontSize: 'medium',
                                color: 'gray',
                                fontWeight: 'bold',
                              }}
                            >
                              Field:
                            </Typography>
                            <Typography
                              component="span"
                              style={{
                                fontSize: 'medium',
                                color: 'gray',
                                marginLeft: '4px',
                                fontWeight: 'normal',
                              }}
                            >
                              {el.field}
                            </Typography>
                          </Box>
                        </>
                      )}
                      {rule.sourceType === 'stage' && (
                        <>
                          <Box display="flex" gap={1}>
                            <Typography
                              style={{
                                fontSize: 'medium',
                                color: 'gray',
                                fontWeight: 'bold',
                              }}
                            >
                              CRM Category:
                              <Typography
                                component="span"
                                style={{
                                  fontSize: 'medium',
                                  color: 'gray',
                                  marginLeft: '4px',
                                  fontWeight: 'normal',
                                }}
                              >
                                {el.category}
                              </Typography>
                            </Typography>
                          </Box>
                          <Box display="flex" gap={1}>
                            {el.categoryID !== '' && (
                              <>
                                <Typography
                                  style={{
                                    fontSize: 'medium',
                                    color: 'gray',
                                    fontWeight: 'bold',
                                    display: 'inline',
                                  }}
                                >
                                  Deal Stage:
                                </Typography>
                                <Typography
                                  // component="span"
                                  style={{
                                    fontSize: 'medium',
                                    color: 'gray',
                                    marginLeft: '4px',
                                    fontWeight: 'normal',
                                  }}
                                >
                                  {rule.stage.title}
                                </Typography>
                              </>
                            )}
                          </Box>
                        </>
                      )}
                      {/* Format */}
                      <Box display="flex" gap={1}>
                        <Typography
                          style={{
                            fontSize: 'medium',
                            color: 'gray',
                            fontWeight: 'bold',
                          }}
                        >
                          Display Format:
                        </Typography>
                        <Typography
                          component="span"
                          style={{
                            fontSize: 'medium',
                            color: 'gray',
                            marginLeft: '4px',
                            fontWeight: 'normal',
                          }}
                        >
                          {rule.format}
                        </Typography>
                      </Box>
                      {/* Hide from User */}
                      <Box display="flex" alignItems="center" gap={1}>
                        <Typography
                          style={{
                            fontSize: 'medium',
                            color: 'gray',
                            fontWeight: 'bold',
                          }}
                        >
                          Hide from Users:
                        </Typography>
                        <Switch checked={rule.isHidden} />
                      </Box>
                    </Box>
                  </ListItem>
                );
              })}
            </Box>
          );
        })}
      </List>
      <Snackbar
        open={snackbarObj.open}
        message={snackbarObj.message}
        severity="success"
      />
    </View>
  );
}
