import React, { useEffect, useState, useMemo } from 'react';
import { Button, SearchField, Text, TextField } from '@aws-amplify/ui-react';
import {
  Box,
  Table as MuiTable,
  TableBody,
  TableContainer,
  TableHead,
  Modal,
  Checkbox,
} from '@mui/material';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
} from '@tanstack/react-table';
import {
  TitleTableCell,
  UsersTableCell,
  UsersTableRow,
} from '../users/TableComponents';
import gql from 'graphql-tag';
import { API } from 'aws-amplify';
import {
  getIntegrationXref,
  listIntegrationXrefs,
} from '../../../graphql/queries';

/**
 * Retrieves feature flags from IntegrationXref table
 * @returns {Promise<string[]>} Array of feature flag names
 */
export async function getFeatureFlags() {
  try {
    // Use the getIntegrationXref query with explicit id parameter
    const response = await API.graphql({
      query: getIntegrationXref,
      variables: {
        id: 'featureFlags', // Make sure this is a string, not null or undefined
      },
    });

    if (response.data?.getIntegrationXref?.mappingJSON) {
      try {
        // Parse the mappingJSON to get the array of feature flags
        const parsedData = JSON.parse(
          response.data.getIntegrationXref.mappingJSON
        );
        return Array.isArray(parsedData) ? parsedData : [];
      } catch (parseError) {
        console.error('Error parsing mappingJSON:', parseError);
        return [];
      }
    }

    // Fallback to listIntegrationXrefs if getIntegrationXref fails
    const listResponse = await API.graphql({
      query: listIntegrationXrefs,
      variables: {
        filter: { id: { eq: 'featureFlags' } },
      },
    });

    const items = listResponse.data?.listIntegrationXrefs?.items || [];
    if (items.length > 0 && items[0].mappingJSON) {
      try {
        // Parse the mappingJSON to get the array of feature flags
        const parsedData = JSON.parse(items[0].mappingJSON);
        return Array.isArray(parsedData) ? parsedData : [];
      } catch (parseError) {
        console.error('Error parsing mappingJSON:', parseError);
      }
    }

    // Return empty array if no data found
    return [];
  } catch (error) {
    console.error('Error fetching feature flags:', error);
    return [];
  }
}

export default function FeaturesTable({ orgsList }) {
  const [checkedOrgs, setCheckedOrgs] = useState({});
  const [currentFeature, setCurrentFeature] = useState(null);
  const [orgsDict, setOrgsDict] = useState({});
  const [searchString, setSearchString] = useState('');
  const [filteredOrgsList, setFilteredOrgsList] = useState(() => orgsList);
  const [officialFeatureFlags, setOfficialFeatureFlags] = useState([]);

  useEffect(() => {
    const init = async () => {
      const featuresFlagObj = {};
      const checkedListTemp = {};
      const orgsDictTemp = {};

      // Get official feature flags from the getFeatureFlags function
      const featureFlags = await getFeatureFlags();
      const sortedFeatureFlags = featureFlags.sort();

      // Store the official feature flags list
      setOfficialFeatureFlags(sortedFeatureFlags);

      orgsList.forEach((org) => {
        checkedListTemp[org.id] = false;
        orgsDictTemp[org.id] = org;

        // Normalize feature flags for this org if they exist
        if (org.featureFlags) {
          // Create normalized copy of feature flags
          if (!orgsDictTemp[org.id].normalizedFeatureFlags) {
            orgsDictTemp[org.id].normalizedFeatureFlags = {};
          }

          // Only process feature flags that exist in the official list
          sortedFeatureFlags.forEach((ff) => {
            // Get the raw feature flag value
            const rawValue = org.featureFlags[ff];

            // Normalize the value based on our rules
            let normalizedValue = null; // Default to disabled

            if (
              Array.isArray(rawValue) &&
              rawValue.length > 0 &&
              rawValue[0] !== ''
            ) {
              // Valid array of emails
              normalizedValue = rawValue;
            } else if (rawValue === 'everyone') {
              // Valid "everyone" value
              normalizedValue = ['everyone'];
            } else if (
              rawValue !== undefined &&
              rawValue.length > 0 &&
              rawValue[0] !== ''
            ) {
              // Handle case where it might be a string instead of array
              normalizedValue = [rawValue];
            }
            // All other cases (including empty string) default to null (disabled)

            // Store the normalized value
            orgsDictTemp[org.id].normalizedFeatureFlags[ff] = normalizedValue;

            // Add to the feature flags object for the table
            if (!featuresFlagObj[ff]) {
              featuresFlagObj[ff] = { [org.id]: normalizedValue };
            } else {
              featuresFlagObj[ff][org.id] = normalizedValue;
            }
          });
        }
      });

      setOrgsDict(orgsDictTemp);
      setCheckedOrgs(checkedListTemp);
    };
    init();
  }, [orgsList]);

  useEffect(() => {
    filterSearch(searchString);
  }, [searchString]);

  const filterSearch = (searchString) => {
    setFilteredOrgsList(
      orgsList.filter((el) => el.id.includes(searchString.toLowerCase()))
    );
  };

  const doUpdates = async (action, orgs, email) => {
    const myMutation = gql`
      mutation UpdateOrg($input: UpdateOrgInput!) {
        updateOrg(input: $input) {
          id
          configParms
        }
      }
    `;

    for (const org of orgs) {
      const newConfigParms = orgsDict[org].configParms;

      if (action === 'enable') {
        newConfigParms.featureFlags[currentFeature] = ['everyone'];
      } else if (
        action === 'disable' &&
        newConfigParms.featureFlags[currentFeature]
      ) {
        delete newConfigParms.featureFlags[currentFeature];
      } else if (action === 'email') {
        newConfigParms.featureFlags[currentFeature] = email;
      }

      try {
        await API.graphql({
          query: myMutation,
          variables: {
            input: {
              id: org,
              configParms: JSON.stringify(newConfigParms),
            },
          },
        });

        // Update the normalized values in the local state
        setOrgsDict((prev) => {
          const updated = { ...prev };

          if (!updated[org].normalizedFeatureFlags) {
            updated[org].normalizedFeatureFlags = {};
          }

          if (action === 'enable') {
            updated[org].normalizedFeatureFlags[currentFeature] = ['everyone'];
          } else if (action === 'disable') {
            updated[org].normalizedFeatureFlags[currentFeature] = null;
          } else if (action === 'email') {
            updated[org].normalizedFeatureFlags[currentFeature] = email;
          }

          return updated;
        });
      } catch (err) {
        console.log('ERROR--', err);
      }
    }

    setCurrentFeature(null);
  };

  const headerColumn = useMemo(
    () => [
      {
        header: () => 'Org',
        accessorKey: 'id',
        cell: ({ row }) => {
          const { id } = row.original;
          return (
            <Box
              onClick={() => setCheckedOrgs((p) => ({ ...p, [id]: !p[id] }))}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Checkbox
                sx={{
                  color: '#FF6A00',
                  '&.Mui-checked': {
                    color: '#FF6A00',
                  },
                }}
                checked={Boolean(checkedOrgs[id])}
                onClick={(e) => e.stopPropagation()}
                onChange={() => setCheckedOrgs((p) => ({ ...p, [id]: !p[id] }))}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
              <Text
                style={{
                  fontWeight: '500',
                  fontSize: '1.2rem',
                }}
              >
                {id}
              </Text>
            </Box>
          );
        },
      },
    ],
    [checkedOrgs]
  );
  const featueFlagsColumns = useMemo(
    () => [
      ...officialFeatureFlags.map((ff) => ({
        header: ff[0].toUpperCase() + ff.slice(1),
        accessorKey: ff,
        cell: ({ row }) => {
          const featureFlagValue = row.original.normalizedFeatureFlags?.[ff];

          const handleClick = () => {
            setCheckedOrgs((p) => ({ ...p, [row.original.id]: true }));
            setCurrentFeature(ff);
          };

          if (!featureFlagValue) {
            return (
              <Box onClick={handleClick} style={{ color: 'gray' }}>
                Disabled
              </Box>
            );
          } else {
            return (
              <Box
                onClick={handleClick}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  maxWidth: '35ch',
                  maxHeight: '10ch',
                  overflowX: 'auto',
                }}
              >
                <Text
                  style={{
                    color:
                      featureFlagValue[0] === 'everyone' ? 'green' : 'blue',
                  }}
                >
                  {featureFlagValue[0] === 'everyone'
                    ? 'Everyone'
                    : featureFlagValue.join(', ')}
                </Text>
              </Box>
            );
          }
        },
      })),
    ],
    [officialFeatureFlags, orgsDict]
  );
  return (
    <Box>
      <Box>
        <Text fontWeight="bold">
          {
            'Click on an org featureFlag cell or select multiple orgs and click the feature flag column header to update '
          }
        </Text>
        <Text>
          {
            '* updating specific email in feature flag access can only be changed for one org at a time'
          }
        </Text>
      </Box>
      <Box
        style={{
          display: 'flex',
          flex: 1,
          backgroundColor: 'white',
          padding: 3,
        }}
      >
        <Text color="#FF6A00" fontWeight="bold">
          Selected orgs:{' '}
        </Text>
        <Text color="#FF6A00">
          {Object.keys(checkedOrgs)
            .filter((el) => checkedOrgs[el])
            .join('  ||  ')}
        </Text>
        <div style={{ flexGrow: 1 }}></div>
        <Text
          color="#FF6A00"
          fontWeight="bold"
          onClick={() => {
            const temp = { ...checkedOrgs };
            for (const org in temp) {
              temp[org] = false;
            }
            setCheckedOrgs(temp);
          }}
        >
          Deselect All
        </Text>
      </Box>
      <SearchField
        value={searchString}
        onChange={(e) => setSearchString(e.target.value)}
        onClear={() => setSearchString('')}
      />
      <Table
        data={filteredOrgsList}
        columns={[...headerColumn, ...featueFlagsColumns]}
        checkedOrgs={checkedOrgs}
        modalSetter={(val) => {
          const checkedArray = Object.keys(checkedOrgs).filter(
            (el) => checkedOrgs[el]
          );
          if (checkedArray.length >= 1) {
            setCurrentFeature(val);
          }
        }}
        orgsList={orgsList}
      />
      <FeatureFlagModal
        isOpen={Boolean(currentFeature)}
        onClose={() => setCurrentFeature(null)}
        checkedOrgs={checkedOrgs}
        currentFeature={currentFeature}
        orgsDict={orgsDict}
        onConfirm={(action, orgs, email) => {
          doUpdates(action, orgs, email);
        }}
      />
    </Box>
  );
}

function Table({ data, columns, checkedOrgs, modalSetter }) {
  const [sorting, setSorting] = useState([]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    enableSortingRemoval: false,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
  });
  return (
    <TableContainer
      sx={{
        minHeight: '50vh',
      }}
    >
      <MuiTable>
        <TableHead>
          {table?.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} style={{ position: 'sticky' }}>
              {headerGroup.headers?.map((header) => {
                return (
                  <TitleTableCell
                    key={header.id}
                    colSpan={header.colSpan}
                    sx={{ borderBottom: 'none' }}
                  >
                    {header.isPlaceholder ? null : (
                      <Box
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                        style={{
                          alignItems: 'center',
                          display: 'flex',
                          justifyContent: 'flex-start',
                          cursor: 'pointer',
                          borderBottom: 'none',
                        }}
                        onClick={() => modalSetter(header.id)}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </Box>
                    )}
                  </TitleTableCell>
                );
              })}
            </tr>
          ))}
        </TableHead>
        <TableBody>
          {table?.getRowModel().rows.map((row) => {
            const { id } = row.original;
            return (
              <UsersTableRow key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <UsersTableCell
                      key={cell.id}
                      style={
                        checkedOrgs[id] ? { backgroundColor: '#c1d0e6' } : null
                      }
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </UsersTableCell>
                  );
                })}
              </UsersTableRow>
            );
          })}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
}

const FeatureFlagModal = ({
  isOpen,
  onClose,
  onConfirm,
  checkedOrgs,
  currentFeature,
  orgsDict,
}) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const [textValue, setTextValue] = useState(null);
  const [updateOrgs, setUpdateOrgs] = useState([]);
  const [emailsList, setEmailsList] = useState([]);

  const [confirm, setConfirm] = useState(false);
  const [action, setAction] = useState(null);

  const handleEmailTextChange = (e) => {
    const val = e.target.value;
    setTextValue(val);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ',' || e.key === ' ') {
      e.preventDefault();
      addEmail();
    }
  };

  const addEmail = () => {
    if (!textValue || textValue.trim() === '') return;

    const newEmail = textValue.trim();

    if (
      emailsList.some((email) => email.toLowerCase() === newEmail.toLowerCase())
    ) {
      // Email already exists, show error or just clear input
      setTextValue('');
      return;
    }

    if (emailRegex.test(newEmail)) {
      setEmailsList((prev) => [...prev, newEmail]);
      setTextValue('');
    } else {
      setTextValue('INVALID EMAIL ADDRESS');
      setTimeout(() => {
        setTextValue(newEmail);
      }, 800);
    }
  };

  useEffect(() => {
    const updateOrgsTemp = Object.keys(checkedOrgs).filter(
      (el) => checkedOrgs[el]
    );
    setUpdateOrgs(updateOrgsTemp);
    if (updateOrgsTemp.length === 1) {
      const [firstOrg] = updateOrgsTemp;
      const valueTemp =
        orgsDict[firstOrg]?.featureFlags[currentFeature] || 'disabled';

      if (valueTemp[0] !== 'everyone' && valueTemp !== 'disabled') {
        setEmailsList(valueTemp);
      }
    }
  }, [currentFeature, checkedOrgs]);

  return (
    <Modal open={isOpen}>
      <Box
        onClick={() => {
          setConfirm(false);
          setEmailsList([]);
          onClose();
        }}
        style={{
          height: '100%',
          width: '100%',
          zIndex: 1,
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          onClick={(e) => e.stopPropagation()}
          style={{
            backgroundColor: 'white',
            overflow: 'hidden',
            minWidth: '40vw',
            maxWidth: '60vw',
            borderRadius: 8,
          }}
        >
          <Box style={{ backgroundColor: 'lightblue', padding: '15px 25px' }}>
            <Text
              style={{
                fontWeight: 600,
                fontSize: 24,
                color: '#FF6A00',
              }}
            >
              {currentFeature} feature flag
            </Text>
            <Text
              style={{
                fontWeight: 500,
                fontSize: 14,
                color: '#FF6A00',
              }}
            >
              {`Orgs: ${updateOrgs.join(', ')}`}
            </Text>
          </Box>
          <Box
            style={{
              padding: '15px 25px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {confirm ? (
              <>
                <Text
                  onClick={() => setConfirm(false)}
                  style={{
                    fontWeight: 'bold',
                    wordWrap: 'break-word',
                    whiteSpace: 'normal',
                  }}
                >
                  {action === 'email'
                    ? `Enable ${currentFeature} for ${emailsList.join(
                        ', '
                      )} of ${updateOrgs[0]}`
                    : `${
                        action[0].toUpperCase() + action.slice(1)
                      } ${currentFeature} for all ${updateOrgs.join(
                        ', '
                      )} users?`}
                </Text>
                <Button
                  style={{ margin: '15px 0' }}
                  onClick={() => {
                    onConfirm(action, [...updateOrgs], emailsList);
                    setConfirm(false);
                    setEmailsList([]);
                  }}
                >
                  Confirm
                </Button>
                <Button onClick={() => setConfirm(false)}>Cancel</Button>
              </>
            ) : (
              <>
                <Button
                  onClick={() => {
                    setAction('disable');
                    setConfirm(true);
                  }}
                  style={{ marginBottom: 5 }}
                >
                  Disable all users
                </Button>
                <Button
                  onClick={() => {
                    setAction('enable');
                    setConfirm(true);
                  }}
                >
                  Enable all users
                </Button>

                <Text
                  style={{ fontWeight: 600, fontSize: 18, marginTop: 5 }}
                  onClick={() => console.log(emailsList)}
                >
                  {`Enable Selected Users`}
                </Text>
                {emailsList.length > 0 && (
                  <Box
                    style={{
                      padding: '5px 0px',
                      display: 'flex',
                      gap: 5,
                      flexWrap: 'wrap',
                    }}
                  >
                    {emailsList.map((email, i) => (
                      <Box
                        key={i}
                        style={{
                          backgroundColor: 'lightblue',
                          padding: '4px 8px',
                          borderRadius: 4,
                          display: 'inline-block',
                        }}
                        onClick={() => {
                          const temp = [...emailsList];
                          const index = temp.indexOf(email);
                          temp.splice(index, 1);
                          setEmailsList(temp);
                        }}
                      >
                        <Text style={{ color: '#FF6A00' }}>{email}</Text>
                      </Box>
                    ))}
                  </Box>
                )}
                <Text style={{ fontWeight: 500, fontSize: 12, marginTop: 3 }}>
                  {
                    'Enter user email separated by space, or enter. click an email address to remove from list.'
                  }
                </Text>
                <Text style={{ fontWeight: 500, fontSize: 12, marginTop: 3 }}>
                  {'to remove all emails please select Disable all users'}
                </Text>
                <TextField
                  disabled={updateOrgs.length > 1}
                  value={textValue || ''}
                  onChange={handleEmailTextChange}
                  onKeyDown={handleKeyDown}
                  style={{
                    marginBottom: 5,
                    color:
                      textValue === 'INVALID EMAIL ADDRESS' ? 'red' : 'black',
                  }}
                  placeholder={
                    updateOrgs.length > 1
                      ? 'available for a single org at a time'
                      : 'enter user emails'
                  }
                />
                <Box style={{ display: 'flex', gap: 10 }}>
                  <Button onClick={addEmail}>Add Email</Button>
                  <Button
                    onClick={() => {
                      if (!emailsList.length) return;
                      setAction('email');
                      setConfirm(true);
                    }}
                    disabled={emailsList.length === 0}
                    style={{
                      opacity: emailsList.length === 0 ? 0.5 : 1,
                      cursor:
                        emailsList.length === 0 ? 'not-allowed' : 'pointer',
                    }}
                  >
                    Save
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
