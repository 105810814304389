import React, { useState, useEffect } from 'react';
import { Drawer, Box, CircularProgress, Typography } from '@mui/material';
import SelectedDealDisplay from '../../../deals/selectedDeal/SelectedDealDisplay';
import DealRepository from '../../../../repository/DealRepository';
import { GamifyToast } from '../../../../common/CustomToasts';
import './DealDetailsDrawerStyles.css';

interface DealDetailsDrawerAdapterProps {
  open: boolean;
  onClose: () => void;
  dealId?: string;
}

const DealDetailsDrawerAdapter = ({
  open,
  onClose,
  dealId,
}: DealDetailsDrawerAdapterProps) => {
  const [dealData, setDealData] = useState<any>(null);
  console.log('🚀 ~ dealData:', dealData);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  // Fetch deal data using DealRepository
  useEffect(() => {
    async function fetchDealData() {
      if (!dealId || !open) return;

      setIsLoading(true);
      setError(null);

      try {
        const data = await DealRepository.fetchDeal(dealId, null);
        console.log('Fetched deal data:', data);

        if (data) {
          // Set the data
          setDealData(data);
        }
      } catch (err) {
        console.error('Error fetching deal:', err);
        setError(
          err instanceof Error ? err : new Error('Failed to fetch deal data')
        );
        GamifyToast.error('Failed to load deal data');
      } finally {
        setIsLoading(false);
      }
    }

    fetchDealData();
  }, [dealId, open]);

  // Handle refetching deal data
  const handleRefetchDeal = async () => {
    if (!dealId) return;

    setIsLoading(true);
    setError(null);

    try {
      const data = await DealRepository.fetchDeal(dealId, null);
      setDealData(data);
    } catch (err) {
      console.error('Error refetching deal:', err);
      setError(
        err instanceof Error ? err : new Error('Failed to refetch deal data')
      );
    } finally {
      setIsLoading(false);
    }
  };

  // Process mainDisplayAttrs from the deal data
  const processMainDisplayAttrs = () => {
    if (!dealData) return [];

    // Create an array to hold the attributes
    const attrs = [];

    try {
      // Add name as the first attribute
      if (dealData.name) {
        attrs.push({ label: 'Name', val: dealData.name });
      }

      // Parse flexAttributes if available
      const flexAttrs = dealData.flexAttributes
        ? JSON.parse(dealData.flexAttributes)
        : {};

      // Add common fields from flexAttributes
      if (flexAttrs['Phone Number']) {
        attrs.push({ label: 'Phone Number', val: flexAttrs['Phone Number'] });
      }

      if (flexAttrs['Email Address']) {
        attrs.push({ label: 'Email Address', val: flexAttrs['Email Address'] });
      }

      if (flexAttrs.Address) {
        attrs.push({ label: 'Address', val: flexAttrs.Address });
      }

      if (flexAttrs.City) {
        attrs.push({ label: 'City', val: flexAttrs.City });
      }

      if (flexAttrs.State) {
        attrs.push({ label: 'State', val: flexAttrs.State });
      }

      if (flexAttrs.ZipCode) {
        attrs.push({ label: 'Zip Code', val: flexAttrs.ZipCode });
      }

      // Add First Name and Last Name if available
      if (flexAttrs['First Name']) {
        attrs.push({ label: 'First Name', val: flexAttrs['First Name'] });
      }

      if (flexAttrs['Last Name']) {
        attrs.push({ label: 'Last Name', val: flexAttrs['Last Name'] });
      }

      if (flexAttrs['# Reps']) {
        attrs.push({ label: '# Reps', val: flexAttrs['# Reps'] });
      }

      if (flexAttrs.Services) {
        attrs.push({ label: 'Services', val: flexAttrs.Services });
      }

      // Add description if available
      if (dealData.description) {
        attrs.push({ label: 'Description', val: dealData.description });
      }

      return attrs;
    } catch (e) {
      console.error('Error processing flexAttributes:', e);

      // Fallback to basic deal info
      const fallbackAttrs = [];

      if (dealData.name) {
        fallbackAttrs.push({ label: 'Name', val: dealData.name });
      }

      if (dealData.description) {
        fallbackAttrs.push({ label: 'Description', val: dealData.description });
      }

      if (dealData.stage?.title) {
        fallbackAttrs.push({ label: 'Stage', val: dealData.stage.title });
      }

      return fallbackAttrs.length > 0
        ? fallbackAttrs
        : [{ label: 'Info', val: 'No additional information available' }];
    }
  };

  // Prepare props for SelectedDealDisplay
  const selectedDealProps = {
    deal: dealData?.deal,
    isOnKanban: false,
    mainDisplayAttrs: processMainDisplayAttrs(),
    fullName: dealData?.name || '',
    dealStage: dealData?.currentStage || '',
    appointments: dealData?.appointments?.items || dealData?.appointments || [],
    comments: dealData?.comments?.items || dealData?.comments || [],
    metaData: dealData?.dealMetaData,
    dealId,
    categoryId: dealData?.categoryID,
    stageId: dealData?.currentStageID,
    userId: dealData?.currentOwnerID,
    setter: dealData?.setter,
    closer: dealData?.closer,
    onTriggerSelectedDealRefetch: handleRefetchDeal,
    handleRefetchDeals: handleRefetchDeal,
    // These are required props but we provide empty functions if not needed
    setAddDealOpen: undefined,
    setSortOpen: undefined,
    setSelectedDealMetaDataOpen: undefined,
    selectedDealMetaDataOpen: false,
    handleOpenOwnerDealStageModal: undefined,
    handleOpenChargeModal: undefined,
    handleDeleteDeal: undefined,
    hideDealMetaData: true,
    hideDealOptionsMenu: true,
  };

  const renderLoading = () => (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        p: 4,
      }}
    >
      <CircularProgress />
    </Box>
  );

  const renderError = () => (
    <Box
      sx={{
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography variant="h6" color="error" sx={{ mb: 2 }}>
        Error Loading Deal
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {error?.message || 'An unknown error occurred'}
      </Typography>
    </Box>
  );

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: { xs: '100%', sm: 580 },
          maxWidth: '100%',
          padding: 0,
          overflow: 'hidden',
          marginTop: '64px',
          height: 'calc(100% - 64px)',
          bgcolor: 'white',
          boxShadow: '-4px 0px 10px rgba(0, 0, 0, 0.1)',
          zIndex: 1300,
        },
      }}
    >
      <Box className="drawer-selected-deal-scroll">
        {isLoading && renderLoading()}
        {error && renderError()}
        {!isLoading && !error && dealData && (
          <div className="drawer-selected-deal">
            <SelectedDealDisplay props={selectedDealProps} />
          </div>
        )}
      </Box>
    </Drawer>
  );
};

export default DealDetailsDrawerAdapter;
