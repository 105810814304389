import React, { useState } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  format,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  isSameMonth,
  isSameDay,
  addMonths,
  subMonths,
  startOfWeek,
  endOfWeek,
} from 'date-fns';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const Calendar = styled(Box)`
  padding: 16px;
  background: transparent;
  width: 100%;
  max-width: 280px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CalendarHeader = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  width: 100%;
  padding: 0 8px;
`;

const MonthContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const MonthYearText = styled(Typography)`
  font-size: 15px;
  font-weight: 500;
  color: #111827;
`;

const NavigationContainer = styled(Box)`
  display: flex;
  gap: 2px;
`;

const NavigationButton = styled(IconButton)`
  color: #111827;
  padding: 4px;
  min-width: 24px;
  min-height: 24px;

  &:hover {
    background-color: #f3f4f6;
  }

  & .MuiSvgIcon-root {
    font-size: 16px;
  }
`;

const CalendarGrid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(7, 36px);
  justify-content: center;
  gap: 0;
  width: 100%;

  & > button {
    border: none;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
    color: #374151;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    padding: 0;
    position: relative;
    margin: 2px;
    line-height: 1;

    &:hover:not(:disabled):not(.weekday):not(.other-month) {
      background-color: rgba(255, 106, 0, 0.04);
    }

    &.selected {
      background-color: #ff6a00;
      color: white;
      font-weight: 600;

      &:hover {
        background-color: #ff6a00;
      }

      &.other-month {
        opacity: 0.8;
      }
    }

    &.today:not(.selected) {
      color: #ff6a00;
      font-weight: 600;

      &::after {
        content: '';
        position: absolute;
        bottom: 4px;
        left: 50%;
        transform: translateX(-50%);
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #ff6a00;
      }
    }

    &:disabled {
      color: #9ca3af;
      cursor: default;
    }

    &.weekday {
      font-size: 12px;
      color: #6b7280;
      font-weight: 500;
      cursor: default;
      margin-bottom: 4px;

      &:hover {
        background-color: transparent;
      }
    }

    &.other-month {
      color: #d1d5db;
      font-weight: 400;
      opacity: 0.8;

      &:hover {
        background-color: transparent;
      }
    }
  }
`;

interface DateRangePickerProps {
  selectedDates: Date[];
  onDateSelect: (date: Date) => void;
  singleDateMode?: boolean;
}

export const DateRangePicker = ({
  selectedDates,
  onDateSelect,
  singleDateMode = false,
}: DateRangePickerProps) => {
  const [currentMonth, setCurrentMonth] = useState(() => {
    return selectedDates[0] || new Date();
  });

  // Get the start and end of the month
  const monthStart = startOfMonth(currentMonth);
  const monthEnd = endOfMonth(currentMonth);

  // Get the start of the first week
  const calendarStart = startOfWeek(monthStart, { weekStartsOn: 0 });

  // Get the end of the last week that contains a day from the current month
  const calendarEnd = endOfWeek(monthEnd, { weekStartsOn: 0 });

  // Get all days to display
  const days = eachDayOfInterval({
    start: calendarStart,
    end: calendarEnd,
  });

  // Use unique keys for each day of the week
  const weekDays = [
    { key: 'sun', label: 'S' },
    { key: 'mon', label: 'M' },
    { key: 'tue', label: 'T' },
    { key: 'wed', label: 'W' },
    { key: 'thu', label: 'T' },
    { key: 'fri', label: 'F' },
    { key: 'sat', label: 'S' },
  ];

  const handleDateClick = (day: Date) => {
    if (singleDateMode) {
      onDateSelect(day);
      return;
    }

    const isDateSelected = selectedDates.some((d: Date) => isSameDay(d, day));

    if (!isDateSelected && selectedDates.length >= 3) {
      // If we already have 3 dates selected and trying to select a new one,
      // replace the entire selection with the new date
      onDateSelect(day);
      return;
    }

    onDateSelect(day);
  };

  return (
    <Calendar>
      <CalendarHeader>
        <MonthContainer>
          <MonthYearText>{format(currentMonth, 'MMMM yyyy')}</MonthYearText>
          <NavigationContainer>
            <NavigationButton
              onClick={() => setCurrentMonth((prev) => subMonths(prev, 1))}
              size="small"
            >
              <ChevronLeftIcon />
            </NavigationButton>
            <NavigationButton
              onClick={() => setCurrentMonth((prev) => addMonths(prev, 1))}
              size="small"
            >
              <ChevronRightIcon />
            </NavigationButton>
          </NavigationContainer>
        </MonthContainer>
      </CalendarHeader>

      <CalendarGrid>
        {weekDays.map((day) => (
          <button key={day.key} disabled className="weekday">
            {day.label}
          </button>
        ))}

        {days.map((day) => {
          const isSelected = selectedDates.some((d: Date) => isSameDay(d, day));
          const isToday = isSameDay(day, new Date());
          const isOtherMonth = !isSameMonth(day, currentMonth);

          return (
            <button
              key={day.toISOString()}
              onClick={() => handleDateClick(day)}
              className={`
                ${isSelected ? 'selected' : ''}
                ${isToday ? 'today' : ''}
                ${isOtherMonth ? 'other-month' : ''}
              `}
            >
              {format(day, 'd')}
            </button>
          );
        })}
      </CalendarGrid>
    </Calendar>
  );
};
