import { useEffect, useState } from 'react';
import { GetKPIValuesArgs, KPILeaderboardState, TokensData } from './_types';
import {
  getKpiValues,
  getOrganizations,
  getTeams,
} from './_kpiLeaderboardHelpers';
import { useGetUserInformationQuery } from '../../store/api/GraphQlQuery';
import getRuleOrder, { applySorting } from './getKPIRuleOrder';

const useGetKpiValues = (initialState: KPILeaderboardState) => {
  const {
    data: userData,
    isError: isErrorUserData,
    isLoading: isLoadingUserData,
  } = useGetUserInformationQuery({});
  const [kpiLeaderboardState, setKpiLeaderboardState] = useState(initialState);
  const [tokens, setTokens] = useState<TokensData>({
    currentToken: '',
    nextToken: '',
    allTokens: [''],
  });

  async function fetchKpiLeaderboardData(args: GetKPIValuesArgs) {
    if (isLoadingUserData || !userData) {
      return;
    }

    if (isErrorUserData) {
      throw new Error('Error fetching user data');
    }

    // Only set loading to true when it isn't the intervalRefresh
    if (!args.isIntervalRefresh) {
      setKpiLeaderboardState((prevState) => ({
        ...prevState,
        loading: true,
      }));
    }

    try {
      const kpiValues = await getKpiValues(args);
      const organizations = await getOrganizations(userData.me.orgID);
      const teams = await getTeams({
        orgID: userData.me.orgID,
        userID: userData.me.id,
      });

      const KPIValueOrder = await getRuleOrder(userData.me.orgID);

      const sortedKpiValues = applySorting(kpiValues, KPIValueOrder);

      const allTokens = kpiValues.nextToken
        ? [...tokens.allTokens, kpiValues.nextToken]
        : tokens.allTokens;

      // Only save the nextToken if it's not in the allTokens array
      if (!tokens.allTokens.includes(kpiValues.nextToken)) {
        setTokens({
          currentToken: args.nextToken,
          nextToken: kpiValues.nextToken ? kpiValues.nextToken : '',
          allTokens,
        });
      }

      setKpiLeaderboardState((prevState) => ({
        ...prevState,
        ...sortedKpiValues,
        organizations,
        teams,
        currentPage: args.currentPage,
        error: null,
        loading: false,
      }));
    } catch (error) {
      setKpiLeaderboardState((prevState) => ({
        ...prevState,
        error,
        loading: false,
      }));
      console.error(error);
    }
  }

  const resetTokens = () => {
    setTokens({
      currentToken: '',
      nextToken: '',
      allTokens: [''],
    });
  };

  useEffect(() => {
    fetchKpiLeaderboardData({
      circleID: kpiLeaderboardState.circleID,
      circleLevelID: kpiLeaderboardState.circleLevelID,
      currentPage: kpiLeaderboardState.currentPage,
      dimensionTimeframe: kpiLeaderboardState.dimensionTimeframe,
      dimensionType: kpiLeaderboardState.dimensionType,
      dimensionValue: kpiLeaderboardState.dimensionValue,
      limit: kpiLeaderboardState.limit,
      sortColumn: kpiLeaderboardState.sortColumn,
      sortOrder: kpiLeaderboardState.sortOrder,
      nextToken: '',
      isIntervalRefresh: false,
    });
  }, [
    kpiLeaderboardState.limit,
    kpiLeaderboardState.dimensionTimeframe,
    kpiLeaderboardState.dimensionType,
    kpiLeaderboardState.dimensionValue,
    kpiLeaderboardState.circleID,
    kpiLeaderboardState.circleLevelID,
    kpiLeaderboardState.sortColumn,
    kpiLeaderboardState.sortOrder,
    isLoadingUserData,
  ]);

  return {
    kpiLeaderboardState,
    setKpiLeaderboardState,
    tokens,
    resetTokens,
    fetchKpiLeaderboardData,
  };
};

export default useGetKpiValues;
