import { API, graphqlOperation } from 'aws-amplify';
import { useQuery } from '@tanstack/react-query';
import getUserAvatar from '../../../../common/utils/getUserAvatar';

export interface Employee {
  id: string;
  name: string;
  role: string;
  avatar: string;
  status: string;
  imageName: string;
  imageType: string;
  functionalRoles?: string[];
}

const fetchUsers = async () => {
  const myQuery = `
    query MyQuery($orgID: ID!, $nextToken: String) {
      searchUsers(
        filter: {
          orgID: { eq: $orgID }
          status: { ne: "deleted" }
        }
        limit: 750
        nextToken: $nextToken
        sort: { direction: asc, field: name }
      ) {
        items {
          id
          name
          status
          imageName
          imageType
          functionalRoles
        }
        nextToken
      }
    }
  `;

  let usersList: any[] = [];
  let usersNextToken: string | null = null;

  try {
    do {
      const userData: any = await API.graphql(
        graphqlOperation(myQuery, {
          orgID: (global as any).me.orgID,
          nextToken: usersNextToken,
        })
      );

      const newUsers = userData.data?.searchUsers?.items || [];
      usersList = [...usersList, ...newUsers];
      usersNextToken = userData.data?.searchUsers?.nextToken;
    } while (usersNextToken);

    // Process all users after fetching complete list
    const processedUsers: Employee[] = [];

    for (const user of usersList) {
      if (user.status === 'deleted') continue;

      const avatarFile = await getUserAvatar(
        user.imageName,
        user.imageType,
        true
      );
      const functionalRole = user.functionalRoles
        ? user.functionalRoles
            .filter((role: string) => role !== '')
            .map((role: string) => role[0].toUpperCase() + role.slice(1))
            .join(' & ')
        : '';

      processedUsers.push({
        id: user.id,
        name: user.name,
        role: functionalRole || 'None',
        avatar: avatarFile,
        status: user.status,
        imageName: user.imageName,
        imageType: user.imageType,
        functionalRoles: user.functionalRoles,
      });
    }

    return processedUsers;
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

export const useUsersQuery = () => {
  return useQuery({
    queryKey: ['users'],
    queryFn: fetchUsers,
    staleTime: 5 * 60 * 1000, // 5 minutes
    gcTime: 30 * 60 * 1000, // 30 minutes
    retry: 2,
    refetchOnWindowFocus: false,
  });
};
