import { API, graphqlOperation } from 'aws-amplify';
import { useQuery } from '@tanstack/react-query';

interface TeamMember {
  id: string;
  isActive: boolean;
  status: string;
  user: {
    id: string;
    name: string;
    status: string;
    imageName: string;
    imageType: string;
    functionalRoles: string[];
  };
}

interface Team {
  id: string;
  name: string;
  imageName: string;
  imageType: string;
  users: {
    items: TeamMember[];
  };
}

interface GraphQLResponse {
  data: {
    searchCircles: {
      items: Team[];
      nextToken: string | null;
    };
  };
}

const fetchTeams = async () => {
  const myQuery = `
    query myQuery($orgID: ID!, $nextToken: String) {
      searchCircles(
        filter: {
          orgID: { eq: $orgID }
          isDeleted: { eq: false }
        }
        limit: 999
        nextToken: $nextToken
        sort: { direction: asc, field: name }
      ) {
        items {
          id
          name
          imageName
          imageType
          users {
            items {
              id
              isActive
              status
              user {
                id
                name
                status
                imageName
                imageType
                functionalRoles
              }
            }
          }
        }
        nextToken
      }
    }
  `;

  let teamsList: Team[] = [];
  let teamsNextToken: string | null = null;

  try {
    do {
      const res = (await API.graphql(
        graphqlOperation(myQuery, {
          orgID: (global as any).me.orgID,
          nextToken: teamsNextToken,
        })
      )) as GraphQLResponse;

      const newTeams =
        res.data?.searchCircles?.items?.filter((team) => {
          const activeUsers = team.users.items.filter(
            (member) =>
              member.isActive &&
              member.status !== 'removed' &&
              member.user &&
              member.user.status !== 'deleted'
          );
          return activeUsers.length > 0;
        }) || [];

      teamsList = [...teamsList, ...newTeams];
      teamsNextToken = res.data?.searchCircles?.nextToken;
    } while (teamsNextToken);

    return teamsList;
  } catch (error) {
    console.error('Error fetching teams:', error);
    throw error;
  }
};

export const useTeamsQuery = () => {
  return useQuery({
    queryKey: ['teams'],
    queryFn: fetchTeams,
    staleTime: 5 * 60 * 1000, // 5 minutes
    gcTime: 30 * 60 * 1000, // 30 minutes
    retry: 2,
    refetchOnWindowFocus: false,
  });
};
