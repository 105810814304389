import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  MenuItem,
  Select,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Switch,
  Snackbar,
} from '@mui/material';
import { CustomTextField } from './styledComponents';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { hasUnsavedChanges } from '../utils/hasUnsavedChanges';
import { FlexFieldEditor } from './FlexFieldEditor';
import './CRMConfigV2.css';
import { newFormTemplate, newFieldTemp } from '../utils/tempsUtils';
import { DragIndicator } from '@mui/icons-material';
import {
  draggable,
  dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { toCamelCase } from '../utils/stringConversion';
import { isValidFlexField } from '../utils/validation';
import DealMapping from './components/DealMapping';

const DraggableFormField = ({
  field,
  fieldIndex,
  moveFlexField,
  expandedField,
  handleFieldAccordionChange,
  formName,
  renderFieldAccordionSummary,
  handleFieldChange,
  deleteField,
  changedForms,
  isNewField,
}) => {
  const dragRef = useRef(null);
  const dropRef = useRef(null);
  const [isOver, setIsOver] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    if (dragRef.current) {
      return draggable({
        element: dragRef.current,
        getInitialData: () => ({ sourceIndex: fieldIndex }),
        onDragStart: () => {
          document.body.style.cursor = 'grabbing';
          setIsDragging(true);
          setIsOver(true);
        },
        onDrop: () => setIsDragging(false),
      });
    }
  }, []);

  useEffect(() => {
    if (!dropRef.current) return;
    return dropTargetForElements({
      element: dropRef.current,
      getData: () => ({ targetIndex: fieldIndex }),
      onDragEnter: ({ self, source }) => {
        if (source.data.sourceIndex !== self.data.targetIndex) {
          moveFlexField(
            formName,
            source.data.sourceIndex,
            self.data.targetIndex
          );
          source.data.sourceIndex = fieldIndex;
        }
        setIsOver(true);
      },
      onDragLeave: () => setIsOver(false),
      onDrop: () => {
        setIsOver(false);
        document.body.style.cursor = 'default';
      },
    });
  }, [dropRef, fieldIndex]);

  return (
    <Accordion
      expanded={expandedField === fieldIndex}
      onChange={handleFieldAccordionChange(fieldIndex)}
      ref={dropRef}
    >
      <div
        ref={dragRef}
        style={{
          opacity: isOver ? 0 : 1,
          cursor: isDragging ? 'grabbing' : 'grab',
          transition: 'transform 0.2s ease-in-out',
        }}
      >
        {renderFieldAccordionSummary(field, fieldIndex, formName)}
      </div>
      <AccordionDetails>
        <FlexFieldEditor
          formName={formName}
          field={field}
          fieldIndex={fieldIndex}
          handleFieldChange={handleFieldChange}
          deleteField={deleteField}
          changedForms={changedForms}
          isNewField={isNewField}
        />
      </AccordionDetails>
    </Accordion>
  );
};

const DealFormEditor = ({
  dealForms,
  stages,
  stageDispositions,
  updateForms,
}) => {
  const [workingDealForms, setWorkingDealForms] = useState({});
  const [changedForms, setChangedForms] = useState([]);
  const [expandedForm, setExpandedForm] = useState(false);
  const [expandedField, setExpandedField] = useState(null);
  const [newFormName, setNewFormName] = useState('');
  const [formNameError, setFormNameError] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [formToDelete, setFormToDelete] = useState('');
  const [disableFormSave, setDisableFormSave] = useState('');
  const [invalidMappingField, setInvalidMappingField] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [openAlert, setOpenAlert] = useState(false);

  useEffect(() => {
    setWorkingDealForms(dealForms);
  }, [dealForms]);

  useEffect(() => {
    const updatedChangedForms = Object.keys(workingDealForms).filter((key) =>
      hasUnsavedChanges(dealForms[key], workingDealForms[key])
    );
    setChangedForms(updatedChangedForms);
  }, [dealForms, workingDealForms]);

  const handleFormChange = (formName, field, value) => {
    const updatedDealForms = {
      ...workingDealForms,
      [formName]: { ...workingDealForms[formName], [field]: value },
    };
    setWorkingDealForms(updatedDealForms);
  };

  const updateDealMappings = (formName, fieldIndex, value) => {
    // Get current values
    let dealName = workingDealForms[formName].dealMappingsName || '';
    let dealDescription =
      workingDealForms[formName].dealMappingsDescription || '';

    // Get original field name
    const originalValue =
      workingDealForms[formName].flexFields[fieldIndex]?.name;

    // Only proceed with replacement if originalValue exists and is not empty
    if (originalValue && originalValue.trim()) {
      // Escape special characters in originalValue
      const escapedOriginal = originalValue.replace(
        /[.*+?^${}()|[\]\\]/g,
        '\\$&'
      );

      const pattern = new RegExp(
        `\\b${escapedOriginal}\\b|\\b${escapedOriginal}\\.`,
        'g'
      );

      if (pattern.test(dealName)) {
        dealName = dealName.replace(pattern, (match) =>
          match.endsWith('.') ? `${value}.` : value
        );
      }

      if (pattern.test(dealDescription)) {
        dealDescription = dealDescription.replace(pattern, (match) =>
          match.endsWith('.') ? `${value}.` : value
        );
      }
    }

    return { dealName, dealDescription };
  };

  const handleFieldChange = (
    formName,
    fieldIndex,
    field,
    value,
    isNewField = false
  ) => {
    // The hope is eventually isNewField can be taken out, but for now it's necessary
    const updateMappings = (fieldNameValue, otherFields) => {
      // otherFields type:  { [field]: value }
      // Check if the field name is included in the deal mappings
      const { dealName, dealDescription } = updateDealMappings(
        formName,
        fieldIndex,
        fieldNameValue
      );

      const updatedFields = [...workingDealForms[formName].flexFields];
      updatedFields[fieldIndex] = {
        ...updatedFields[fieldIndex],
        name: fieldNameValue,
        ...otherFields,
      };

      // Need to ensure that the deal mappings and field names are simultanously updated
      const updatedDealForms = {
        ...workingDealForms,
        [formName]: {
          ...workingDealForms[formName],
          dealMappingsName: dealName,
          dealMappingsDescription: dealDescription,
          flexFields: updatedFields,
        },
      };
      setWorkingDealForms(updatedDealForms);
    };

    const isDuplicateFieldName = (fieldNameValue) =>
      dealForms[formName].flexFields
        .map((flexField) => flexField.name)
        .includes(fieldNameValue);

    if (field === 'name' && isNewField && !isDuplicateFieldName(value)) {
      if (
        workingDealForms[formName].flexFields[fieldIndex].type === 'Order Lines'
      ) {
        setAlertMessage(
          'Cannot change the Field Name when the Type is "Order"'
        );
        setOpenAlert(true);
        return;
      }
      updateMappings(value, {});
      return;
    } else if (field === 'name' && isNewField && isDuplicateFieldName(value)) {
      setAlertMessage(
        'The Field Name must be unique from all other Field Names in the form'
      );
      setOpenAlert(true);
      return;
    }

    if (
      field === 'label' &&
      workingDealForms[formName].flexFields[fieldIndex].type !==
        'Order Lines' &&
      isNewField &&
      !isDuplicateFieldName(toCamelCase(value))
    ) {
      updateMappings(toCamelCase(value), { [field]: value });
      return;
    } else if (
      field === 'label' &&
      workingDealForms[formName].flexFields[fieldIndex].type !==
        'Order Lines' &&
      isNewField &&
      isDuplicateFieldName(toCamelCase(value))
    ) {
      setAlertMessage(
        'The Field Name must be unique from all other Field Names in the form'
      );
      setOpenAlert(true);
      return;
    }
    if (field === 'type' && value === 'Order Lines') {
      if (isNewField && !isDuplicateFieldName('Service')) {
        updateMappings('Service', { [field]: value });
      }
      if (isNewField && isDuplicateFieldName('Service')) {
        setAlertMessage(
          'The Field Name must be unique from all other Field Names in the form (Since the Field Name is always "Service" when the Type is "Order", there can only be one field per form of Type "Order")'
        );
        setOpenAlert(true);
      }
      if (!isNewField) {
        setAlertMessage(
          "You cannot change the Type of an existing field to 'Order Lines' because it will automatically change the Field Name. If you need to add an 'Order' field to this form, please add a new field"
        );
        setOpenAlert(true);
      }
      return;
    }
    const updatedFields = [...workingDealForms[formName].flexFields];
    updatedFields[fieldIndex] = {
      ...updatedFields[fieldIndex],
      [field]: value,
    };

    handleFormChange(formName, 'flexFields', updatedFields);
  };

  const deleteField = (formName, fieldIndex) => {
    const updatedFields = [...workingDealForms[formName].flexFields];
    updatedFields.splice(fieldIndex, 1);
    handleFormChange(formName, 'flexFields', updatedFields);
    setExpandedField(null);
  };

  const isNewField = (formName, field) => {
    if (
      dealForms[formName].flexFields
        .map((flexField) => flexField.name)
        .includes(field.name)
    ) {
      return false;
    } else {
      return true;
    }
  };

  const saveChanges = (formName) => {
    const updatedForm = { ...workingDealForms[formName] };

    const displayFields = updatedForm.flexFields
      .filter((f) => f.displayField)
      .map((f) => f.name);

    const pickerDisplayFields = updatedForm.flexFields
      .filter((f) => f.pickerDisplayField)
      .map((f) => f.name);

    updatedForm.displayFields = displayFields;
    updatedForm.pickerDisplayFields = pickerDisplayFields;

    const allForms = { ...dealForms, [formName]: updatedForm };

    updateForms(allForms);
    setChangedForms(changedForms.filter((name) => name !== formName));
  };

  const resetChanges = (formName, e) => {
    e.stopPropagation();
    const originalForm = dealForms[formName];
    const updatedDealForms = {
      ...workingDealForms,
      [formName]: { ...originalForm },
    };
    setWorkingDealForms(updatedDealForms);
    setDisableFormSave('');
    setInvalidMappingField('');
  };

  const handleFormAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedForm(isExpanded ? panel : false);
  };

  const handleFieldAccordionChange = (fieldIndex) => (event, isExpanded) => {
    setExpandedField(isExpanded ? fieldIndex : null);
  };

  const handleFormNameChange = (formName, value) => {
    if (value === 'deal') return; // Prevent setting the form name to 'deal'
    const updatedDealForms = { ...workingDealForms };
    updatedDealForms[value] = { ...updatedDealForms[formName] };
    delete updatedDealForms[formName];
    setWorkingDealForms(updatedDealForms);
    setExpandedForm(value);
  };

  const confirmDeleteForm = (formName) => {
    setFormToDelete(formName);
    setOpenDialog(true);
  };

  const handleDeleteForm = () => {
    if (formToDelete === 'deal') return; // Prevent deleting the form with name 'deal'
    const updatedDealForms = { ...workingDealForms };
    delete updatedDealForms[formToDelete];
    setWorkingDealForms(updatedDealForms);
    setOpenDialog(false);
    setFormToDelete('');
    updateForms(updatedDealForms);
  };

  const addNewField = (formName) => {
    const updatedFields = [
      newFieldTemp,
      ...workingDealForms[formName].flexFields,
    ];
    handleFormChange(formName, 'flexFields', updatedFields);
    setExpandedField(0);
  };

  const createNewForm = () => {
    if (newFormName.trim() === '' || workingDealForms[newFormName]) {
      setFormNameError(true);
      return;
    }

    const newForm = {
      ...newFormTemplate,
      flexFields: [],
    };

    setWorkingDealForms({
      ...workingDealForms,
      [newFormName]: newForm,
    });

    setNewFormName('');
    setFormNameError(false);
    setExpandedForm(newFormName);
    setShowCreateForm(false);
  };

  const handleNewFormNameChange = (e) => {
    const { value } = e.target;
    setNewFormName(value);
    setFormNameError(!!workingDealForms[value] && value !== '');
  };

  const moveFlexField = (formName, fromIndex, toIndex) => {
    setWorkingDealForms((prevForms) => {
      const updatedFields = [...prevForms[formName].flexFields];
      const [draggedItem] = updatedFields.splice(fromIndex, 1);
      updatedFields.splice(toIndex, 0, draggedItem);

      return {
        ...prevForms,
        [formName]: {
          ...prevForms[formName],
          flexFields: updatedFields,
        },
      };
    });
  };

  // This is only necessary for working with multiple selections without decreasing performance
  const handleMappingChange = (formName, field, value) => {
    const stringifiedValue = Array.isArray(value) ? value.join(', ') : value;
    handleFormChange(formName, field, stringifiedValue);
  };

  // The component uses a string array and we store the mappings as a string, so conversion is necessary for display and updating the data
  const convertDealMappings = (mappingValue, flexFields) => {
    // Need to filter out an invalid fieldNames due to a pass mess up with the mapping data.
    const displayValue = mappingValue
      ? mappingValue.split(', ').filter((fieldName) => {
          if (fieldName === '') return false;
          return isValidFlexField(flexFields, fieldName);
        })
      : [];
    return displayValue;
  };

  const renderFieldAccordionSummary = (field, fieldIndex, formName) => (
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls={`field${fieldIndex}-content`}
      id={`field${fieldIndex}-header`}
      style={{
        backgroundColor: expandedField === fieldIndex ? '#f0f0f0' : 'inherit',
        padding: '10px 35px',
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        gap={5}
        width={'100%'}
        flexWrap={'wrap'}
      >
        <Box display="flex" gap={1}>
          <DragIndicator style={{ color: 'gray' }} />
          <Typography
            style={{ fontSize: 'medium', fontWeight: 600, width: '180px' }}
          >
            {field.label}
          </Typography>
        </Box>
        <Typography
          style={{
            fontSize: 'medium',
            color: 'gray',
            marginLeft: '8px',
            fontWeight: 'bold',
            width: '90px',
          }}
        >
          Type:
          <Typography
            component="span"
            style={{
              fontSize: 'medium',
              color: 'gray',
              marginLeft: '4px',
              fontWeight: 'normal',
            }}
          >
            {field.type}
          </Typography>
        </Typography>
        <Typography
          style={{
            fontSize: 'medium',
            color: 'gray',
            marginLeft: '8px',
            fontWeight: 'bold',
            width: '150px',
          }}
        >
          Heading:
          <Typography
            component="span"
            style={{
              fontSize: 'medium',
              color: 'gray',
              marginLeft: '4px',
              fontWeight: 'normal',
            }}
          >
            {field.heading}
          </Typography>
        </Typography>
        <Typography
          style={{
            fontSize: 'medium',
            color: 'gray',
            marginLeft: '8px',
            fontWeight: 'bold',
            width: '150px',
          }}
        >
          Label:
          <Typography
            component="span"
            style={{
              fontSize: 'medium',
              color: 'gray',
              marginLeft: '4px',
              fontWeight: 'normal',
            }}
          >
            {field.label}
          </Typography>
        </Typography>
        <Typography
          style={{
            fontSize: 'medium',
            color: 'gray',
            marginLeft: '8px',
            fontWeight: 'bold',
            width: '150px',
          }}
        >
          Field Name:
          <Typography
            component="span"
            style={{
              display: 'flex',
              fontSize: 'medium',
              color: 'gray',
              marginLeft: '4px',
              fontWeight: 'normal',
            }}
          >
            {field.name}
          </Typography>
        </Typography>
        <Box style={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
          <FormControlLabel
            control={
              <Switch
                checked={field.required}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => {
                  handleFieldChange(
                    formName,
                    fieldIndex,
                    'required',
                    e.target.checked
                  );
                }}
                color="primary"
              />
            }
            onClick={(e) => e.stopPropagation()}
            label="Required"
          />
          <FormControlLabel
            control={
              <Switch
                checked={field.hideLabel}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) =>
                  handleFieldChange(
                    formName,
                    fieldIndex,
                    'hideLabel',
                    e.target.checked
                  )
                }
                color="primary"
              />
            }
            onClick={(e) => e.stopPropagation()}
            label="Hide Label on Form"
          />
          <FormControlLabel
            control={
              <Switch
                checked={field.displayField}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) =>
                  handleFieldChange(
                    formName,
                    fieldIndex,
                    'displayField',
                    e.target.checked
                  )
                }
                color="primary"
              />
            }
            onClick={(e) => e.stopPropagation()}
            label="Display Field"
          />
        </Box>
      </Box>
    </AccordionSummary>
  );

  return (
    <Box border={'1px solid gray'}>
      {Object.keys(workingDealForms).map((formName, index) => {
        return (
          <Accordion
            key={formName}
            expanded={expandedForm === formName}
            onChange={handleFormAccordionChange(formName)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
              style={{
                backgroundColor:
                  expandedForm === formName ? '#e0cfe8' : 'inherit',
                padding: '10px 35px',
              }}
            >
              <Typography style={{ fontSize: 'large', fontWeight: 700 }}>
                {formName}{' '}
                {changedForms.includes(formName) && (
                  <>
                    <span style={{ color: 'red', fontWeight: 400 }}>
                      (Unsaved Changes)
                    </span>
                    <Typography
                      component="span"
                      style={{
                        color: 'gray',
                        cursor: 'pointer',
                        marginLeft: '10px',
                      }}
                      onClick={(e) => resetChanges(formName, e)}
                    >
                      reset
                    </Typography>
                  </>
                )}
              </Typography>
              {changedForms.includes(formName) && (
                <Button
                  disabled={disableFormSave !== ''}
                  variant="contained"
                  color="primary"
                  onClick={() => saveChanges(formName)}
                  style={{ marginLeft: 'auto', marginRight: '15px' }}
                >
                  Save Changes
                </Button>
              )}
            </AccordionSummary>
            <AccordionDetails style={{ padding: '10px 0px' }}>
              <Box style={{ padding: '5px 35px' }}>
                <Box mb={2}>
                  <Typography style={{ fontSize: 'medium', fontWeight: 600 }}>
                    Form Name
                  </Typography>
                  <CustomTextField
                    value={formName}
                    onChange={(e) =>
                      handleFormNameChange(formName, e.target.value)
                    }
                    disabled={true}
                    fullWidth
                    margin="normal"
                  />
                </Box>
                <Box display="flex" justifyContent="space-between" mb={2}>
                  <Box width="48%">
                    <Typography style={{ fontSize: 'medium', fontWeight: 600 }}>
                      Availability Disposition
                    </Typography>
                    <FormControl fullWidth margin="normal">
                      <Select
                        className="square-select"
                        value={
                          workingDealForms[formName].availabiltyDisposition ||
                          ''
                        }
                        onChange={(e) =>
                          handleFormChange(
                            formName,
                            'availabiltyDisposition',
                            e.target.value
                          )
                        }
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 200,
                            },
                          },
                        }}
                      >
                        <MenuItem
                          value=""
                          style={{
                            backgroundColor: 'white',
                            border: '1px solid lightgray',
                            borderTop: 'none',
                            fontStyle: 'italic',
                            color: 'gray',
                          }}
                        >
                          Select Availability Disposition...
                        </MenuItem>
                        {stageDispositions.map((disposition) => {
                          return (
                            <MenuItem
                              key={disposition.id}
                              value={disposition.id}
                              style={{
                                backgroundColor: 'white',
                                border: '1px solid lightgray',
                                borderTop: 'none',
                              }}
                              onMouseEnter={(e) =>
                                (e.target.style.backgroundColor = 'lightgray')
                              }
                              onMouseLeave={(e) =>
                                (e.target.style.backgroundColor = 'white')
                              }
                            >
                              {disposition.title}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box width="48%">
                    <Typography style={{ fontSize: 'medium', fontWeight: 600 }}>
                      Availability Stage
                    </Typography>
                    <FormControl fullWidth margin="normal">
                      <Select
                        className="square-select"
                        value={
                          workingDealForms[formName].availabiltyStage || ''
                        }
                        onChange={(e) =>
                          handleFormChange(
                            formName,
                            'availabiltyStage',
                            e.target.value
                          )
                        }
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 200,
                            },
                          },
                        }}
                      >
                        <MenuItem
                          value=""
                          style={{
                            backgroundColor: 'white',
                            border: '1px solid lightgray',
                            borderTop: 'none',
                            fontStyle: 'italic',
                            color: 'gray',
                          }}
                        >
                          Select Availability Stage...
                        </MenuItem>
                        {stages.map((stage) => (
                          <MenuItem
                            key={stage.id}
                            value={stage.id}
                            style={{
                              backgroundColor: 'white',
                              border: '1px solid lightgray',
                              borderTop: 'none',
                            }}
                            onMouseEnter={(e) =>
                              (e.target.style.backgroundColor = 'lightgray')
                            }
                            onMouseLeave={(e) =>
                              (e.target.style.backgroundColor = 'white')
                            }
                          >
                            {stage.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                <Box display="flex" justifyContent="space-between" mb={2}>
                  <Box width="48%">
                    <Box mb={2}>
                      <DealMapping
                        formName={formName}
                        workingDealForms={workingDealForms}
                        fieldName="dealMappingsName"
                        disableFormSave={disableFormSave}
                        invalidMappingField={invalidMappingField}
                        handleMappingChange={handleMappingChange}
                        convertDealMappings={convertDealMappings}
                      />
                    </Box>
                  </Box>
                  <Box width="48%">
                    <Box mb={2}>
                      <DealMapping
                        formName={formName}
                        workingDealForms={workingDealForms}
                        fieldName="dealMappingsDescription"
                        disableFormSave={disableFormSave}
                        invalidMappingField={invalidMappingField}
                        handleMappingChange={handleMappingChange}
                        convertDealMappings={convertDealMappings}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box display="flex" justifyContent="flex-end" mb={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => confirmDeleteForm(formName)}
                    disabled={formName === 'deal'}
                    style={{ marginLeft: 'auto' }}
                  >
                    Delete Form
                  </Button>
                </Box>
              </Box>
              <Box
                style={{ margin: '5px 35px', border: '1px solid lightgray' }}
              >
                <Box
                  style={{
                    backgroundColor: '#99c2d9',
                    padding: '15px 35px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography style={{ fontSize: 'medium', fontWeight: 600 }}>
                    Form Fields
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      if (dealForms[formName]) {
                        addNewField(formName);
                      } else {
                        setAlertMessage(
                          'You must save the newly created form before adding a new field'
                        );
                        setOpenAlert(true);
                      }
                    }}
                    style={{ marginLeft: 'auto' }}
                  >
                    Add New Field
                  </Button>
                </Box>
                <Box style={{ minHeight: '60px' }}>
                  {workingDealForms[formName].flexFields?.length === 0 && (
                    <Box mb={2} display="flex">
                      <Typography m="auto" pt="15px" color="gray">
                        No fields
                      </Typography>
                    </Box>
                  )}
                  {workingDealForms[formName].flexFields?.map(
                    (field, fieldIndex) => (
                      <DraggableFormField
                        key={`${formName}-${fieldIndex}`}
                        field={field}
                        fieldIndex={fieldIndex}
                        moveFlexField={moveFlexField}
                        expandedField={expandedField}
                        handleFieldAccordionChange={handleFieldAccordionChange}
                        formName={formName}
                        renderFieldAccordionSummary={
                          renderFieldAccordionSummary
                        }
                        isNewField={isNewField(formName, field)}
                        handleFieldChange={handleFieldChange}
                        deleteField={deleteField}
                        changedForms={changedForms}
                      />
                    )
                  )}
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        );
      })}
      <Box className="create_form_container">
        {!showCreateForm && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowCreateForm(true)}
          >
            Create New Form
          </Button>
        )}
        {showCreateForm && (
          <Box display="flex" alignItems="center" gap={2}>
            <CustomTextField
              value={newFormName}
              onChange={handleNewFormNameChange}
              placeholder="New Form Name"
              error={formNameError}
              helperText={
                formNameError ? 'Form name already exists or is invalid' : ''
              }
              margin="normal"
            />
            <Button variant="contained" color="primary" onClick={createNewForm}>
              Create
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setShowCreateForm(false)}
            >
              Cancel
            </Button>
          </Box>
        )}
      </Box>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Confirm Delete'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deleted forms cannot be restored. Are you sure you want to delete
            this form?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteForm} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openAlert}
        autoHideDuration={6000}
        onClose={() => setOpenAlert(false)}
        message={alertMessage}
      />
    </Box>
  );
};

export default DealFormEditor;
