import React, { useState, useEffect } from 'react';
import {
  useNavigate,
  useParams,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import {
  Box,
  Button,
  CardMedia,
  // IconButton,
  // Table as MuiTable,
  // TableBody,
  // TableContainer,
  // TableHead,
  Avatar,
  Typography,
  // Checkbox,
  // InputAdornment,
  CircularProgress,
} from '@mui/material';
import { Text } from '@aws-amplify/ui-react';
// import gql from 'graphql-tag';
import { API } from 'aws-amplify';
import * as mutations from '../../../graphql/mutations';
import fetchProfileWithTeams from './fetchProfileWithTeams';
import { BackIcon, GamifyIcon } from '../../components/GamifyIcon';
import AddMemberLinkTeamModal from '../teams/AddMemberLinkTeamModal';
import './ProfileAboutPage.css';
import { GamifyToast } from '../../common/CustomToasts';
// import EditUserModal from '../users/EditUserModal';
import DynamicConfirmModal from '../../common/DynamicConfirmModal';
import PermissionsModal from './PermissionsModal';
import TeamCard from './TeamCard';
import ProfileAboutPageSkeleton from './profileSkeletons/ProfileAboutPageSkeleton';
import ProfileNoUserDisplay from './ProfileNoUserDisplay';
import UserFormModal from '../users/UserFormModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAllProfiles,
  loadProfileAction,
  removeUserAction,
  removeProfileAction,
  updateProfileAction,
} from '../../store/reducers/users';
import { usePermissions } from '../../context/PermissionsContext/PermissionsContext';
import { PermissionKeys } from '../../context/PermissionsContext/permissionTypes';
import { useCurrentUserStore } from '../../store/zustand/useCurrentUserStore';

export default function ProfileAboutPage() {
  const { userId } = useParams();
  const dispatch = useDispatch();

  const reduxProfiles = useSelector(selectAllProfiles);
  const reduxProfile = reduxProfiles[userId] ? reduxProfiles[userId] : null;

  const [myProfile, setMyProfile] = useState(reduxProfile?.user || null);

  const { hasPermission, checkPermission, isSuperAdmin, orgRoles } =
    usePermissions();

  const normalizedOrgRoles = orgRoles.reduce((acc, role) => {
    acc[role.id] = role;
    return acc;
  }, {});

  const isSuper = isSuperAdmin();

  const showEdit = hasPermission(PermissionKeys.TeamsPeople_UpdateUser, [
    userId,
  ]);
  const showCalendar = hasPermission(
    PermissionKeys.Scheduling_ViewAppointments,
    [userId]
  );
  // Non-super admin shouldn't be able to change a super admin's role
  const showChangeRole =
    checkPermission(PermissionKeys.TeamsPeople_ChangePersonsRole) &&
    (!normalizedOrgRoles[myProfile?.orgs?.items[0]?.roleID]?.isSuper ||
      (isSuper &&
        normalizedOrgRoles[myProfile?.orgs?.items[0]?.roleID]?.isSuper));
  const showChangeStatus = checkPermission(
    PermissionKeys.TeamsPeople_ChangePersonsStatus
  );

  const showPermissions = showChangeRole || showChangeStatus;

  const showTeams = hasPermission(PermissionKeys.TeamsPeople_ViewTeamDetails, [
    userId,
  ]);

  const { addMemberToCurrentUserTeams } = useCurrentUserStore();

  // NOTE: Teams on a profile in redux is a normalized object, convert back to an array
  let reduxTeamsArr = [];
  if (reduxProfile?.teams) {
    reduxTeamsArr = Object.values(reduxProfile?.teams);
    reduxTeamsArr.sort((a, b) => {
      if (a.circle?.name < b.circle?.name) {
        return -1;
      }
      if (a.circle?.name > b.circle?.name) {
        return 1;
      }
      return 0;
    });
  }

  const [teamId, setTeamId] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [routeBackToTeamPage, setRouteBackToTeamPage] = useState(false);
  const [routeBackToLeaderboard, setRouteBackToLeaderbaord] = useState(false);

  // console.log('ProfileStatsPage userId: ', userId);

  const [teams, setTeams] = useState(reduxTeamsArr);
  const [showUserFormModal, setShowUserFormModal] = useState(false);

  //   const [subPage, setSubPage] = useState('about');
  const [subPage, setSubPage] = useState(
    reduxProfile?.subPage?.about || searchParams.get('subPage') || 'about'
  );
  const [showAddProfileToTeamsModal, setShowAddProfileToTeamsModal] =
    useState(false);
  // const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  // const [showPermissionsModal, setShowPermissionsModal] = useState(false);
  const [permissionsAnchorEl, setPermissionsAnchorEl] = useState(null);
  const [rolePositionObj, setRolePositionObj] = useState(
    reduxProfile
      ? {
          original: reduxProfile.user.orgs.items[0].roleID,
          form: reduxProfile.user.orgs.items[0].roleID,
        }
      : {
          original: null,
          form: null,
        }
  );

  const [isLoading, setIsLoading] = useState(true);

  const [isTeamListLoading, setIsTeamListLoading] = useState(false);

  // console.log('UserAboutPage myProfile: ', myProfile);
  //   console.log('UserAboutPage teams: ', teams);

  useEffect(() => {
    // use location state to determine whether user navigated from TeamPage or UsersTable for correct routing on going back
    if (location?.state?.teamPage) setRouteBackToTeamPage(true);
    // use location state to get the teamId if coming from a TeamPage for correct TeamPage routing on going baack
    if (location?.state?.teamId) setTeamId(location.state.teamId);
    // use location state to determine whether user navigated from the Leaderboard for correct routing on going back
    if (location?.state?.leaderboard) setRouteBackToLeaderbaord(true);
  }, []);

  useEffect(() => {
    if (!userId) return;

    const profileForRedux = { ...reduxProfile } || {};

    async function prepareProfile() {
      setIsLoading(true);

      if (!reduxProfile?.subPage) {
        profileForRedux.subPage = {};
        profileForRedux.subPage.about = searchParams.get('subPage') || 'about';
      }

      // For if subPage was set for the about profile in current redux store
      if (!reduxProfile?.subPage?.stats) {
        profileForRedux.subPage.about = searchParams.get('subPage') || 'about';
      }

      // Only fetch if teams isn't already in redux (teams come out of user in the fetchProfileWithTeams)...
      // TO DO: Split teams out of the profile fetch to clean this up...
      if (!reduxProfile?.teams) {
        const user = await fetchProfileWithTeams(userId);

        // Handle invalid userId
        if (!user) {
          setIsLoading(false);
          return;
        }

        setMyProfile(user);

        setRolePositionObj({
          original: user?.orgs?.items[0]?.roleID,
          form: user?.orgs?.items[0]?.roleID,
        });

        profileForRedux.user = user;

        const userTeams = user.circles.items;
        setTeams(userTeams);

        // NOTE: The teams key on a redux profile is a normalized object, the teams state in this component is an array
        const normalizedUserTeams = userTeams.reduce((acc, team) => {
          acc[team.circleID] = team;
          return acc;
        }, {});

        profileForRedux.teams = normalizedUserTeams;
      }

      // Set profile in redux
      dispatch(loadProfileAction(profileForRedux));

      setIsLoading(false);
    }

    if (!reduxProfile?.teams) {
      prepareProfile();
    } else {
      setIsLoading(false);
    }

    // console.log(`ON page for -> ${userId}`);
  }, [userId]);

  //   console.log('ProfileAboutPage myProfile: ', myProfile);

  // useEffect(() => {
  //   console.log(myProfile);
  // }, [myProfile]);

  // useEffect for changing searchParams in url
  useEffect(() => {
    searchParams.set('subPage', subPage);
    navigate(`?${searchParams.toString()}`);
  }, [subPage, searchParams]);

  // Update states everytime reduxProfile is updated with dispatch
  useEffect(() => {
    if (reduxProfile?.user) {
      setMyProfile(reduxProfile.user);
    }

    if (reduxProfile?.teams) {
      const reduxTeamsArr = Object.values(reduxProfile.teams);
      reduxTeamsArr.sort((a, b) => {
        if (a.circle?.name < b.circle?.name) {
          return -1;
        }
        if (a.circle?.name > b.circle?.name) {
          return 1;
        }
        return 0;
      });
      setTeams(reduxTeamsArr);
    }
  }, [reduxProfile]);

  const handleBack = () => {
    if (routeBackToTeamPage) {
      navigate(`/teams/${teamId}`);
    } else if (routeBackToLeaderboard) {
      navigate('performance/leaderboard');
    } else {
      navigate('/people');
    }
  };

  const addProfileToTeams = async (teams) => {
    // console.log('addProfileToTeams teams: ', teams);
    if (!teams.length) return;

    const requests = [];
    for (const team of teams) {
      requests.push(
        API.graphql({
          query: mutations.modifyCircleMember,
          variables: {
            circleID: team.id,
            personID: userId,
            status: 'accepted',
            role: '',
          },
        })
      );
    }

    const allPromise = Promise.all(requests);

    allPromise
      // .then(() => {
      //   onTriggerTeamRefetch();
      // })
      .catch((error) => {
        console.error('addProfileToTeams error: ', error);
      });
  };

  const onSubmitTeams = async (newTeams) => {
    // console.log('onSubmitTeams newTeams: ', newTeams);
    // console.log('onSubmitTeams initialAcitveMembers: ', teams);
    setIsTeamListLoading(true);
    await addProfileToTeams(newTeams);

    const numNewTeams = newTeams.length;

    // TO DO: Replace refetch with redux update...
    const newTeamsObjForRedux = {};

    for (const team of newTeams) {
      const reduxTeamObj = {
        circleID: team.id,
        memberType: 'member',
        status: 'approved',
        isActive: true,
        isDeleted: false,
        circle: {
          imageName: team.imageName,
          imageType: team.imageType,
          name: team.name,
          isDeleted: false,
          isLeague: false,
          avatarFile: team.avatarFile,
          id: team.id,
        },
      };
      newTeamsObjForRedux[team.id] = reduxTeamObj;
    }

    const updatedReduxProfile = {
      ...reduxProfile,
      teams: {
        ...reduxProfile?.teams,
        ...newTeamsObjForRedux,
      },
    };

    dispatch(updateProfileAction(updatedReduxProfile));

    // setTimeout(() => {
    //   onTriggerTeamRefetch();
    // }, 500);

    // setTimeout(() => {
    if (numNewTeams === 0) {
      console.log(`${myProfile.name} not added to any new teams`);
    } else if (numNewTeams === 1) {
      // newTeams is only the teams the user is to be added to (the only team in this case), don't need to filter for the new team in adding user to multiple teams use cases
      const newTeam = newTeams[0];
      GamifyToast.success(
        `${myProfile.name} added to ${newTeam.name} successfully`
      );
    } else {
      GamifyToast.success(
        `${myProfile.name} added to ${numNewTeams} teams successfully`
      );
    }

    // Run user through addMemberToCurrentUserTeams function to update current user store
    const storeUser = {
      colorCode: myProfile.colorCode,
      id: myProfile.id,
      imageName: myProfile.imageName,
      isDeleted: myProfile.isDeleted,
      name: myProfile.name,
      status: myProfile.status,
    };

    addMemberToCurrentUserTeams(
      storeUser,
      teams.map((team) => team.id)
    );

    setIsTeamListLoading(false);
    // }, 750);
  };

  const profileButtons = [];

  // NOTE: The super user cannot be edited by non-super users, verify the user of the profile is either not a super admin OR the profile user is a super admin AND the current logged in user is a super admin before showing the edit button
  if (
    showEdit &&
    (!normalizedOrgRoles[myProfile?.orgs?.items[0]?.roleID]?.isSuper ||
      (isSuper &&
        normalizedOrgRoles[myProfile?.orgs?.items[0]?.roleID]?.isSuper))
  ) {
    profileButtons.push({
      // icon: '/images/Edit_Pencil.svg',
      icon: 'edit',
      title: 'Edit',
      clickFunction: function () {
        setShowUserFormModal(true);

        // NOTE: No longer need to navigate to separate route, UserFormPage is now a modal
        // navigate(`/people/edit/${myProfile.id}`, {
        //   // Indicate that user is editing from ProfileStatsPage so they reroute back here when cancelling or updating
        //   // NOTE: pass myProfile to UserFormModal as the user prop
        //   state: { user: myProfile, aboutProfile: true },
        // });
      },
    });
  }

  if (showCalendar) {
    profileButtons.push({
      // icon: '/images/add-people-icon.svg',
      icon: 'calendar',
      title: 'Availability',
      clickFunction: function () {
        console.log('Clicked Availability');
        // setShowAddMemberModal(true);
      },
    });
  }

  if (showPermissions) {
    profileButtons.push({
      // icon: '/images/link-icon.svg',
      icon: 'role',
      title: 'Permissions',
      clickFunction: function (e) {
        // console.log('Clicked Permissions');
        // setShowPermissionsModal(true);
        setPermissionsAnchorEl(e.currentTarget);
      },
    });
  }

  if (isLoading)
    return (
      <ProfileAboutPageSkeleton
        routeBackToTeamPage={routeBackToTeamPage}
        teamId={teamId}
        routeBackToLeaderboard={routeBackToLeaderboard}
      ></ProfileAboutPageSkeleton>
    );

  // At a url with a userId for a user that doesn't exist
  if (!myProfile)
    return <ProfileNoUserDisplay isDeleted={false}></ProfileNoUserDisplay>;

  // At a url for a user that has been deleted
  if (myProfile.status === 'deleted')
    return <ProfileNoUserDisplay isDeleted={true}></ProfileNoUserDisplay>;

  return (
    <>
      <UserFormModal
        showUserFormModal={showUserFormModal}
        setShowUserFormModal={setShowUserFormModal}
        userId={myProfile.id}
        user={myProfile}
        zIndex={100000}
      ></UserFormModal>
      <Box
        style={{
          // height: '52vh',
          height: 480,
          width: '100vw',
          paddingTop: '90px',
          paddingLeft: '60px',
          paddingRight: '60px',
          margin: 0,
          background:
            'radial-gradient(circle at 50% 80%, #FFD773 0%, #FF8630 50%, #FF6A00 100%)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            height: '100%',
            paddingTop: 34,
          }}
        >
          <Button
            className={'profile-about-page-back-button'}
            onClick={(e) => handleBack(e)}
            disableRipple={true}
          >
            <BackIcon></BackIcon>
            <Typography
              style={{
                color: '#0B0B0C',
                fontWeight: 600,
                fontSize: 16,
                marginLeft: 16,
              }}
            >
              {routeBackToTeamPage
                ? 'Back to team'
                : routeBackToLeaderboard
                ? 'Back to leaderboard'
                : 'Back to people'}
            </Typography>
          </Button>
        </Box>
        <Box
          style={{
            width: '40vw',
            height: '100%',
            padding: '15px 15px 45px 15px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          {/* Users created on web get imageType 'image/<jpeg, png, etc...>', account for that */}
          {myProfile.avatarFile ? (
            myProfile.imageType === 'picture' ||
            myProfile.imageType.split('/')[0] === 'image' ? (
              <Box
                component={'img'}
                sx={{
                  width: 240,
                  height: 240,
                  minHeight: 240,
                  objectFit: 'cover',
                  borderRadius: '2000px',
                }}
                alt={myProfile.name}
                src={myProfile.avatarFile}
              />
            ) : (
              <CardMedia
                image={myProfile.imageName}
                component="img"
                sx={{
                  borderRadius: '2000px',
                  width: 240,
                  height: 240,
                  objectFit: 'contain',
                }}
              />
            )
          ) : (
            <Avatar
              sx={{
                backgroundColor: '#868686',
                fontSize: '96px',
                width: 240,
                height: 240,
                minHeight: 240,
                borderRadius: '2000px',
              }}
              variant="rounded"
            >
              {myProfile.initials}
            </Avatar>
          )}
          <Box style={{ display: 'flex' }}>
            <Text
              style={{
                fontSize: '36px',
                fontWeight: 700,
                marginTop: '16px',
                whiteSpace: 'nowrap',
              }}
            >
              {myProfile.name}
            </Text>
            {myProfile.status !== 'active' && (
              <Text
                style={{
                  // color: 'red',
                  color: '#DA2424',
                  fontSize: '36px',
                  fontWeight: 400,
                  marginTop: '16px',
                  marginLeft: 4,
                }}
              >
                (Deactivated)
              </Text>
            )}
          </Box>
          <Text
            style={{
              fontSize: '18px',
              fontWeight: 400,
            }}
          >
            {myProfile.title}
          </Text>
        </Box>
        {/* Box below keeps team image, name, and description centered */}
        <Box
          style={{
            display: 'flex',
            minWidth: routeBackToTeamPage
              ? 155.86
              : routeBackToLeaderboard
              ? 210.48
              : 159.45,
          }}
        >
          <Typography style={{ color: 'transparent' }}>.</Typography>
        </Box>
      </Box>
      <Box
        style={{
          position: 'relative',
          top: '-30px',
          marginTop: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          style={{
            display: 'flex',
            width: '30vw',
            minWidth: 250,
            justifyContent: 'space-around',
          }}
        >
          {profileButtons.map((button, i) => {
            return (
              <Box
                key={i}
                onClick={button.clickFunction}
                className="hov"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: 91, // keep width uniform to stay centered
                }}
              >
                <Box
                  style={{
                    height: '56px',
                    width: '56px',
                    borderRadius: '50%',
                    boxShadow: '-1px 1px 4px 0 rgba(0, 0, 0, 0.1)',
                    // border: '1px solid red',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    marginBottom: '10px',
                  }}
                >
                  <GamifyIcon icon={button.icon} color={'#FF6A00'}></GamifyIcon>
                </Box>
                <Text
                  style={{
                    color: '#FF6A00',
                    fontWeight: '600',
                    fontSize: 14,
                  }}
                >
                  {button.title}
                </Text>
              </Box>
            );
          })}
          <AddMemberLinkTeamModal
            title={`Add User to Teams`}
            team={null} // not needed for adding members
            teamLevelNumIdMap={null} // not needed for adding members
            teamLevelNumNameMap={null} // not needed for adding members
            noAutoSelect={true}
            pickCircles={true}
            pickUsers={false}
            update={null}
            filter={global.me.orgID}
            itemValue={''}
            onSubmit={(newTeams) => {
              //   console.log('AMLT Modal newTeams onSubmit: ', newTeams);
              // NOTE: First team of initialActiveMembers/teams is the first element of newTeams even when no teams selected, removing it to avoid any issues...
              // Typically, newTeams would include all initialActiveMembers/teams, but only has the first one... may be due to difference in shape of objects in teams and the teams in AMLT Modal...
              newTeams.shift();
              onSubmitTeams(newTeams);
            }}
            hideInitialChecked
            initialActiveMembers={teams}
            setShowModal={setShowAddProfileToTeamsModal}
            showModal={showAddProfileToTeamsModal}
          ></AddMemberLinkTeamModal>
        </Box>
        <Box style={{ width: '40vw', height: '100%', marginTop: '4ch' }}>
          <Box style={{ borderBottom: '1px solid #868686', display: 'flex' }}>
            <Box
              className="profile-about-page-section-button hov"
              style={{
                borderBottom:
                  subPage === 'about' ? '3px solid #FF6A00' : 'none',
                color: subPage === 'about' ? '#FF6A00' : '#868686',
              }}
              onClick={() => {
                setSubPage('about');

                // Update in redux
                const updatedReduxProfile = {
                  ...reduxProfile,
                  subPage: {
                    ...reduxProfile.subPage,
                    about: 'about',
                  },
                };
                dispatch(updateProfileAction(updatedReduxProfile));
              }}
            >
              About
            </Box>

            {showTeams && (
              <Box
                className="profile-about-page-section-button hov"
                style={{
                  borderBottom:
                    subPage === 'teams' ? '3px solid #FF6A00' : 'none',
                  color: subPage === 'teams' ? '#FF6A00' : '#868686',
                }}
                onClick={() => {
                  setSubPage('teams');

                  // Update in redux
                  const updatedReduxProfile = {
                    ...reduxProfile,
                    subPage: {
                      ...reduxProfile.subPage,
                      about: 'teams',
                    },
                  };
                  dispatch(updateProfileAction(updatedReduxProfile));
                }}
              >
                {`Teams (${teams.length})`}
              </Box>
            )}
          </Box>
        </Box>
        <Box
          style={{
            width: '40vw',
            marginTop: '2ch',
            minWidth: 'fit-content',
          }}
        >
          {subPage === 'about' && myProfile && (
            // <AboutCard
            //   team={myProfile}
            //   childTeams={childTeams}
            //   isMemberListLoading={isMemberListLoading}
            // />
            <UserAbout
              user={myProfile}
              teams={teams}
              teamId={teamId}
              routeBackToTeamPage={routeBackToTeamPage}
              routeBackToLeaderboard={routeBackToLeaderboard}
            ></UserAbout>
          )}
          {subPage === 'teams' && myProfile && (
            // <TeamMembers
            //   teamId={teamId}
            //   members={members}
            //   isMemberListLoading={isMemberListLoading}
            //   setIsMemberListLoading={setIsMemberListLoading}
            //   onTriggerRefetch={onTriggerRefetch}
            // />
            <UserTeams
              user={myProfile}
              teams={teams}
              setShowAddProfileToTeamsModal={setShowAddProfileToTeamsModal}
              isTeamListLoading={isTeamListLoading}
            ></UserTeams>
          )}
        </Box>
      </Box>
      {/* <EditUserModal
        user={myProfile}
        open={isEditModalOpen}
        onClose={() => {
          setIsEditModalOpen(false);
        }}
        onTriggerTableRefetch={() => onTriggerRefetch()}
      /> */}
      <PermissionsModal
        user={myProfile}
        rolePositionObj={rolePositionObj}
        setRolePositionObj={setRolePositionObj}
        // showPermissionsModal={showPermissionsModal}
        // setShowPermissionsModal={setShowPermissionsModal}
        permissionsAnchorEl={permissionsAnchorEl}
        setPermissionsAnchorEl={setPermissionsAnchorEl}
      ></PermissionsModal>
    </>
  );
}

function UserAbout({
  user,
  teamId,
  routeBackToTeamPage,
  routeBackToLeaderboard,
}) {
  // console.log('UserStats stats: ', stats);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { removeMembersFromAllCurrentUserTeam } = useCurrentUserStore();

  const { hasPermission, orgRoles } = usePermissions();

  const normalizedOrgRoles = orgRoles.reduce((acc, role) => {
    acc[role.id] = role;
    return acc;
  }, {});

  const showDelete = hasPermission(PermissionKeys.TeamsPeople_DeleteUser, [
    user.id,
  ]);

  const [isSaving, setIsSaving] = useState(false);
  const [dynamicModalObj, setDynamicModalObj] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const modalsConfig = {
    delete: {
      //   icon: "warning",
      title: `Delete ${user?.name}?`,
      subtitle: `You won’t be able to revert this\naction.`,
      //   iconBackground: '#FFDCF3',
      // confirmButtonColor: '#FF5C77',
      confirmButtonColor: '#E35050',
      onConfirm: () => {
        setIsSaving(true);
        changeUserStatus(user, 'delete', (res) => {
          setIsSaving(false);
          if (res) {
            setShowDeleteModal(false);
            setDynamicModalObj(null);

            if (routeBackToTeamPage) {
              navigate(`/teams/${teamId}`);
            } else if (routeBackToLeaderboard) {
              navigate('/performance/leaderboard');
            } else {
              navigate('/people');
            }
            GamifyToast.success(`${user.name} Deleted`);
          }
        });

        // Remove this user from current user store teams
        removeMembersFromAllCurrentUserTeam([user.id]);
      },
    },
  };

  //
  //  Do user admin functions
  //
  async function changeUserStatus(user, action, onComplete) {
    // LOG.debug ("changeUserStatus: ", user);
    try {
      await API.graphql({
        query: mutations.personAdminFunctions,
        variables: {
          userID: user.id,
          username: user.username,
          action,
        },
      });
      // LOG.debug ("userAdminFunctionsRes: ", userAdminFunctionsRes);
    } catch (err) {
      console.error(
        'changeUserStatus(): error updating UserStatus:',
        JSON.stringify(err)
      );
      GamifyToast.error('Error updating status, please try again');
      onComplete(false);
      return false;
    }
    // let alertMessage;
    // if (action === "delete") {
    //   alertMessage = `${user.name} deleted`;
    // } else {
    //   alertMessage = `${user.name}'s Status has been updated`;
    // }
    // alert(alertMessage);

    // Remove user from profiles and users in redux:
    dispatch(removeUserAction(user.id));
    dispatch(removeProfileAction(user.id));

    onComplete(true);
    return true;
  }

  return (
    <>
      <DynamicConfirmModal
        showDynamicConfirmModal={showDeleteModal}
        setShowDynamicConfirmModal={setShowDeleteModal}
        zIndex={100002}
        title={dynamicModalObj?.title}
        subtitle={dynamicModalObj?.subtitle}
        subtitleColor={dynamicModalObj?.subtitleColor}
        // icon={dynamicModalObj?.icon}
        // iconBackground={dynamicModalObj?.iconBackground}
        confirmButtonColor={dynamicModalObj?.confirmButtonColor}
        isSaving={isSaving}
        onConfirm={dynamicModalObj?.onConfirm}
      ></DynamicConfirmModal>
      <Box id="about-subpage">
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 16,
          }}
        >
          <Typography
            style={{
              color: '#222428',
              fontSize: '16px',
              fontWeight: 500,
              whiteSpace: 'nowrap',
            }}
          >
            Email:
          </Typography>
          <Typography
            style={{
              color: '#868686',
              fontSize: '16px',
              fontWeight: 500,
              whiteSpace: 'nowrap',
            }}
          >
            {user.email !== '' ? user.email : 'None'}
          </Typography>
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 16,
          }}
        >
          <Typography
            style={{
              color: '#222428',
              fontSize: '16px',
              fontWeight: 500,
              whiteSpace: 'nowrap',
            }}
          >
            Phone number:
          </Typography>
          <Typography
            style={{
              color: '#868686',
              fontSize: '16px',
              fontWeight: 500,
              whiteSpace: 'nowrap',
            }}
          >
            {user.phoneNumber}
          </Typography>
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 16,
          }}
        >
          <Typography
            style={{
              color: '#222428',
              fontSize: '16px',
              fontWeight: 500,
              whiteSpace: 'nowrap',
            }}
          >
            Title:
          </Typography>
          <Typography
            style={{
              color: '#868686',
              fontSize: '16px',
              fontWeight: 500,
              whiteSpace: 'nowrap',
            }}
          >
            {user.title !== '' ? user.title : 'None'}
          </Typography>
        </Box>
        {/* NOTE: User level is not used, so this has been removed */}
        {/* <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 16,
          }}
        >
          <Typography
            style={{ color: '#222428', fontSize: '16px', fontWeight: 500 }}
          >
            Level:
          </Typography>
          <Typography
            style={{ color: '#868686', fontSize: '16px', fontWeight: 500 }}
          >
            {user.circleLevel ? user.circleLevel.name : 'Undefined'}
          </Typography>
        </Box> */}
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 16,
          }}
        >
          <Typography
            style={{
              color: '#222428',
              fontSize: '16px',
              fontWeight: 500,
              whiteSpace: 'nowrap',
            }}
          >
            Location:
          </Typography>
          <Typography
            style={{
              color: '#868686',
              fontSize: '16px',
              fontWeight: 500,
              whiteSpace: 'nowrap',
            }}
          >
            {user.location !== '' ? user.location : 'None'}
          </Typography>
        </Box>
        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography
            style={{
              color: '#222428',
              fontSize: '16px',
              fontWeight: 500,
              whiteSpace: 'nowrap',
            }}
          >
            Role:
          </Typography>
          <Typography
            style={{
              color: '#868686',
              fontSize: '16px',
              fontWeight: 500,
              whiteSpace: 'nowrap',
            }}
          >
            {user.orgs?.items[0]?.roleID !== '' && user.orgs?.items[0]?.roleID
              ? normalizedOrgRoles[user.orgs?.items[0]?.roleID]?.roleName
              : 'None'}
          </Typography>
        </Box>
      </Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '2ch',
        }}
      >
        {/* NOTE: If the user of the current profile is a super user the cannot be deleted even if the current user has user deleting permission */}
        {showDelete &&
          !normalizedOrgRoles[user.orgs?.items[0]?.roleID]?.isSuper && (
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                setDynamicModalObj(modalsConfig.delete);
                setShowDeleteModal(true);
              }}
            >
              <GamifyIcon
                icon={'trash'}
                color={'#DA2424'}
                backgroundColor={'white'}
              ></GamifyIcon>
              <Typography
                style={{ color: '#DA2424', fontSize: '16px', fontWeight: 600 }}
              >
                Remove user
              </Typography>
            </Box>
          )}
      </Box>
    </>
  );
}

function UserTeams({
  user,
  teams,
  setShowAddProfileToTeamsModal,
  isTeamListLoading,
}) {
  const { hasPermission } = usePermissions();

  const showAddToTeam = hasPermission(
    PermissionKeys.TeamsPeople_AddPersonToTeam,
    [user.id]
  );

  const onRemoveMemberFromTeam = async (member, teamId) => {
    try {
      API.graphql({
        query: mutations.modifyCircleMember,
        variables: {
          circleID: teamId,
          personID: member.id,
          status: 'removed',
          role: '',
        },
      });

      // Success toast in MemberOptionMenu
      return true;
    } catch (err) {
      console.error('onRemoveMember error: ', err);
      GamifyToast.error(
        `We couldn't remove ${member.name}. Please try again later.`
      );
      return false;
    }
  };

  return (
    <>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '2ch',
        }}
      >
        {showAddToTeam && (
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            sx={{ cursor: 'pointer' }}
            onClick={() => setShowAddProfileToTeamsModal(true)}
          >
            <GamifyIcon
              icon={'add'}
              color={'#FF6A00'}
              backgroundColor={'white'}
            ></GamifyIcon>
            <Typography
              style={{ color: '#FF6A00', fontSize: '16px', fontWeight: 600 }}
            >
              Add to a new team
            </Typography>
          </Box>
        )}
      </Box>
      {isTeamListLoading && (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '20px',
          }}
        >
          <CircularProgress style={{ color: 'black' }}></CircularProgress>
        </Box>
      )}
      {teams.length > 0 ? (
        teams.map((team, i) => {
          return (
            <TeamCard
              key={i}
              user={user}
              team={team}
              onRemoveMemberFromTeam={onRemoveMemberFromTeam}
              isOnUserForm={false}
            />
          );
        })
      ) : (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 150,
          }}
        >
          <Typography>This user is on no teams</Typography>
        </Box>
      )}
    </>
  );
}
