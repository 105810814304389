export function isValidFlexField(
  flexFields: { name: string; contactFields?: { [key: string]: any } }[],
  fieldName: string
): boolean {
  if (fieldName.includes('.')) {
    const [parentFieldName, childFieldName] = fieldName.split('.');
    const parentField = flexFields.find(
      (field) => field.name === parentFieldName
    );
    if (!parentField) return false;
    return parentField.contactFields?.[childFieldName] !== undefined || false;
  }
  return flexFields.some((flexField) => flexField.name === fieldName) ?? false;
}
