import React, { useState, useMemo, useEffect } from 'react';
import { Scheduler } from '@paiv/react-scheduler';
import {
  Box,
  Typography,
  Avatar,
  CircularProgress,
  Alert,
  Snackbar,
  Dialog,
  IconButton,
  Menu,
  MenuItem,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@mui/material';
import { ProcessedEvent } from '@paiv/react-scheduler/types';
import Fuse from 'fuse.js';
import { API } from 'aws-amplify';
import * as mutations from '../../../../graphql/mutations';
import { useTeamsQuery } from './api/useTeamsQuery';
import { useUsersQuery, Employee } from './api/useUsersQuery';
import { CalendarEvent } from './types';
import { fetchAppointmentGrid } from './api/fetchAppointmentGrid';
import { format, addMinutes, isValid, isSameDay } from 'date-fns';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { SchedulingSidebar } from './components/SchedulingSidebar';
import { View } from '@paiv/react-scheduler/components/nav/Navigation';
import CloseIcon from '@mui/icons-material/Close';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import PeopleIcon from '@mui/icons-material/People';
import DescriptionIcon from '@mui/icons-material/Description';
import EditIcon from '@mui/icons-material/Edit';
import AppointmentEditForm from './components/AppointmentEditForm';
import { GamifyIcon } from '../../../components/GamifyIcon';
import { markAppointment } from './api/markAppointment';
import DealDetailsDrawerAdapter from './components/DealDetailsDrawerAdapter';
import { Deal } from './api/useDealsQuery';
import { GamifyToast } from '../../../common/CustomToasts';
import { renderAgendaEvent } from './components/AgendaEventRenderer';

type CustomView = View | 'agenda' | 'month';
type ViewMode = 'calendar' | 'agenda';

type SelectionMode = 'manyDays' | 'week' | 'month';

const formatTime = (date: Date): string => {
  return date
    .toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    })
    .toLowerCase();
};

const COLOR_PALETTE = [
  '#FFD6CC',
  '#CCE5FF',
  '#D6F5D6',
  '#FFF0CC',
  '#E5CCFF',
  '#FFE5CC',
  '#CCF2FF',
  '#FFCCF2',
  '#E6FFCC',
  '#F2CCFF',
];

interface AppointmentRequest {
  appointmentDate: string;
  appointmentStartTime: string;
  appointmentLength: number;
  notes: string;
  closerID: string;
  action: string;
  actionTimestamp: string;
  appointmentID?: string;
  dealID?: string;
  statusRole: string;
}

const roundToNearest30 = (date: Date): Date => {
  const minutes = date.getMinutes();
  const adjustment = minutes % 30;
  let toAdd = 0;

  if (adjustment === 0) {
    toAdd = 0;
  } else if (adjustment <= 15) {
    toAdd = -adjustment;
  } else {
    toAdd = 30 - adjustment;
  }

  return addMinutes(date, toAdd);
};

const getLocalTimeRounded = (gmtDate: Date): string => {
  const date = new Date(gmtDate);
  const roundedDate = roundToNearest30(date);
  const hours = roundedDate.getHours().toString().padStart(2, '0');
  const minutes = roundedDate.getMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
};

const getLocalDate = (gmtDate: Date): string => {
  const date = new Date(gmtDate);
  return format(date, 'yyyy-MM-dd');
};

const newLocalToISODateFormat = (dateStr?: Date | string): string => {
  if (!dateStr || dateStr === 'undefined' || dateStr === '') {
    return '';
  }

  try {
    const date = typeof dateStr === 'string' ? new Date(dateStr) : dateStr;

    if (!isValid(date)) {
      return '';
    }

    return date.toISOString();
  } catch (error) {
    console.error('Error formatting date:', error);
    return '';
  }
};

interface TeamWithClosers {
  id: string;
  name: string;
  isSelected: boolean;
  closers: Employee[];
  image?: string;
}

const getWeekDates = (date: Date): Date[] => {
  const inputDate = new Date(date);
  console.log('Input date for getWeekDates:', inputDate);

  const dayOfWeek = inputDate.getDay();
  console.log('Day of week:', dayOfWeek);

  const weekDates: Date[] = [];

  const sundayDate = new Date(inputDate);

  if (dayOfWeek !== 0) {
    sundayDate.setDate(inputDate.getDate() - dayOfWeek);
  }

  console.log('Starting Sunday date:', sundayDate);

  for (let i = 0; i < 7; i++) {
    const currentDate = new Date(sundayDate);
    currentDate.setDate(sundayDate.getDate() + i);

    currentDate.setHours(12, 0, 0, 0);
    weekDates.push(currentDate);
  }

  return weekDates;
};

const SchedulerNavBar: React.FC<{
  selectedDate: Date;
  appointmentCount: number;
  currentView: CustomView;
  viewMode: ViewMode;
  selectionMode: SelectionMode;
  onViewModeChange: (mode: ViewMode) => void;
  onSelectionModeChange: (mode: SelectionMode) => void;
  onNavigatePrev: () => void;
  onNavigateNext: () => void;
}> = ({
  selectedDate,
  appointmentCount,
  currentView,
  viewMode,
  selectionMode,
  onViewModeChange,
  onSelectionModeChange,
  onNavigatePrev,
  onNavigateNext,
}) => {
  const [selectionMenuAnchor, setSelectionMenuAnchor] =
    useState<null | HTMLElement>(null);

  const handleSelectionMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setSelectionMenuAnchor(event.currentTarget);
  };

  const handleSelectionMenuClose = () => {
    setSelectionMenuAnchor(null);
  };

  const handleSelectionModeSelect = (mode: SelectionMode) => {
    onSelectionModeChange(mode);
    handleSelectionMenuClose();
  };

  const getHeaderText = () => {
    if (currentView === 'week' || currentView === 'agenda') {
      const dayOfWeek = selectedDate.getDay();
      const firstDayOfWeek = new Date(selectedDate);
      if (dayOfWeek !== 0) {
        firstDayOfWeek.setDate(selectedDate.getDate() - dayOfWeek);
      }
      return `Week of ${format(firstDayOfWeek, 'MMMM d, yyyy')}`;
    } else if (currentView === 'day') {
      return format(selectedDate, 'MMMM d, yyyy');
    } else {
      return format(selectedDate, 'MMMM yyyy');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px 16px',
        borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
        backgroundColor: '#fff',
        height: '48px',
        position: 'sticky',
        top: 0,
      }}
    >
      <Box display="flex" alignItems="center">
        <IconButton
          onClick={onNavigatePrev}
          size="small"
          sx={{
            padding: '4px',
            color: '#666',
          }}
        >
          <KeyboardArrowLeftIcon fontSize="small" />
        </IconButton>
        <IconButton
          onClick={onNavigateNext}
          size="small"
          sx={{
            padding: '4px',
            color: '#666',
            mr: 1,
          }}
        >
          <KeyboardArrowRightIcon fontSize="small" />
        </IconButton>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 500,
            fontSize: '14px',
            color: '#333',
          }}
        >
          {getHeaderText()}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            ml: 1,
            color: '#666',
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
            padding: '2px 8px',
            borderRadius: '12px',
            fontSize: '12px',
            fontWeight: 400,
          }}
        >
          {appointmentCount} Appointments
        </Typography>
      </Box>

      <Box display="flex" alignItems="center">
        {/* Selection Mode Button */}
        <Button
          variant="outlined"
          endIcon={<ArrowDropDownIcon />}
          onClick={handleSelectionMenuOpen}
          sx={{
            mr: 2,
            textTransform: 'none',
            borderColor: '#D7D9D6',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderRadius: '8px',
            color: '#333',
            fontSize: '13px',
            fontWeight: 500,
            padding: '0 10px',
            minWidth: '80px',
            height: '36px',
            boxSizing: 'border-box',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#FFF',
            '&:hover': {
              borderColor: '#D7D9D6',
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
          }}
        >
          {selectionMode === 'manyDays'
            ? 'Days'
            : selectionMode === 'week'
            ? 'Week'
            : 'Month'}
        </Button>

        {/* Selection Mode Menu */}
        <Menu
          anchorEl={selectionMenuAnchor}
          open={Boolean(selectionMenuAnchor)}
          onClose={handleSelectionMenuClose}
          PaperProps={{
            sx: {
              background: 'white',
              boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
              borderRadius: '4px',
              minWidth: '120px',
              zIndex: 1000,
            },
          }}
        >
          <MenuItem
            onClick={() => handleSelectionModeSelect('manyDays')}
            selected={selectionMode === 'manyDays'}
            sx={{
              fontSize: '13px',
              padding: '6px 16px',
              minHeight: '36px',
              backgroundColor:
                selectionMode === 'manyDays'
                  ? 'rgba(0, 0, 0, 0.04)'
                  : 'transparent',
            }}
          >
            Days
          </MenuItem>
          <MenuItem
            onClick={() => handleSelectionModeSelect('week')}
            selected={selectionMode === 'week'}
            sx={{
              fontSize: '13px',
              padding: '6px 16px',
              minHeight: '36px',
              backgroundColor:
                selectionMode === 'week'
                  ? 'rgba(0, 0, 0, 0.04)'
                  : 'transparent',
            }}
          >
            Week
          </MenuItem>
          <MenuItem
            onClick={() => handleSelectionModeSelect('month')}
            selected={selectionMode === 'month'}
            sx={{
              fontSize: '13px',
              padding: '6px 16px',
              minHeight: '36px',
              backgroundColor:
                selectionMode === 'month'
                  ? 'rgba(0, 0, 0, 0.04)'
                  : 'transparent',
            }}
          >
            Month
          </MenuItem>
        </Menu>

        {currentView !== 'manyDays' && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              border: '1px solid #D7D9D6',
              borderRadius: '8px',
              overflow: 'hidden',
              mr: 2,
              backgroundColor: '#FFF',
              height: '36px',
              width: '80px',
              justifyContent: 'space-between',
            }}
          >
            <Box
              onClick={() => onViewModeChange('calendar')}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '0',
                color: viewMode === 'calendar' ? '#FF6A00' : '#666',
                backgroundColor:
                  viewMode === 'calendar'
                    ? 'rgba(255, 106, 0, 0.05)'
                    : 'transparent',
                height: '100%',
                width: '50%',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor:
                    viewMode === 'calendar'
                      ? 'rgba(255, 106, 0, 0.05)'
                      : 'rgba(0, 0, 0, 0.04)',
                },
              }}
            >
              <GamifyIcon
                icon="calendar"
                color={viewMode === 'calendar' ? '#FF6A00' : '#666'}
                width={20}
                height={20}
              />
            </Box>
            <Box
              sx={{ width: '1px', height: '24px', backgroundColor: '#D7D9D6' }}
            />
            <Box
              onClick={() => onViewModeChange('agenda')}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '0',
                color: viewMode === 'agenda' ? '#FF6A00' : '#666',
                backgroundColor:
                  viewMode === 'agenda'
                    ? 'rgba(255, 106, 0, 0.05)'
                    : 'transparent',
                height: '100%',
                width: '50%',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor:
                    viewMode === 'agenda'
                      ? 'rgba(255, 106, 0, 0.05)'
                      : 'rgba(0, 0, 0, 0.04)',
                },
              }}
            >
              <GamifyIcon
                icon="check"
                color={viewMode === 'agenda' ? '#FF6A00' : '#666'}
                width={20}
                height={20}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export const SchedulingScreenView: React.FC = () => {
  const [selectedEmployees, setSelectedEmployees] = useState<string[]>([]);
  const [activeTab, setActiveTab] = useState<'people' | 'team'>('people');
  const [searchQuery, setSearchQuery] = useState('');
  const [teamMembers, setTeamMembers] = useState<Employee[]>([]);
  const [schedulerView, setSchedulerView] = useState<CustomView>('week');
  const [viewMode, setViewMode] = useState<ViewMode>('calendar');
  const [selectionMode, setSelectionMode] = useState<SelectionMode>('week');
  const isAgendaView = viewMode === 'agenda';

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filteredEmployees, setFilteredEmployees] = useState<Employee[]>([]);
  const [filteredTeamMembers, setFilteredTeamMembers] = useState<Employee[]>(
    []
  );
  console.log('!!filteredTeamMembers', filteredTeamMembers);
  const [calendarEvents, setCalendarEvents] = useState<CalendarEvent[]>([]);
  const [isLoadingEvents, setIsLoadingEvents] = useState(false);

  const [selectedDates, setSelectedDates] = useState<Date[]>([new Date()]);
  const [colorAssignments, setColorAssignments] = useState<
    Record<string, string>
  >({});

  const { data: teams = [], isLoading: isTeamsLoading } = useTeamsQuery();
  const { data: employees = [], isLoading: isUsersLoading } = useUsersQuery();

  const [selectedTeams, setSelectedTeams] = useState<string[]>([]);
  const [teamsWithClosers, setTeamsWithClosers] = useState<TeamWithClosers[]>(
    []
  );
  const maxTeamMembers = 500;

  const options = {
    keys: ['name', 'role'],
    includeScore: true,
    threshold: 0.2,
  };

  const fuse = new Fuse(
    activeTab === 'people' ? employees : teamMembers,
    options
  );

  useEffect(() => {
    if (activeTab === 'team' && teams.length > 0) {
      const allMembers = teams.reduce((acc, team) => {
        team.users.items
          .filter(
            (member) =>
              member.isActive &&
              member.status !== 'removed' &&
              member.user &&
              member.user.status !== 'deleted'
          )
          .forEach((member) => {
            if (member.user?.id) {
              const user = employees.find((emp) => emp.id === member.user.id);
              if (user) {
                acc.add(user);
              }
            }
          });
        return acc;
      }, new Set<Employee>());

      setTeamMembers(Array.from(allMembers));
    }
  }, [activeTab, teams, employees]);

  useEffect(() => {
    if (searchQuery) {
      const results = fuse.search(searchQuery).map(({ item }) => item);
      if (activeTab === 'people') {
        setFilteredEmployees(results);
      } else {
        setFilteredTeamMembers(results);
      }
    } else {
      setFilteredEmployees(employees);
      setFilteredTeamMembers(teamMembers);
    }
  }, [searchQuery, activeTab, employees, teamMembers]);

  const resources = employees.map((emp) => ({
    admin_id: emp.id,
    title: emp.name,
    mobile: emp.role,
    avatar: emp.avatar,
    color:
      emp.role === 'Tech'
        ? '#e8f3e3'
        : emp.role === 'Sales'
        ? '#fff3d9'
        : '#f3e8f3',
  }));

  const eventRenderer = (props: any) => {
    const { event, onClick, ...restProps } = props;
    const assignedColor = colorAssignments[event.admin_id] || '#e8f3e3';
    const displayFields = event.extendedProps?.displayFields || {};

    const assignedUser = resources.find((r) => r.admin_id === event.admin_id);

    const getTimeString = (start: Date, end: Date) => {
      try {
        return `${formatTime(start)} - ${formatTime(end)}`;
      } catch (error) {
        return 'Invalid time';
      }
    };

    const timeString = getTimeString(event.start, event.end);

    const getImprovedTopPositioning = (startTime: Date) => {
      const hours = startTime.getHours();
      if (hours <= 11) {
        return -11;
      }
      // Add 2px per hour after 12PM
      return -12 - (hours - 12) * 2.2;
    };

    const marginTop = getImprovedTopPositioning(event.start);

    const displayTitle =
      displayFields.customerName ||
      event.extendedProps?.dealName ||
      event.title;

    const handleClick = (e: React.MouseEvent) => {
      e.stopPropagation();
      onClick(e);
    };

    return (
      <Box
        {...restProps}
        onClick={handleClick}
        sx={{
          cursor: 'pointer',
          p: 1.5,
          height: 'calc(100% - 1px)',
          bgcolor: assignedColor,
          borderRadius: '4px',
          display: 'flex',
          flexDirection: 'column',
          gap: 0.5,
          position: 'absolute',
          right: 0,
          left: 0,
          top: `${marginTop}px`,
          overflow: 'hidden',
          zIndex: 1,
          '&:hover': {
            filter: 'brightness(0.97)',
          },
          '&:active': {
            cursor: 'grabbing',
            opacity: 0.8,
            transform: 'scale(0.98)',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 0.25,
          }}
        >
          <Typography
            variant="subtitle2"
            noWrap
            sx={{
              fontWeight: 500,
              color: '#2c2c2c',
              fontSize: '0.8rem',
              flex: 1,
              mr: 0.5,
            }}
          >
            {displayTitle}
          </Typography>
          {assignedUser?.avatar && (
            <Avatar
              src={assignedUser.avatar}
              alt={assignedUser.title}
              sx={{
                width: 18,
                height: 18,
                fontSize: '0.7rem',
                flexShrink: 0,
              }}
            />
          )}
        </Box>
        <Typography
          variant="caption"
          sx={{
            color: '#666',
            fontSize: '0.7rem',
            lineHeight: 1.1,
          }}
        >
          {timeString}
        </Typography>
        {displayFields.fullAddress && (
          <Typography
            variant="caption"
            sx={{
              color: '#666',
              fontSize: '0.7rem',
              display: '-webkit-box',
              WebkitLineClamp: 1,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              lineHeight: 1.1,
              wordBreak: 'break-word',
            }}
          >
            {displayFields.fullAddress}
          </Typography>
        )}
        {displayFields.notes && (
          <Typography
            variant="caption"
            sx={{
              color: '#666',
              fontSize: '0.7rem',
              display: '-webkit-box',
              WebkitLineClamp: 1,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              lineHeight: 1.1,
              fontStyle: 'italic',
              wordBreak: 'break-word',
            }}
          >
            {displayFields.notes}
          </Typography>
        )}
      </Box>
    );
  };

  const filteredResources = useMemo(
    () =>
      resources.filter((resource) =>
        selectedEmployees.includes(resource.admin_id)
      ),
    [resources, selectedEmployees]
  );

  const filteredEvents = useMemo(
    () =>
      calendarEvents.filter((event) =>
        selectedEmployees.includes(event.admin_id)
      ),
    [calendarEvents, selectedEmployees]
  );

  useEffect(() => {
    console.log('Selected Employees:', selectedEmployees);
    console.log('Filtered Resources:', filteredResources);
    console.log('Filtered Events:', filteredEvents);
  }, [selectedEmployees, filteredResources, filteredEvents]);

  // Add useEffect to style month events
  useEffect(() => {
    const styleMonthEvents = () => {
      // Only run in month view
      if (schedulerView !== 'month') return;

      // Find all event dots and transform them into left borders
      const eventDots = document.querySelectorAll('.rs__event_dot');
      eventDots.forEach((dot) => {
        const dotEl = dot as HTMLElement;

        // Find the parent event element
        const eventEl = dotEl.closest(
          '.rs__event, .rs__month_event, [role="button"]'
        ) as HTMLElement;
        if (!eventEl) return;

        // Get the dot's color
        const dotStyle = window.getComputedStyle(dotEl);
        const dotColor = dotStyle.backgroundColor;

        // Apply the styling to convert dot to left border
        eventEl.style.backgroundColor = 'white';
        eventEl.style.background = 'white';
        eventEl.style.borderLeft = `4px solid ${dotColor}`;
        eventEl.style.boxShadow = 'none';
        eventEl.style.borderRadius = '0';
      });
    };

    // Style events initially and whenever schedulerView changes
    const timer = setTimeout(styleMonthEvents, 300);

    return () => clearTimeout(timer);
  }, [schedulerView, calendarEvents, filteredEvents]);

  const scheduleAppointment = async ({
    appointmentID,
    closerID,
    appointmentStartTime,
    appointmentEndTime,
    notes = '',
    dealId = '',
    onSuccess,
  }: {
    appointmentID?: string;
    closerID: string;
    appointmentStartTime: Date;
    appointmentEndTime: Date;
    notes?: string;
    dealId?: string;
    onSuccess: (result: string | false) => void;
  }): Promise<void> => {
    console.debug('!!!scheduleAppointment: ', {
      appointmentID,
      closerID,
      appointmentStartTime,
      appointmentEndTime,
      notes,
      dealId,
    });

    try {
      const generatedAppointmentId = appointmentID || `app-${Date.now()}`;

      const startMinutes =
        appointmentStartTime.getHours() * 60 +
        appointmentStartTime.getMinutes();
      const endMinutes =
        appointmentEndTime.getHours() * 60 + appointmentEndTime.getMinutes();
      const appointmentLength = endMinutes - startMinutes;

      console.log(
        'Calculated appointment length:',
        appointmentLength,
        'minutes'
      );

      const request: AppointmentRequest = {
        appointmentDate: getLocalDate(appointmentStartTime),
        appointmentStartTime: getLocalTimeRounded(appointmentStartTime),
        appointmentLength,
        notes,
        closerID,
        action: appointmentID ? 'rescheduled' : 'scheduled',
        actionTimestamp: newLocalToISODateFormat(appointmentStartTime),
        statusRole: 'setter',
      };

      if (appointmentID) {
        request.appointmentID = appointmentID;
      } else {
        request.appointmentID = generatedAppointmentId;
      }

      if (dealId) {
        request.dealID = dealId;
      }

      const requestJson = JSON.stringify(request);
      console.log('Request being sent to API:', request);
      console.log('Request JSON:', requestJson);

      const response = await API.graphql({
        query: mutations.bookCalendarSlot,
        variables: {
          request: requestJson,
        },
      });

      console.log('API response:', response);

      const responseData = response as any;
      console.log('Response data:', responseData.data);

      let resultId = generatedAppointmentId;
      if (responseData.data && responseData.data.bookCalendarSlot) {
        try {
          const bookCalendarSlotResponse = JSON.parse(
            responseData.data.bookCalendarSlot
          );
          console.log(
            'Parsed bookCalendarSlot response:',
            bookCalendarSlotResponse
          );

          if (bookCalendarSlotResponse && bookCalendarSlotResponse.id) {
            resultId = bookCalendarSlotResponse.id;
            console.log('Using appointment ID from response:', resultId);
          }
        } catch (parseError) {
          console.error('Error parsing bookCalendarSlot response:', parseError);
        }
      }

      setTimeout(() => {
        console.log(
          'Silently refreshing calendar data after appointment creation/update'
        );
        refreshCalendarData(true);
      }, 2000);

      setSnackbar({
        open: true,
        message: appointmentID
          ? 'Appointment rescheduled'
          : 'Appointment scheduled',
        severity: 'success',
      });

      onSuccess(resultId);
    } catch (err) {
      console.error('Error scheduling appointment:', err);
      setSnackbar({
        open: true,
        message: 'Error while trying to schedule an appointment',
        severity: 'error',
      });
      onSuccess(false);
    }
  };

  const addAssignedColorValuesToEvents = (
    events: CalendarEvent[]
  ): CalendarEvent[] => {
    return events.map((event) => ({
      ...event,
      color: colorAssignments[event.admin_id] || event.color,
    }));
  };

  const refreshCalendarData = async (silent = false) => {
    if (selectedEmployees.length > 0) {
      try {
        if (!silent) {
          setIsLoadingEvents(true);
        }

        console.log('Refreshing calendar data...');
        console.log('Selected employees:', selectedEmployees);
        console.log('Selected dates:', selectedDates);

        let datesToFetch = [...selectedDates];

        if (
          (schedulerView === 'week' || isAgendaView) &&
          selectedDates.length > 0
        ) {
          datesToFetch = getWeekDates(selectedDates[0]);
          console.log('Week/Agenda view - selected date:', selectedDates[0]);
          console.log(
            'Week/Agenda view - day of week:',
            selectedDates[0].getDay()
          );
          console.log(
            'Week/Agenda view - fetching dates:',
            datesToFetch.map((d) => ({
              date: d,
              day: d.getDate(),
              month: d.getMonth() + 1,
              dayOfWeek: d.getDay(),
            }))
          );
        } else if (schedulerView === 'month') {
          // For month view, get all days in the month
          const year = selectedDates[0].getFullYear();
          const month = selectedDates[0].getMonth();
          const daysInMonth = new Date(year, month + 1, 0).getDate();

          datesToFetch = Array.from({ length: daysInMonth }, (_, i) => {
            const date = new Date(year, month, i + 1);
            date.setHours(12, 0, 0, 0);
            return date;
          });

          console.log(
            'Month view - fetching dates:',
            datesToFetch.length,
            'days'
          );
        }

        const extendedDates = [...datesToFetch];
        if (datesToFetch.length > 0) {
          const firstDate = new Date(datesToFetch[0]);
          firstDate.setDate(firstDate.getDate() - 1);
          extendedDates.unshift(firstDate);

          const lastDate = new Date(datesToFetch[datesToFetch.length - 1]);
          lastDate.setDate(lastDate.getDate() + 1);
          extendedDates.push(lastDate);
        }

        console.log('Fetching appointments for dates:', extendedDates);

        const events = await fetchAppointmentGrid(
          extendedDates,
          selectedEmployees
        );

        // Apply user color assignments to the events
        const eventsWithUserColors = addAssignedColorValuesToEvents(events);

        console.log('Refreshed calendar events:', eventsWithUserColors);
        console.log(
          `Loaded ${eventsWithUserColors.length} events from the backend`
        );

        if (selectedDeal) {
          const eventsWithDealId = eventsWithUserColors.filter(
            (event) => event.dealId === selectedDeal.id
          );
          console.log(
            `Found ${eventsWithDealId.length} events with deal ID ${selectedDeal.id}:`,
            eventsWithDealId
          );
        }

        setCalendarEvents(eventsWithUserColors);
      } catch (error) {
        console.error('Error refreshing calendar data:', error);
      } finally {
        if (!silent) {
          setIsLoadingEvents(false);
        }
      }
    } else {
      console.log('No employees selected, skipping calendar refresh');
    }
  };

  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: 'success' | 'error' | 'warning';
  }>({
    open: false,
    message: '',
    severity: 'success',
  });

  const MAX_SELECTED_MEMBERS = 5;

  const handleEmployeeSelection = (employeeId: string) => {
    setSelectedEmployees((prev) => {
      const isSelected = prev.includes(employeeId);
      if (isSelected) {
        setColorAssignments((prevColors) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { [employeeId]: removedColor, ...rest } = prevColors;
          return rest;
        });
        return prev.filter((id) => id !== employeeId);
      } else {
        if (prev.length >= MAX_SELECTED_MEMBERS) {
          setSnackbar({
            open: true,
            message: `You can only select up to ${MAX_SELECTED_MEMBERS} members`,
            severity: 'warning',
          });
          return prev;
        }

        const availableColors = COLOR_PALETTE.filter(
          (color) => !Object.values(colorAssignments).includes(color)
        );
        const newColor = availableColors[0] || COLOR_PALETTE[0];
        setColorAssignments((prev) => ({ ...prev, [employeeId]: newColor }));
        return [...prev, employeeId];
      }
    });
  };

  const handleTeamSelection = (teamId: string) => {
    setSelectedTeams((prev) => {
      const isSelected = prev.includes(teamId);
      if (isSelected) {
        const teamClosers =
          teamsWithClosers.find((t) => t.id === teamId)?.closers || [];
        setSelectedEmployees((prev) =>
          prev.filter((id) => !teamClosers.some((closer) => closer.id === id))
        );
        return prev.filter((id) => id !== teamId);
      } else {
        const team = teamsWithClosers.find((t) => t.id === teamId);
        if (team) {
          const closersToAdd = team.closers
            .slice(0, MAX_SELECTED_MEMBERS)
            .map((closer) => closer.id);

          const newColorAssignments = { ...colorAssignments };
          closersToAdd.forEach((closerId, index) => {
            if (!newColorAssignments[closerId]) {
              newColorAssignments[closerId] =
                COLOR_PALETTE[index % COLOR_PALETTE.length];
            }
          });
          setColorAssignments(newColorAssignments);

          setSelectedEmployees(closersToAdd);
        }
        return [...prev, teamId];
      }
    });
  };

  const handleTeamMemberSelection = (employeeId: string, teamId: string) => {
    const team = teamsWithClosers.find((t) => t.id === teamId);
    if (!team) return;

    setSelectedEmployees((prev) => {
      const isSelected = prev.includes(employeeId);
      if (isSelected) {
        setColorAssignments((prevColors) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { [employeeId]: removedColor, ...rest } = prevColors;
          return rest;
        });
        return prev.filter((id) => id !== employeeId);
      } else {
        if (prev.length >= MAX_SELECTED_MEMBERS) {
          setSnackbar({
            open: true,
            message: `You can only select up to ${MAX_SELECTED_MEMBERS} members`,
            severity: 'warning',
          });
          return prev;
        }

        const availableColors = COLOR_PALETTE.filter(
          (color) => !Object.values(colorAssignments).includes(color)
        );
        const newColor = availableColors[0] || COLOR_PALETTE[0];
        setColorAssignments((prev) => ({ ...prev, [employeeId]: newColor }));
        return [...prev, employeeId];
      }
    });
  };

  useEffect(() => {
    if (teams.length > 0 && employees.length > 0) {
      const updatedTeams: TeamWithClosers[] = teams.map((team) => ({
        id: team.id,
        name: team.name,
        isSelected: selectedTeams.includes(team.id),
        closers: team.users.items
          .filter(
            (member) =>
              member.isActive &&
              member.status !== 'removed' &&
              member.user &&
              member.user.status !== 'deleted'
          )
          .map((member) => {
            if (!member.user?.id) return undefined;
            const employee = employees.find((emp) => emp.id === member.user.id);
            if (!employee) return undefined;
            return {
              ...employee,

              avatar:
                member.user.imageName && member.user.imageType
                  ? `${member.user.imageName}.${member.user.imageType}`
                  : employee.avatar,
            };
          })
          .filter((user): user is Employee => user !== undefined)
          .slice(0, maxTeamMembers),
      }));

      setTeamsWithClosers(updatedTeams);
    }
  }, [teams, employees, selectedTeams, maxTeamMembers]);

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const handleEventDrop = async (
    _: React.DragEvent<HTMLButtonElement>,
    droppedOn: Date,
    updatedEvent: ProcessedEvent,
    originalEvent: ProcessedEvent & { event_id: string; dealId: string }
  ): Promise<ProcessedEvent | void> => {
    console.log('Drop Event Debug:', {
      originalEvent,
      updatedEvent,
      droppedOn,
      currentEvents: calendarEvents,
      schedulerView,
      viewMode,
    });

    const targetAdminId =
      schedulerView === 'week' || isAgendaView
        ? originalEvent.admin_id
        : (updatedEvent.admin_id as string);

    const newEvent: CalendarEvent = {
      ...originalEvent,
      start: updatedEvent.start,
      end: updatedEvent.end,
      admin_id: targetAdminId,
      event_id: originalEvent.event_id,
      id: originalEvent.event_id,
      dealId: originalEvent.dealId,
      title: originalEvent.title,
      extendedProps: originalEvent.extendedProps,
    };

    const uniqueDatesSet = new Set<string>();
    uniqueDatesSet.add(
      new Date(originalEvent.start).toISOString().split('T')[0]
    );
    uniqueDatesSet.add(
      new Date(updatedEvent.start).toISOString().split('T')[0]
    );
    selectedDates.forEach((date) => {
      uniqueDatesSet.add(date.toISOString().split('T')[0]);
    });

    setCalendarEvents((prevEvents) =>
      prevEvents.map((event) =>
        event.id === originalEvent.event_id ? newEvent : event
      )
    );

    try {
      await scheduleAppointment({
        appointmentID: originalEvent.event_id,
        closerID: targetAdminId,
        appointmentStartTime: updatedEvent.start,
        appointmentEndTime: updatedEvent.end,
        notes: originalEvent.extendedProps?.displayFields?.notes || '',
        dealId: originalEvent.dealId,
        onSuccess: async (result) => {
          if (!result) {
            setCalendarEvents((prevEvents) => {
              return prevEvents.map((event) => {
                if (event.id === originalEvent.event_id) {
                  return {
                    ...originalEvent,
                    event_id: originalEvent.event_id,
                    id: originalEvent.event_id,
                    dealId: originalEvent.dealId,
                  } as CalendarEvent;
                }
                return event;
              });
            });

            setSnackbar({
              open: true,
              message: 'Failed to update appointment',
              severity: 'error',
            });
          } else {
            setSnackbar({
              open: true,
              message: 'Appointment updated successfully',
              severity: 'success',
            });
          }
        },
      });
      return updatedEvent;
    } catch (error) {
      console.error('Error updating appointment:', error);

      setCalendarEvents((prevEvents) => {
        return prevEvents.map((event) => {
          if (event.id === originalEvent.event_id) {
            return {
              ...originalEvent,
              event_id: originalEvent.event_id,
              id: originalEvent.event_id,
              dealId: originalEvent.dealId,
            } as CalendarEvent;
          }
          return event;
        });
      });

      setSnackbar({
        open: true,
        message: 'Failed to update appointment',
        severity: 'error',
      });

      return originalEvent;
    }
  };

  useEffect(() => {
    console.log('Calendar Events Updated:', {
      events: calendarEvents,
      filtered: filteredEvents,
    });
  }, [calendarEvents, filteredEvents]);

  useEffect(() => {
    const fetchData = async () => {
      if (selectedEmployees.length > 0 && selectedDates.length > 0) {
        try {
          setIsLoadingEvents(true);
          let datesToFetch = [...selectedDates];

          if (
            schedulerView === 'week' ||
            (isAgendaView && selectionMode !== 'month')
          ) {
            datesToFetch = getWeekDates(selectedDates[0]);
          } else if (
            schedulerView === 'month' ||
            (isAgendaView && selectionMode === 'month')
          ) {
            // For month view, get all days in the month
            const currentDate = selectedDates[0];
            const year = currentDate.getFullYear();
            const month = currentDate.getMonth();
            const daysInMonth = new Date(year, month + 1, 0).getDate();

            datesToFetch = Array.from({ length: daysInMonth }, (_, i) => {
              const date = new Date(year, month, i + 1);
              date.setHours(12, 0, 0, 0);
              return date;
            });

            console.log(
              'Month view - fetching dates:',
              datesToFetch.length,
              'days'
            );
          }

          const events = await fetchAppointmentGrid(
            datesToFetch,
            selectedEmployees
          );

          // Apply user color assignments to the events
          const eventsWithUserColors = addAssignedColorValuesToEvents(events);
          setCalendarEvents(eventsWithUserColors);
        } catch (error) {
          console.error('Error fetching appointments:', error);
        } finally {
          setIsLoadingEvents(false);
        }
      } else {
        setCalendarEvents([]);
      }
    };

    fetchData();
  }, [
    selectedEmployees,
    selectedDates,
    schedulerView,
    isAgendaView,
    selectionMode,
    colorAssignments,
  ]);

  const handleDateSelect = (date: Date) => {
    const newDate = new Date(date);
    newDate.setHours(12, 0, 0, 0);

    switch (selectionMode) {
      case 'manyDays':
        setSelectedDates((prev) => {
          if (prev.some((d) => isSameDay(d, newDate))) {
            const filtered = prev.filter((d) => !isSameDay(d, newDate));
            return filtered.length === 0 ? [new Date()] : filtered;
          }
          if (prev.length >= 3) {
            const sortedDates = [...prev].sort(
              (a, b) => a.getTime() - b.getTime()
            );
            return [...sortedDates.slice(1), newDate].sort(
              (a, b) => a.getTime() - b.getTime()
            );
          }
          return [...prev, newDate].sort((a, b) => a.getTime() - b.getTime());
        });
        break;

      case 'week':
        const dayOfWeek = newDate.getDay();
        const startOfWeek = new Date(newDate);
        startOfWeek.setDate(newDate.getDate() - dayOfWeek);
        startOfWeek.setHours(12, 0, 0, 0);
        const weekDates = Array.from({ length: 7 }, (_, i) => {
          const date = new Date(startOfWeek);
          date.setDate(startOfWeek.getDate() + i);
          return date;
        });
        setSelectedDates(weekDates);
        break;

      case 'month':
        // Get all days in the selected month
        const year = newDate.getFullYear();
        const month = newDate.getMonth();
        const daysInMonth = new Date(year, month + 1, 0).getDate();

        const monthDates = Array.from({ length: daysInMonth }, (_, i) => {
          const date = new Date(year, month, i + 1);
          date.setHours(12, 0, 0, 0);
          return date;
        });

        setSelectedDates(monthDates);
        break;
    }
  };

  const handleSelectionModeChange = (mode: SelectionMode) => {
    setSelectionMode(mode);

    // Sync schedulerView with selection mode
    switch (mode) {
      case 'manyDays':
        setSchedulerView('manyDays');
        setViewMode('calendar'); // Force calendar view for manyDays
        break;
      case 'week':
        setSchedulerView('week');
        break;
      case 'month':
        setSchedulerView('month');
        break;
    }

    // Reset date selection based on the new mode
    const currentDate = new Date();
    currentDate.setHours(12, 0, 0, 0);

    switch (mode) {
      case 'manyDays':
        setSelectedDates([currentDate]);
        GamifyToast.success(
          'Many Days Mode',
          'You can select up to 3 individual days',
          { autoClose: 10000 }
        );
        break;

      case 'week':
        const dayOfWeek = currentDate.getDay();
        const startOfWeek = new Date(currentDate);
        startOfWeek.setDate(currentDate.getDate() - dayOfWeek);
        startOfWeek.setHours(12, 0, 0, 0);

        const weekDates = Array.from({ length: 7 }, (_, i) => {
          const date = new Date(startOfWeek);
          date.setDate(startOfWeek.getDate() + i);
          return date;
        });

        setSelectedDates(weekDates);
        GamifyToast.success(
          'Week Mode',
          'Selecting a day will select the entire week',
          { autoClose: 10000 }
        );
        break;

      case 'month':
        // Get all days in the current month
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth();
        const daysInMonth = new Date(year, month + 1, 0).getDate();

        const monthDates = Array.from({ length: daysInMonth }, (_, i) => {
          const date = new Date(year, month, i + 1);
          date.setHours(12, 0, 0, 0);
          return date;
        });

        setSelectedDates(monthDates);
        GamifyToast.success(
          'Month Mode',
          'Selecting a day will select the entire month',
          { autoClose: 10000 }
        );
        break;
    }
  };

  // OPTIONAL: Add keyboard shortcuts to change selection mode
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      // Alt+1 for Many Days mode
      if (e.ctrlKey && e.altKey && e.key === '1') {
        handleSelectionModeChange('manyDays');
      }
      // Ctrl+Alt+2 for Week mode
      else if (e.ctrlKey && e.altKey && e.key === '2') {
        handleSelectionModeChange('week');
      }
      // Ctrl+Alt+3 for Month mode
      else if (e.ctrlKey && e.altKey && e.key === '3') {
        handleSelectionModeChange('month');
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  const renderAgendaHeader = (date: Date) => {
    const dayNumber = format(date, 'd');
    const dayName = format(date, 'EEE').toUpperCase();
    const isToday =
      format(new Date(), 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd');

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'relative',
          height: '100%',
          background: '#fff',
          mb: '16px',
        }}
      >
        <Typography
          sx={{
            fontSize: '11px',
            color: 'text.secondary',
            fontWeight: 500,
            textTransform: 'uppercase',
          }}
        >
          {dayName}
        </Typography>
        <Box
          sx={{
            width: 30,
            height: 30,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            backgroundColor: isToday ? '#FF6A00' : 'transparent',
          }}
        >
          <Typography
            sx={{
              fontSize: '15px',
              fontWeight: 600,
              color: isToday ? '#fff' : '#000',
              lineHeight: 1,
            }}
          >
            {dayNumber}
          </Typography>
        </Box>
      </Box>
    );
  };

  const handleViewModeChange = (mode: ViewMode) => {
    console.log('Changing view mode to:', mode);
    setViewMode(mode);

    // When switching to agenda view, maintain the current view context
    if (mode === 'agenda') {
      if (selectionMode === 'month') {
        // For month view, get all days in the month
        const currentDate = selectedDates[0] || new Date();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth();
        const daysInMonth = new Date(year, month + 1, 0).getDate();

        const monthDates = Array.from({ length: daysInMonth }, (_, i) => {
          const date = new Date(year, month, i + 1);
          date.setHours(12, 0, 0, 0);
          return date;
        });

        setSelectedDates(monthDates);
        setSchedulerView('month'); // Keep it in month view for proper context

        // Force a refresh of calendar data with the entire month
        setTimeout(() => {
          const startDate = new Date(year, month, 1);
          const endDate = new Date(year, month + 1, 0);
          console.log('Fetching month data from', startDate, 'to', endDate);

          // Generate all dates for the month
          const allDatesInMonth = [];
          const currentDate = new Date(startDate);
          // eslint-disable-next-line
          while (currentDate <= endDate) {
            allDatesInMonth.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
          }

          // Update selected dates with all dates in the month
          setSelectedDates(allDatesInMonth);
          refreshCalendarData(true);
        }, 100);
      } else {
        // For week view, calculate week dates
        const currentDate = selectedDates[0] || new Date();
        const dayOfWeek = currentDate.getDay();
        const startOfWeek = new Date(currentDate);
        startOfWeek.setDate(currentDate.getDate() - dayOfWeek);
        startOfWeek.setHours(12, 0, 0, 0);

        const weekDates = Array.from({ length: 7 }, (_, i) => {
          const date = new Date(startOfWeek);
          date.setDate(startOfWeek.getDate() + i);
          return date;
        });

        setSelectedDates(weekDates);
        setSchedulerView('week');

        // Refresh calendar data for the week
        setTimeout(() => {
          refreshCalendarData(true);
        }, 100);
      }
    } else {
      // When switching back to calendar view, maintain the current selection mode view
      if (selectionMode === 'month') {
        setSchedulerView('month');
      } else if (selectionMode === 'week') {
        setSchedulerView('week');
      } else {
        setSchedulerView('manyDays');
      }
    }
  };

  const handleNavigatePrev = () => {
    setSelectedDates((prev) => {
      const newDate = new Date(prev[0]);
      if (schedulerView === 'week') {
        newDate.setDate(newDate.getDate() - 7);
      } else if (schedulerView === 'month') {
        newDate.setMonth(newDate.getMonth() - 1);
      } else {
        newDate.setDate(newDate.getDate() - 1);
      }
      return [newDate];
    });
  };

  const handleNavigateNext = () => {
    setSelectedDates((prev) => {
      const newDate = new Date(prev[0]);
      if (schedulerView === 'week') {
        newDate.setDate(newDate.getDate() + 7);
      } else if (schedulerView === 'month') {
        newDate.setMonth(newDate.getMonth() + 1);
      } else {
        newDate.setDate(newDate.getDate() + 1);
      }
      return [newDate];
    });
  };

  const [editFormOpen, setEditFormOpen] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState<any>(null);

  const handleEditClick = (appointment: any) => {
    console.log('Opening appointment for editing:', appointment);
    console.log(
      'Notes in appointment being edited:',
      appointment.extendedProps?.displayFields?.notes
    );

    setSelectedAppointment(appointment);
    setEditFormOpen(true);
  };

  const handleCreateAppointment = async (appointmentData: any) => {
    try {
      console.log('Creating appointment with data:', appointmentData);
      console.log('Deal ID:', appointmentData.dealId);
      console.log('Admin ID (assigned user):', appointmentData.admin_id);

      if (!appointmentData.admin_id) {
        console.error('No admin_id (assigned user) provided');
        setSnackbar({
          open: true,
          message: 'Please select an assigned user',
          severity: 'error',
        });
        return;
      }

      await scheduleAppointment({
        closerID: appointmentData.admin_id,
        appointmentStartTime: appointmentData.start,
        appointmentEndTime: appointmentData.end,
        notes: appointmentData.extendedProps?.displayFields?.notes || '',
        dealId: appointmentData.dealId,
        onSuccess: (result) => {
          if (result) {
            console.log('Appointment created with ID:', result);

            const assignedUser = employees.find(
              (emp) => emp.id === appointmentData.admin_id
            );

            if (!assignedUser) {
              console.warn(
                'Assigned user not found in employees:',
                appointmentData.admin_id
              );
            } else {
              console.log('Found assigned user:', assignedUser);
            }

            const newEvent: CalendarEvent = {
              ...appointmentData,
              event_id: result,
              id: result,

              admin_id: appointmentData.admin_id,

              dealId: appointmentData.dealId,

              extendedProps: {
                ...appointmentData.extendedProps,
                displayFields: {
                  ...appointmentData.extendedProps?.displayFields,

                  assignedUser:
                    appointmentData.extendedProps?.displayFields
                      ?.assignedUser ||
                    (assignedUser
                      ? {
                          id: assignedUser.id,
                          name: assignedUser.name,
                          avatar: assignedUser.avatar,
                        }
                      : undefined),
                },
              },
            };

            console.log('Adding new event to calendar:', newEvent);
            setCalendarEvents((prevEvents) => [...prevEvents, newEvent]);

            setSnackbar({
              open: true,
              message: 'Appointment created successfully',
              severity: 'success',
            });

            setSelectedDeal(null);
          } else {
            setSnackbar({
              open: true,
              message: 'Failed to create appointment',
              severity: 'error',
            });
          }
        },
      });
    } catch (error) {
      console.error('Error creating appointment:', error);
      setSnackbar({
        open: true,
        message: 'Error creating appointment',
        severity: 'error',
      });
    }
  };

  const handleSaveAppointment = async (appointmentData: any) => {
    try {
      console.log('Saving appointment with data:', appointmentData);

      const existingAppointmentIndex = calendarEvents.findIndex(
        (event) => event.id === appointmentData.id
      );

      if (existingAppointmentIndex === -1) {
        console.error('Appointment not found in calendar events');
        return;
      }

      const assignedUser = employees.find(
        (emp) => emp.id === appointmentData.admin_id
      );

      await scheduleAppointment({
        appointmentID: appointmentData.id,
        closerID: appointmentData.admin_id,
        appointmentStartTime: appointmentData.start,
        appointmentEndTime: appointmentData.end,
        notes: appointmentData.extendedProps?.displayFields?.notes || '',
        dealId: appointmentData.dealId,
        onSuccess: (result) => {
          if (result) {
            console.log('Appointment updated with ID:', result);

            const updatedEvent: CalendarEvent = {
              ...appointmentData,
              event_id: appointmentData.id,

              admin_id: appointmentData.admin_id,

              dealId: appointmentData.dealId,

              extendedProps: {
                ...appointmentData.extendedProps,
                displayFields: {
                  ...appointmentData.extendedProps?.displayFields,

                  assignedUser:
                    appointmentData.extendedProps?.displayFields
                      ?.assignedUser ||
                    (assignedUser
                      ? {
                          id: assignedUser.id,
                          name: assignedUser.name,
                          avatar: assignedUser.avatar,
                        }
                      : undefined),
                },
              },
            };

            console.log('Updating event in calendar:', updatedEvent);
            setCalendarEvents((prevEvents) => {
              const newEvents = [...prevEvents];
              newEvents[existingAppointmentIndex] = updatedEvent;
              return newEvents;
            });

            setSnackbar({
              open: true,
              message: 'Appointment updated successfully',
              severity: 'success',
            });
          } else {
            setSnackbar({
              open: true,
              message: 'Failed to update appointment',
              severity: 'error',
            });
          }
        },
      });
    } catch (error) {
      console.error('Error updating appointment:', error);
      setSnackbar({
        open: true,
        message: 'Error updating appointment',
        severity: 'error',
      });
    }
  };

  const handleDeleteAppointment = async (appointmentId: string) => {
    try {
      const success = await markAppointment(
        appointmentId,
        'cancelled',
        '',
        (result) => {
          if (result) {
            setCalendarEvents((prevEvents) =>
              prevEvents.filter((event) => event.id !== appointmentId)
            );

            setTimeout(() => {
              console.log(
                'Silently refreshing calendar data after appointment deletion'
              );
              refreshCalendarData(true);
            }, 2000);
          }
        }
      );

      if (success) {
        setSnackbar({
          open: true,
          message: 'Appointment deleted successfully',
          severity: 'success',
        });
      }
    } catch (error) {
      console.error('Error deleting appointment:', error);
      setSnackbar({
        open: true,
        message: 'Error deleting appointment',
        severity: 'error',
      });
    }
  };

  const [createFormOpen, setCreateFormOpen] = useState(false);

  const [selectedDeal, setSelectedDeal] = useState<Deal | null>(null);

  const handleDealSelect = (deal: Deal) => {
    console.log('SchedulingScreen - Deal selected:', deal);

    if (!deal.closer && deal.setter) {
      console.log('Deal has no closer, using setter as closer:', deal.setter);
      deal.closer = { ...deal.setter };
    }

    setSelectedDeal(deal);

    setSelectedAppointment(null);
    setCreateFormOpen(true);
  };

  const getExistingAppointmentsForDeals = useMemo(() => {
    return calendarEvents
      .filter((event) => event.dealId)
      .map((event) => ({
        dealId: event.dealId as string,
        assignedUser: {
          id: event.admin_id,
          name:
            employees.find((emp) => emp.id === event.admin_id)?.name ||
            'Unknown',
          avatar: employees.find((emp) => emp.id === event.admin_id)?.avatar,
        },
        start: event.start,
        end: event.end,
      }));
  }, [calendarEvents, employees]);

  const sidebarProps = {
    activeTab,
    setActiveTab,
    searchQuery,
    setSearchQuery,
    isLoading: isTeamsLoading || isUsersLoading,
    selectedEmployees,
    handleEmployeeSelection,
    colorAssignments,
    employees,
    teamsWithClosers,
    handleTeamSelection,
    handleTeamMemberSelection,
    selectedDates,
    onDateSelect: handleDateSelect,
    schedulerView,
    onDealSelect: handleDealSelect,
    existingAppointments: getExistingAppointmentsForDeals,
    setSelectedEmployees,
    setColorAssignments,
  };

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [appointmentToDelete, setAppointmentToDelete] = useState<string | null>(
    null
  );

  const confirmDeleteAppointment = (appointmentId: string) => {
    setAppointmentToDelete(appointmentId);
    setConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = (confirmed: boolean) => {
    setConfirmDialogOpen(false);

    if (confirmed && appointmentToDelete) {
      handleDeleteAppointment(appointmentToDelete);
    }

    setAppointmentToDelete(null);
  };

  const [dealDetailsOpen, setDealDetailsOpen] = useState(false);
  const [selectedDealId, setSelectedDealId] = useState<string | undefined>(
    undefined
  );

  const handleDealClick = (dealId: string) => {
    setSelectedDealId(dealId);
    setDealDetailsOpen(true);
  };

  const renderCustomViewer = (event: any, close: any) => {
    if (editFormOpen || createFormOpen) {
      return <></>;
    }

    const displayFields = event.extendedProps?.displayFields || {};
    const assignedUser = resources.find((r) => r.admin_id === event.admin_id);
    const displayTitle =
      displayFields.customerName ||
      event.extendedProps?.dealName ||
      event.title;

    return (
      <Dialog
        open
        onClose={() => {
          close();
        }}
        maxWidth="xs"
        PaperProps={{
          sx: {
            borderRadius: '8px',
            border: '1px solid #ECEDEC',
            overflow: 'visible',
            width: '492px',
            padding: 0,
            zIndex: 100000,
            position: 'relative',
          },
        }}
        sx={{
          '& .MuiBackdrop-root': {
            backgroundColor: 'transparent',
          },
          zIndex: 100000,
          position: 'fixed',
        }}
        BackdropProps={{
          invisible: true,
        }}
        disablePortal={false}
        container={document.body}
      >
        <Box
          sx={{
            position: 'relative',
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: '20px 24px 32px 24px',
            gap: '16px',
            borderRadius: '8px',
            boxShadow: '0px 4px 104px 0px rgba(101, 101, 101, 0.25)',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              width: '100%',
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: 600,
                fontSize: '16px',
                color: '#14191A',
                flex: 1,
                lineHeight: 1.2,
              }}
            >
              {displayTitle}
            </Typography>
            <Box sx={{ display: 'flex' }}>
              <IconButton
                size="small"
                sx={{
                  padding: '4px',
                  color: '#6F878F',
                  marginLeft: '4px',
                  backgroundColor: 'transparent',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  },
                }}
                onClick={() => {
                  close();
                  confirmDeleteAppointment(event.id);
                }}
              >
                <GamifyIcon
                  icon={'trash'}
                  color={'red'}
                  backgroundColor={'#fff'}
                  height={24}
                  width={24}
                ></GamifyIcon>
              </IconButton>
              <IconButton
                size="small"
                onClick={() => handleEditClick(event)}
                sx={{
                  padding: '4px',
                  color: '#6F878F',
                  marginLeft: '4px',
                  backgroundColor: 'transparent',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  },
                }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
              <IconButton
                size="small"
                onClick={close}
                sx={{
                  padding: '4px',
                  color: '#6F878F',
                  marginLeft: '4px',
                  backgroundColor: 'transparent',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  },
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          </Box>

          <Typography
            variant="body2"
            sx={{
              color: '#29373B',
              fontSize: '13px',
              marginTop: '-8px',
            }}
          >
            {format(event.start, 'EEEE, MMMM d')} ·{' '}
            {format(event.start, 'h:mm')} - {format(event.end, 'h:mm a')}
          </Typography>

          {displayFields.fullAddress && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                width: '100%',
                borderTop: '1px solid rgba(0, 0, 0, 0.08)',
                paddingTop: '16px',
              }}
            >
              <LocationOnIcon
                sx={{
                  color: '#6F878F',
                  fontSize: '20px',
                  mt: 0.5,
                  mr: 1.5,
                  minWidth: '20px',
                }}
              />
              <Typography
                variant="body2"
                sx={{ color: '#14191A', fontSize: '13px' }}
              >
                {displayFields.fullAddress}
              </Typography>
            </Box>
          )}

          {displayFields.phoneNumber && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                borderTop: '1px solid rgba(0, 0, 0, 0.08)',
                paddingTop: '16px',
              }}
            >
              <PhoneIcon
                sx={{
                  color: '#6F878F',
                  fontSize: '20px',
                  mr: 1.5,
                  minWidth: '20px',
                }}
              />
              <Typography
                variant="body2"
                sx={{ color: '#14191A', fontSize: '13px' }}
              >
                {displayFields.phoneNumber}
              </Typography>
            </Box>
          )}

          {assignedUser && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                borderTop: '1px solid rgba(0, 0, 0, 0.08)',
                paddingTop: '16px',
              }}
            >
              <PeopleIcon
                sx={{
                  color: '#6F878F',
                  fontSize: '20px',
                  mr: 1.5,
                  minWidth: '20px',
                }}
              />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                {assignedUser && (
                  <>
                    <Avatar
                      src={assignedUser.avatar}
                      alt={assignedUser.title}
                      sx={{
                        width: 24,
                        height: 24,
                        mr: 1,
                      }}
                    />
                    <Typography
                      variant="body2"
                      sx={{
                        color: '#14191A',
                        fontSize: '13px',
                        mr: 1,
                      }}
                    >
                      {assignedUser.title}
                    </Typography>
                  </>
                )}
                {displayFields.otherAssignees && (
                  <>
                    <Avatar
                      src={displayFields.otherAssignees.avatar}
                      alt={displayFields.otherAssignees.name}
                      sx={{
                        width: 24,
                        height: 24,
                        mr: 1,
                      }}
                    />
                    <Typography
                      variant="body2"
                      sx={{ color: '#14191A', fontSize: '13px' }}
                    >
                      {displayFields.otherAssignees.name}
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
          )}

          {(displayFields.dealId || event.dealId) && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                borderTop: '1px solid rgba(0, 0, 0, 0.08)',
                paddingTop: '16px',
              }}
            >
              <DescriptionIcon
                sx={{
                  color: '#6F878F',
                  fontSize: '20px',
                  mr: 1.5,
                  minWidth: '20px',
                }}
              />
              <Typography
                variant="body2"
                sx={{
                  color: '#FF6A00',
                  fontSize: '13px',
                  fontWeight: 500,
                  cursor: 'pointer',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  const dealId = displayFields.dealId || event.dealId;
                  if (dealId) {
                    handleDealClick(dealId);
                  }
                  close();
                }}
              >
                Deal #{displayFields.dealId || event.dealId}
              </Typography>
            </Box>
          )}
        </Box>
      </Dialog>
    );
  };

  const renderDaysHeader = ({ user, date }: { user: any; date: any }) => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        pt: 1.2,
        position: 'relative',
        height: '100%',
        background: '#fff',
        zIndex: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          left: 0,
          right: 0,
          textAlign: 'center',
          pb: 0.3,
        }}
      >
        <Typography variant="body1" sx={{ fontWeight: 500, mt: 1 }}>
          {date.getDate()}
        </Typography>
        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
          {date.toLocaleDateString('en-US', {
            weekday: 'short',
          })}
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          mt: 1,
          maxWidth: '100%',
          overflow: 'hidden',
        }}
      >
        <Avatar
          src={user.avatar}
          alt={user.title}
          sx={{
            width: 24,
            height: 24,
            flexShrink: 0,
          }}
        />
        <Box sx={{ ml: 1, overflow: 'hidden' }}>
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 'bold',
              mb: 0,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              lineHeight: 1.2,
            }}
          >
            {user.title}
          </Typography>
          <Typography
            variant="caption"
            sx={{
              color: 'text.secondary',
              display: 'block',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              lineHeight: 1.2,
            }}
          >
            {user.mobile}
          </Typography>
        </Box>
      </Box>
    </Box>
  );

  const cellRenderer = (props: any) => (
    <div
      {...props}
      onClick={() => null}
      style={{
        ...(props.style || {}),
        padding: 0,
        margin: 0,
        height: '100%',
        boxSizing: 'border-box',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        position: 'relative',
      }}
    />
  );

  return (
    <Box
      display="flex"
      height="100vh"
      sx={{
        '.css-9xd0fv-MuiPaper-root': {
          boxShadow: 'none !important',
          transition: 'none !important',
        },
      }}
    >
      <SchedulingSidebar {...sidebarProps} />
      <Box
        flex={1}
        sx={{
          position: 'relative',
          height: '100%',
          overflow: 'hidden',
          marginTop: '90px',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <SchedulerNavBar
            selectedDate={selectedDates[0]}
            appointmentCount={calendarEvents.length}
            currentView={schedulerView}
            viewMode={viewMode}
            selectionMode={selectionMode}
            onViewModeChange={handleViewModeChange}
            onSelectionModeChange={handleSelectionModeChange}
            onNavigatePrev={handleNavigatePrev}
            onNavigateNext={handleNavigateNext}
          />

          {isLoadingEvents ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flex={1}
            >
              <CircularProgress />
            </Box>
          ) : selectedEmployees.length === 0 ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flex={1}
              flexDirection="column"
              gap={2}
            >
              <Typography variant="h6" color="text.secondary">
                Select team members to view their schedules
              </Typography>
              <Typography variant="body2" color="text.secondary">
                You can select up to {MAX_SELECTED_MEMBERS} members at a time
              </Typography>
            </Box>
          ) : (
            <Box
              sx={{
                flex: 1,
                overflow: 'auto',
                height: 'calc(150vh)',
                paddingBottom: '100px',
                '& .MuiPaper-elevation': {
                  boxShadow: 'none !important',
                },
                '& .MuiPaper-elevation1': {
                  boxShadow: 'none !important',
                },
                '& .rs__event': {
                  position: 'relative',
                },
                '& .rs__time': {
                  zIndex: 2,
                },

                '& .MuiPopover-paper': {
                  backgroundColor: '#fff !important',
                },
                '& .css-cb4umw-MuiPaper-root-MuiPopover-paper': {
                  backgroundColor: '#fff !important',
                },
              }}
            >
              <Scheduler
                events={calendarEvents}
                eventRenderer={(props) => {
                  console.log('Event renderer called with viewMode:', viewMode);
                  console.log('Event data:', props.event);

                  if (viewMode === 'agenda') {
                    console.log('Using agenda renderer');
                    return renderAgendaEvent({
                      event: props.event,
                      resources,
                      colorAssignments,
                      handleEditClick,
                    });
                  }
                  console.log('Using default renderer');
                  return eventRenderer(props);
                }}
                // view={viewMode === 'agenda' ? 'week' : (schedulerView as View)}
                view={schedulerView as View}
                selectedDate={selectedDates[0]}
                navigation={false}
                disableViewNavigator
                agenda={viewMode === 'agenda'}
                // alwaysShowAgendaDays
                month={{
                  weekDays: [0, 1, 2, 3, 4, 5, 6],
                  weekStartOn: 0,
                  disableGoToDay: true,
                  startHour: 0,
                  endHour: 23,
                  navigation: false,
                  headRenderer: renderAgendaHeader,
                  cellRenderer,
                }}
                week={{
                  weekDays: [0, 1, 2, 3, 4, 5, 6],
                  weekStartOn: 0,
                  startHour: 8,
                  endHour: 20,
                  step: 30,
                  headRenderer: renderAgendaHeader,
                  cellRenderer,
                }}
                onEventDrop={handleEventDrop}
                editable={viewMode !== 'agenda'}
                deletable={false}
                draggable={viewMode !== 'agenda'}
                timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone}
                customViewer={renderCustomViewer}
                manyDays={{
                  startHour: 6,
                  endHour: 24,
                  step: 30,
                  weekDays: [0, 1, 2, 3, 4, 5, 6],
                  weekStartOn: 0,
                  daysList: selectedDates.map(
                    (date) =>
                      new Date(
                        date.getFullYear(),
                        date.getMonth(),
                        date.getDate(),
                        0,
                        0,
                        0
                      )
                  ),
                  cellRenderer,
                }}
                resources={
                  schedulerView === 'manyDays' ? filteredResources : []
                }
                resourceFields={{
                  idField: 'admin_id',
                  textField: 'title',
                  avatarField: 'avatar',
                  colorField: 'color',
                }}
                height={750}
                manyDaysHeaderComponent={renderDaysHeader}
              />
            </Box>
          )}
        </Box>
      </Box>

      {/* Add the AppointmentEditForm component for editing */}
      <AppointmentEditForm
        open={editFormOpen}
        onClose={() => setEditFormOpen(false)}
        appointment={selectedAppointment}
        onSave={handleSaveAppointment}
        onDelete={handleDeleteAppointment}
        availableUsers={employees}
        isCreating={false}
        hasRoundRobin={true}
      />

      <AppointmentEditForm
        open={createFormOpen}
        onClose={() => setCreateFormOpen(false)}
        onSave={handleCreateAppointment}
        availableUsers={employees}
        isCreating={true}
        initialDeal={selectedDeal}
        hasRoundRobin={true}
      />

      {/* Confirmation Dialog */}
      <Dialog
        open={confirmDialogOpen}
        onClose={() => handleConfirmDialogClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Cancel Appointment</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to cancel this appointment?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleConfirmDialogClose(false)}
            color="primary"
          >
            No
          </Button>
          <Button
            onClick={() => handleConfirmDialogClose(true)}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      <DealDetailsDrawerAdapter
        open={dealDetailsOpen}
        onClose={() => setDealDetailsOpen(false)}
        dealId={selectedDealId}
      />
    </Box>
  );
};
