import React, { useEffect, useState } from 'react';
import { Text } from '@aws-amplify/ui-react';
import {
  Box,
  Backdrop,
  Avatar,
  Checkbox,
  InputAdornment,
  TextField,
  CircularProgress,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import './reports.css';

/**
 * SelectionModal - A reusable component for selecting items from a list
 *
 * @param {Object} props - Component props
 * @param {Object} props.config - Configuration object for the modal
 * @param {string} props.config.type - The type of items being selected (e.g., 'users', 'teams', 'KPIs')
 * @param {Function} props.config.fetchFunction - Function to fetch the list of items
 * @param {Array} props.config.optionsList - List of options to display
 * @param {Object} props.config.checkedList - Object containing the currently selected items
 * @param {Function} props.config.checkedListSetter - Function to update the selected items
 * @param {boolean} props.config.useAvatar - Whether to display avatars for the items
 * @param {Function} props.config.selectAll - Function to select all items
 * @param {Function} props.config.clearAll - Function to clear all selections
 * @param {boolean} props.config.fetchOnStringChange - Whether to refetch when search string changes
 * @param {string} props.searchString - Current search string
 * @param {Function} props.setSearchString - Function to update the search string
 * @param {boolean} props.open - Whether the modal is open
 * @param {Function} props.close - Function to close the modal
 */
export default function SelectionModal({
  config,
  searchString,
  setSearchString,
  close,
  open,
}) {
  const {
    type,
    fetchFunction,
    optionsList,
    checkedList,
    checkedListSetter,
    useAvatar,
    selectAll,
    clearAll,
    fetchOnStringChange,
  } = config;

  const [localList, setLocalList] = useState(optionsList);

  const filterSearch = (el, term) => {
    return new RegExp(term, 'gi').test(el);
  };

  useEffect(() => {
    if (!optionsList) fetchFunction(searchString);
  }, []);

  useEffect(() => {
    setLocalList(optionsList);
  }, [optionsList]);

  useEffect(() => {
    if (!optionsList) return;
    if (fetchOnStringChange) {
      fetchFunction(searchString);
    } else {
      const newList = optionsList
        .filter((el) => filterSearch(el.title || el.name, searchString))
        .sort((a, b) => {
          const aName = a.title || a.name;
          const bName = b.title || b.name;
          if (aName < bName) {
            return -1;
          } else if (aName > bName) {
            return 1;
          } else return 0;
        });
      setLocalList(newList);
    }
  }, [searchString]);

  return (
    <Backdrop
      open={open}
      onClick={close}
      sx={{
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Box
        className="selection-modal-container"
        onClick={(e) => e.stopPropagation()}
      >
        <Box className="selection-modal-header">{`Select ${type[0].toUpperCase()}${type.slice(
          1,
          type.length - 1
        )}(${type[type.length - 1]})`}</Box>
        <Box style={{ padding: '10px', height: '100%' }}>
          <Box position="relative">
            <TextField
              style={{ width: '100%', borderRadius: '5px' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              placeholder={`Search ${type}`}
              value={searchString}
              onChange={(e) => setSearchString(e.target.value)}
            />
            {searchString !== '' && (
              <Box
                className="clear-search-icon hov"
                onClick={() => setSearchString('')}
              >
                <ClearIcon />
              </Box>
            )}
          </Box>
          <Box className="clear-select-all-container">
            <Box onClick={selectAll} className="clear-select-all hov">
              Select All
            </Box>
            {Object.values(checkedList).length > 0 ? (
              <Box className="clear-select-all hov" onClick={clearAll}>
                Clear All
              </Box>
            ) : null}
          </Box>
          <Box className="list-container">
            {localList ? (
              localList.map((option, index) => (
                <Box
                  key={index}
                  name={option.id}
                  className="hov menu-item"
                  onClick={() =>
                    checkedListSetter(option.id, option.name || option.title)
                  }
                  style={{
                    backgroundColor: checkedList[option.id]
                      ? '#FAC8B2'
                      : 'white',
                    border: `1px solid ${
                      checkedList[option.id] ? '#FF6A00' : 'lightgray'
                    }`,
                  }}
                >
                  <Checkbox
                    sx={{
                      color: '#FF6A00',
                      '&.Mui-checked': {
                        color: '#FF6A00',
                      },
                    }}
                    checked={Boolean(checkedList[option.id]?.length) || false}
                  />
                  {useAvatar &&
                    (option.avatar ? (
                      <Avatar
                        src={option.avatar}
                        style={{ marginRight: '10px' }}
                      />
                    ) : (
                      <Avatar style={{ marginRight: '10px' }}>
                        {option.initials}
                      </Avatar>
                    ))}
                  <Text>{option.name || option.title}</Text>
                </Box>
              ))
            ) : (
              <Box
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </Box>
            )}
            <div className="done-button-container">
              <Box onClick={close} className="clear-select-all hov done-button">
                Done
              </Box>
            </div>
          </Box>
        </Box>
      </Box>
    </Backdrop>
  );
}
