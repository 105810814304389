import React, { useState } from 'react';
import { Box, Button, SelectChangeEvent, Typography } from '@mui/material';
// import { StyledSelect, StyledMenuItem } from '../teams/StyledComponent';
import { StyledSelect, StyledMenuItem } from '../../common/StyledComponents';
import { Title, ViewContainer } from './KPILeaderboard.styles';
import KPITable from './KPITable';
import { GetKPIValuesArgs, KPILeaderboardState, TokensData } from './_types';
import KPIFilter from './KPIFilter';
import featureFlag from '../../common/featureFlag';

interface KPILeaderboardViewProps {
  kpiLeaderboardState: KPILeaderboardState;
  onChangeKpiLeaderboardState: (
    kpiLeaderboardState: KPILeaderboardState
  ) => void;
  columns: any;
  data: any;
  tokens: TokensData;
  resetTokens: () => void;
  fetchKpiLeaderboardData: (fetchDataArgs: GetKPIValuesArgs) => void;
}

const KPILeaderboardView: React.FC<KPILeaderboardViewProps> = ({
  columns,
  data,
  kpiLeaderboardState,
  onChangeKpiLeaderboardState,
  tokens,
  resetTokens,
  fetchKpiLeaderboardData,
}) => {
  const [refreshIntervalAmount, setRefreshIntervalAmount] =
    useState<number>(10000);

  const intervalList = [
    {
      label: '10 sec',
      value: 10000,
    },
    {
      label: '15 sec',
      value: 15000,
    },
    {
      label: '20 sec',
      value: 20000,
    },
    {
      label: '30 sec',
      value: 30000,
    },
    {
      label: '60 sec',
      value: 60000,
    },
  ] as const;

  const handleDownloadCSV = () => {
    if (!data || data.length === 0) {
      console.warn('No data available for export.');
      return;
    }

    const csvRows = [];

    // Extract column headers using accessorKey
    const headers = columns
      .map((col: { accessorKey: any }) => col.accessorKey)
      .join(',');
    csvRows.push(headers);

    // Convert row data to CSV format
    data.forEach((row: { [x: string]: any }) => {
      const values = columns.map((col: { accessorKey: string | number }) => {
        const value = row[col.accessorKey];

        // Ensure we only add primitive values, handle undefined/null cases
        if (value === undefined || value === null) {
          return '""';
        }

        return `"${String(value).replace(/"/g, '""')}"`; // Escape double quotes
      });

      csvRows.push(values.join(','));
    });

    // Create CSV string and trigger download
    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = 'KPI_Leaderboard.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <React.Fragment>
      <ViewContainer>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Title>Leaderboard</Title>
          <Button
            variant="contained"
            style={{ backgroundColor: '#ff6a00', height: 40 }}
            onClick={() => handleDownloadCSV()}
          >
            Download CSV
          </Button>

          {featureFlag('leaderboardRefreshInterval') && (
            <Box>
              <Typography style={{ fontSize: '15px', fontWeight: 700 }}>
                Refresh Interval
              </Typography>
              <StyledSelect
                style={{ width: '100%', borderRadius: 8 }}
                onChange={(e: SelectChangeEvent<unknown>) =>
                  setRefreshIntervalAmount(parseInt(e.target.value as string))
                }
                value={refreshIntervalAmount}
                name="status"
                MenuProps={{
                  style: { zIndex: 100002 },
                  MenuListProps: {
                    sx: {
                      maxHeight: 300,
                      overflowY: 'auto',
                      paddingTop: 0,
                      paddingBottom: 0,
                      borderRadius: '8px',
                      border: '1px solid #D0D2D8',
                    },
                  },
                }}
              >
                {intervalList.map((opt, i) => (
                  <StyledMenuItem value={opt.value} key={i}>
                    {opt.label}
                  </StyledMenuItem>
                ))}
              </StyledSelect>
            </Box>
          )}
        </Box>
        <KPIFilter
          kpiLeaderboardState={kpiLeaderboardState}
          onChangeKpiLeaderboardState={onChangeKpiLeaderboardState}
          resetTokens={resetTokens}
        />
        <KPITable
          data={data}
          columns={columns}
          kpiLeaderboardState={kpiLeaderboardState}
          tokens={tokens}
          resetTokens={resetTokens}
          fetchKpiLeaderboardData={fetchKpiLeaderboardData}
          onChangeKpiLeaderboardState={onChangeKpiLeaderboardState}
          refreshIntervalAmount={refreshIntervalAmount}
        />
      </ViewContainer>
    </React.Fragment>
  );
};

export default KPILeaderboardView;
