// ############################################################
// Copyright (C) 2021 by Gamify Software Inc - All Rights Reserved
// You may NOT distribute or modify this code without the expressed
// written consent of Gamify Software Inc.
// #############################################################
//
// PickerSearch
//

import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import gql from 'graphql-tag';
import { LOG } from './Utilities';
import { Field } from 'formik';
import { View } from '@aws-amplify/ui-react';
import { Text } from 'react-native';
import {
  Box,
  Select,
  MenuItem,
  TextField,
  Checkbox,
  Typography,
  Button,
} from '@mui/material';
import { useParams } from 'react-router';

const screenWidth = innerWidth;

export function SelectFromList(props) {
  LOG.debug('!!!SelectFromList: ', props);
  const [itemList, setItemList] = useState([]);
  const [itemValue, setItemValue] = useState('');

  if (props.items !== undefined && props.items !== itemList) {
    setItemList(props.items);
  }
  if (itemValue !== props.value) {
    setItemValue(props.value);
  }

  return (
    <>
      {itemList.length > 0 && (
        <Field
          className={'field-dropdown'}
          as="select"
          name={props.name}
          placeholder={props.placeholder}
          value={itemValue}
          items={itemList}
          onChange={(val) => {
            props.fieldSetter(
              val.target.value,
              val.target.selectedOptions[0].label
            );
          }}
        >
          {itemList.map((item) => (
            <option key={item.label} value={item.value}>
              {item.label}
            </option>
          ))}
        </Field>
      )}
    </>
  );
}

export function SelectGiftcardAccount(props) {
  LOG.debug('SelectGiftcardAccount: ', props);

  // props:
  //
  // value = value to be displayed
  // defaultMission={name: 'Will Select Later', label:'select_later'}
  // fieldSetter

  const [itemList, setItemList] = useState([]);
  const [itemValue, setItemValue] = useState('');

  async function fetchGiftCardAccounts() {
    // LOG.debug ("fetchMissions: ", searchStr);

    const myQuery = gql`
      query MyQuery($userID: ID!) {
        listUserGiftCardAccountByUser(userID: $userID) {
          items {
            id
            name
          }
        }
      }
    `;

    const res = await API.graphql({
      query: myQuery,
      variables: { userID: global.me.id },
    });
    const accounts = res.data.listUserGiftCardAccountByUser.items;
    LOG.debug('accounts: ', accounts);

    const accountList = [];
    for (const account of accounts) {
      accountList[accountList.length] = {
        label: account.name,
        value: account.id,
      };
    }
    const sortedAccountList = accountList.sort((a, b) => {
      return b.label - a.label;
    });
    setItemList(sortedAccountList);
  }

  useEffect(() => {
    fetchGiftCardAccounts();
  }, []);

  if (itemValue !== props.value) {
    setItemValue(props.value);
  }
  // const paddingRight = props.paddingRight !== undefined ? props.paddingRight : 0;

  return (
    <Field
      className={'field-dropdown'}
      name={props.name}
      as="select"
      placeholder="Select Gift Card Account"
      searchable={'true'}
      value={itemValue}
      items={itemList}
      onChange={(val) => {
        props.fieldSetter(val.target.value, val.target.label);
      }}
    >
      {itemList.map((item) => (
        <option key={item.label} name={item.value} value={item.value}>
          {item.label}
        </option>
      ))}
    </Field>
  );
}

// export function SelectCircleLevelNew(props) {
//   LOG.debug("SelectCircleLevelNew: ", props);
//
//   const [itemList, setItemList] = useState([]);
//   const [itemValue, setItemValue] = useState(null);
//   const [open, setOpen] = useState(false);
//
//   async function fetchCircleLevels() {
//     //LOG.debug ("fetchMissions: ", searchStr);
//
//     const myQuery = gql`
//       query MyQuery($orgID: ID!) {
//         listCircleLevelByOrg (orgID: $orgID, limit: 10000) {
//           items {
//             id
//             name
//             levelNum
//           }
//         }
//       }`
//
//     const res = await API.graphql({query: myQuery, variables: {"orgID": global.me.orgID}});
//     const circleLevels = res.data.listCircleLevelByOrg.items;
//
//     let circleLevelList = [];
//     for (let circleLevel of circleLevels) {
//       circleLevelList[circleLevelList.length] = {
//         label: circleLevel.name,
//         value: circleLevel.id,
//         levelNum: circleLevel.levelNum
//       }
//     }
//     let sorted_circleLevelList = circleLevelList.sort((a, b) => {
//       return (b.levelNum - a.levelNum)
//     });
//     setItemList(sorted_circleLevelList);
//   }
//
//   useEffect(() => {
//     fetchCircleLevels();
//   }, []);
//
//   if (itemValue !== props.value) {
//     setItemValue(props.value);
//   }
//   let paddingRight = props.paddingRight !== undefined ? props.paddingRight : 0;
//
//   return (
//     <DropDownPicker
//       open={open}
//       placeholder='Select Level'
//       searchable={true}
//       value={itemValue}
//       items={itemList}
//       setOpen={setOpen}
//       setValue={setItemValue}
//       setItems={setItemList}
//       zIndex={4000}
//       zIndexReverse={1000}
//       listMode='MODAL'
//       labelStyle={[styles.light, {color: props.textColor ? 'white' : 'black'}]}
//       arrowIconStyle={{color: 'white'}}
//       dropDownContainerStyle={{paddingTop: 100}}
//       onSelectItem={(val) => {
//         LOG.debug("!!!value: ", val);
//         props.fieldSetter(val.value, val.label);
//       }}
//       placeholderStyle={{color: props.textColor !== undefined ? props.textColor : 'black'}}
//       style={{
//         backgroundColor: props.backgroundColor !== undefined ? props.backgroundColor : 'white',
//         borderWidth: 0,
//         width: props.width ? props.width : screenWidth - 20 - paddingRight,
//         paddingBottom: 0,
//       }}
//     />
//   );
// }
//
//
// export function SelectServiceArea(props) {
//   //LOG.debug ("SelectAvatar: ", props);
//
//   // props:
//   //
//   // value = value to be displayed
//   // defaultMission={name: 'Will Select Later', label:'select_later'}
//   // fieldSetter
//
//   const [itemList, setItemList] = useState([]);
//   const [itemValue, setItemValue] = useState("");
//   const [open, setOpen] = useState(false);
//   const [lastUpdate, setLastUpdate] = useState(-1);
//
//   async function fetchServiceAreas() {
//     //LOG.debug ("fetchMissions: ", searchStr);
//
//     //
//     //  TODO: Refactor this to use an index
//     //
//     const listServcieAreasQuery = gql`
//       query MyQuery($orgID: ID!) {
//         listServiceAreas(filter: {orgID: {eq: $orgID}}, limit: 10000) {
//           items {
//             id
//             name
//           }
//         }
//       }`
//
//     const listServcieAreasQueryRes = await API.graphql({
//       query: listServcieAreasQuery,
//       variables: {"orgID": global.me.orgID}
//     });
//     const serviceAreas = listServcieAreasQueryRes.data.listServiceAreas.items;
//
//     let serviceAreaList = [];
//     for (let serviceArea of serviceAreas) {
//       serviceAreaList[serviceAreaList.length] = {
//         label: serviceArea.name,
//         value: serviceArea.id
//       }
//     }
//     let sorted_serviceAreaList = serviceAreaList.sort((a, b) => {
//       if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
//       if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
//       return 0;
//     });
//     setItemList(sorted_serviceAreaList);
//   }
//
//   if (props.update !== lastUpdate) {
//     fetchServiceAreas();
//     setLastUpdate(props.update);
//   }
//
//   if (itemValue !== props.value) {
//     setItemValue(props.value);
//   }
//   let paddingRight = props.paddingRight !== undefined ? props.paddingRight : 0;
//
//   return (
//     <DropDownPicker
//       open={open}
//       placeholder='Select Service Area'
//       searchable={true}
//       value={itemValue}
//       items={itemList}
//       setOpen={setOpen}
//       setValue={setItemValue}
//       setItems={setItemList}
//       zIndex={4000}
//       zIndexReverse={1000}
//       listMode='MODAL'
//       labelStyle={styles.light}
//       dropDownContainerStyle={{paddingTop: 100}}
//       onSelectItem={(val) => {
//         LOG.debug("!!!value: ", val);
//         props.fieldSetter(val.value, val.label);
//       }}
//       placeholderStyle={{color: props.textColor !== undefined ? props.textColor : 'black'}}
//       style={{
//         backgroundColor: 'white',
//         borderWidth: 0,
//         width: screenWidth - 20 - paddingRight,
//         paddingBottom: 0,
//       }}
//     />
//   );
// }

export function SelectReward(props) {
  // LOG.debug ("SelectReward: ", props);

  // props:
  //
  // value = value to be displayed
  // fieldSetter

  const [itemList, setItemList] = useState([]);
  const [itemValue, setItemValue] = useState('');

  async function fetchRewards() {
    // LOG.debug ("fetchRewards: ", searchStr);

    let myQuery;
    if (props.all) {
      myQuery = gql`
        query MyQuery($orgID: ID!) {
          listRewardsByOrgName(
            orgID: $orgID
            filter: { isDeleted: { eq: false }, isActive: { eq: true } }
            limit: 10000
          ) {
            items {
              id
              name
            }
          }
        }
      `;
    } else {
      myQuery = gql`
        query MyQuery($orgID: ID!) {
          listRewardsByOrgName(
            orgID: $orgID
            filter: {
              ownerType: { eq: mission }
              isDeleted: { eq: false }
              isActive: { eq: true }
            }
            limit: 10000
          ) {
            items {
              id
              name
            }
          }
        }
      `;
    }
    const listRewardsRes = await API.graphql({
      query: myQuery,
      variables: { orgID: global.me.orgID },
    });

    const rewards = listRewardsRes.data.listRewardsByOrgName.items;
    // LOG.debug ("!!!rewards: ", rewards);
    const assigneeList = [];
    for (const reward of rewards) {
      assigneeList[assigneeList.length] = {
        value: reward.id,
        label: reward.name,
      };
    }
    const sortedAssigneeList = assigneeList.sort((a, b) => {
      if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
      if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
      return 0;
    });
    setItemList(sortedAssigneeList);
  }

  useEffect(() => {
    fetchRewards();
  }, []);

  if (itemValue !== props.value) {
    setItemValue(props.value);
  }

  return (
    <Field
      className={'field-dropdown'}
      as="select"
      name={props.name}
      placeholder="Select Reward"
      searchable={'true'}
      value={itemValue}
      items={itemList}
      onChange={(val) => {
        props.fieldSetter(val.target.value, val.target.label);
      }}
    >
      <option defaultValue>Select Reward</option>
      {itemList.map((item) => (
        <option key={item.label} name={item.value} value={item.value}>
          {item.label}
        </option>
      ))}
    </Field>
  );
}

export function SelectPerson(props) {
  LOG.debug('SelectPerson: ', props);

  // props:
  //
  // value = value to be displayed
  // fieldSetter
  // TODO:  Refactor
  const [itemList, setItemList] = useState([]);
  const [searchString] = useState('');
  const [itemValue, setItemValue] = useState(null);
  const [update, setUpdate] = useState(-1);

  async function fetchPeople(ourSearchString) {
    LOG.debug('fetchPeople: ', ourSearchString);

    const mySearchString = ourSearchString + '*';

    const myQuery = gql`
      query MyQuery($orgID: ID!, $searchString: String!) {
        searchUsers(
          filter: { name: { wildcard: $searchString }, orgID: { eq: $orgID } }
          limit: 999
          sort: { direction: asc, field: name }
        ) {
          items {
            id
            name
            status
          }
          nextToken
        }
      }
    `;
    const userData = await API.graphql({
      query: myQuery,
      variables: { orgID: global.me.orgID, searchString: mySearchString },
    });
    LOG.debug('userData: ', userData);
    const users = userData.data.searchUsers.items;

    const assigneeList = [];
    if (props.allOption) {
      assigneeList[0] = {
        label: 'All ' + global.appSettings.labels.person.plural,
        value: 'all',
      };
    }

    for (const user of users) {
      LOG.debug('!!!user: ', user);
      if (user.status === 'active') {
        assigneeList[assigneeList.length] = {
          value: user.id,
          label: user.name,
        };
      }
    }

    LOG.debug('!!!hideAssigned: ', props.hideAssigned);
    if (props.hideAssigned !== undefined) {
      LOG.debug('!!!filtering!!!!');
      for (const item of assigneeList) {
        if (props.hideAssigned.includes(item.value)) {
          LOG.debug('!!item.value: ', item.value);
          item.selectable = false;
          item.disabled = true;
          item.labelStyle = { color: 'lightgray' };
        }
      }
    }
    setItemList(assigneeList);
  }

  useEffect(() => {
    if (props.update !== undefined) {
      fetchPeople(searchString);
    }
  }, []);
  if (props.update !== undefined && update !== props.update) {
    LOG.debug('!!!update changed!!!');
    fetchPeople(searchString);
    setUpdate(props.update);
  }

  LOG.debug('**update: ', props.update);

  if (itemValue !== props.value) {
    setItemValue(props.value);
  }

  return (
    <View style={{ flex: 1, alignSelf: 'stretch', flexDirection: 'row' }}>
      <Field
        className={'field-dropdown'}
        as="select"
        name={props.name}
        placeholder={
          props.placeholder !== undefined
            ? props.placeholder
            : 'Select ' + global.appSettings.labels.person.proper
        }
        searchable={'true'}
        value={itemValue}
        items={itemList}
        onChange={(val) => {
          LOG.debug('!!!value: ', val);
          props.fieldSetter(val.target.value, val.target.label);
        }}
      >
        <option defaultValue>Select Person</option>
        {itemList.map((item, a) => (
          <option key={a} name={item.value} value={item.value}>
            {item.label}
          </option>
        ))}
      </Field>
    </View>
  );
}

// export function SelectPersonNotInCircle(props) {
//   //LOG.debug ("SelectPerson: ", props);
//
//   // props:
//   //
//   // value = value to be displayed
//   // fieldSetter
//   // TODO:  Refactor
//
//   const [itemList, setItemList] = useState([]);
//   const [searchString, setSearchString] = useState("");
//
//   async function fetchPeople(ourSearchString, circleID) {
//     //LOG.debug ("fetchPeople: ", searchStr);
//
//     const mySearchString = ourSearchString + "*";
//
//     const myQuery = gql`
//     query MyQuery($orgID: ID!, $searchString: String!, $circleID: ID!) {
//       searchUsers(
//         filter: {
//           name: {wildcard: $searchString},
//           orgID: {eq: $orgID}
//         },
//         limit: 999,
//         sort: { direction: asc, field: name })
//
//         {
//         items {
//           id
//           name
//           status
//           circles(circleID: {eq: $circleID}) {
//             items {
//               status
//             }
//           }
//         }
//         nextToken
//       }
//     }`
//
//     const userData = await API.graphql({
//       query: myQuery,
//       variables: {"orgID": global.me.orgID, "searchString": mySearchString, "circleID": circleID}
//     });
//     LOG.debug("userData: ", userData);
//     const users = userData.data.searchUsers.items;
//
//     var assigneeList = [];
//     for (var user of users) {
//       LOG.debug("!!!user: ", user);
//       if (user.status === 'active') {
//
//         var okToShow = false;
//         if (user.circles.items.length === 0) {
//           okToShow = true;
//         } else {
//           if (user.circles.items[0].status !== 'accepted' && user.circles.items[0].status !== 'approved') {
//             okToShow = true;
//           }
//         }
//         if (okToShow) {
//           assigneeList[assigneeList.length] = {
//             name: user.name,
//             id: user.id,
//             value: user.name,
//             label: user.id
//           }
//         }
//       }
//     }
//     LOG.debug("!!!assigneeList: ", assigneeList);
//     setItemList(assigneeList);
//   }
//
//   useEffect(() => {
//     fetchPeople(searchString, props.circleID);
//   }, []);
//
//   var paddingRight = 0;
//   if (props.paddingRight !== undefined) {
//     paddingRight = props.paddingRight;
//   }
//
//   return (
//     <>
//       <SearchableDropdown
//         placeholder="Select a Person"
//         onItemSelect={(item) => {
//           //LOG.debug ("selected: ",item);
//           props.fieldSetter(item.id, item.name)
//         }}
//         itemStyle={{
//           padding: 5,
//           marginTop: 2,
//           marginLeft: 10,
//           backgroundColor: '#ddd',
//           borderColor: '#bbb',
//           borderWidth: 1,
//           borderRadius: 8,
//           width: screenWidth - 20 - paddingRight,
//         }}
//         itemTextStyle={{color: '#222'}}
//         itemsContainerStyle={{maxHeight: 140}}
//         items={itemList}
//         defaultIndex={1}
//         resetValue={false}
//         textInputProps={
//           {
//             placeholder: searchString.length === 0 ? "Start typing..." : searchString,
//             placeholderTextColor: 'gray',
//             underlineColorAndroid: "transparent",
//             onTextChange: text => {
//               setSearchString(text);
//               fetchPeople(text, props.circleID);
//             },
//             style: {
//               fontSize: 16,
//               width: screenWidth - 20 - paddingRight,
//               marginLeft: 10,
//               padding: 5,
//               borderWidth: 1,
//               borderColor: 'gray',
//               borderRadius: 8,
//             }
//           }
//         }
//         listProps={
//           {
//             nestedScrollEnabled: true,
//           }
//         }
//       />
//     </>
//   );
// }
//
//
// export function SelectQuest() {
//   return (<View>
//     <Text>NOT IMPLEMENTED</Text>
//   </View>)
// }

export function SelectMission(props) {
  // LOG.debug ("SelectMission: ", props);

  // props:
  //
  // value = value to be displayed
  // defaultMission={name: 'Will Select Later', label:'select_later'}
  // fieldSetter

  const [itemList, setItemList] = useState([]);
  const [itemValue, setItemValue] = useState(null);

  async function fetchMissions() {
    // LOG.debug ("fetchMissions: ", searchStr);

    const listMissionQuery = gql`
      query listMissionsByOrgName($orgID: ID!) {
        listMissionsByOrgName(
          orgID: $orgID
          filter: { isDeleted: { eq: false } }
          limit: 10000
        ) {
          items {
            id
            name
          }
        }
      }
    `;

    const missionData = await API.graphql({
      query: listMissionQuery,
      variables: { orgID: global.me.orgID },
    });
    const missions = missionData.data.listMissionsByOrgName.items;

    const missionList = [];
    if (props.allOption) {
      missionList[0] = {
        name: 'All ' + global.appSettings.labels.mission.plural,
        id: 'all',
      };
    }
    if (props.defaultMission) {
      missionList[missionList.length] = {
        name: props.defaultMission.name,
        id: props.defaultMission.label,
      };
    }
    for (const mission of missions) {
      missionList[missionList.length] = {
        label: mission.name,
        value: mission.id,
      };
    }
    const sortedMissionList = missionList.sort((a, b) => {
      if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
      if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
      return 0;
    });
    setItemList(sortedMissionList);
  }

  useEffect(() => {
    fetchMissions('');
  }, []);

  if (itemValue !== props.value) {
    setItemValue(props.value);
  }
  const paddingRight =
    props.paddingRight !== undefined ? props.paddingRight : 0;

  return (
    <React.Fragment>
      {itemList.length > 0 && (
        <Field
          className={'field-dropdown'}
          name={props.name}
          as={'select'}
          placeholder="Select Mission"
          searchable="true"
          value={itemValue}
          items={itemList}
          onChange={(val) => {
            LOG.debug('!!!value: ', val);
            props.fieldSetter(val.target.value, val.target.label);
          }}
          style={{
            backgroundColor: 'white',
            borderWidth: 0,
            width: screenWidth - 20 - paddingRight,
            paddingBottom: 0,
          }}
        >
          {itemList.map((item) => (
            <option key={item.label} name={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </Field>
      )}
    </React.Fragment>
  );
}

export function SelectCircle(props) {
  LOG.debug('!!SelectCircle: ', props);

  // props:
  //
  // value = value to be displayed
  // fieldSetter
  // TODO:  Refactor

  const [itemList, setItemList] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [lastUpdate, setLastUpdate] = useState(-1);
  const [goodToGo, setGoodToGo] = useState(false);
  const [itemValue, setItemValue] = useState(null);

  async function fetchCircles(ourSearchString) {
    LOG.debug('fetchCircles: ', ourSearchString);

    let filter = '{';
    filter += 'name: {wildcard: $searchString},';
    filter += 'orgID: {eq: $orgID},';
    filter += ' isDeleted: {eq: false}';
    if (props.hideCircleLevels !== undefined) {
      for (const circleLevelID of props.hideCircleLevels) {
        filter += ', levelID: {ne: "' + circleLevelID + '"}';
      }
    }
    filter += '}';

    LOG.debug('SelectCircle filter:', filter);
    const myQuery = gql`
    query MyQuery($orgID: ID!, $searchString: String!, $userID: ID!) {
      searchCircles(
        filter: ${filter},
        limit: 50,
        sort: { direction: asc, field: name })

        {
        items {
          id
          name
          users(filter: {id: {eq: $userID}}) {
            items {
              id
              userID
              status
              memberType
            }
          }
        }
        nextToken
      }
    }`;
    const mySearchString = ourSearchString + '*';

    const circleData = await API.graphql({
      query: myQuery,
      variables: {
        orgID: global.me.orgID,
        searchString: mySearchString,
        userID: global.me.id,
      },
    });
    LOG.debug('---circleData: ', circleData);
    const circles = circleData.data.searchCircles.items;
    LOG.debug('---circles: ', circles);

    const assigneeList = [];
    if (props.allOption) {
      assigneeList[0] = {
        label: 'All ' + global.appSettings.labels.circle.plural,
        value: 'all',
        name: 'All ' + global.appSettings.labels.circle.plural,
        id: 'all',
      };
    }

    let userID = global.me.id;
    if (props.userID !== undefined) {
      // LOG.debug ("setting userID to props");
      userID = props.userID;
    }

    for (const circle of circles) {
      // LOG.debug ("circle.name", circle.name);
      LOG.debug('circle.id: ', circle.id);
      if (circle.id === global.me.orgID) {
        continue;
      }
      // const inheritedCircleAdmin = true;
      const inheritedCircleAdmin = global.me.myChildCircles.includes(circle.id);
      let userHasRecord = false;
      //
      //  The user is approved if they're the admin, an inherited admin, or we're not checking permissions
      //
      let userApproved =
        global.me.isAdmin ||
        inheritedCircleAdmin ||
        !props.checkCreatePermissions;

      for (const user of circle.users.items) {
        //
        //  If the user ia attached to this circle, the user is an admin, or the user inherits admin to this cicle,
        //  by default we're approved
        //
        if (
          user.userID === userID ||
          global.me.isAdmin ||
          inheritedCircleAdmin
        ) {
          if (user.status === 'approved' || user.status === 'accepted') {
            userApproved = true;
            if (
              props.checkCreatePermissions &&
              user.memberType === 'member' &&
              !global.me.isAdmin &&
              !inheritedCircleAdmin
            ) {
              //
              // Members cannot create stuff using this circle, so do not approve them
              //
              LOG.debug('User rejected');
              userApproved = false;
              break;
            }
          }
          if (user.userID === userID) {
            userHasRecord = true;
          }
        }
        if (props.showAll) {
          userApproved = true;
        }
        LOG.debug(
          'props.checkCreatePermissions: ',
          props.checkCreatePermissions
        );
        LOG.debug('user.memberType: ', user.memberType);
      }
      LOG.debug('user approved:', userApproved);
      LOG.debug('user has record:', userHasRecord);
      if (userApproved && props.skipCirclesUserBelongsTo === undefined) {
        LOG.debug('case 1');
        assigneeList[assigneeList.length] = {
          name: circle.name,
          id: circle.id,
          value: circle.id,
          label: circle.name,
        };
      }
      if (userApproved && props.skipCirclesUserBelongsTo !== undefined) {
        LOG.debug('case 2');
        if (!userHasRecord) {
          assigneeList[assigneeList.length] = {
            name: circle.name,
            id: circle.id,
            value: circle.id,
            label: circle.name,
          };
        }
      }
    }
    LOG.debug('!!!assigneeList: ', assigneeList);

    let filteredAssigneeList = assigneeList;
    if (props.hideAssigned !== undefined) {
      filteredAssigneeList = [];

      for (const item of assigneeList) {
        if (!props.hideAssigned.includes(item.id)) {
          filteredAssigneeList[filteredAssigneeList.length] = item;
        }
      }
    }
    LOG.debug('!!!filtered_assigneeList: ', filteredAssigneeList);

    for (const item of filteredAssigneeList) {
      LOG.debug('**item ', item);
      if (item.id === props.value) {
        if (searchString !== item.name) {
          LOG.debug('setting name');
          setSearchString(item.name);
        }
      }
      if (
        filteredAssigneeList.length === 1 &&
        searchString !== item.name &&
        !props.noAutoSelect
      ) {
        //
        //  If there is only one circle in our list, select it by default
        //
        setSearchString(item.name);
        props.fieldSetter(item.id, item.name, searchString);
      }
    }

    setItemList(filteredAssigneeList);
    setGoodToGo(true);
  }

  if (lastUpdate !== props.update) {
    LOG.debug('***HERE***');
    setSearchString('');
    fetchCircles('');
    setLastUpdate(props.update);
  }

  if (itemValue !== props.value) {
    setItemValue(props.value);
  }
  // const paddingRight = props.paddingRight !== undefined ? props.paddingRight : 0;

  LOG.debug('**update: ', props.update);

  return (
    <View style={{ flex: 1, alignSelf: 'stretch', flexDirection: 'row' }}>
      {goodToGo && (
        <Field
          className={'field-dropdown'}
          as="select"
          name={props.name}
          placeholder={'Select a ' + global.appSettings.labels.circle.proper}
          searchable={'true'}
          value={itemValue}
          items={itemList}
          onChange={(val) => {
            props.fieldSetter(
              val.target.value,
              val.target.selectedOptions[0].label
            );
            // props.fieldSetter(val.target.id, val.target.name, searchString);
            // setSearchString("");
          }}
        >
          <option defaultValue>Select a Team</option>
          {itemList.map((item) => (
            <option key={item.label} value={item.value}>
              {item.label}
            </option>
          ))}
        </Field>
      )}
    </View>
  );
}

export function NewSelectCircleOrPerson(props) {
  LOG.debug('!!NewSelectCircleOrPerson: ', props);

  // props:
  //
  // value = value to be displayed
  // fieldSetter
  // TODO:  Refactor

  const [itemList, setItemList] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [lastUpdate, setLastUpdate] = useState(-1);
  const [goodToGo, setGoodToGo] = useState(false);
  const [itemValue, setItemValue] = useState(null);

  async function fetchCircles(ourSearchString) {
    LOG.debug('fetchCircles: ', ourSearchString);

    let filteredAssigneeList = [];
    if (props.pickCircles) {
      let filter = '{';
      filter += 'name: {wildcard: $searchString},';
      filter += 'orgID: {eq: $orgID},';
      filter += ' isDeleted: {eq: false}';
      if (props.hideCircleLevels !== undefined) {
        for (const circleLevelID of props.hideCircleLevels) {
          filter += ', levelID: {ne: "' + circleLevelID + '"}';
        }
      }

      if (
        !global.me.isAdmin &&
        (props.useCirclesList || props.useChildCirclesList)
      ) {
        //
        //  If the user is looking for all deals and they're NOT an admin, filter so that they see only the deals
        //  for the teams that they're in
        //
        filter += ', or: [';
        let first = true;

        if (props.useCirclesList) {
          for (const circleID of global.me.myCircles) {
            if (!first) {
              filter += ',';
            }
            filter += '{id: {eq: "' + circleID + '"}}';
            first = false;
          }
        }

        if (props.useChildCirclesList) {
          //
          //  Include all the circles under the user
          //
          for (const circleID of global.me.myChildCircles) {
            if (circleID !== global.me.leagueID) {
              //
              //  Allow the user to see all leads for any of their teams EXCEPT for their
              //  league.  This allows us to group people into leagues without allowing everyone
              //  in the same league to see everyone else's leads
              //
              if (!first) {
                filter += ',';
              }
              filter += '{id: {eq: "' + circleID + '"}}';
              first = false;
            }
          }
        }
        filter += ']';
      }
      filter += '}';

      LOG.debug('SelectCircle filter:', filter);
      const myQuery = gql`
        query MyQuery($orgID: ID!, $searchString: String!, $userID: ID!) {
          searchCircles(
            filter: ${filter},
            limit: 50,
            sort: { direction: asc, field: name })

            {
            items {
              id
              name
              users(filter: {id: {eq: $userID}}) {
                items {
                  id
                  userID
                  status
                  memberType
                }
              }
            }
            nextToken
          }
        }`;
      const mySearchString = ourSearchString + '*';

      const circleData = await API.graphql({
        query: myQuery,
        variables: {
          orgID: global.me.orgID,
          searchString: mySearchString,
          userID: global.me.id,
        },
      });
      LOG.debug('---circleData: ', circleData);
      const circles = circleData.data.searchCircles.items;
      LOG.debug('---circles: ', circles);

      const assigneeList = [];
      let userID = global.me.id;
      if (props.userID !== undefined) {
        // LOG.debug ("setting userID to props");
        userID = props.userID;
      }

      for (const circle of circles) {
        // LOG.debug ("circle.name", circle.name);
        LOG.debug('circle.id: ', circle.id);
        if (circle.id === global.me.orgID && !props.allowAllCircle) {
          continue;
        }
        // const inheritedCircleAdmin = true;
        const inheritedCircleAdmin = global.me.myChildCircles.includes(
          circle.id
        );
        let userHasRecord = false;
        // LOG.debug ("global.me.myChildCircles: ", global.me.myChildCircles);
        //
        //  The user is approved if they're the admin, an inherited admin, or we're not checking permissions
        //
        let userApproved =
          global.me.isAdmin ||
          inheritedCircleAdmin ||
          !props.checkCreatePermissions;

        for (const user of circle.users.items) {
          //
          //  If the user ia attached to this circle, the user is an admin, or the user inherits admin to this cicle,
          //  by default we're approved
          //
          if (
            user.userID === userID ||
            global.me.isAdmin ||
            inheritedCircleAdmin
          ) {
            if (user.status === 'approved' || user.status === 'accepted') {
              userApproved = true;
              if (
                props.checkCreatePermissions &&
                user.memberType === 'member' &&
                !global.me.isAdmin &&
                !inheritedCircleAdmin
              ) {
                //
                // Members cannot create stuff using this circle, so do not approve them
                //
                LOG.debug('User rejected');
                userApproved = false;
                break;
              }
            }
            if (user.userID === userID) {
              userHasRecord = true;
            }
          }
          if (props.showAll) {
            userApproved = true;
          }
          LOG.debug(
            'props.checkCreatePermissions: ',
            props.checkCreatePermissions
          );
          LOG.debug('user.memberType: ', user.memberType);
        }
        LOG.debug('user approved:', userApproved);
        LOG.debug('user has record:', userHasRecord);
        if (userApproved && props.skipCirclesUserBelongsTo === undefined) {
          LOG.debug('case 1');
          assigneeList[assigneeList.length] = {
            name: circle.name,
            id: circle.id,
            value: circle.id,
            label: circle.name,
            type: 'circle',
          };
        }
        if (userApproved && props.skipCirclesUserBelongsTo !== undefined) {
          LOG.debug('case 2');
          if (!userHasRecord) {
            assigneeList[assigneeList.length] = {
              name: circle.name,
              id: circle.id,
              value: circle.id,
              label: circle.name,
              type: 'circle',
            };
          }
        }
      }
      LOG.debug('!!!assigneeList: ', assigneeList);

      filteredAssigneeList = assigneeList;
      if (props.hideAssigned !== undefined) {
        filteredAssigneeList = [];

        for (const item of assigneeList) {
          if (!props.hideAssigned.includes(item.id)) {
            filteredAssigneeList[filteredAssigneeList.length] = item;
          }
        }
      }
      LOG.debug('!!!filtered_assigneeList: ', filteredAssigneeList);

      for (const item of filteredAssigneeList) {
        LOG.debug('**item ', item);
        if (item.id === props.value) {
          if (searchString !== item.name) {
            LOG.debug('setting name');
            setSearchString(item.name);
          }
        }
        if (
          filteredAssigneeList.length === 1 &&
          searchString !== item.name &&
          !props.noAutoSelect
        ) {
          //
          //  If there is only one circle in our list, select it by default
          //
          setSearchString(item.name);
          props.fieldSetter(item.id, item.name, searchString);
        }
      }
    }

    if (props.pickUsers) {
      LOG.debug('!!!pickUsers!!!!');

      const myPersonQuery = gql`
        query MyQuery($orgID: ID!, $searchString: String!) {
          searchUsers(
            filter: { name: { wildcard: $searchString }, orgID: { eq: $orgID } }
            limit: 999
            sort: { direction: asc, field: name }
          ) {
            items {
              id
              name
              status
            }
            nextToken
          }
        }
      `;
      const mySearchString = ourSearchString + '*';
      const userData = await API.graphql({
        query: myPersonQuery,
        variables: { orgID: global.me.orgID, searchString: mySearchString },
      });
      LOG.debug('userData: ', userData);
      const users = userData.data.searchUsers.items;

      const personAssigneeList = [];
      for (const user of users) {
        LOG.debug('!!!user: ', user);
        if (user.status === 'active') {
          personAssigneeList[personAssigneeList.length] = {
            value: user.id,
            label: user.name,
            type: 'user',
          };
        }
      }

      if (props.hideAssigned !== undefined) {
        for (const item of personAssigneeList) {
          if (!props.hideAssigned.includes(item.id)) {
            filteredAssigneeList[filteredAssigneeList.length] = item;
          }
        }
      } else {
        for (const item of personAssigneeList) {
          filteredAssigneeList[filteredAssigneeList.length] = item;
        }
      }
    }

    let sortedAssigneeList = filteredAssigneeList;
    if (props.pickUsers && props.pickCircles) {
      //
      //  sort the combined list
      //
      sortedAssigneeList = filteredAssigneeList.sort((a, b) => {
        if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
        if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
        return 0;
      });
    }
    if (props.allOption) {
      sortedAssigneeList.unshift({
        label: 'All ' + global.appSettings.labels.circle.plural,
        value: 'all',
        name: 'All ' + global.appSettings.labels.circle.plural,
        id: 'all',
      });
    }

    setItemList(sortedAssigneeList);
    setGoodToGo(true);
  }

  if (lastUpdate !== props.update) {
    LOG.debug('***HERE***');
    setSearchString('');
    fetchCircles('');
    setLastUpdate(props.update);
  }

  if (itemValue !== props.value) {
    setItemValue(props.value);
  }
  // const paddingRight = props.paddingRight !== undefined ? props.paddingRight : 0;

  LOG.debug('**update: ', props.update);

  let label;
  if (props.pickUsers && props.pickCircles) {
    label =
      'Select a ' +
      global.appSettings.labels.person.proper +
      ' or a ' +
      global.appSettings.labels.circle.proper;
  } else if (props.pickUsers) {
    label = 'Select a ' + global.appSettings.labels.person.proper;
  } else {
    label = 'Select a ' + global.appSettings.labels.circle.proper;
  }
  return (
    <View style={{ flex: 1, alignSelf: 'stretch', flexDirection: 'row' }}>
      {goodToGo && (
        <Field
          className={'field-dropdown'}
          name={props.name}
          as="select"
          placeholder={label}
          searchable={'true'}
          value={itemValue}
          items={itemList}
          onChange={(val) => {
            props.fieldSetter(val.target.value, val.target.label, searchString);
            setSearchString('');
          }}
          disableLocalSearch={true}
          onChangeSearchText={(text) => {
            setSearchString(text);
            fetchCircles(text);
          }}
        >
          {itemList.map((item) => (
            <option key={item.label} name={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </Field>
      )}
    </View>
  );
}

// export function SelectChildCircle(props) {
//   //LOG.debug ("SelectCircle: ", props);
//
//   // props:
//   //
//   // value = value to be displayed
//   // fieldSetter
//
//   const [itemList, setItemList] = useState([]);
//   const [open, setOpen] = useState(false);
//   const [itemValue, setItemValue] = useState(null);
//
//   async function fetchCircles() {
//     //LOG.debug ("fetchCircles: ", searchStr);
//
//
//     const myQuery = gql`
//       query MyQuery($orgID: ID!) {
//         listCirclesByOrg(orgID: $orgID, filter: {isDeleted: {eq: false}}, limit: 10000) {
//           items {
//             name
//             id
//             parentCircleID
//             parent {
//               id
//               name
//             }
//             users {
//               items {
//                 userID
//                 status
//               }
//             }
//           }
//         }
//       }`
//
//     //LOG.debug ("orgID: ", orgID);
//     const circleData = await API.graphql({query: myQuery, variables: {"orgID": global.me.orgID}});
//     //LOG.debug ("circleData: ", circleData);
//     const circles = circleData.data.listCirclesByOrg.items;
//     LOG.debug("circles: ", circles);
//
//     let assigneeList = [];
//     if (props.allOption) {
//       assigneeList[0] = {
//         label: "All " + global.appSettings.labels.circle.plural,
//         value: "all"
//       }
//     }
//
//     let userID = global.me.id;
//     if (props.userID !== undefined) {
//       //LOG.debug ("setting userID to props");
//       userID = props.userID;
//     }
//     //LOG.debug ("props.circleID: ", props.circleID);
//
//     let circleID = "";
//     if (props.circleID !== undefined) {
//       //LOG.debug ("setting userID to props");
//       circleID = props.circleID;
//     }
//     //LOG.debug ("circleID: ", circleID);
//
//     for (let circle of circles) {
//       //LOG.debug ("circle.name", circle.name);
//       if (circle.id !== circleID && circle.parentCircleID !== circleID) {
//         let userHasRecord = false;
//         let userApproved = false;
//         let user;
//         for (user of circle.users.items) {
//           if (user.userID === userID || global.me.isAdmin) {
//             if (user.status === "approved" ||
//               user.status === "accepted") {
//               userApproved = true;
//             }
//             if (user.userID === userID) {
//               userHasRecord = true;
//             }
//           }
//           if (props.showAll) {
//             userApproved = true;
//           }
//         }
//         //LOG.debug ("user approved:" , userApproved);
//         let parentCircleID = "";
//         let parentName = "";
//
//         if (circle.parent !== null) {
//           parentCircleID = circle.parent.id;
//           parentName = circle.parent.name;
//         }
//         if (userApproved && props.skipCirclesUserBelongsTo === undefined) {
//           //LOG.debug ("case 1");
//           assigneeList[assigneeList.length] = {
//             label: circle.name,
//             value: {
//               childID: circle.id,
//               childName: circle.name,
//               parentID: parentCircleID,
//               parentName: parentName
//             }
//           }
//         }
//         if (!userApproved && props.skipCirclesUserBelongsTo !== undefined) {
//           //LOG.debug ("case 2");
//           if (!userHasRecord) {
//             assigneeList[assigneeList.length] = {
//               label: circle.name,
//               value: {
//                 childID: circle.id,
//                 childName: circle.name,
//                 parentID: parentCircleID,
//                 parentName: parentName
//               }
//             }
//           }
//         }
//       }
//     }
//     var sorted_assigneeList = assigneeList.sort((a, b) => {
//       if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
//       if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
//       return 0;
//     });
//
//     setItemList(sorted_assigneeList);
//   }
//
//   useEffect(() => {
//     fetchCircles();
//   }, []);
//
//   if (itemValue !== props.value) {
//     setItemValue(props.value);
//   }
//   let paddingRight = props.paddingRight !== undefined ? props.paddingRight : 0;
//
//   return (
//
//     <DropDownPicker
//       open={open}
//       placeholder='Select Team'
//       searchable={true}
//       value={itemValue}
//       items={itemList}
//       setOpen={setOpen}
//       setValue={setItemValue}
//       setItems={setItemList}
//       zIndex={4000}
//       zIndexReverse={1000}
//       listMode='MODAL'
//       labelStyle={styles.light}
//       dropDownContainerStyle={{paddingTop: 100}}
//       onSelectItem={(val) => {
//         LOG.debug("!!!value: ", val);
//         props.fieldSetter(val.value.childID, val.value.childName, val.value.parentID, val.value.parentName);
//       }}
//       placeholderStyle={{color: props.textColor !== undefined ? props.textColor : 'black'}}
//       style={{
//         backgroundColor: 'white',
//         borderWidth: 0,
//         width: screenWidth - 20 - paddingRight,
//         paddingBottom: 0,
//       }}
//     />
//   );
// }

export function SelectKPIRule(props) {
  // LOG.debug ("SelectKPI: ", props);

  // props:
  //
  // value = value to be displayed
  // fieldSetter

  const [itemList, setItemList] = useState([]);
  const [itemValue, setItemValue] = useState(null);

  async function fetchKPIRules() {
    // LOG.debug ("fetchRewards: ", searchStr);

    const myQuery = gql`
      query MyQuery($orgID: ID!) {
        listKPIRulesByOrg(
          orgID: $orgID
          filter: { isDeleted: { eq: false } }
          limit: 10000
        ) {
          items {
            id
            title
            action
            valueUnit
            valueUnitPlural
            actionPastTense
          }
        }
      }
    `;

    const getKPIList = await API.graphql({
      query: myQuery,
      variables: { orgID: global.me.orgID },
    });
    const KPIRules = getKPIList.data.listKPIRulesByOrg.items;
    const assigneeList = [];

    for (const KPIRule of KPIRules) {
      //
      //  Omit KPI's (dealstages) with the sequence <= 0 if props.ignoreNoDealStages is true
      //
      // LOG.debug ("KPIRule.id: ", KPIRule.id + " === props.value: " + props.value + "?")
      assigneeList[assigneeList.length] = {
        value: KPIRule.id,
        label: KPIRule.title,
        rule: KPIRule,
      };
    }

    const sortedAssigneeList = assigneeList.sort((a, b) => {
      if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
      if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
      return 0;
    });
    setItemList(sortedAssigneeList);
  }

  useEffect(() => {
    fetchKPIRules();
  }, []);

  if (itemValue !== props.value) {
    setItemValue(props.value);
  }

  return (
    <Field
      className={'field-dropdown'}
      as="select"
      name={props.name}
      placeholder="Select KPI Rule"
      searchable="true"
      value={itemValue}
      items={itemList}
      onChange={(val) => {
        props.fieldSetter(
          val.target.value,
          val.target.selectedOptions[0].label,
          val.target.selectedOptions[0].dataset.ruleaction, // rule.action
          val.target.selectedOptions[0].dataset.ruleactiontypeplural, // rule.valueUnitPlural
          val.target.selectedOptions[0].dataset.ruleactionpasttense, // rule.actionPastTense
          val.target.selectedOptions[0].dataset.rulevalueunit // rule.valueUnit
        );
      }}
      // style={{
      //   backgroundColor: 'white',
      //   borderWidth: 0,
      //   width: screenWidth - 20 - paddingRight,
      //   paddingBottom: 0,
      // }}
    >
      <option defaultValue>Select KPI Rule</option>
      {itemList.map((item, a) => (
        <option
          key={a}
          data-ruletitle={item.rule.title}
          data-ruleaction={item.rule.action}
          data-ruleactiontypeplural={item.rule.valueUnitPlural}
          data-ruleactionpasttense={item.rule.actionPastTense}
          data-rulevalueunit={item.rule.valueUnit}
          name={item.label}
          value={item.value}
        >
          {item.label}
        </option>
      ))}
    </Field>
  );
}
export function SelectStage(props) {
  const { orgID } = useParams();
  const [itemList, setItemList] = useState([]);
  const [fetchDone, setFetchDone] = useState(false);
  // const [itemValue, setItemValue] = useState(null);

  async function fetchStages(categoryID) {
    const myQuery = gql`
      query MyQuery($orgID: ID!, $nextToken: String) {
        listStages(
          filter: { orgID: { eq: $orgID }, isDeleted: { eq: false } }
          limit: 10000
          nextToken: $nextToken
        ) {
          items {
            id
            sequence
            title
            flexAttributes
            description
            categoryID
          }
          nextToken
        }
      }
    `;
    try {
      let stages = [];
      let _nextToken = null;
      do {
        const getStageRes = await API.graphql({
          query: myQuery,
          variables: { orgID, nextToken: _nextToken },
        });
        const _stages = getStageRes.data.listStages.items;
        stages = [...stages, ..._stages];
        _nextToken = getStageRes.data.listStages.nextToken;
      } while (_nextToken);

      const assigneeList = [];

      let minSequence;
      if (props.minSequence !== undefined) {
        minSequence = props.minSequence;
      } else if (props.ignoreNoDealStages) {
        minSequence = 1;
      } else {
        minSequence = -1000;
      }
      for (const stage of stages.filter(
        (el) => el.categoryID === props.categoryID
      )) {
        if (stage.sequence >= minSequence) {
          const listEntry = {
            id: stage.id,
            name: stage.title,
            value: stage.id,
            label: stage.title,
            display: stage.title,
            config: stage.flexAttributes,
            categoryID,
            sequence: stage.sequence,
          };
          assigneeList[assigneeList.length] = listEntry;
        }
      }
      const sortedAssigneeList = assigneeList.sort((a, b) => {
        if (a.sequence < b.sequence) return -1;
        if (a.sequence > b.sequence) return 1;
        return 0;
      });
      setItemList(sortedAssigneeList);
      setFetchDone(true);
    } catch (e) {
      console.log('ERROR FETCHING DEAL STAGES --- ', e);
    }
  }

  useEffect(() => {
    fetchStages(props.categoryID);
  }, [props.categoryID]);

  // if (itemValue !== props.value) {
  //   setItemValue(props.value);
  // }

  if (!itemList.length) {
    if (!fetchDone) return null;
    return (
      <TextField
        disabled={true}
        placeholder="No Stages Available"
        value="No Stages Available"
        style={{ width: '100%' }}
      />
    );
  }
  return (
    // <Box>
    <Select
      value={props.value}
      style={{ width: '100%' }}
      onChange={(e) => {
        // setItemValue(e.target.value);
        // props.setter((prev) => ({ ...prev, triggerID: e.target.value }));
        props.setter(e);
      }}
    >
      {itemList.map((el, i) => (
        <MenuItem
          key={i}
          value={el.id}
          style={{
            backgroundColor: 'white',
            border: '1px solid lightgray',
          }}
          onMouseEnter={(e) => (e.target.style.backgroundColor = 'lightgray')}
          onMouseLeave={(e) => (e.target.style.backgroundColor = 'white')}
        >
          {el.label}
        </MenuItem>
      ))}
    </Select>
  );
}
// export function SelectStage(props) {
//   //LOG.debug ("SelectStage: ", props);

//   // props:
//   //
//   // value = value to be displayed
//   // fieldSetter

//   const [itemList, setItemList] = useState([]);
//   const [itemValue, setItemValue] = useState(null);
//   const [open, setOpen] = useState(false);

//   async function fetchStages(categoryID) {
//     //LOG.debug ("fetchRewards: ", searchStr);

//     let myQuery;
//     if (categoryID !== undefined) {
//       myQuery = gql`
//         query MyQuery($categoryID: ID!) {
//           listStageByCategorySequence(categoryID: $categoryID, filter: {isDeleted: {eq: false}}, limit: 10000) {
//             items {
//               id
//               sequence
//               title
//               flexAttributes
//               description
//             }
//           }
//         }`
//     } else {
//       myQuery = gql`
//         query MyQuery($orgID: ID!) {
//           listStages(filter: {orgID: {eq: $orgID}, isDeleted: {eq: false}}, limit: 10000) {
//             items {
//               id
//               sequence
//               title
//               flexAttributes
//               description
//             }
//           }
//         }`
//     }

//     const getStageRes = await API.graphql({
//       query: myQuery,
//       variables: {"orgID": global.me.orgID, "categoryID": categoryID}
//     });
//     //LOG.debug ("getKPIList",getKPIList);
//     let stages;
//     if (categoryID !== undefined) {
//       stages = getStageRes.data.listStageByCategorySequence.items;
//     } else {
//       stages = getStageRes.data.listStages.items;
//     }

//     //LOG.debug ("KPIs",KPIs);
//     let assigneeList = [];
//     if (props.activeOption) {
//       assigneeList[assigneeList.length] = {
//         label: "- Active Only",
//         id: "active",
//         value: "active"
//       }
//     }
//     if (props.allOption) {
//       assigneeList[assigneeList.length] = {
//         label: "- All",
//         id: "all",
//         value: "all"
//       }
//     }

//     let minSequence;
//     if (props.minSequence !== undefined) {
//       minSequence = props.minSequence;
//     } else if (props.ignoreNoDealStages) {
//       minSequence = 1;
//     } else {
//       minSequence = -1000;
//     }
//     for (let stage of stages) {
//       //LOG.debug ("stage: ", stage);
//       if (stage.sequence >= minSequence) {
//         //
//         //  Omit KPI's (dealstages) with the sequence <= 0 if props.ignoreNoDealStages is true
//         //
//         //LOG.debug ("stage.id: ", stage.id + " === props.value: " + props.value + "?")
//         // if ( stage.id === props.value ) {
//         //   //LOG.debug ("YES: ", stage.title)
//         //   setName ( stage.title );
//         // }
//         let listEntry;
//         listEntry = {
//           id: stage.id,
//           name: stage.title,
//           value: stage.id,
//           label: stage.title,
//           display: stage.title,
//           config: stage.flexAttributes,
//           categoryID: categoryID,
//           sequence: stage.sequence,
//         }
//         assigneeList[assigneeList.length] = listEntry;
//       }
//     }

//     let sorted_assigneeList = assigneeList.sort((a, b) => {
//       if (a.sequence < b.sequence) return -1;
//       if (a.sequence > b.sequence) return 1;
//       return 0;
//     });
//     //LOG.debug ("sorted_assigneeList: ", sorted_assigneeList);
//     setItemList(sorted_assigneeList);

//   }

//   useEffect(() => {
//     fetchStages(props.categoryID);
//   }, []);

//   if (itemValue !== props.value) {
//     setItemValue(props.value);
//   }
//   let paddingRight = props.paddingRight !== undefined ? props.paddingRight : 0;

//   return (
//     <View style={{width: '80%', marginTop: -12}}>
//       {itemList.length > 0 &&
//         <DropDownPicker
//           open={open}
//           placeholder='Select Stage'
//           searchable={true}
//           value={itemValue}
//           items={itemList}
//           setOpen={setOpen}
//           setValue={setItemValue}
//           setItems={setItemList}
//           zIndex={4000}
//           zIndexReverse={1000}
//           listMode='MODAL'
//           labelStyle={styles.light}
//           dropDownContainerStyle={{paddingTop: 100}}
//           onSelectItem={(val) => {
//             LOG.debug("!!!value: ", val);
//             props.fieldSetter(val);
//           }}
//           placeholderStyle={{color: props.textColor !== undefined ? props.textColor : 'black'}}
//           style={{
//             backgroundColor: 'white',
//             borderWidth: 0,
//             width: screenWidth - 60 - paddingRight,
//             paddingBottom: 0,
//           }}
//         />
//       }
//     </View>
//   );
// }

//
//
// export function MultiSelectDisposition({ categoryID, orgID, value, setter }) {
//   const [itemList, setItemList] = useState([]);
//   const [fetchDone, setFetchDone] = useState(false);

//   async function fetchDispositions() {
//     console.log('CATEGORY ID IN MULTI SELECT DISPOSITIONS: ', categoryID);
//     const myQuery = gql`
//       query MyQuery($orgID: ID!) {
//         listStageDispositions(
//           filter: { orgID: { eq: $orgID }, isDeleted: { eq: false } }
//           limit: 10000
//         ) {
//           items {
//             id
//             iconName
//             iconType
//             iconColor
//             title
//             isSetupDeal
//           }
//         }
//       }
//     `;

//     const getDispositionRes = await API.graphql({
//       query: myQuery,
//       variables: { orgID },
//     });

//     const dispositions = getDispositionRes.data.listStageDispositions.items;
//     const dispositionsList = dispositions.map((disposition) => ({
//       id: disposition.id,
//       name: disposition.title,
//       label: disposition.title,
//       value: disposition.id,
//       display: disposition.title,
//       isSetupDeal: disposition.isSetupDeal,
//     }));
//     console.log('dispositionsList:::', dispositionsList);
//     setItemList(dispositionsList);
//     setFetchDone(true);
//   }

//   useEffect(() => {
//     fetchDispositions();
//   }, [categoryID]);

//   const selectedValues = value ? value.split(',') : [];

//   const handleCheckboxChange = (id) => {
//     let updatedValues;
//     if (selectedValues.includes(id)) {
//       updatedValues = selectedValues.filter((item) => item !== id);
//     } else {
//       updatedValues = [...selectedValues, id];
//     }
//     setter(updatedValues.join(','));
//   };

//   if (!itemList.length) {
//     if (!fetchDone) return null;
//     return <Text>No Stages Dispositions</Text>;
//   }

//   return (
//     <Box
//       style={{
//         width: '100%',
//         maxHeight: '20%',
//         backgroundColor: 'red',
//         overflowY: 'scroll',
//       }}
//     >
//       {itemList.map((el) => (
//         <Box
//           key={el.id}
//           display="flex"
//           alignItems="center"
//           style={{
//             borderBottom: '1px solid lightgray',
//             padding: '8px 0',
//           }}
//         >
//           <Checkbox
//             checked={selectedValues.includes(el.id)}
//             onChange={() => handleCheckboxChange(el.id)}
//           />
//           <Text>{el.label}</Text>
//         </Box>
//       ))}
//     </Box>
//   );
// }
export function MultiSelectDisposition({ orgID, value, setter }) {
  const [itemList, setItemList] = useState([]);
  const [fetchDone, setFetchDone] = useState(false);
  const [selectedDispositions, setSelectedDispositions] = useState(
    value ? value.split(',') : []
  );
  const [invalidDispositionSelected, setInvalidDispositionSelected] =
    useState(false);

  async function fetchDispositions() {
    const myQuery = gql`
      query MyQuery($orgID: ID!) {
        listStageDispositions(
          filter: { orgID: { eq: $orgID }, isDeleted: { eq: false } }
          limit: 10000
        ) {
          items {
            id
            iconName
            iconType
            iconColor
            title
            isSetupDeal
          }
        }
      }
    `;

    const getDispositionRes = await API.graphql({
      query: myQuery,
      variables: { orgID },
    });

    const dispositions = getDispositionRes.data.listStageDispositions.items;
    const dispositionsList = dispositions.map((disposition) => ({
      id: disposition.id,
      name: disposition.title,
      label: disposition.title,
      value: disposition.id,
      display: disposition.title,
      isSetupDeal: disposition.isSetupDeal,
    }));
    setItemList(dispositionsList);
    setFetchDone(true);
  }

  useEffect(() => {
    fetchDispositions();
  }, [orgID]);

  useEffect(() => {
    const orgDispositionIds = itemList.map((disposition) => disposition.id);
    // Check for any invalid (out of org) dispositions every time selectedDispositions changes. Putting a warning if there is one and provide the option to clear all selected dispositions.
    for (const dispositionId of selectedDispositions) {
      if (!orgDispositionIds.includes(dispositionId)) {
        setInvalidDispositionSelected(true);
        break;
      }
    }
  }, [selectedDispositions]);

  const handleCheckboxChange = (id) => {
    let updatedDispositions;
    if (selectedDispositions.includes(id)) {
      updatedDispositions = selectedDispositions.filter((item) => item !== id);
    } else {
      updatedDispositions = [...selectedDispositions, id];
    }
    setSelectedDispositions(updatedDispositions);
    setter(updatedDispositions.join(','));
  };

  if (!itemList.length) {
    if (!fetchDone) return null;
    return <Text>No Stages Dispositions</Text>;
  }

  return (
    <>
      {invalidDispositionSelected && (
        <Typography
          style={{ color: 'red' }}
        >{`WARNING: There is an invalid disposition selected that is not visible (it is not associated with this org), please click RESET and reselect the desired dipsositions.`}</Typography>
      )}
      <Button onClick={() => setSelectedDispositions([])}>RESET</Button>
      <Box
        style={{
          width: '100%',
          maxHeight: '20%',
          // backgroundColor: 'red',
          overflowY: 'scroll',
          marginBottom: '10px',
        }}
      >
        {itemList.map((el) => (
          <Box
            key={el.id}
            display="flex"
            alignItems="center"
            style={{
              borderBottom: '1px solid lightgray',
              padding: '8px 0',
            }}
          >
            <Checkbox
              checked={selectedDispositions.includes(el.id)}
              onChange={() => handleCheckboxChange(el.id)}
            />
            <Text>{el.label}</Text>
          </Box>
        ))}
      </Box>
    </>
  );
}
export function SelectDisposition(props) {
  //  LOG.debug ("!!!!SelectDisposition: ", props);
  const { orgID } = useParams();
  // props:
  //
  // value = value to be displayed
  // fieldSetter

  const [itemList, setItemList] = useState([]);
  const [fetchDone, setFetchDone] = useState(false);
  // const [itemValue, setItemValue] = useState(null);
  // const [open, setOpen] = useState(false);

  async function fetchDispositions(categoryID) {
    let myQuery;
    // if (categoryID !== undefined) {
    if (categoryID && categoryID.length) {
      myQuery = gql`
        query MyQuery($categoryID: ID!) {
          listStageDispositions(
            filter: {
              categoryID: { eq: $categoryID }
              isDeleted: { eq: false }
            }
            limit: 10000
          ) {
            items {
              id
              iconName
              iconType
              iconColor
              title
              isSetupDeal
            }
          }
        }
      `;
    } else {
      myQuery = gql`
        query MyQuery($orgID: ID!) {
          listStageDispositions(
            filter: { orgID: { eq: $orgID }, isDeleted: { eq: false } }
            limit: 10000
          ) {
            items {
              id
              iconName
              iconType
              iconColor
              title
              isSetupDeal
            }
          }
        }
      `;
    }
    // const myQuery = gql`
    //   query MyQuery($orgID: ID!) {
    //     listStageDispositions(
    //       filter: { orgID: { eq: $orgID }, isDeleted: { eq: false } }
    //       limit: 10000
    //     ) {
    //       items {
    //         id
    //         iconName
    //         iconType
    //         iconColor
    //         title
    //         isSetupDeal
    //       }
    //     }
    //   }
    // `;

    const getDispositionRes = await API.graphql({
      query: myQuery,
      variables: { orgID, categoryID },
    });
    const dispositions = getDispositionRes.data.listStageDispositions.items;

    const assigneeList = [];
    for (const disposition of dispositions) {
      //
      //  Omit KPI's (dealstages) with the sequence <= 0 if props.ignoreNoDealStages is true
      //

      const listEntry = {
        id: disposition.id,
        name: disposition.title,
        label: disposition.title,
        value: disposition.id,
        display: disposition.title,
        isSetupDeal: disposition.isSetupDeal,
      };
      assigneeList[assigneeList.length] = listEntry;
    }

    const sortedAssigneeList = assigneeList.sort((a, b) => {
      if (a.sequence < b.sequence) return -1;
      if (a.sequence > b.sequence) return 1;
      return 0;
    });

    setItemList(sortedAssigneeList);
    setFetchDone(true);
  }

  useEffect(() => {
    fetchDispositions(props.categoryID);
  }, [props.categoryID]);

  // if (itemValue !== props.value) {
  //   setItemValue(props.value);
  // }
  // let paddingRight = props.paddingRight !== undefined ? props.paddingRight : 0;
  if (!itemList.length) {
    if (!fetchDone) return null;
    return (
      <TextField
        disabled={true}
        placeholder="No Stages Dispositions"
        value="No Stages Dispositions"
        style={{ width: '100%' }}
      />
    );
  }
  return (
    <React.Fragment>
      <Select
        value={props.value}
        style={{ width: '100%' }}
        onChange={(e) => {
          props.setter(e);
          // setItemValue(e.target.value);
          // props.setter((prev) => ({
          //   ...prev,
          //   triggerID: e.target.value,
          // }));
        }}
      >
        {itemList.map((el, i) => (
          <MenuItem
            key={i}
            value={el.id}
            style={{
              backgroundColor: 'white',
              border: '1px solid lightgray',
            }}
            onMouseEnter={(e) => (e.target.style.backgroundColor = 'lightgray')}
            onMouseLeave={(e) => (e.target.style.backgroundColor = 'white')}
          >
            {el.label}
          </MenuItem>
        ))}
      </Select>
    </React.Fragment>
  );
}
// export function SelectDisposition(props) {
//   //LOG.debug ("!!!!SelectDisposition: ", props);
//
//   // props:
//   //
//   // value = value to be displayed
//   // fieldSetter
//
//   const [itemList, setItemList] = useState([]);
//   const [itemValue, setItemValue] = useState(null);
//   const [open, setOpen] = useState(false);
//
//   async function fetchDispositions(categoryID) {
//     //LOG.debug ("fetchDispositions: ", categoryID);
//
//     let myQuery;
//     //
//     //  TODO:  Use indexes here
//     //
//     if (categoryID !== undefined) {
//       myQuery = gql`
//       query MyQuery($categoryID: ID!) {
//         listStageDispositions(filter: {categoryID: {eq: $categoryID}, isDeleted: {eq: false}}, limit: 10000) {
//           items {
//             id
//             iconName
//             iconType
//             iconColor
//             title
//             isSetupDeal
//           }
//         }
//       }`
//     } else {
//       myQuery = gql`
//       query MyQuery($orgID: ID!) {
//         listStageDispositions(filter: {orgID: {eq: $orgID}, isDeleted: {eq: false}}, limit: 10000) {
//           items {
//             id
//             iconName
//             iconType
//             iconColor
//             title
//             isSetupDeal
//           }
//         }
//       }`
//     }
//
//     const getDispositionRes = await API.graphql({
//       query: myQuery,
//       variables: {"orgID": global.me.orgID, "categoryID": categoryID}
//     });
//     //LOG.debug ("getDispositionRes",getDispositionRes);
//     const dispositions = getDispositionRes.data.listStageDispositions.items;
//     //LOG.debug ("KPIs",KPIs);
//     let assigneeList = [];
//     for (let disposition of dispositions) {
//       //LOG.debug ("disposition: ", disposition);
//       //
//       //  Omit KPI's (dealstages) with the sequence <= 0 if props.ignoreNoDealStages is true
//       //
//       let listEntry;
//       listEntry = {
//         id: disposition.id,
//         name: disposition.title,
//         label: disposition.title,
//         value: disposition.id,
//         display: disposition.title,
//         isSetupDeal: disposition.isSetupDeal,
//       }
//       assigneeList[assigneeList.length] = listEntry;
//     }
//
//     let sorted_assigneeList = assigneeList.sort((a, b) => {
//       if (a.sequence < b.sequence) return -1;
//       if (a.sequence > b.sequence) return 1;
//       return 0;
//     });
//     //LOG.debug ("sorted_assigneeList: ", sorted_assigneeList);
//     setItemList(sorted_assigneeList);
//
//   }
//
//   useEffect(() => {
//     fetchDispositions(props.categoryID);
//   }, []);
//
//   if (itemValue !== props.value) {
//     setItemValue(props.value);
//   }
//   let paddingRight = props.paddingRight !== undefined ? props.paddingRight : 0;
//
//   return (
//     <React.Fragment>
//       {itemList.length > 0 &&
//         <DropDownPicker
//           open={open}
//           placeholder='Select Disposition'
//           searchable={true}
//           value={itemValue}
//           items={itemList}
//           setOpen={setOpen}
//           setValue={setItemValue}
//           setItems={setItemList}
//           zIndex={4000}
//           zIndexReverse={1000}
//           listMode='MODAL'
//           labelStyle={styles.light}
//           dropDownContainerStyle={{paddingTop: 100}}
//           onSelectItem={(val) => {
//             LOG.debug("!!!value: ", val);
//             props.fieldSetter(val);
//           }}
//           placeholderStyle={{color: props.textColor !== undefined ? props.textColor : 'black'}}
//           style={{
//             backgroundColor: 'white',
//             borderWidth: 0,
//             width: screenWidth - 60 - paddingRight,
//             paddingBottom: 0,
//           }}
//         />
//       }
//     </React.Fragment>
//   );
// }

export function SelectMissionCategory(props) {
  // LOG.debug ("SelectKPI: ", props);

  // props:
  //
  // value = value to be displayed
  // fieldSetter

  const [itemList, setItemList] = useState([]);
  const [itemValue, setItemValue] = useState(null);

  async function fetchCategories() {
    // LOG.debug ("fetchRewards: ", searchStr);

    //
    //  TODO:  Use an index here
    //
    const myQuery = gql`
      query MyQuery($orgID: ID!) {
        listMissionCategorys(
          filter: { orgID: { eq: $orgID }, isDeleted: { eq: false } }
          limit: 10000
        ) {
          items {
            id
            title
            isDefault
            doNotShow
          }
        }
      }
    `;

    const getMissionCategorysRes = await API.graphql({
      query: myQuery,
      variables: { orgID: global.me.orgID },
    });
    // LOG.debug ("getKPIList",getKPIList);
    const categories = getMissionCategorysRes.data.listMissionCategorys.items;
    // LOG.debug ("categories: ",categories);
    const assigneeList = [];
    if (props.allOption) {
      assigneeList[0] = {
        label: '- All',
        value: 'all',
      };
    }

    for (const category of categories) {
      // LOG.debug ("category: ", category);
      //
      //  Omit KPI's (dealstages) with the sequence <= 0 if props.ignoreNoDealStages is true
      //
      if (!category.doNotShow) {
        assigneeList[assigneeList.length] = {
          value: category.id,
          label: category.title,
        };
      }
    }

    const sortedAssigneeList = assigneeList.sort((a, b) => {
      if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
      if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
      return 0;
    });
    // LOG.debug ("sorted_assigneeList: ", sorted_assigneeList);
    setItemList(sortedAssigneeList);
  }

  useEffect(() => {
    fetchCategories();
  }, []);

  if (itemValue !== props.value) {
    setItemValue(props.value);
  }
  const paddingRight =
    props.paddingRight !== undefined ? props.paddingRight : 0;

  return (
    <Field
      className={'field-dropdown'}
      name={props.name}
      placeholder="Select Category"
      searchable="true"
      value={itemValue}
      items={itemList}
      onChange={(val) => {
        LOG.debug('!!!value: ', val);
        props.fieldSetter(val.target.id, val.target.title);
      }}
      style={{
        backgroundColor: 'white',
        borderWidth: 0,
        width: screenWidth - 60 - paddingRight,
        paddingBottom: 0,
      }}
    >
      {itemList.map((item) => (
        <option key={item.label} name={item.value} value={item.value}>
          {item.label}
        </option>
      ))}
    </Field>
  );
}

export function SelectKPICategory(props) {
  const [itemList, setItemList] = useState([]);
  const [itemValue, setItemValue] = useState(null);
  const [flexAttDict, setFlexAttDict] = useState({});
  async function fetchCategories() {
    const myQuery = gql`
      query MyQuery($orgID: ID!) {
        listStageCategorys(
          filter: { orgID: { eq: $orgID }, isDeleted: { eq: false } }
          limit: 10000
        ) {
          items {
            id
            title
            flexAttributes
          }
        }
      }
    `;

    const listStageCategorysRet = await API.graphql({
      query: myQuery,
      variables: { orgID: props.orgID || global.me.orgID },
    });

    const categories = listStageCategorysRet.data.listStageCategorys.items;

    const assigneeList = [];
    const flexAttTemp = {};
    for (const category of categories) {
      if (!category.doNotShow) {
        flexAttTemp[category.id] = category.flexAttributes;
        assigneeList[assigneeList.length] = {
          id: category.id,
          value: category.id,
          label: category.title,
          flexAttributes: category.flexAttributes,
        };
      }
    }
    setFlexAttDict(flexAttTemp);
    const sortedAssigneeList = assigneeList.sort((a, b) => {
      if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
      if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
      return 0;
    });
    setItemList(sortedAssigneeList);
  }

  useEffect(() => {
    fetchCategories();
  }, []);

  if (itemValue !== props.value) {
    setItemValue(props.value);
  }
  if (!itemList.length) return;
  return (
    <Box>
      <Select
        value={itemValue}
        style={{ width: '100%' }}
        onChange={(e) => {
          setItemValue(e.target.value);
          props.setter((prev) => ({
            ...prev,
            categoryID: e.target.value,
            triggerID: '',
            flexFields: flexAttDict[e.target.value],
          }));
        }}
      >
        {itemList.map((el, i) => (
          <MenuItem
            key={i}
            value={el.id}
            style={{
              backgroundColor: 'white',
              border: '1px solid lightgray',
            }}
            onMouseEnter={(e) => (e.target.style.backgroundColor = 'lightgray')}
            onMouseLeave={(e) => (e.target.style.backgroundColor = 'white')}
          >
            {el.label}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
}
// export function SelectKPICategory(props) {
//   //LOG.debug ("SelectKPI: ", props);

//   // props:
//   //
//   // value = value to be displayed
//   // fieldSetter

//   const [itemList, setItemList] = useState([]);
//   const [itemValue, setItemValue] = useState(null);
//   const [open, setOpen] = useState(false);

//   async function fetchCategories() {
//     //LOG.debug ("fetchRewards: ", searchStr);

//     const myQuery = gql`
//       query MyQuery($orgID: ID!) {
//         listStageCategorys(filter: {orgID: {eq: $orgID}, isDeleted: {eq: false}}, limit: 10000) {
//           items {
//             id
//             title
//             flexAttributes
//           }
//         }
//       }`

//     const listStageCategorysRet = await API.graphql({query: myQuery, variables: {"orgID": global.me.orgID}});
//     //LOG.debug ("getKPIList",getKPIList);
//     const categories = listStageCategorysRet.data.listStageCategorys.items;
//     //LOG.debug ("categories: ",categories);
//     let assigneeList = [];

//     for (let category of categories) {
//       //LOG.debug ("category: ", category);
//       //
//       //  Omit KPI's (dealstages) with the sequence <= 0 if props.ignoreNoDealStages is true
//       //
//       if (!category.doNotShow) {
//         assigneeList[assigneeList.length] = {
//           id: category.id,
//           value: category.id,
//           label: category.title,
//           flexAttributes: category.flexAttributes
//         }
//       }
//     }

//     let sorted_assigneeList = assigneeList.sort((a, b) => {
//       if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
//       if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
//       return 0;
//     });
//     //LOG.debug ("!!!sorted_assigneeList: ", sorted_assigneeList);
//     setItemList(sorted_assigneeList);

//   }

//   useEffect(() => {
//     fetchCategories();
//   }, []);

//   if (itemValue !== props.value) {
//     setItemValue(props.value);
//   }
//   let paddingRight = props.paddingRight !== undefined ? props.paddingRight : 0;

//   return (
//     <DropDownPicker
//       open={open}
//       placeholder='Select KPI Category'
//       searchable={true}
//       value={itemValue}
//       items={itemList}
//       setOpen={setOpen}
//       setValue={setItemValue}
//       setItems={setItemList}
//       zIndex={4000}
//       zIndexReverse={1000}
//       listMode='MODAL'
//       labelStyle={styles.light}
//       dropDownContainerStyle={{paddingTop: 100}}
//       onSelectItem={(val) => {
//         LOG.debug("!!!value: ", val);
//         props.fieldSetter(val);
//       }}
//       placeholderStyle={{color: props.textColor !== undefined ? props.textColor : 'black'}}
//       style={{
//         backgroundColor: 'white',
//         borderWidth: 0,
//         width: screenWidth - 60 - paddingRight,
//         paddingBottom: 0,
//       }}
//     />
//   );
// }
//
//
// export function SelectFlexField(props) {
//   LOG.debug("SelectFlexField: ", props);
//
//   // props:
//   //
//   // value = value to be displayed
//   // fieldSetter
//
//   const [itemList, setItemList] = useState([]);
//   const [itemValue, setItemValue] = useState(null);
//   const [open, setOpen] = useState(false);
//
//   async function fetchFields(flexFieldsString) {
//     LOG.debug("fetchFields: ", flexFieldsString);
//
//     const ff_json = JSON.parse(flexFieldsString);
//     LOG.debug("ff_json: ", ff_json);
//
//     let flexFields = [];
//     for (let ff of ff_json.config.deal.flexFields) {
//       LOG.debug("ff: ", ff);
//       flexFields[flexFields.length] = {
//         value: ff.label,
//         label: ff.label
//       }
//     }
//
//
//     LOG.debug("flexFields: ", flexFields);
//     let sorted_flexFields = flexFields.sort((a, b) => {
//       if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
//       if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
//       return 0;
//     });
//     //LOG.debug ("!!!sorted_assigneeList: ", sorted_assigneeList);
//     setItemList(sorted_flexFields);
//
//   }
//
//   useEffect(() => {
//     fetchFields(props.flexFields);
//   }, []);
//
//   if (itemValue !== props.value) {
//     setItemValue(props.value);
//   }
//   let paddingRight = props.paddingRight !== undefined ? props.paddingRight : 0;
//
//   return (
//     <React.Fragment>
//       {itemList.length > 0 &&
//         <DropDownPicker
//           open={open}
//           placeholder='Select KPI Category'
//           searchable={true}
//           value={itemValue}
//           items={itemList}
//           setOpen={setOpen}
//           setValue={setItemValue}
//           setItems={setItemList}
//           zIndex={4000}
//           zIndexReverse={1000}
//           listMode='MODAL'
//           labelStyle={styles.light}
//           dropDownContainerStyle={{paddingTop: 100}}
//           onSelectItem={(val) => {
//             LOG.debug("!!!value: ", val);
//             props.fieldSetter(val);
//           }}
//           placeholderStyle={{color: props.textColor !== undefined ? props.textColor : 'black'}}
//           style={{
//             backgroundColor: 'white',
//             borderWidth: 0,
//             width: screenWidth - 60 - paddingRight,
//             paddingBottom: 0,
//           }}
//         />
//       }
//     </React.Fragment>
//   );
// }
export function SelectFlexField(props) {
  const [itemList, setItemList] = useState([]);
  const [itemValue, setItemValue] = useState(null);
  // const [open, setOpen] = useState(false);

  async function fetchFields(flexFieldsString) {
    const ffJson = JSON.parse(flexFieldsString);

    const flexFields = [];
    for (const ff of ffJson.config.deal.flexFields) {
      // LOG.debug('ff: ', ff);
      flexFields[flexFields.length] = {
        value: ff.name,
        label: ff.label,
      };
    }

    // LOG.debug('flexFields: ', flexFields);
    const sortedFlexFields = flexFields.sort((a, b) => {
      if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
      if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
      return 0;
    });
    setItemList(sortedFlexFields);
  }

  useEffect(() => {
    fetchFields(props.flexFields);
  }, []);

  if (itemValue !== props.value) {
    setItemValue(props.value);
  }

  return (
    <React.Fragment>
      <Select
        value={itemValue}
        style={{ width: '100%' }}
        onChange={(e) => {
          setItemValue(e.target.value);
          props.setter((prev) => ({
            ...prev,
            triggerID: e.target.value,
          }));
        }}
      >
        {itemList.map((el, i) => (
          <MenuItem
            key={i}
            value={el.value}
            style={{ backgroundColor: 'white' }}
            onMouseEnter={(e) => (e.target.style.backgroundColor = 'lightgray')}
            onMouseLeave={(e) => (e.target.style.backgroundColor = 'white')}
          >
            {el.label}
          </MenuItem>
        ))}
      </Select>
    </React.Fragment>
  );
}
