import React from 'react';
import { Box, Typography, Avatar } from '@mui/material';
import { format, isValid } from 'date-fns';

interface AgendaEventRendererProps {
  event: any;
  resources: any[];
  colorAssignments: Record<string, string>;
  handleEditClick: (event: any) => void;
}

export const renderAgendaEvent = ({
  event,
  resources,
  colorAssignments,
  handleEditClick,
}: AgendaEventRendererProps) => {
  if (!event) return null;

  const displayFields = event.extendedProps?.displayFields || {};
  const assignedUser = resources.find((r) => r.admin_id === event.admin_id);
  const assignedColor = colorAssignments[event.admin_id] || '#e8f3e3';

  const displayTitle =
    displayFields.customerName ||
    event.extendedProps?.dealName ||
    event.title ||
    'Untitled Event';

  const isValidStart =
    event.start instanceof Date && !isNaN(event.start.getTime());
  const isValidEnd = event.end instanceof Date && !isNaN(event.end.getTime());

  const formatTimeString = (date: Date | null): string => {
    if (!date || !isValid(date)) return '';
    try {
      return format(date, 'h:mm a').toLowerCase();
    } catch (error) {
      console.error('Error formatting date:', error, date);
      return '';
    }
  };

  const startTimeString = isValidStart ? formatTimeString(event.start) : '';
  const endTimeString = isValidEnd ? formatTimeString(event.end) : '';
  const timeString =
    startTimeString && endTimeString
      ? `${startTimeString} - ${endTimeString}`
      : '';

  return (
    <Box
      sx={{
        display: 'flex',
        p: 1.5,
        borderRadius: '4px',
        margin: '4px 0',
        backgroundColor: 'white',
        boxShadow: '0 1px 3px rgba(0,0,0,0.05)',
        border: '1px solid rgba(0,0,0,0.08)',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.02)',
          boxShadow: '0 2px 5px rgba(0,0,0,0.08)',
        },
        cursor: 'pointer',
        position: 'static',
        width: '100%',
      }}
      onClick={() => handleEditClick(event)}
    >
      <Box
        sx={{
          width: 4,
          minHeight: 50,
          backgroundColor: assignedColor,
          borderRadius: 1,
          mr: 2,
          flexShrink: 0,
        }}
      />
      <Box sx={{ flex: 1 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 600,
              color: '#2c2c2c',
              fontSize: '0.875rem',
            }}
          >
            {displayTitle}
          </Typography>
          {assignedUser?.avatar && (
            <Avatar
              src={assignedUser.avatar}
              alt={assignedUser.title || ''}
              sx={{
                width: 20,
                height: 20,
                fontSize: '0.75rem',
                flexShrink: 0,
              }}
            />
          )}
        </Box>

        {timeString && (
          <Typography
            variant="caption"
            sx={{
              color: '#666',
              fontSize: '0.75rem',
              lineHeight: 1.2,
              mb: 0.5,
              display: 'block',
            }}
          >
            {timeString}
          </Typography>
        )}

        {displayFields.fullAddress && (
          <Typography
            variant="caption"
            sx={{
              color: '#666',
              fontSize: '0.75rem',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              lineHeight: 1.2,
              wordBreak: 'break-word',
              mb: 0.5,
            }}
          >
            {displayFields.fullAddress}
          </Typography>
        )}

        {displayFields.notes && (
          <Typography
            variant="caption"
            sx={{
              color: '#666',
              fontSize: '0.75rem',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              lineHeight: 1.2,
              fontStyle: 'italic',
              wordBreak: 'break-word',
            }}
          >
            {displayFields.notes}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
