import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  Typography,
  Box,
  IconButton,
  CircularProgress,
  Divider,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { useDealsQuery, Deal } from '../api/useDealsQuery';

declare global {
  interface Window {
    me?: {
      orgID?: string;
      id?: string;
      name?: string;
      isAdmin?: boolean;
    };
  }
}

const renderAddress = (deal: Deal) => {
  // If there's a formatted address, use it
  if (deal.formattedAddress) {
    return deal.formattedAddress;
  }

  // If there's a description, use it as the primary address
  if (deal.description) {
    // Try to enhance the description with city/state if available
    const cityState = [];
    if (deal.pinAddress?.city) cityState.push(deal.pinAddress.city);
    if (deal.pinAddress?.region) cityState.push(deal.pinAddress.region);
    if (deal.pinAddress?.postalCode) cityState.push(deal.pinAddress.postalCode);

    if (cityState.length > 0) {
      return `${deal.description}, ${cityState.join(', ')}`;
    }
    return deal.description;
  }

  // If there's a regular address, use it
  if (deal.address) {
    // Try to enhance the address with city/state if available
    const cityState = [];
    if (deal.pinAddress?.city) cityState.push(deal.pinAddress.city);
    if (deal.pinAddress?.region) cityState.push(deal.pinAddress.region);
    if (deal.pinAddress?.postalCode) cityState.push(deal.pinAddress.postalCode);

    if (cityState.length > 0) {
      return `${deal.address}, ${cityState.join(', ')}`;
    }
    return deal.address;
  }

  // If there's a pinAddress, construct an address from its components
  if (deal.pinAddress) {
    const addressParts = [];

    // Add street address
    if (deal.pinAddress.street) {
      addressParts.push(deal.pinAddress.street);
    } else if (deal.pinAddress.address) {
      addressParts.push(deal.pinAddress.address);
    }

    // Add city, region, postal code
    const cityRegionPostal = [];
    if (deal.pinAddress.city) cityRegionPostal.push(deal.pinAddress.city);
    if (deal.pinAddress.region) cityRegionPostal.push(deal.pinAddress.region);
    if (deal.pinAddress.postalCode)
      cityRegionPostal.push(deal.pinAddress.postalCode);

    if (cityRegionPostal.length > 0) {
      addressParts.push(cityRegionPostal.join(', '));
    }

    // Add country if available and different from region
    if (
      deal.pinAddress.country &&
      deal.pinAddress.country !== deal.pinAddress.region
    ) {
      addressParts.push(deal.pinAddress.country);
    }

    if (addressParts.length > 0) {
      return addressParts.join(', ');
    }
  }

  // If we have state directly on the deal
  if (deal.state) {
    return `State: ${deal.state}`;
  }

  return 'No address available';
};

interface DealSelectionModalProps {
  open: boolean;
  onClose: () => void;
  onDealSelect: (deal: Deal) => void;
  existingAppointments?: Array<{
    dealId: string;
    assignedUser?: {
      id: string;
      name: string;
      avatar?: string;
    };
    start: Date;
    end: Date;
  }>;
}

const DealSelectionModal: React.FC<DealSelectionModalProps> = ({
  open,
  onClose,
  onDealSelect,
  existingAppointments = [],
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedQuery, setDebouncedQuery] = useState('');

  const { deals, isLoading, error, fetchDeals, hasMore, loadMore } =
    useDealsQuery({
      searchString: debouncedQuery,
      categoryId: 'abf7340d-0816-4c23-8f64-d30fa0ed6b24',
    });

  // Filter out cancelled deals
  const filteredDeals = deals.filter((deal) => {
    // Skip deals with cancelled, lost, or similar statuses
    if (!deal.status) return true;

    const lowerStatus = deal.status.toLowerCase();
    return !['cancelled', 'lost', 'canceled', 'inactive', 'rejected'].includes(
      lowerStatus
    );
  });

  // Debounce search input and fetch deals when the query changes
  useEffect(() => {
    const handler = setTimeout(() => {
      console.log(`Debounced search query: "${searchTerm}"`);
      setDebouncedQuery(searchTerm);
      fetchDeals(searchTerm);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm, fetchDeals]);

  useEffect(() => {
    console.log(
      'DealSelectionModal - using orgID:',
      window.me?.orgID || 'Not found'
    );
  }, [deals, isLoading, error]);

  // Add a useEffect to log when deals are updated
  useEffect(() => {
    console.log(
      `Deals updated: ${deals.length} deals loaded, ${filteredDeals.length} active deals, hasMore: ${hasMore}`
    );
  }, [deals, filteredDeals, hasMore]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
    setDebouncedQuery('');
  };

  const handleDealClick = (deal: Deal) => {
    console.log('Deal selected:', deal);
    onDealSelect(deal);
    onClose();
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleRefresh = () => {
    console.log('Refreshing deals...');
    setSearchTerm('');
    setDebouncedQuery('');
    fetchDeals('');
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    // Calculate how close to the bottom we are
    const scrollPosition = scrollHeight - scrollTop - clientHeight;
    console.log(
      'Scroll position:',
      scrollPosition,
      'hasMore:',
      hasMore,
      'isLoading:',
      isLoading
    );

    // Load more when we're within 100px of the bottom
    if (scrollPosition < 100 && hasMore && !isLoading) {
      console.log('Loading more deals...');
      loadMore();
    }
  };

  const renderDealItem = (deal: Deal) => {
    // Check if this deal has an existing appointment
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const existingAppointment = existingAppointments.find(
      (appointment) => appointment.dealId === deal.id
    );

    // Get the full address
    const fullAddress = renderAddress(deal);

    return (
      <ListItem
        key={deal.id}
        button
        onClick={() => handleDealClick(deal)}
        sx={{
          borderRadius: '4px',
          mb: 1,
          border: '1px solid #e0e0e0',
          p: 1.5,
          position: 'relative',
          '&:hover': {
            backgroundColor: 'rgba(0, 172, 193, 0.08)',
            borderColor: '#00ACC1',
          },
          '&.Mui-selected': {
            backgroundColor: 'rgba(0, 172, 193, 0.12)',
            borderColor: '#00ACC1',
          },
        }}
      >
        <Box sx={{ width: '100%', pr: 3 }}>
          {/* Customer Name */}
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: 'bold', fontSize: '0.9rem', mb: 0.5 }}
          >
            {deal.name || 'Unnamed Deal'}
          </Typography>

          {/* Address */}
          <Typography
            variant="body2"
            sx={{
              color: 'text.secondary',
              fontSize: '0.8rem',
              mb: 1,
              lineHeight: 1.3,
              display: '-webkit-box',
              WebkitLineClamp: 3, // Show up to 3 lines
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {fullAddress}
          </Typography>

          {/* Show assigned user if there's an existing appointment */}
          {/* {existingAppointment && existingAppointment.assignedUser && (
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
              <Chip
                avatar={
                  <Avatar
                    src={existingAppointment.assignedUser.avatar}
                    sx={{ width: 20, height: 20 }}
                  >
                    {existingAppointment.assignedUser.name.charAt(0)}
                  </Avatar>
                }
                label={`Assigned to ${existingAppointment.assignedUser.name}`}
                size="small"
                sx={{
                  backgroundColor: 'rgba(25, 118, 210, 0.1)',
                  color: '#1976d2',
                  fontSize: '0.7rem',
                  height: '24px',
                }}
              />
            </Box>
          )} */}

          {/* Right arrow icon */}
          <Box
            sx={{
              position: 'absolute',
              right: 16,
              top: '50%',
              transform: 'translateY(-50%)',
            }}
          >
            <ChevronRight fontSize="small" color="action" />
          </Box>
        </Box>
      </ListItem>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '8px',
          maxHeight: '80vh',
        },
      }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          pb: 1,
          pt: 2,
        }}
      >
        <Typography variant="h6" fontWeight={500}>
          Which deal is this appointment for?
        </Typography>
        <Box>
          {/* <IconButton
            edge="end"
            onClick={handleRefresh}
            size="small"
            sx={{ mr: 1 }}
          >
            <RefreshIcon />
          </IconButton> */}
          <IconButton edge="end" onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Box px={3} pb={2}>
        {/* Search Box */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: 1.5,
            border: 'none',
            borderRadius: '8px',
            bgcolor: 'rgba(0, 0, 0, 0.04)',
            mb: 2,
          }}
        >
          <Box
            sx={{
              mr: 1.5,
              color: 'text.secondary',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <SearchIcon fontSize="small" />
          </Box>
          <input
            type="text"
            placeholder="Search deals..."
            value={searchTerm}
            onChange={handleSearchChange}
            style={{
              border: 'none',
              outline: 'none',
              background: 'transparent',
              width: '100%',
              fontSize: '13px',
              lineHeight: '20px',
            }}
          />
          {searchTerm && (
            <IconButton
              size="small"
              onClick={handleClearSearch}
              sx={{ p: 0.5 }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          )}
        </Box>
      </Box>
      <Divider />
      <DialogContent
        sx={{
          p: 0,
          px: 3,
          pb: 2,
          height: '400px',
          overflow: 'hidden',
        }}
      >
        {isLoading && filteredDeals.length === 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <CircularProgress size={40} />
          </Box>
        ) : error ? (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="100%"
            gap={2}
          >
            <Typography color="error">
              Error loading deals. Please try again.
            </Typography>
          </Box>
        ) : filteredDeals.length === 0 ? (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="100%"
            gap={2}
          >
            <Typography>No deals found matching your search.</Typography>
          </Box>
        ) : (
          <div
            style={{
              width: '100%',
              height: '100%',
              overflow: 'auto',
              paddingRight: '4px',
            }}
            onScroll={handleScroll}
          >
            <List sx={{ width: '100%', pt: 0 }}>
              {filteredDeals.map((deal) => renderDealItem(deal))}
              {hasMore && (
                <Box display="flex" justifyContent="center" my={2}>
                  <CircularProgress size={24} />
                </Box>
              )}
              {!hasMore && filteredDeals.length > 0 && (
                <Box display="flex" justifyContent="center" my={2}>
                  <Typography variant="body2" color="text.secondary">
                    No more deals to load
                  </Typography>
                </Box>
              )}
              {filteredDeals.length === 0 && (
                <Box display="flex" justifyContent="center" my={2}>
                  <Typography variant="body2" color="text.secondary">
                    No active deals found
                  </Typography>
                </Box>
              )}
            </List>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DealSelectionModal;
