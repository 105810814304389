import React, { useState, useEffect } from 'react';
import { Text } from '@aws-amplify/ui-react';
import {
  Box,
  Backdrop,
  CircularProgress,
  Radio,
  RadioGroup,
  FormControlLabel,
  Divider,
  Checkbox,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableChartIcon from '@mui/icons-material/TableChart';
import SelectionModal from './SelectionModal';
import './reports.css';

/**
 * BulkDownloadModal - A comprehensive download manager for reports
 *
 * Guides users through selecting download format and options in a step-by-step process
 */
export default function BulkDownloadModal({
  open,
  onClose,
  reports,
  teamColorMap,
  downloadAllChartsAsPDF,
  downloadAllOrgChartsAsPDF,
}) {
  // Step tracking
  const [activeStep, setActiveStep] = useState(0);
  const [downloadType, setDownloadType] = useState('');
  const [pdfOptions, setPdfOptions] = useState('combined');

  // Selection states
  const [selectedTeams, setSelectedTeams] = useState({});
  const [selectedKpis, setSelectedKpis] = useState({});
  const [searchString, setSearchString] = useState('');
  const [modalType, setModalType] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  // Add a new state for comparison chart inclusion
  const [includeComparisons, setIncludeComparisons] = useState(true);

  // Extract team and KPI data from reports
  const allTeams = React.useMemo(() => {
    const teams = {};
    if (reports && reports.reports) {
      Object.values(reports.reports).forEach((report) => {
        report.data_sets.forEach((dataset) => {
          teams[dataset.label] = dataset.label;
        });
      });
    }
    return teams;
  }, [reports]);

  const allKpis = React.useMemo(() => {
    const kpis = {};
    if (reports && reports.reports) {
      Object.values(reports.reports).forEach((report) => {
        kpis[report.report_info.id] = report.report_info.title;
      });
    }
    return kpis;
  }, [reports]);

  // Initialize selections when modal opens
  useEffect(() => {
    if (open) {
      setActiveStep(0);
      setDownloadType('');
      setSelectedTeams({ ...allTeams });
      setSelectedKpis({ ...allKpis });
      setPdfOptions('combined');
    }
  }, [open, allTeams, allKpis]);

  // Selection modal configuration
  const modalConfiguration = {
    team: {
      type: 'teams',
      fetchFunction: async () => {}, // Not needed as we already have the teams
      optionsList: Object.values(allTeams).map((team) => ({
        id: team,
        name: team,
      })),
      checkedList: selectedTeams,
      checkedListSetter: (id, name) => {
        const temp = { ...selectedTeams };
        if (temp[id]) {
          delete temp[id];
        } else {
          temp[id] = name;
        }
        setSelectedTeams(temp);
      },
      useAvatar: false,
      selectAll: () => setSelectedTeams({ ...allTeams }),
      clearAll: () => setSelectedTeams({}),
      fetchOnStringChange: false,
    },
    kpi: {
      type: 'KPIs',
      fetchFunction: async () => {}, // Not needed as we already have the KPIs
      optionsList: Object.entries(allKpis).map(([id, title]) => ({
        id,
        name: title,
      })),
      checkedList: selectedKpis,
      checkedListSetter: (id, name) => {
        const temp = { ...selectedKpis };
        if (temp[id]) {
          delete temp[id];
        } else {
          temp[id] = name;
        }
        setSelectedKpis(temp);
      },
      useAvatar: false,
      selectAll: () => setSelectedKpis({ ...allKpis }),
      clearAll: () => setSelectedKpis({}),
      fetchOnStringChange: false,
    },
  };

  // Simplify the generateAndDownloadCSV function to only handle standard format
  const generateAndDownloadCSV = (filteredReports, filename) => {
    console.log('Generating CSV with standard format');

    try {
      // Helper function to format dates consistently
      const formatDate = (dateString, reportData) => {
        // Remove day of week if present (e.g., "Wed 10/30" -> "10/30")
        const dateWithoutDay = dateString.replace(
          /^(Mon|Tue|Wed|Thu|Fri|Sat|Sun)\s+/,
          ''
        );

        // Parse the date parts
        const [month, day] = dateWithoutDay
          .split('/')
          .map((part) => parseInt(part.trim(), 10));

        // Determine year based on report data and date patterns
        let year;

        // Check if we have report metadata with dates
        if (reportData && reportData.date_range) {
          // Use the start and end dates from the report
          year = determineYearFromDateRange(month, day, reportData.date_range);
        } else {
          // Fallback to a heuristic approach based on month sequence
          year = inferYearFromSequence(month, dateString, reportData);
        }

        // Format as MM/DD/YYYY
        return `${month}/${day}/${year}`;
      };

      // Helper to determine year from date range
      const determineYearFromDateRange = (month, day, dateRange) => {
        // Extract start and end dates
        let startYear, endYear;

        if (dateRange.start_date) {
          const startDate = new Date(dateRange.start_date);
          startYear = startDate.getFullYear();
        } else {
          startYear = new Date().getFullYear() - 1; // Default to last year
        }

        if (dateRange.end_date) {
          const endDate = new Date(dateRange.end_date);
          endYear = endDate.getFullYear();
        } else {
          endYear = new Date().getFullYear(); // Default to current year
        }

        // If report spans multiple years, determine which year this date falls in
        if (startYear !== endYear) {
          // For dates in later months (Oct-Dec) if the start year includes these months
          if (month >= 10 && startYear) {
            return startYear;
          }
          // For dates in earlier months (Jan-Mar) if the end year includes these months
          else if (month <= 3 && endYear) {
            return endYear;
          }
          // For months in the middle, need more context - default to start year
          else {
            return startYear;
          }
        }

        return startYear || new Date().getFullYear();
      };

      // Helper to infer year based on sequence of dates
      const inferYearFromSequence = (month, dateString, reportData) => {
        // Check if we can infer from the report labels (dates) array
        if (reportData && reportData.labels && reportData.labels.length > 0) {
          // Look at all dates to determine pattern
          const months = reportData.labels
            .map((label) => {
              const datePart = label.replace(
                /^(Mon|Tue|Wed|Thu|Fri|Sat|Sun)\s+/,
                ''
              );
              return parseInt(datePart.split('/')[0], 10);
            })
            .filter((m) => !isNaN(m));

          // Check if we have a month crossing December to January
          const hasDecember = months.includes(12);
          const hasJanuary = months.includes(1);

          if (hasDecember && hasJanuary) {
            // We span multiple years
            // If this is January-March and we also have December, this is likely the later year
            if (month <= 3) {
              return new Date().getFullYear(); // Assume current year for early months
            } else if (month >= 10) {
              return new Date().getFullYear() - 1; // Assume previous year for late months
            }
          }
        }

        // Get the user's selected filter period if available
        if (reportData.filter_period) {
          if (reportData.filter_period === 'last_year') {
            return new Date().getFullYear() - 1;
          } else if (reportData.filter_period === 'year_to_date') {
            return new Date().getFullYear();
          }
        }

        // Default to current year if we can't determine
        return new Date().getFullYear();
      };

      // Standard format: Date, Team, KPI, Value
      let csvString = '';
      if (filteredReports.report_type === 'team') {
        csvString = 'Date,Team,KPI,Value\r\n';
      } else {
        csvString = 'Date,KPI,Value\r\n';
      }

      // Process each report that matches selected KPIs
      Object.values(filteredReports.reports).forEach((report) => {
        // Process each dataset (team)
        report.data_sets.forEach((dataset) => {
          const teamName = dataset.label;

          // Add each data point to the CSV
          dataset.data.forEach((value, index) => {
            if (value !== null && value !== undefined) {
              const date = formatDate(report.labels[index], report);
              if (filteredReports.report_type === 'team') {
                csvString += `"${date}","${teamName}","${report.report_info.title}",${value}\r\n`;
              } else {
                csvString += `"${date}","${report.report_info.title}",${value}\r\n`;
              }
            }
          });
        });
      });

      // Create and download the CSV file
      const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', filename || 'report_export.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      return true;
    } catch (error) {
      console.error('Error generating CSV:', error);
      return false;
    }
  };

  // Replace the comparison chart generation function
  const generateComparisonReports = (filteredReports, selectedTeamNames) => {
    // Only proceed if we have multiple teams selected and comparison charts are enabled
    if (selectedTeamNames.length < 2 || !includeComparisons) {
      console.log(
        'No comparisons to generate: either only one team selected or comparisons disabled'
      );
      return filteredReports;
    }

    // Create a deep clone to avoid modifying the original
    const reportsWithComparisons = JSON.parse(JSON.stringify(filteredReports));

    // Check if reports is an array or object and handle accordingly
    const reportsArray = Array.isArray(reportsWithComparisons.reports)
      ? reportsWithComparisons.reports
      : Object.values(reportsWithComparisons.reports);

    console.log('Reports structure check:', {
      isArray: Array.isArray(reportsWithComparisons.reports),
      type: typeof reportsWithComparisons.reports,
      keys: Object.keys(reportsWithComparisons.reports),
    });

    // Process each report (using array iteration)
    reportsArray.forEach((report) => {
      // Create ONE comparison object per KPI that includes ALL selected teams
      const teamsInThisKpi = [];

      // Find which teams have data for this KPI
      selectedTeamNames.forEach((teamName) => {
        const hasData = report.data_sets.some((ds) => ds.label === teamName);
        if (hasData) {
          teamsInThisKpi.push(teamName);
        }
      });

      // Only create a comparison if we have at least 2 teams with data
      if (teamsInThisKpi.length >= 2) {
        console.log(
          `Creating multi-team comparison for ${report.report_info.title} with teams:`,
          teamsInThisKpi
        );

        // Add the comparison metadata - using a single comparison that lists all teams
        const comparisonMeta = {
          isComparison: true,
          isMultiTeamComparison: true, // Flag to indicate this is a multi-team comparison
          teams: teamsInThisKpi,
          label: `All Teams Comparison`, // Generic label
        };

        // Add this metadata to the report for use in PDF generation
        if (!report.comparisons) {
          report.comparisons = [];
        }

        // Only add one comparison entry per KPI
        report.comparisons = [comparisonMeta];
      }
    });

    return reportsWithComparisons;
  };

  // Navigate to the next step
  const handleNext = () => {
    // If on first step, go to second step
    if (activeStep === 0) {
      setActiveStep(1);
      return;
    }

    // Otherwise, start processing the download
    setIsProcessing(true);

    // Create a deep clone of reports to avoid mutating the original
    const reportsClone = JSON.parse(JSON.stringify(reports));

    // Filter data based on selected teams and KPIs
    let filteredReports = { ...reportsClone };

    // Filter to only include selected KPIs
    if (Object.keys(selectedKpis).length > 0) {
      filteredReports.reports = Object.keys(filteredReports.reports)
        .filter((reportId) => selectedKpis[reportId])
        .reduce((obj, reportId) => {
          obj[reportId] = filteredReports.reports[reportId];
          return obj;
        }, {});
    }

    // Filter datasets to only include selected teams
    Object.keys(filteredReports.reports).forEach((reportId) => {
      filteredReports.reports[reportId].data_sets = filteredReports.reports[
        reportId
      ].data_sets.filter((ds) => selectedTeams[ds.label]);
    });

    console.log(
      `Filtered data: ${
        Object.keys(filteredReports.reports).length
      } reports with selected teams and KPIs`
    );

    // Handle CSV export
    if (downloadType === 'csv') {
      try {
        console.log('Starting CSV export process');

        // Convert reports object to array format for generateAndDownloadCSV
        const processedReports = {
          ...filteredReports,
          reports: Object.values(filteredReports.reports),
        };

        // Generate filename
        const filename = `${reports.org_id}_kpi_report_${
          new Date().toISOString().split('T')[0]
        }.csv`;

        // Generate and download the CSV directly - always using standard format
        const success = generateAndDownloadCSV(processedReports, filename);

        console.log('CSV export completed:', success);

        // Update UI state regardless of success
        setTimeout(() => {
          setIsProcessing(false);
          if (success) {
            onClose();
          }
        }, 500);
      } catch (error) {
        console.error('Error in CSV export:', error);
        setIsProcessing(false);
      }
    }
    // Handle PDF export
    else if (downloadType === 'pdf') {
      try {
        console.log('Starting PDF export process with options:', pdfOptions);

        // Handle comparison charts if needed
        if (includeComparisons && pdfOptions !== 'separate') {
          const selectedTeamNames = Object.keys(selectedTeams);
          filteredReports = generateComparisonReports(
            filteredReports,
            selectedTeamNames
          );
        }

        // Convert to array format
        const processedReports = {
          ...filteredReports,
          reports: Object.values(filteredReports.reports),
        };

        console.log('PDF generation: format prepared');

        // Generate separate PDFs for each team
        if (pdfOptions === 'separate') {
          Object.keys(selectedTeams).forEach((teamName) => {
            const teamReports = {
              ...processedReports,
              reports: processedReports.reports.map((report) => ({
                ...report,
                data_sets: report.data_sets.filter(
                  (ds) => ds.label === teamName
                ),
              })),
            };

            if (reports.report_type === 'team') {
              downloadAllChartsAsPDF(
                teamReports,
                teamColorMap,
                `${teamName}_kpi_report.pdf`
              );
            } else {
              downloadAllOrgChartsAsPDF(
                teamReports,
                `${teamName}_kpi_report.pdf`
              );
            }
          });
        }
        // Generate combined PDF
        else {
          if (reports.report_type === 'team') {
            downloadAllChartsAsPDF(processedReports, teamColorMap);
          } else {
            downloadAllOrgChartsAsPDF(processedReports);
          }
        }

        // PDF generation is handled asynchronously, so we rely on setIsGeneratingPDF
        onClose();
      } catch (error) {
        console.error('Error in PDF export:', error);
        setIsProcessing(false);
      }
    }
  };

  // Go back to the previous step
  const handleBack = () => {
    setActiveStep((prevStep) => Math.max(0, prevStep - 1));
  };

  // Render the content for the current step
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            <Text
              style={{
                fontWeight: 'bold',
                fontSize: '18px',
                marginBottom: '10px',
                textAlign: 'center',
              }}
            >
              Select download format:
            </Text>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: 4,
                width: '100%',
                maxWidth: '500px',
              }}
            >
              <Box
                className={`format-option ${
                  downloadType === 'csv' ? 'selected' : ''
                }`}
                onClick={() => {
                  setDownloadType('csv');
                  // Auto-advance to next step
                  setActiveStep(1);
                }}
                sx={{
                  flex: '1',
                  maxWidth: '200px',
                  height: '200px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: '2px solid #e0e0e0',
                  borderRadius: '8px',
                  padding: '20px',
                  cursor: 'pointer',
                  transition: 'all 0.2s ease',
                  backgroundColor: downloadType === 'csv' ? '#FFF3E0' : 'white',
                  borderColor: downloadType === 'csv' ? '#FF6A00' : '#e0e0e0',
                  '&:hover': {
                    borderColor: '#FF6A00',
                    backgroundColor: '#FFF3E0',
                    transform: 'translateY(-3px)',
                    boxShadow: '0 5px 15px rgba(0,0,0,0.1)',
                  },
                }}
              >
                <TableChartIcon
                  style={{
                    fontSize: '60px',
                    color: downloadType === 'csv' ? '#FF6A00' : '#666',
                    marginBottom: '15px',
                  }}
                />
                <Text
                  style={{
                    fontSize: '18px',
                    fontWeight: downloadType === 'csv' ? 'bold' : 'normal',
                    color: downloadType === 'csv' ? '#FF6A00' : '#333',
                  }}
                >
                  CSV File
                </Text>
              </Box>

              <Box
                className={`format-option ${
                  downloadType === 'pdf' ? 'selected' : ''
                }`}
                onClick={() => {
                  setDownloadType('pdf');
                  // Auto-advance to next step
                  setActiveStep(1);
                }}
                sx={{
                  flex: '1',
                  maxWidth: '200px',
                  height: '200px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: '2px solid #e0e0e0',
                  borderRadius: '8px',
                  padding: '20px',
                  cursor: 'pointer',
                  transition: 'all 0.2s ease',
                  backgroundColor: downloadType === 'pdf' ? '#FFF3E0' : 'white',
                  borderColor: downloadType === 'pdf' ? '#FF6A00' : '#e0e0e0',
                  '&:hover': {
                    borderColor: '#FF6A00',
                    backgroundColor: '#FFF3E0',
                    transform: 'translateY(-3px)',
                    boxShadow: '0 5px 15px rgba(0,0,0,0.1)',
                  },
                }}
              >
                <PictureAsPdfIcon
                  style={{
                    fontSize: '60px',
                    color: downloadType === 'pdf' ? '#FF6A00' : '#666',
                    marginBottom: '15px',
                  }}
                />
                <Text
                  style={{
                    fontSize: '18px',
                    fontWeight: downloadType === 'pdf' ? 'bold' : 'normal',
                    color: downloadType === 'pdf' ? '#FF6A00' : '#333',
                  }}
                >
                  PDF File
                </Text>
              </Box>
            </Box>
          </Box>
        );

      case 1:
        return (
          <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
            {/* First section: Format options */}
            <Box sx={{ mb: 2 }}>
              <Text
                style={{
                  fontWeight: 'bold',
                  fontSize: '16px',
                  marginBottom: '10px',
                }}
              >
                {downloadType === 'csv' ? 'CSV Format:' : 'PDF Format Options:'}
              </Text>

              {downloadType === 'csv' && (
                <Box
                  sx={{
                    padding: '10px',
                    border: '1px solid #e0e0e0',
                    borderRadius: '4px',
                    backgroundColor: '#f9f9f9',
                  }}
                >
                  <Text>Standard format (Date, Team, KPI, Value)</Text>
                  <Text
                    sx={{
                      fontSize: '12px',
                      color: '#666',
                      marginTop: '4px',
                    }}
                  >
                    Exports data in a single table with one row per data point
                  </Text>
                </Box>
              )}

              {downloadType === 'pdf' && (
                <RadioGroup
                  value={pdfOptions}
                  onChange={(e) => setPdfOptions(e.target.value)}
                >
                  {/* Only show format options for team reports */}
                  {reports.report_type === 'team' ? (
                    <>
                      <FormControlLabel
                        value="combined"
                        control={
                          <Radio
                            sx={{
                              color: '#FF6A00',
                              '&.Mui-checked': { color: '#FF6A00' },
                            }}
                          />
                        }
                        label="Combined PDF (all selected teams in one file)"
                      />
                      <FormControlLabel
                        value="separate"
                        control={
                          <Radio
                            sx={{
                              color: '#FF6A00',
                              '&.Mui-checked': { color: '#FF6A00' },
                            }}
                          />
                        }
                        label="Separate PDFs (one file per team)"
                      />
                    </>
                  ) : (
                    // For organization reports, show a simple information message
                    <Box
                      sx={{
                        padding: '10px',
                        border: '1px solid #e0e0e0',
                        borderRadius: '4px',
                        backgroundColor: '#f9f9f9',
                      }}
                    >
                      <Text>Combined PDF format (all data in one file)</Text>
                      <Text
                        sx={{
                          fontSize: '12px',
                          color: '#666',
                          marginTop: '4px',
                        }}
                      >
                        Organization reports are always exported as a single PDF
                      </Text>
                    </Box>
                  )}
                </RadioGroup>
              )}
            </Box>

            <Divider />

            {/* Second section: Teams & KPIs selection */}
            <Box sx={{ mt: 2 }}>
              <Text
                style={{
                  fontWeight: 'bold',
                  fontSize: '16px',
                  marginBottom: '10px',
                }}
              >
                Select data to include:
              </Text>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                }}
              >
                {(reports.report_type === 'team' ||
                  reports.report_type === 'user') && (
                  <Box
                    className="selection-button hov"
                    onClick={() => setModalType('team')}
                    sx={{
                      border: '1px solid #ddd',
                      borderRadius: '4px',
                      padding: '10px 15px',
                      cursor: 'pointer',
                      transition: 'all 0.2s ease',
                      '&:hover': {
                        borderColor: '#FF6A00',
                        backgroundColor: '#FFF3E0',
                      },
                    }}
                  >
                    <Text>
                      Teams ({Object.keys(selectedTeams).length} selected)
                    </Text>
                  </Box>
                )}

                <Box
                  className="selection-button hov"
                  onClick={() => setModalType('kpi')}
                  sx={{
                    border: '1px solid #ddd',
                    borderRadius: '4px',
                    padding: '10px 15px',
                    cursor: 'pointer',
                    transition: 'all 0.2s ease',
                    '&:hover': {
                      borderColor: '#FF6A00',
                      backgroundColor: '#FFF3E0',
                    },
                  }}
                >
                  <Text>
                    KPIs ({Object.keys(selectedKpis).length} selected)
                  </Text>
                </Box>

                {/* Only show comparison chart option for PDF format with team reports */}
                {downloadType === 'pdf' &&
                  pdfOptions !== 'separate' &&
                  reports.report_type === 'team' &&
                  Object.keys(selectedTeams).length > 1 && (
                    <Box sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
                      <Checkbox
                        checked={includeComparisons}
                        onChange={(e) =>
                          setIncludeComparisons(e.target.checked)
                        }
                        sx={{
                          padding: '4px',
                          color: '#FF6A00',
                          '&.Mui-checked': {
                            color: '#FF6A00',
                          },
                        }}
                      />
                      <Text>Include comparison charts</Text>
                    </Box>
                  )}
              </Box>
            </Box>

            {/* Export button */}
            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
              <Box
                className="generate-button hov"
                onClick={handleNext}
                style={{
                  backgroundColor: '#FF6A00',
                  color: 'white',
                  padding: '12px 24px',
                  borderRadius: '8px',
                  width: 'auto',
                  height: 'auto',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  cursor: 'pointer',
                  boxShadow: '0 2px 8px rgba(255, 106, 0, 0.3)',
                  transition: 'all 0.2s ease',
                }}
              >
                {isProcessing ? (
                  <>
                    <CircularProgress size={20} style={{ color: 'white' }} />
                    Processing...
                  </>
                ) : (
                  <>
                    <DownloadIcon />
                    Export Now
                  </>
                )}
              </Box>
            </Box>
          </Box>
        );

      default:
        return 'Unknown step';
    }
  };

  return (
    <Backdrop
      open={open}
      onClick={onClose}
      sx={{
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Box
        className="selection-modal-container"
        onClick={(e) => e.stopPropagation()}
        style={{
          height: '75vh',
          width: '60vw',
          maxWidth: '900px',
          maxHeight: '700px',
          marginTop: '50px',
        }}
      >
        <Box className="selection-modal-header">Bulk Download Manager</Box>

        <Box
          sx={{
            padding: '20px',
            height: 'calc(100% - 3rem - 70px)',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              flex: '1 1 auto',
              overflow: 'auto',
              minHeight: '0',
            }}
          >
            {getStepContent(activeStep)}
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            p: 2,
            borderTop: '1px solid #eee',
            height: '70px',
          }}
        >
          <Box
            className="clear-select-all hov"
            onClick={onClose}
            style={{
              padding: '8px 20px',
              cursor: 'pointer',
              transition: 'all 0.2s ease',
            }}
          >
            Cancel
          </Box>

          <Box sx={{ display: 'flex', gap: 1 }}>
            {activeStep > 0 && (
              <Box
                className="clear-select-all hov"
                onClick={handleBack}
                style={{
                  padding: '8px 20px',
                  cursor: 'pointer',
                  transition: 'all 0.2s ease',
                }}
              >
                Back
              </Box>
            )}

            {/* Next button removed as the user wants to auto-advance */}
          </Box>
        </Box>

        {modalType && (
          <SelectionModal
            config={modalConfiguration[modalType]}
            searchString={searchString}
            setSearchString={setSearchString}
            open={Boolean(modalType)}
            close={() => {
              setModalType(null);
              setSearchString('');
            }}
          />
        )}
      </Box>
    </Backdrop>
  );
}
