import gql from 'graphql-tag';
import { API } from 'aws-amplify';

export default async function getRuleOrder(orgID) {
  const myQuery = gql`
    query MyQuery($orgID: ID!, $nextToken: String = null) {
      listKPIRules(
        filter: { orgID: { eq: $orgID }, isDeleted: { eq: false } }
        limit: 10000
        nextToken: $nextToken
      ) {
        nextToken
        items {
          id
          title
          abbreviation
          order
        }
      }
    }
  `;
  let nextToken = null;
  const KPIRules = [];
  do {
    const listKPIRulesRet = await API.graphql({
      query: myQuery,
      variables: { orgID, nextToken },
    });
    KPIRules.push(...listKPIRulesRet.data.listKPIRules.items);
    nextToken = listKPIRulesRet.data.listKPIRules.nextToken;
  } while (nextToken !== null);

  // The sort order ensures that columns with null or undefined are put at the end
  // The columns with 0 are put after columns of numbers greater than 0
  // The columns with numbers greater than 0 are sorted in ascending order
  KPIRules.sort((a, b) => {
    if (
      (a.order === null || a.order === undefined) &&
      (b.order === null || b.order === undefined)
    )
      return 0;
    if (a.order === null || a.order === undefined || 0) return 1;
    if (b.order === null || b.order === undefined || 0) return -1;
    if (a.order === 0 && b.order > 0) {
      return 1;
    }
    if (a.order > 0 && b.order === 0) {
      return -1;
    }
    return a.order - b.order;
  });

  return KPIRules;
}

export function applySorting(kpiValues, KPIValueOrder) {
  if (!kpiValues || !KPIValueOrder || !KPIValueOrder.length) return kpiValues;

  const abbreviationOrderMap = {};
  KPIValueOrder.forEach((rule, index) => {
    if (rule.abbreviation) {
      abbreviationOrderMap[rule.abbreviation] = index;
    }
  });

  //   Sorting is done in the same way in multiple parts of the object
  const sortObjectByAbbreviation = (obj) => {
    if (!obj) return obj;

    const entries = Object.entries(obj);

    entries.sort((a, b) => {
      const orderA = abbreviationOrderMap[a[0]] ?? Infinity;
      const orderB = abbreviationOrderMap[b[0]] ?? Infinity;
      return orderA - orderB;
    });

    return Object.fromEntries(entries);
  };

  const sortedData = JSON.parse(JSON.stringify(kpiValues));

  if (sortedData.highlightsData) {
    sortedData.highlightsData = sortObjectByAbbreviation(
      sortedData.highlightsData
    );
  }

  if (sortedData.kpiList) {
    sortedData.kpiList = sortObjectByAbbreviation(sortedData.kpiList);
  }

  if (sortedData.results && Array.isArray(sortedData.results)) {
    sortedData.results = sortedData.results.map((result) => {
      if (result && result.kpis) {
        return {
          ...result,
          kpis: sortObjectByAbbreviation(result.kpis),
        };
      }
      return result;
    });
  }

  return sortedData;
}
