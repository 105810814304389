import React, { API } from 'aws-amplify';
import gql from 'graphql-tag';
import { Typography, Box } from '@mui/material';
import getUserAvatar from '../common/utils/getUserAvatar';

export default class DealRepository {
  static async getDefaultConfig(ourCategoryID) {
    const myQuery = gql`
      query MyQuery($categoryID: ID!) {
        getStageCategory(id: $categoryID) {
          id
          flexAttributes
        }
      }
    `;
    const myQueryRes = await API.graphql({
      query: myQuery,
      variables: { categoryID: ourCategoryID },
    });
    // console.log('DealItem ourCategoryID: ', ourCategoryID);
    // console.log('DealItem myQueryRes.data: ', myQueryRes.data);
    const flexAttributes = JSON.parse(
      myQueryRes.data.getStageCategory.flexAttributes
    );
    return flexAttributes.config.deal;
  }

  static async fetchAppointments(dealID) {
    try {
      const appointmentListByDealQuery = gql`
        query MyQuery($dealID: ID!) {
          listAppointmentByDeal(dealID: $dealID, sortDirection: ASC) {
            nextToken
            items {
              id
              appointmentDate
              appointmentStartTime
              appointmentEndTime
              notes
              dealID
              status
              deal {
                id
                name
                categoryID
                currentStageID
              }
              user {
                id
                name
              }
              setter {
                id
                name
              }
            }
          }
        }
      `;

      const getDealAppointmentsRes = await API.graphql({
        query: appointmentListByDealQuery,
        variables: { dealID },
      });
      return getDealAppointmentsRes.data.listAppointmentByDeal.items;
    } catch (error) {
      console.error('error in DealRepository: fetchAppointments: ', error);
    }
  }

  static async fetchUser(userID) {
    try {
      const userByIdQuery = gql`
        query MyQuery($userID: ID!) {
          getUser(id: $userID) {
            email
            id
            name
            extID
          }
        }
      `;

      const getUserRes = await API.graphql({
        query: userByIdQuery,
        variables: { userID },
      });
      return getUserRes.data.getUser;
    } catch (error) {
      console.error('error in DealRepository: fetchUser: ', error);
    }
  }

  static async fetchDeal(dealID, searchUserId) {
    // needed for sorting appointments
    function unformatTime(appointmentDate, minutesPastMidnight) {
      const [year, month, day] = appointmentDate.split('-');

      const hours = Math.floor(minutesPastMidnight / 60);
      const minutes = minutesPastMidnight % 60;

      const formattedHours = String(hours).padStart(2, '0');
      const formattedMinutes = String(minutes).padStart(2, '0');

      const unformattedTimeString = `${year}-${month}-${day}T${formattedHours}:${formattedMinutes}:00-00:00`;

      return unformattedTimeString;
    }
    // console.log('@@@fetchDeal: ', dealID);
    try {
      const myQuery = gql`
        query MyQuery($dealID: ID!) {
          getDeal(id: $dealID) {
            categoryID
            currentOwnerID
            currentOwnerType
            currentStageID
            orgID
            description
            extID
            flexAttributes
            id
            imageName
            imageType
            isDeleted
            name
            notes
            status
            value
            createdBy
            createdAt
            lastUpdatedBy
            updatedAt
            ownerHistory(
              filter: { isActive: { eq: true }, isDeleted: { eq: false } }
            ) {
              items {
                ownerType
                user {
                  id
                  name
                  imageType
                  imageName
                  initials
                }
              }
            }
            stage {
              title
              category {
                flexAttributes
              }
            }
            user {
              id
              imageName
              imageType
              initials
              name
              title
            }
            comments(
              sortDirection: DESC
              filter: { isDeleted: { eq: false } }
            ) {
              items {
                comment
                contactType
                createdAt
                createdBy
                updatedAt
                dispositionID
                followupDate
                id
                isDeleted
                disposition {
                  title
                }
                user {
                  id
                  imageName
                  imageType
                  initials
                  name
                  title
                }
              }
            }
            appointments {
              items {
                id
                status
                notes
                createdAt
                slot {
                  id
                  startTime
                  endTime
                }
              }
            }
          }
        }
      `;

      const getDealListRet = await API.graphql({
        query: myQuery,
        variables: { dealID },
      });
      const deal = getDealListRet.data.getDeal;
      console.log('~~~deal:', deal);
      if (deal == null) {
        return null;
      }
      // console.log('~~~deal isDeleted: ', deal.isDeleted);
      if (deal.isDeleted) {
        return null;
      }
      const ourDefaultConfig = await this.getDefaultConfig(deal.categoryID);
      // console.log('ourDefaultConfig: ', ourDefaultConfig);

      const ourAppointments = await this.fetchAppointments(dealID);
      // console.log('DealRepository: ourAppointments: ', ourAppointments);

      const createdByUser = await this.fetchUser(deal.createdBy);

      let lastUpdatedByUser;
      if (deal.lastUpdatedBy) {
        lastUpdatedByUser = await this.fetchUser(deal.lastUpdatedBy);
      }

      const dealMetaData = {
        id: deal.id,
        extID: deal.extID,
        createdAt: deal.createdAt,
        createdBy: createdByUser,
        updatedAt: deal.updatedAt,
        updatedBy: lastUpdatedByUser,
      };

      // console.log('DealRepository metadata: ', dealMetaData);

      let flexAttributes = {};
      if (deal.flexAttributes != null) {
        flexAttributes = JSON.parse(deal.flexAttributes);
      }
      const pickerDisplayFields = ourDefaultConfig.pickerDisplayFields;
      const mainDisplayFields = ourDefaultConfig.displayFields;
      const flexFields = ourDefaultConfig.flexFields;

      let searchName = '';
      const attrs = [];
      if (flexAttributes !== {} && pickerDisplayFields.length > 0) {
        for (const attr of pickerDisplayFields) {
          if (attr in flexAttributes) {
            searchName = searchName + '|' + flexAttributes[attr];
            attrs[attrs.length] = flexAttributes[attr];
          }
        }
      }

      let dropDownName;
      // const assigneeName =
      //   deal.user?.id !== searchUserId ? deal.user?.name : '';
      console.log('searchUserId: ', searchUserId);
      const assigneeName = deal.user?.name;

      const dealStage = deal.stage.title;

      // const ownerProfilePicture = await getUserAvatar(deal.user.imageName);
      const currentOwnerID = deal.currentOwnerID;

      if (attrs.length === 0) {
        searchName = deal.name;
        dropDownName = deal.name;
      } else {
        dropDownName = attrs[0];
      }

      let setterOwner = null;
      let closerOwner = null;
      for (const owner of deal.ownerHistory.items) {
        if (owner.ownerType === 'setter') {
          setterOwner = owner;
        } else if (owner.ownerType === 'closer') {
          closerOwner = owner;
        }
      }
      // console.log('!!setterOwner: ', setterOwner);
      // console.log('@@closerOwner: ', closerOwner);

      if (deal.user !== null) {
        // console.log("is a user owner");
        // console.log("profilePictureInfo: ", deal.user.id, deal.user.imageName);
        deal.profileType = 'person';
        if (setterOwner === null) {
          //
          //  There is no owner history, so use the current owner as the setter
          //
          deal.setter = { user: deal.user };
          deal.setter.assigneeType = 'person';
          deal.setter.profilePicture = await getUserAvatar(
            // deal.user.id,
            deal.user.imageName,
            deal.user.imageType,
            true
          );
        } else {
          //
          // Use the setter from the owner history
          //
          deal.setter = setterOwner;
          deal.setter.assigneeType = 'person';
          deal.setter.profilePicture = await getUserAvatar(
            // setterOwner.user.id,
            setterOwner.user.imageName,
            setterOwner.user.imageType,
            true
          );
        }

        if (closerOwner !== null) {
          //
          // Use the closer from the owner history
          //
          deal.closer = closerOwner;
          deal.closer.assigneeType = 'person';
          deal.closer.profilePicture = await getUserAvatar(
            // closerOwner.user.id,
            closerOwner.user.imageName,
            closerOwner.user.imageType,
            true
          );
        }
      }

      // TO DO: look into where display is used
      const display = (
        <Box>
          <Typography>{assigneeName}</Typography>
          <Typography>{dealStage}</Typography>
          {attrs.length > 0 ? (
            attrs.map((attr, i) => (
              <Box key={i}>
                <Typography>{attr}</Typography>
              </Box>
            ))
          ) : (
            <Typography>{deal.name}</Typography>
          )}
        </Box>
      );

      let fullName = '';
      // let initials = '';

      // console.log('DealRepository flexAttributes: ', flexAttributes);
      // console.log('DealRepository flexFields: ', flexFields);
      // console.log('DealRepository mainDisplayFields: ', mainDisplayFields);

      const mainDisplayAttrs = [];
      if (flexAttributes !== {} && mainDisplayFields.length > 0) {
        for (let attr of mainDisplayFields) {
          // console.log('DealRepository attr: ', attr);
          // need to lowercase the 'p' in Pictures attr to match the 'pictures' attribute in flexAttributes
          if (attr === 'Pictures' && 'pictures' in flexAttributes) {
            attr = attr.toLowerCase();
          }
          // console.log(
          //   'Deal Repository no spaces attr: ',
          //   attr.replace(/\s+/g, '')
          // );
          // sometimes attr has spaces while flexAttributes doesn't, check for it
          if (attr.replace(/\s+/g, '') in flexAttributes) {
            attr = attr.replace(/\s+/g, '');
          }
          if (attr in flexAttributes) {
            let fieldLabel = attr;
            let fieldType = 'String';
            let fieldHeading;
            for (const ff of flexFields) {
              // console.log('DealRepository ff.name vs. attr: ', ff.name, attr);
              if (ff.name === attr) {
                fieldLabel = ff.label;
                fieldType = ff.type;
                fieldHeading = ff.heading; // added to check for kws headings to split them from the rest in display
                break;
              }
            }
            // console.log(
            //   'DealRepository inside mainDisplay loop attr, fieldLabel, field,Type, fieldHeading: ',
            //   attr,
            //   fieldLabel,
            //   fieldType,
            //   fieldHeading
            // );

            let val = flexAttributes[attr];
            if (fieldType === 'Boolean') {
              if (val === true) {
                // val = <Typography style={[{ color: 'black' }]}>YES</Typography>;
                val = 'YES';
              } else {
                // val = <Typography style={[{ color: 'black' }]}>NO</Typography>;
                val = 'NO';
              }
            } else if (fieldType === 'Money') {
              val = `$ ${val}`;
              // // val = <Typography>$ {numberWithCommas(val)}</Typography>
              // val = <Typography>$ {val}</Typography>;
              // // } else if (fieldType == "ImageList") {
              // //   val = processImageList (val, fieldLabel, (image) => {
              // //     setShowImage(image);
              // //   });
              // //   fieldLabel="";
            } else if (fieldType === 'Power' && val) {
              // console.log('DealRepository Power val: ', val);
              // add type b/c val for Power is object, in case more fieldTypes are also objects will be able to distinguish
              val = { ...val, type: 'Power' };
            } else if (fieldType === 'ImageList' && val) {
              val = { images: val, type: 'ImageList' };
            }

            // console.log('DealRepository fieldHeading: ', fieldHeading);
            if (fieldHeading === 'kws') {
              val = { val, type: 'kws' };
            }

            if (attr === 'First Name') {
              fullName += flexAttributes[attr];
              // initials += flexAttributes[attr][0];
            } else if (attr === 'Last Name') {
              fullName += ` ${flexAttributes[attr]}`;
              // initials += flexAttributes[attr][0];
            } else {
              mainDisplayAttrs[mainDisplayAttrs.length] = {
                label: fieldLabel,
                val,
              };
            }
          }
        }
      }

      // console.log('!!!!mainDisplayAttrs: ', mainDisplayAttrs);

      // mainDisplay JSX moved to SelectedDealDisplay to allow for hooks
      const mainDisplay = {
        mainDisplayAttrs,
        fullName,
        dealStage,
        // ownerProfilePicture,
        currentOwnerID,
        // initials,
        // assigneeName,
      };

      const dealValue = 0;
      // TODO:  Put dealvalue back in

      let stageTitle = '';
      if (deal.stage) {
        stageTitle = deal.stage.title;
      }

      const comments = deal.comments.items;
      for (const comment of comments) {
        comment.user.profilePicture = await getUserAvatar(
          comment.user.imageName
        );
      }

      const sortedComments = comments.sort((a, b) => {
        return (
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        );
      });

      // console.log('DealRepository appointments BEFORE: ', ourAppointments);

      for (const appointment of ourAppointments) {
        // // dynamically format w/ methods instead of conditionals
        // if (appointment.status === 'customer_rescheduled') {
        //   appointment.status = 'Customer Rescheduled';
        // } else if (appointment.status === 'technician_rescheduled') {
        //   appointment.status = 'Technician Rescheduled';
        // } else if (appointment.status === 'customer_cancelled') {
        //   appointment.status = 'Customer Cancelled';
        // } else if (appointment.status === 'technician_cancelled') {
        //   appointment.status = 'Technician Cancelled';
        // }
        appointment.status = appointment.status
          .split('_')
          .map((word) => word[0].toUpperCase() + word.slice(1))
          .join(' ');
      }

      for (const appointment of ourAppointments) {
        appointment.appointmentStartAt = unformatTime(
          appointment.appointmentDate,
          appointment.appointmentStartTime
        );
      }

      const sortedAppointments = ourAppointments.sort((a, b) => {
        return (
          // new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          new Date(b.appointmentStartAt).getTime() -
          new Date(a.appointmentStartAt).getTime()
        );
      });

      // console.log('DealRepository appointments AFTER: ', ourAppointments);

      const listEntry = {
        deal,
        id: deal.id,
        name: searchName,
        display,
        dropDownName,
        mainDisplay,
        dealValue,
        // dealStages: dealStages,
        currentStage: stageTitle,
        currentStageID: deal.currentStageID,
        comments: sortedComments,
        appointments: sortedAppointments,
        dealMetaData,
      };

      // console.log('listEntry: ', listEntry);
      return listEntry;
    } catch (err) {
      console.error('error in DealDetailsScreen: fetchDeal: ', err);
      return null;
    }
  }
}
