import React, { useState } from 'react';
// import gql from 'graphql-tag';
import { API } from 'aws-amplify';
import * as mutations from '../../../graphql/mutations';
import { /* Backdrop, */ Box, Typography } from '@mui/material';
import { CustomMenu, CustomMenuItem } from '../../common/StyledComponents';
import DynamicConfirmModal from '../../common/DynamicConfirmModal';
import RoleModal from './RoleModal';
import { GamifyToast } from '../../common/CustomToasts';
import { GamifyIcon } from '../../components/GamifyIcon';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateUserAction,
  updateProfileAction,
  selectAllUsers,
  selectAllProfiles,
} from '../../store/reducers/users';
import { usePermissions } from '../../context/PermissionsContext/PermissionsContext';
import { PermissionKeys } from '../../context/PermissionsContext/permissionTypes';

export default function PermissionsModal(props) {
  const {
    user,
    rolePositionObj,
    setRolePositionObj,
    // showPermissionsModal,
    // setShowPermissionsModal,
    permissionsAnchorEl,
    setPermissionsAnchorEl,
  } = props;
  const dispatch = useDispatch();
  const reduxUsers = useSelector(selectAllUsers);
  const reduxUser = reduxUsers[user.id];
  const reduxProfiles = useSelector(selectAllProfiles);
  const reduxProfile = reduxProfiles[user.id];

  const { checkPermission, orgRoles, isSuperAdmin } = usePermissions();

  const normalizedOrgRoles = orgRoles.reduce((acc, role) => {
    acc[role.id] = role;
    return acc;
  }, {});

  const isSuper = isSuperAdmin();

  const showChangeRole =
    checkPermission(PermissionKeys.TeamsPeople_ChangePersonsRole) &&
    (!normalizedOrgRoles[user?.orgs?.items[0]?.roleID]?.isSuper ||
      (isSuper && normalizedOrgRoles[user?.orgs?.items[0]?.roleID]?.isSuper));
  const showChangeStatus = checkPermission(
    PermissionKeys.TeamsPeople_ChangePersonsStatus
  );

  const [isSaving, setIsSaving] = useState(false);
  const [showRoleModal, setShowRoleModal] = useState(false);
  const [dynamicModalObj, setDynamicModalObj] = useState(null);
  const [showUserStatusModal, setShowUserStatusModal] = useState(false);

  //   console.log('PermissionsModal rolePositionObj: ', rolePositionObj);
  //   console.log('PermissionsModal user: ', user);

  const modalsConfig = {
    deactivate: {
      //   icon: "disable",
      title: `Do you want to deactivate ${user?.name}?`,
      subtitle: 'You can activate and deactivate users\nanytime. ',
      onConfirm: () => {
        // LOG.debug("this is great");
        setIsSaving(true);
        changeUserStatus(user, 'disable', (res) => {
          setIsSaving(false);

          if (res) {
            setShowUserStatusModal(false);
            // setShowPermissionsModal(false);
            setPermissionsAnchorEl(null);
            setDynamicModalObj(null);

            // NOTE: No longer need to refetch, just update users and profiels in the Users slice of state in the redux store
            // onTriggerProfileDetailsRefetch();

            if (reduxUser) {
              const updatedReduxUser = { ...reduxUser, status: 'disabled' };

              dispatch(updateUserAction(updatedReduxUser));
            }

            const updatedReduxProfile = {
              ...reduxProfile,
              user: {
                ...reduxProfile.user,
                status: 'disabled',
              },
            };

            dispatch(updateProfileAction(updatedReduxProfile));

            GamifyToast.success(`${user.name} Deactivated`);
          }
        });
      },
    },
    activate: {
      //   icon: "enable",
      title: `Do you want to activate ${user?.name}?`,
      subtitle: 'You can activate and deactivate users\nanytime. ',
      onConfirm: () => {
        // console.log('this is great');
        setIsSaving(true);
        changeUserStatus(user, 'enable', (res) => {
          // console.log("MY RES - ", res);
          setIsSaving(false);

          if (res) {
            setShowUserStatusModal(false);
            // setShowPermissionsModal(false);
            setPermissionsAnchorEl(null);
            setDynamicModalObj(null);

            // NOTE: No longer need to refetch, just update users and profiels in the Users slice of state in the redux store
            // onTriggerProfileDetailsRefetch();

            if (reduxUser) {
              const updatedReduxUser = { ...reduxUser, status: 'active' };

              dispatch(updateUserAction(updatedReduxUser));
            }

            const updatedReduxProfile = {
              ...reduxProfile,
              user: {
                ...reduxProfile.user,
                status: 'active',
              },
            };

            dispatch(updateProfileAction(updatedReduxProfile));

            GamifyToast.success(`${user.name} Activated`);
          }
        });
      },
    },
  };

  //
  //  Do user admin functions
  //
  async function changeUserStatus(user, action, onComplete) {
    // console.log("changeUserStatus: ", user);
    try {
      await API.graphql({
        query: mutations.personAdminFunctions,
        variables: {
          userID: user.id,
          username: user.username,
          action,
        },
      });
      // console.log("userAdminFunctionsRes: ", userAdminFunctionsRes);
    } catch (err) {
      console.error(
        'changeUserStatus(): error updating UserStatus:',
        JSON.stringify(err)
      );
      GamifyToast.error('Error updating status, please try again');
      onComplete(false);
      return false;
    }
    onComplete(true);
    return true;
  }

  // const handleCloseClick = () => {
  //   setShowPermissionsModal(false);
  // };

  const handleMenuClose = () => {
    setPermissionsAnchorEl(null);
  };

  return (
    <>
      {/* Position Modal */}
      {showChangeRole && (
        <RoleModal
          user={user}
          positionOnly={true}
          rolePositionObj={rolePositionObj}
          setRolePositionObj={setRolePositionObj}
          showRoleModal={showRoleModal}
          setShowRoleModal={setShowRoleModal}
        ></RoleModal>
      )}
      {/* Activate or Deactivate Modal */}
      {showChangeStatus && (
        <DynamicConfirmModal
          showDynamicConfirmModal={showUserStatusModal}
          setShowDynamicConfirmModal={setShowUserStatusModal}
          zIndex={100002}
          title={dynamicModalObj?.title}
          subtitle={dynamicModalObj?.subtitle}
          subtitleColor={dynamicModalObj?.subtitleColor}
          confirmButtonColor={dynamicModalObj?.confirmButtonColor}
          isSaving={isSaving}
          onConfirm={dynamicModalObj?.onConfirm}
        ></DynamicConfirmModal>
      )}
      {/* Permissions Modal */}
      {/* NOTE: The custom Backdrop component was replaced by the DynamicConfirmModal which was then replaced by CustomMenu and CustomMenuItem components per design documents */}
      <CustomMenu
        anchorEl={permissionsAnchorEl}
        open={Boolean(permissionsAnchorEl)}
        onClose={() => {
          handleMenuClose();
        }}
      >
        {showChangeRole && (
          <CustomMenuItem
            sx={{
              background: 'white',
              color: 'black',
              paddingTop: '9px',
              paddingBottom: '9px',
            }}
            onClick={(e) => {
              e.stopPropagation();
              setShowRoleModal(true);
              setPermissionsAnchorEl(null);
            }}
            disableRipple={true}
          >
            <Box
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography style={{ fontWeight: 500 }}>Change Role</Typography>
              <GamifyIcon icon={'role'} color={'black'}></GamifyIcon>
            </Box>
          </CustomMenuItem>
        )}
        {showChangeStatus && (
          <CustomMenuItem
            sx={{
              background: 'white',
              color: 'black',
              paddingTop: '9px',
              paddingBottom: '9px',
            }}
            onClick={(e) => {
              e.stopPropagation();
              if (user.status === 'active') {
                setDynamicModalObj(modalsConfig.deactivate);
              } else {
                setDynamicModalObj(modalsConfig.activate);
              }
              setShowUserStatusModal(true);
              setPermissionsAnchorEl(null);
            }}
            disableRipple={true}
          >
            <Box
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                style={{
                  color: user.status === 'active' ? '#DA2424' : 'black',
                  fontWeight: 500,
                }}
              >
                {user.status === 'active' ? 'Deactivate' : 'Activate'}
              </Typography>
              <GamifyIcon
                icon={
                  user.status === 'active' ? 'deactivateUser' : 'activateUser'
                }
                color={user.status === 'active' ? '' : 'black'}
              ></GamifyIcon>
            </Box>
          </CustomMenuItem>
        )}
      </CustomMenu>
    </>
  );
}
