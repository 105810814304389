import React, { useEffect, useState } from 'react';
// import { Box, List, ListItem, CircularProgress } from '@mui/material';
import { API } from 'aws-amplify';
import gql from 'graphql-tag';
import { Button, Text, View } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router';
import './orgList.css';
import FeaturesTable from './FeaturesTable';
import { Box, List, ListItem, CircularProgress } from '@mui/material';

export default function OrgsList() {
  const navigate = useNavigate();
  const [currentScreen, setCurrentScreen] = useState('list');
  const [searchInput, setSearchInput] = useState('');
  const [orgsList, setOrgsList] = useState([]);
  const [sortedOrgs, setSortedOrgs] = useState([]);
  const [fetchedOrgs, setFetchedOrgs] = useState(false);
  const [focusedOrg, setFocusedOrg] = useState(null);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'ArrowDown') {
        if (focusedOrg === null || focusedOrg === undefined) {
          setFocusedOrg(0);
        } else if (focusedOrg < sortedOrgs.length - 1) {
          setFocusedOrg((p) => p + 1);
        }
        return;
      }
      if (e.key === 'ArrowUp' && focusedOrg !== 0 && !isNaN(focusedOrg)) {
        setFocusedOrg((p) => p - 1);
        return;
      }

      if (e.key === 'Enter' && !isNaN(focusedOrg) && sortedOrgs.length) {
        navigate(`/admin-config/${sortedOrgs[focusedOrg].id}`);
      }
    };
    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  });

  const listOrgs = async () => {
    let hasNextPage = true;
    let nextToken = null;
    let allItems = [];

    while (hasNextPage) {
      const myQuery = gql`
        query MyQuery($limit: Int, $nextToken: String) {
          listOrgs(limit: $limit, nextToken: $nextToken) {
            items {
              id
              configParms
            }
            nextToken
          }
        }
      `;

      const res = await API.graphql({
        query: myQuery,
        variables: {
          limit: 100,
          nextToken,
        },
      });

      const { items, nextToken: newNextToken } = res.data.listOrgs;
      allItems = allItems.concat(items);
      nextToken = newNextToken;
      hasNextPage = !!nextToken;
    }
    allItems.forEach((org) => {
      org.configParms = JSON.parse(org.configParms);
      org.featureFlags = org.configParms.featureFlags;
      org.features = org.configParms.features;
    });
    setOrgsList(allItems);
    setFetchedOrgs(true);
    // console.log(allItems);
  };

  // const getFeatures = () => {
  //   const featuresObj = {};
  //   const featuresFlagObj = {};
  //   let featuresNum = null;
  //   orgsList.forEach((org) => {
  //     const { features, featureFlags } = org;
  //     Object.keys(features).forEach((ft) => {
  //       if (!featuresObj[ft]) {
  //         featuresObj[ft] = { [org.id]: org.features[ft].enabled };
  //       } else {
  //         featuresObj[ft][org.id] = org.features[ft].enabled;
  //       }
  //     });
  //     Object.keys(featureFlags).forEach((ff) => {
  //       if (!featuresFlagObj[ff]) {
  //         featuresFlagObj[ff] = { [org.id]: org.featureFlags[ff] };
  //       } else {
  //         featuresFlagObj[ff][org.id] = org.featureFlags[ff];
  //       }
  //     });
  //   });
  //   console.log('featuresObj');
  //   console.log(featuresObj);
  //   console.log('featuresFlagObj');
  //   console.log(featuresFlagObj);
  // };
  useEffect(() => {
    if (
      global.me?.role !== 'admin' ||
      !global.me.isAdmin ||
      global.me.orgID !== 'paiv'
    )
      navigate('/');
    listOrgs();
  }, []);

  useEffect(() => {
    if (!orgsList.length) return;
    const newList = orgsList
      .filter((el) => filterSearch(el.id, searchInput))
      .sort((a, b) => {
        if (a.id < b.id) {
          return -1;
        } else if (a.id > b.id) {
          return 1;
        } else return 0;
      });
    setSortedOrgs(() => newList);
    if (sortedOrgs.length < orgsList.length) {
      setFocusedOrg(0);
    } else {
      setFocusedOrg(null);
    }
  }, [searchInput, orgsList]);

  const filterSearch = (el, term) => {
    return new RegExp(term, 'gi').test(el);
  };
  if (!fetchedOrgs)
    return (
      <CircularProgress
        style={{ position: 'absolute', top: '30vh', left: '50%' }}
      />
    );
  return (
    <View style={{ padding: '100px 50px' }}>
      <Box style={{ display: 'flex', gap: '5px', padding: '15px 0' }}>
        <Button onClick={() => setCurrentScreen('list')}>Orgs List</Button>
        <Button onClick={() => setCurrentScreen('features')}>
          Feature flags
        </Button>
      </Box>
      {currentScreen === 'list' && (
        <Box>
          <Box id="list-header">
            <Text style={{ fontWeight: 'bold', fontSize: '1.6rem' }}>
              Org List
            </Text>
            <input
              autoFocus
              autoComplete="off"
              id="org-search-input"
              type="text"
              placeholder="Search org"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </Box>
          <List
            style={{ display: 'flex', flexDirection: 'column', color: 'white' }}
          >
            {sortedOrgs.length ? (
              sortedOrgs.map((el, i) => (
                <ListItem
                  key={i}
                  className="org-tile"
                  style={{
                    backgroundColor: i === focusedOrg ? 'lightblue' : 'white',
                  }}
                  onClick={() => navigate(`/admin-config/${el.id}`)}
                >
                  <Text
                    className="org-tile"
                    onClick={() => navigate(`/admin-config/${el.id}`)}
                  >
                    {el.id}
                  </Text>
                </ListItem>
              ))
            ) : (
              <Text>{`No match to org name ${searchInput} found!`}</Text>
            )}
          </List>
        </Box>
      )}
      {currentScreen === 'features' && (
        <Box>
          <FeaturesTable orgsList={orgsList} />
          {/* <Text onClick={getFeatures}>HELLO!</Text>
          <OrgsFeatures orgsList={orgsList} /> */}
        </Box>
      )}
    </View>
  );
}

// function OrgsFeatures({ featuresList, featureFlagsList, orgsList }) {
//   const [checkedOrgs, setCheckedOrgs] = useState({});

//   const handleOrgCheck = (id) => {
//     console.log(id);
//   };

//   const columns = useMemo(
//     () => [
//       {
//         accessorKey: 'id',
//         cell: ({ row }) => {
//           const { id } = row.original;
//           return (
//             <Box
//               sx={{
//                 display: 'flex',
//                 flexDirection: 'row',
//                 alignItems: 'center',
//               }}
//             >
//               <Checkbox
//                 sx={{
//                   color: '#FF6A00',
//                   '&.Mui-checked': {
//                     color: '#FF6A00',
//                   },
//                 }}
//                 checked={Boolean(checkedOrgs[id])}
//                 onChange={() => handleOrgCheck(id)}
//                 inputProps={{ 'aria-label': 'primary checkbox' }}
//               />
//               <Text>{id}</Text>
//             </Box>
//           );
//         },
//       },
//     ],
//     [checkedOrgs]
//   );

//   // return <FaeaturesTables1 data={orgsList} columns={columns} />;
// }

// function FaeaturesTables1({ data, columns, checkedOrgs }) {
//   const [sorting, setSorting] = useState([]);
//   console.log('data');
//   console.log(data);
//   const table = useReactTable({
//     data,
//     columns,
//     state: { sorting },
//     enableSortingRemoval: false,
//     getCoreRowModel: getCoreRowModel(),
//   });
//   const fieldsWithSorting = useMemo(
//     () => ['name', 'role', 'Member Since', 'status'],
//     []
//   );
//   return (
//     <TableContainer>
//       <MuiTable>
//         <TableHead>
//           {table?.getHeaderGroups()?.map((headerGroup) => (
//             <UsersTableRow key={headerGroup.id} sx={{ borderBottom: 'none' }}>
//               {headerGroup.headers?.map((header) => {
//                 return (
//                   <TitleTableCell
//                     key={header.id}
//                     colSpan={header.colSpan}
//                     sx={{ borderBottom: 'none' }}
//                   >
//                     {header.isPlaceholder ? null : (
//                       <Box
//                         {...{
//                           className: header.column.getCanSort()
//                             ? 'cursor-pointer select-none'
//                             : '',
//                           onClick: header.column.getToggleSortingHandler(),
//                         }}
//                         style={{
//                           alignItems: 'center',
//                           display: 'flex',
//                           justifyContent: 'flex-start',
//                           cursor: 'pointer',
//                           borderBottom: 'none',
//                         }}
//                       >
//                         {flexRender(
//                           header.column.columnDef.header,
//                           header.getContext()
//                         )}
//                         {fieldsWithSorting.includes(header.id) && (
//                           <img src="/images/sort-icon.svg" />
//                         )}
//                       </Box>
//                     )}
//                   </TitleTableCell>
//                 );
//               })}
//               <TitleTableCell sx={{ borderBottom: 'none' }} />
//             </UsersTableRow>
//           ))}
//         </TableHead>
//         <TableBody>
//           {table.getRowModel().rows.map((row) => {
//             return (
//               <UsersTableRow key={row.id}>
//                 {row.getVisibleCells().map((cell) => {
//                   return (
//                     <UsersTableCell key={cell.id}>
//                       {flexRender(
//                         cell.column.columnDef.cell,
//                         cell.getContext()
//                       )}
//                     </UsersTableCell>
//                   );
//                 })}
//               </UsersTableRow>
//             );
//           })}
//         </TableBody>
//       </MuiTable>
//     </TableContainer>
//   );
// }
