import React, { useState, useMemo } from 'react';
import {
  Box,
  Typography,
  Checkbox,
  Paper,
  Stack,
  Avatar,
  Chip,
  CircularProgress,
  IconButton,
  Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import MenuIcon from '@mui/icons-material/Menu';
import { Employee } from '../api/useUsersQuery';
import { TeamWithClosers } from '../types';
import Fuse from 'fuse.js';
import { DateRangePicker } from '../../../../components/DateRangePicker/DateRangePicker';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { View } from '@paiv/react-scheduler/components/nav/Navigation';
import DealSelectionModal from './DealSelectionModal';
import { Deal } from '../api/useDealsQuery';

// Define a custom view type that includes 'agenda'
type CustomView = View | 'agenda';

const StyledSidebar = styled(Paper)(({ theme }) => ({
  width: 300,
  height: 'calc(125vh)',
  borderRight: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(2),
  marginTop: 70,
  transition: 'width 0.3s ease',
  position: 'relative',
}));

const CollapsedSidebar = styled(Paper)(({ theme }) => ({
  width: 50,
  height: 'calc(100vh - 70px)',
  borderRight: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(2),
  marginTop: 70,
  transition: 'width 0.3s ease',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const SearchInput = styled('input')`
  padding: 8px 12px 8px 48px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  font-size: 14px;
  width: 100%;
  outline: none;
  height: 48px;

  &:focus {
    border-color: #ff6a00;
  }

  &::placeholder {
    color: #9ca3af;
  }
`;

const SearchWrapper = styled(Box)`
  position: relative;

  & svg {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: #9ca3af;
  }
`;

const TabsContainer = styled(Box)`
  display: flex;
  gap: 24px;
  padding-bottom: 8px;
  border-bottom: 1px solid #e5e7eb;
  justify-content: center;
  align-items: center;
`;

const TabButton = styled(Typography)<{ active: boolean }>`
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  color: ${({ active }) => (active ? '#ff6a00' : '#6b7280')};
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: -9px;
    left: 0;
    right: 0;
    height: 2px;
    background-color: #ff6a00;
    opacity: ${({ active }) => (active ? 1 : 0)};
  }
`;

const Header = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => theme.spacing(1)};
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  background-color: #fff;
  margin: -16px -16px 16px -16px;
  padding-top: 8px;
`;

interface SchedulingSidebarProps {
  activeTab: 'people' | 'team';
  setActiveTab: (tab: 'people' | 'team') => void;
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  isLoading: boolean;
  selectedEmployees: string[];
  setSelectedEmployees: React.Dispatch<React.SetStateAction<string[]>>;
  handleEmployeeSelection: (id: string) => void;
  colorAssignments: Record<string, string>;
  setColorAssignments: React.Dispatch<
    React.SetStateAction<Record<string, string>>
  >;
  employees: Employee[];
  teamsWithClosers: TeamWithClosers[];
  handleTeamSelection: (id: string) => void;
  handleTeamMemberSelection: (employeeId: string, teamId: string) => void;
  selectedDates: Date[];
  onDateSelect: (date: Date) => void;
  schedulerView: CustomView;
  onDealSelect: (deal: Deal) => void;
  existingAppointments?: Array<{
    dealId: string;
    assignedUser?: {
      id: string;
      name: string;
      avatar?: string;
    };
    start: Date;
    end: Date;
  }>;
}

export const SchedulingSidebar: React.FC<SchedulingSidebarProps> = ({
  activeTab,
  setActiveTab,
  searchQuery,
  setSearchQuery,
  isLoading,
  selectedEmployees,
  setSelectedEmployees,
  handleEmployeeSelection,
  colorAssignments,
  setColorAssignments,
  employees,
  teamsWithClosers,
  handleTeamSelection,
  handleTeamMemberSelection,
  selectedDates,
  onDateSelect,
  schedulerView,
  onDealSelect,
  existingAppointments = [],
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isDealModalOpen, setIsDealModalOpen] = useState(false);

  // Configure Fuse instances for both users and teams with improved settings
  const usersFuse = useMemo(
    () =>
      new Fuse(employees, {
        keys: ['name', 'role'],
        threshold: 0.2,
        shouldSort: true,
      }),
    [employees]
  );

  const teamsFuse = useMemo(
    () =>
      new Fuse(teamsWithClosers, {
        keys: [
          { name: 'name', weight: 1 },
          { name: 'closers.name', weight: 2 },
        ],
        threshold: 0.2,
        shouldSort: true,
      }),
    [teamsWithClosers]
  );

  // Separate filtered lists for teams and employees
  const filteredTeams = useMemo(() => {
    if (!searchQuery) return teamsWithClosers;
    const results = teamsFuse.search(searchQuery);
    return results.map((result) => result.item);
  }, [searchQuery, teamsFuse, teamsWithClosers]);

  const filteredPeople = useMemo(() => {
    if (!searchQuery) return employees;
    const results = usersFuse.search(searchQuery);
    return results.map((result) => result.item);
  }, [searchQuery, usersFuse, employees]);

  const renderTeamMembers = () => (
    <Stack spacing={2}>
      {filteredTeams.length === 0 && !isLoading ? (
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ p: 2, textAlign: 'center' }}
        >
          No teams found
        </Typography>
      ) : (
        filteredTeams.map((team) => {
          // Filter closers based on search query if needed
          const closers = team.closers;

          if (closers.length === 0 && searchQuery) return null;

          return (
            <Box key={team.id}>
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  p: '4px',
                  borderRadius: 1,
                  backgroundColor: 'background.paper',
                  '&:hover': { bgcolor: 'action.hover' },
                }}
              >
                <Avatar
                  src={team.image}
                  alt={team.name}
                  sx={{
                    width: 24,
                    height: 24,
                    mr: 1,
                    bgcolor: '#BDBDBD',
                    fontSize: '0.75rem',
                    fontWeight: 500,
                  }}
                >
                  {team.name.slice(0, 2).toUpperCase()}
                </Avatar>
                <Typography flex={1} variant="subtitle2">
                  {team.name}
                </Typography>
                <Checkbox
                  checked={team.isSelected}
                  onChange={() => handleTeamSelection(team.id)}
                  size="small"
                  sx={{
                    color: '#e5e7eb',
                    '&.Mui-checked': {
                      color: '#ff6a00',
                    },
                  }}
                />
              </Box>

              {team.isSelected && closers.length > 0 && (
                <Stack spacing={1} sx={{ ml: 3, mt: 1 }}>
                  {closers.map((closer) => (
                    <Box
                      key={closer.id}
                      display="flex"
                      alignItems="center"
                      sx={{
                        p: '4px',
                        borderRadius: 1,
                        backgroundColor: 'background.paper',
                        '&:hover': { bgcolor: 'action.hover' },
                      }}
                    >
                      <Avatar
                        src={closer.avatar}
                        alt={closer.name}
                        sx={{
                          width: 24,
                          height: 24,
                          mr: 1,
                          fontSize: '0.75rem',
                        }}
                      />
                      <Typography flex={1} variant="body2">
                        {closer.name}
                      </Typography>
                      <Checkbox
                        checked={selectedEmployees.includes(closer.id)}
                        onChange={() =>
                          handleTeamMemberSelection(closer.id, team.id)
                        }
                        size="small"
                        sx={{
                          color: '#e5e7eb',
                          '&.Mui-checked': {
                            color: colorAssignments[closer.id] || '#ff6a00',
                          },
                        }}
                      />
                    </Box>
                  ))}
                </Stack>
              )}
            </Box>
          );
        })
      )}
    </Stack>
  );

  const handleOpenDealModal = () => {
    setIsDealModalOpen(true);
  };

  const handleCloseDealModal = () => {
    setIsDealModalOpen(false);
  };

  const handleDealSelect = (deal: Deal) => {
    onDealSelect(deal);
  };

  if (isCollapsed) {
    return (
      <CollapsedSidebar>
        <IconButton onClick={() => setIsCollapsed(false)} sx={{ mb: 2 }}>
          <MenuIcon />
        </IconButton>
        {selectedEmployees.map((id) => {
          const employee = employees.find((emp) => emp.id === id);
          return (
            employee && (
              <Avatar
                key={employee.id}
                src={employee.avatar}
                alt={employee.name}
                sx={{
                  width: 32,
                  height: 32,
                  mb: 1,
                  border: '2px solid',
                  borderColor: colorAssignments[employee.id] || '#e8f3e3',
                }}
              />
            )
          );
        })}
      </CollapsedSidebar>
    );
  }

  return (
    <StyledSidebar sx={{ backgroundColor: '#ecedec', pt: 3.5 }}>
      <Header>
        <IconButton onClick={() => setIsCollapsed(true)}>
          <MenuIcon />
        </IconButton>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 600,
            fontSize: 16,
            color: '#2c2c2c',
            marginLeft: 1,
          }}
        >
          Scheduling
        </Typography>
      </Header>
      <Stack spacing={2}>
        <Button
          variant="contained"
          fullWidth
          onClick={handleOpenDealModal}
          sx={{
            backgroundColor: '#ff6a00',
            color: 'white',
            '&:hover': {
              backgroundColor: '#e65f00',
            },
            textTransform: 'none',
            fontWeight: 600,
            borderRadius: '8px',
            display: 'flex',
            gap: 1,
            alignItems: 'center',
          }}
        >
          <CalendarTodayIcon sx={{ fontSize: 20 }} />
          Schedule
        </Button>
        <DateRangePicker
          selectedDates={selectedDates}
          onDateSelect={onDateSelect}
          // onClose={() => {}}
          singleDateMode={schedulerView !== 'manyDays'}
        />
      </Stack>

      <Stack spacing={2} height="100%">
        <SearchWrapper>
          <SearchIcon fontSize="small" color="action" />
          <SearchInput
            placeholder={`Search ${
              activeTab === 'people' ? 'people' : 'teams'
            }...`}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </SearchWrapper>
        <TabsContainer>
          <TabButton
            active={activeTab === 'people'}
            onClick={() => setActiveTab('people')}
          >
            People
          </TabButton>
          <TabButton
            active={activeTab === 'team'}
            onClick={() => setActiveTab('team')}
          >
            Team
          </TabButton>
        </TabsContainer>

        <Stack spacing={2} flex={0.5}>
          <Box sx={{ flex: 1 }}>
            {isLoading ? (
              <Box display="flex" justifyContent="center" p={2}>
                <CircularProgress size={24} />
              </Box>
            ) : (
              <Stack
                spacing={1}
                sx={{
                  height: '100%',
                  maxHeight: '50vh',
                  overflowY: 'auto',
                  pr: 1,
                  mr: -1,
                }}
              >
                {activeTab === 'people'
                  ? filteredPeople.map((employee) => (
                      <Box
                        key={employee.id}
                        display="flex"
                        alignItems="center"
                        sx={{
                          p: '4px',
                          borderRadius: 1,
                          backgroundColor: 'background.paper',
                          '&:hover': { bgcolor: 'action.hover' },
                        }}
                      >
                        <Avatar
                          src={employee.avatar}
                          alt={employee.name}
                          sx={{ width: 24, height: 24, mr: 1 }}
                        />
                        <Typography flex={1} variant="body2">
                          {employee.name}
                        </Typography>
                        <Checkbox
                          checked={selectedEmployees.includes(employee.id)}
                          onChange={() => handleEmployeeSelection(employee.id)}
                          size="small"
                          sx={{
                            color: '#e5e7eb',
                            '&.Mui-checked': {
                              color: colorAssignments[employee.id] || '#ff6a00',
                            },
                          }}
                        />
                      </Box>
                    ))
                  : renderTeamMembers()}
              </Stack>
            )}
          </Box>

          {selectedEmployees.length > 0 && (
            <Box sx={{ borderTop: '1px solid #e5e7eb' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  mb: 1,
                }}
              >
                <Typography variant="subtitle2" sx={{ fontWeight: '600' }}>
                  Selected Members
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    color: '#ff6a00',
                    cursor: 'pointer',
                    fontWeight: '500',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                  onClick={() => {
                    setSelectedEmployees([]);
                    setColorAssignments({});
                  }}
                >
                  Clear All
                </Typography>
              </Box>
              <Stack direction="row" flexWrap="wrap" gap={1}>
                {selectedEmployees.map((id) => {
                  const employee = employees.find((emp) => emp.id === id);
                  return (
                    employee && (
                      <Chip
                        key={employee.id}
                        avatar={<Avatar src={employee.avatar} />}
                        label={employee.name}
                        onDelete={() => handleEmployeeSelection(employee.id)}
                        size="small"
                        sx={{
                          bgcolor: colorAssignments[employee.id] || '#e8f3e3',
                          '& .MuiAvatar-root': {
                            border: '1px solid rgba(255,255,255,0.2)',
                          },
                        }}
                      />
                    )
                  );
                })}
              </Stack>
            </Box>
          )}
        </Stack>
      </Stack>

      <DealSelectionModal
        open={isDealModalOpen}
        onClose={handleCloseDealModal}
        onDealSelect={handleDealSelect}
        existingAppointments={existingAppointments}
      />
    </StyledSidebar>
  );
};
