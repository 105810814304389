import { Appointment, CalendarEvent } from '../types';

const fieldAliases = {
  firstName: [
    'firstName',
    'name',
    'First Name',
    'first_name',
    'first name',
    'FirstName',
    'first Name',
    'First name',
  ],
  lastName: [
    'lastName',
    'Last Name',
    'last_name',
    'last name',
    'LastName',
    'last Name',
    'Last name',
  ],
  phoneNumber: [
    'phoneNumber',
    'Phone Number',
    'phone_number',
    'phone number',
    'PhoneNumber',
    'phone Number',
    'Phone number',
    'Phone',
  ],
  address: ['address', 'Address', 'ADDRESS'],
  city: ['City', 'city', 'CITY'],
  state: ['State', 'state', 'STATE'],
  zipCode: [
    'zip',
    'Zip',
    'ZIP',
    'zipCode',
    'Zip Code',
    'ZipCode',
    'zip_code',
    'ZIP_CODE',
    'Zipcode',
  ],
  notes: [
    'Notes',
    'notes',
    'Appointment Notes',
    'AppointmentNotes',
    'appointment_notes',
    'appointment notes',
    'Comments',
  ],
};

// Helper function to get field value using aliases
const getFieldValueWithAliases = (
  fields: Record<string, any>,
  fieldName: string
): string => {
  const aliases = fieldAliases[fieldName as keyof typeof fieldAliases] || [];

  for (const alias of aliases) {
    if (
      fields[alias] !== undefined &&
      fields[alias] !== null &&
      fields[alias] !== ''
    ) {
      return fields[alias];
    }
  }

  return '';
};

export const convertAppointmentsToEvents = (
  appointmentGridResponse: string
): CalendarEvent[] => {
  try {
    const parsed = JSON.parse(appointmentGridResponse) as {
      appointments: Record<string, Record<string, Appointment[]>>;
    };
    if (!parsed.appointments) {
      console.log('No appointments found in parsed response');
      return [];
    }

    console.log(
      'Raw parsed appointment data:',
      JSON.stringify(parsed, null, 2)
    );
    const events: CalendarEvent[] = [];

    // Color mappings for different admin users
    const colorMap: Record<string, { color: string; textColor: string }> = {
      default: { color: '#1976d2', textColor: '#000000' },
      admin1: { color: '#E1F5FE', textColor: '#000000' },
      admin2: { color: '#E8F5E9', textColor: '#000000' },
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(parsed.appointments).forEach(([date, userAppointments]) => {
      Object.entries(userAppointments).forEach(([userId, appointments]) => {
        (appointments as Appointment[]).forEach((appointment) => {
          const displayFields = appointment.displayFields || {};
          console.log(
            'Converting appointment to event:',
            JSON.stringify(appointment, null, 2)
          );
          console.log('Appointment ID:', appointment.appointmentId);
          console.log('Deal ID:', appointment.dealId);
          console.log(
            'Display fields:',
            JSON.stringify(displayFields, null, 2)
          );

          console.log('Deal Name (raw):', appointment.dealName);
          console.log('Deal Name type:', typeof appointment.dealName);
          console.log('Deal Name truthiness:', Boolean(appointment.dealName));

          const firstName = getFieldValueWithAliases(
            displayFields,
            'firstName'
          );
          const lastName = getFieldValueWithAliases(displayFields, 'lastName');
          let customerName = '';

          if (firstName && lastName) {
            customerName = `${firstName} ${lastName}`;
          } else if (firstName) {
            customerName = firstName;
          } else if (lastName) {
            customerName = lastName;
          }

          console.log('Customer name from fields:', customerName);

          const dealNameStr =
            appointment.dealName != null
              ? String(appointment.dealName).trim()
              : '';

          const title = customerName || dealNameStr || 'Untitled';
          console.log('Final title calculation:', {
            customerName,
            dealNameStr,
            title,
          });

          const address = getFieldValueWithAliases(displayFields, 'address');
          const city = getFieldValueWithAliases(displayFields, 'city');
          const state = getFieldValueWithAliases(displayFields, 'state');
          const zipCode = getFieldValueWithAliases(displayFields, 'zipCode');

          const phoneNumber = getFieldValueWithAliases(
            displayFields,
            'phoneNumber'
          );
          const notes = getFieldValueWithAliases(displayFields, 'notes');
          console.log('Extracted notes:', notes);

          const fullAddress = [address, city, state, zipCode]
            .filter(Boolean)
            .join(', ');

          const formattedDate = appointment.appointmentDate.replace(/-/g, '/');

          const startHours = Math.floor(appointment.appointmentStartTime / 60);
          const startMinutes = appointment.appointmentStartTime % 60;
          const startTimeStr = `${startHours
            .toString()
            .padStart(2, '0')}:${startMinutes.toString().padStart(2, '0')}`;
          const startDate = new Date(`${formattedDate} ${startTimeStr}`);

          const endHours = Math.floor(appointment.appointmentEndTime / 60);
          const endMinutes = appointment.appointmentEndTime % 60;
          const endTimeStr = `${endHours
            .toString()
            .padStart(2, '0')}:${endMinutes.toString().padStart(2, '0')}`;
          const endDate = new Date(`${formattedDate} ${endTimeStr}`);

          if (!appointment.appointmentId) {
            console.warn('Appointment missing ID:', appointment);
          }

          const dealId = appointment.dealId || appointment.dealID || '';
          if (!dealId) {
            console.warn('Appointment missing deal ID:', appointment);
          }

          // Get color mapping for this user
          const colorObj = colorMap[userId] || colorMap.default;

          const event: CalendarEvent = {
            event_id: appointment.appointmentId,
            id: appointment.appointmentId,
            title,
            start: startDate,
            end: endDate,
            admin_id: userId,
            dealId,
            color: colorObj.color,
            textColor: colorObj.textColor,
            extendedProps: {
              displayFields: {
                ...appointment.displayFields,
                customerName: customerName || dealNameStr || '',
                fullAddress,
                phoneNumber,
                notes,
                firstName,
                lastName,
                address,
                city,
                state,
                zipCode,
              },
              dealName: dealNameStr,
            },
          };

          console.log('Created calendar event with title:', event.title);
          events.push(event);
        });
      });
    });

    events.sort((a, b) => a.start.getTime() - b.start.getTime());

    console.log(`Converted ${events.length} appointments to calendar events`);
    return events;
  } catch (error) {
    console.error('Error converting appointments to events:', error);
    return [];
  }
};
