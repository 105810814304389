import React, { useEffect, useState } from 'react';
import { Text } from '@aws-amplify/ui-react';
import { API } from 'aws-amplify';
import gql from 'graphql-tag';
import {
  Box,
  Button,
  // ButtonGroup,
  Backdrop,
  Slider,
  TextField,
  Typography,
} from '@mui/material';
import './kpis.css';
import { useParams } from 'react-router';
import { GamifyToast } from '../../common/CustomToasts';

export default function FormulaWizard(props) {
  const orgRuleIds = props.orgRuleIds;
  const invalidRuleError = props.invalidRuleError;
  const setInvalidRuleError = props.setInvalidRuleError;

  const mathOperators = ['*', '/', '+', '-'];
  const compOptions = [
    ['=', 'eq'],
    ['≠', 'ne'],
    ['>', 'gt'],
    ['<', 'lt'],
    ['>=', 'ge'],
    ['<=', 'le'],
    ['Stars With', 'startswith'],
    ['Contains', 'contains'],
    ['Or', 'or'],
    ['And', 'and'],
  ];
  const subOptions = [
    ['Percent', '@percent+p'],
    ['Round', '@round+p'],
    ['Sum', '@sum'],
    ['Replace', '@replace'],
    ['Field', '@field+f'],
    ['Rule', '@rule+r'],
    ['Previous', '@previous+f'],
    ['Member Of', '@memeberof+c'],
  ];
  const { orgID } = useParams();
  const { closer, setFormula } = props;
  const [circles, setCircles] = useState(null);
  // const [addMenu, setAddMenu] = useState('');
  const [newFormula, setNewFormula] = useState('');
  const [precision, setPrecision] = useState(0);
  const [extraInput, setExtraInput] = useState('');
  const [inputFieldName, setInputFieldName] = useState('');
  const [shortSave, setShortSave] = useState(null);
  const [formulaObjs, setFormulaObjs] = useState([]);
  //   const [ogFurmual, setOgFormula] = useState(props.formula);

  async function fetchCircles() {
    const myQuery = gql`
      query MyQuery($orgID: ID!) {
        listCirclesByOrg(
          orgID: $orgID
          filter: { isDeleted: { eq: false } }
          limit: 10000
        ) {
          items {
            name
            id
          }
        }
      }
    `;

    const circleData = await API.graphql({
      query: myQuery,
      variables: { orgID },
    });
    const circles = circleData.data.listCirclesByOrg.items;
    setCircles(circles);
  }

  useEffect(() => {
    fetchCircles();
  }, []);

  useEffect(() => {
    let isInvalidRule = false;
    // Check if any formulaObjs have rule ids that are not part of this org
    for (const formulaObj of formulaObjs) {
      // Rules are only obj in formulaObjs with title and id properties
      if (formulaObj.title && formulaObj.id) {
        // If the rule id is not in the orgRuleIdsArr, make a large error show that notifies the user (Also need to check this in the KPI form)
        if (!orgRuleIds.includes(formulaObj.id)) {
          isInvalidRule = true;
          break;
        }
      }
    }

    if (isInvalidRule) {
      // Set invalid rule error if there is an invalid rule in the formula
      setInvalidRuleError(
        `There is an invalid rule in your KPI's formula, please Restart the formula.`
      );
    } else {
      // Reset invalid rule error to an empty string if it already isn't
      if (invalidRuleError !== '') {
        setInvalidRuleError('');
      }
    }

    // If formulaObjs has length > 0 check if there's anything manually typed into formula, if so just concatenate the last formualtObj.textVal, if not then join all formulatObj.textVal's with a ' ' between each
    if (formulaObjs.length > 0) {
      const lastFormulaObj = formulaObjs.slice(-1);
      const lastFormulaObjStr = lastFormulaObj[0].textVal;
      const otherFormulaObjs = formulaObjs.slice(0, -1);
      const otherFormulaObjsStr = otherFormulaObjs
        .map((el) => el.textVal)
        .join(' ');

      if (otherFormulaObjsStr === newFormula) {
        const temp = formulaObjs.map((el) => el.textVal).join(' ');
        setNewFormula(temp);
      } else {
        setNewFormula((prev) => prev + ' ' + lastFormulaObjStr);
      }
    } else {
      // When resetting formulaObjs, the length of the array is 0
      const temp = formulaObjs.map((el) => el.textVal).join(' ');
      setNewFormula(temp);
    }

    if (shortSave) setShortSave(null);
    if (extraInput.length) setExtraInput('');
    // if (addMenu.length) setAddMenu('');
    if (inputFieldName.length) setInputFieldName('');
  }, [formulaObjs]);

  useEffect(() => {
    // If invalidRuleError is set to it's string, notify the user with a toast in addition to the other errors shown in the UI!
    if (invalidRuleError) {
      GamifyToast.error(invalidRuleError);
    }
  }, [invalidRuleError]);

  const addMathOperator = (e) => {
    const val = e.currentTarget.getAttribute('name');
    const temp = [...formulaObjs, { textVal: val, type: 'math' }];
    setFormulaObjs(() => temp);
    return true;
  };

  const addComp = (e) => {
    const val = e.currentTarget.getAttribute('name');
    const temp = [...formulaObjs, { textVal: val, type: 'comp' }];
    setFormulaObjs(() => temp);
  };

  const addSub = (e) => {
    const val = e.currentTarget.getAttribute('name');
    if (val.split('+')[0] === val) {
      const temp = [...formulaObjs, { textVal: val, type: 'sub' }];
      setFormulaObjs(() => temp);
      return true;
    }
    setExtraInput(val.split('+')[1]);
    return setShortSave(val);
  };

  const saveSub = (val) => {
    const textVal = `${shortSave.split('+')[0]}(${val})`;
    const temp = [...formulaObjs, { textVal, type: 'sub' }];
    setFormulaObjs(() => temp);
    return true;
  };
  const saveRule = (e) => {
    const [id, title] = e.currentTarget.getAttribute('name').split('+');
    const temp = [
      ...formulaObjs,
      { textVal: `@rule(${title})`, type: 'sub', title, id },
    ];
    setFormulaObjs(temp);
    return true;
  };

  const saveMemberOf = (e) => {
    const name = e.currentTarget.getAttribute('name').split('+')[1];
    const temp = [
      ...formulaObjs,
      { textVal: `@memberof(${name})`, type: 'sub' },
    ];
    setFormulaObjs(temp);
    return true;
  };

  const saveField = () => {
    const temp = [
      ...formulaObjs,
      { textVal: `${shortSave.split('+')[0]}(${inputFieldName})`, type: 'sub' },
    ];
    setFormulaObjs(temp);
    return true;
  };

  const saveAndExit = () => {
    setFormula((prev) => ({ ...prev, formula: databaseFormula(newFormula) }));
    setFormulaObjs([]);
    closer(false);
  };

  const databaseFormula = (formula) => {
    const ruleRegex = /@rule\(([^)]+)\)/g;
    const resultFormula = formula.replace(ruleRegex, (match, title) => {
      // Look up the UUID for the title
      const rule = props.rulesProp.find((el) => el.title === title);
      const uuid = rule ? rule.id : 'unknown';
      return `@rule(${uuid})`;
    });

    return resultFormula;
  };

  return (
    <Backdrop
      sx={{
        backgroundColor: 'rgba(0,0,0,0.6)',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={props.open}
    >
      <Box id="formula-wizard-box">
        <Box>
          <Box className="backdrop-close-button" onClick={() => closer(false)}>
            X
          </Box>
          <Text style={{ fornSize: '1.5rem', fontWeight: 'Bold' }}>
            FORMULA WIZARD
          </Text>
          {invalidRuleError !== '' && (
            <Typography style={{ color: 'red' }}>{invalidRuleError}</Typography>
          )}
          <Text>Add:</Text>
          {/* <ButtonGroup variant="contained" aria-label="outlined button group">
            <Button onClick={() => setAddMenu(() => 'comp')}>Comparison</Button>
            <Button onClick={() => setAddMenu(() => 'sub')}>
              Substitutions
            </Button>
            <Button onClick={() => setAddMenu(() => 'math')}>
              Mathematical Operators
            </Button>
          </ButtonGroup> */}
          {/* {addMenu === 'math' && ( */}
          <Box style={{ marginTop: '10px' }}>
            <Text>Operators</Text>
            <Box className="input-options-box">
              {mathOperators.map((op, i) => (
                <Box
                  className="op-button math-option"
                  key={i}
                  name={op}
                  onClick={(e) => addMathOperator(e)}
                >
                  {op}
                </Box>
              ))}
            </Box>
          </Box>
          {/* )} */}
          {/* {addMenu === 'comp' && ( */}
          <Box style={{ marginTop: '10px' }}>
            <Text>Comparison</Text>
            <Box className="input-options-box">
              {compOptions.map((op, i) => (
                <Box
                  className="op-button comp-option"
                  key={i}
                  name={op[1]}
                  onClick={(e) => addComp(e)}
                >
                  {op[0]}
                </Box>
              ))}
            </Box>
          </Box>
          {/* )} */}
          {/* {addMenu === 'sub' && ( */}
          <Box style={{ marginTop: '10px' }}>
            <Text>Comparison</Text>
            <Box className="input-options-box">
              {subOptions.map((op, i) => (
                <Box
                  className={
                    shortSave
                      ? shortSave === op[1]
                        ? 'op-button sub-option'
                        : 'op-button disabled-op'
                      : 'op-button sub-option'
                  }
                  key={i}
                  name={op[1]}
                  onClick={(e) => addSub(e)}
                >
                  {op[0]}
                </Box>
              ))}
            </Box>
            {extraInput === 'p' && (
              <Box
                style={{
                  borderRadius: '15px',
                  border: '1px solid lightgray',
                  marginTop: '10px',
                  padding: '5px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                }}
              >
                <Text>{`Precision: ${precision}`}</Text>
                <Slider
                  style={{ width: '50%' }}
                  size="small"
                  value={precision}
                  onChange={(e) => setPrecision(e.target.value)}
                  min={0}
                  max={2}
                  step={1}
                  marks
                />
                <Button variant="outlined" onClick={() => saveSub(precision)}>
                  Add
                </Button>
              </Box>
            )}
            {extraInput === 'r' && (
              <Box className="formula-rules-box">
                {props.rulesProp.map(
                  (el, i) =>
                    el.id !== props.currentRuleId && (
                      <Box
                        key={i}
                        className="op-button sub-option"
                        name={`${el.id}+${el.title}`}
                        onClick={saveRule}
                      >
                        {el.title}
                      </Box>
                    )
                )}
              </Box>
            )}
            {extraInput === 'c' && (
              <Box className="formula-rules-box">
                {circles.map((el, i) => (
                  <Box
                    key={i}
                    className="op-button sub-option"
                    name={`${el.id}+${el.name}`}
                    onClick={saveMemberOf}
                  >
                    {el.name}
                  </Box>
                ))}
              </Box>
            )}
            {extraInput === 'f' && (
              <Box className="formula-rules-box flex-center">
                <Text>Type Field Name</Text>
                <TextField
                  value={inputFieldName}
                  onChange={(e) => setInputFieldName(e.target.value)}
                  placeholder="Filed Name"
                  style={{ width: '100%' }}
                />
                <Button variant="outlined" onClick={saveField}>
                  Add
                </Button>
              </Box>
            )}
          </Box>
          {/* )} */}
        </Box>
        <Box>
          <Text>Formula</Text>
          <TextField
            value={newFormula}
            style={{ width: '100%' }}
            onChange={(e) => setNewFormula(e.target.value)}
          />
          <Box>
            <Button
              variant="outlined"
              onClick={saveAndExit}
              style={{ margin: '10px 15px 0 0' }}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                // If last thing in newFormula is from formulaObjs, remove it, if it was manually typed delete the last character of newFormula
                if (formulaObjs.length > 0) {
                  const lastFormulaObj = formulaObjs[formulaObjs.length - 1];
                  const lastFormulaObjTextVal = lastFormulaObj.textVal;
                  const lastFormulaObjTextValLen = lastFormulaObjTextVal.length;

                  // Check if the end of newFormula is lastFormulaObjTextVal, if it is then remove the last formulaObj, otherwise just remove the last character of newFormula
                  if (
                    newFormula.slice(-lastFormulaObjTextValLen) ===
                    lastFormulaObjTextVal
                  ) {
                    setFormulaObjs((prev) => prev.slice(0, prev.length - 1));
                  } else {
                    setNewFormula((prev) => prev.slice(0, prev.length - 1));
                  }
                } else if (newFormula.length > 0) {
                  setNewFormula((prev) => prev.slice(0, prev.length - 1));
                }
              }}
              style={{ margin: '10px 15px 0 0' }}
            >
              Undo
            </Button>
            <Button
              variant="outlined"
              onClick={() => setFormulaObjs([])}
              style={{ margin: '10px 15px 0 0' }}
            >
              Restart
            </Button>
          </Box>
        </Box>
      </Box>
    </Backdrop>
  );
}
