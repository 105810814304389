import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Text, View } from '@aws-amplify/ui-react';
import { useNavigate, useParams } from 'react-router';
import GeneralConfig from './GeneralConfig';
import KpisConfig from './KpisConfig';
import './orgConfigPage.css';
// import CRMConfig from './CRMConfig';
import TeamsConfigurator from './TeamsConfigurator';
import CRMConfigV2 from './CRMConfigV2/CRMConfigV2';

const configTabsTemp = {
  general: false,
  kpi: false,
  teams: false,
  crm: false,
};

export default function OrgConfigPage() {
  const navigate = useNavigate();
  const { orgID } = useParams();
  const [activeTab, setActiveTab] = useState({
    ...configTabsTemp,
    general: true,
  });

  useEffect(() => {
    if (
      global.me?.role !== 'admin' ||
      !global.me.isAdmin ||
      global.me.orgID !== 'paiv'
    )
      navigate('/');
  }, []);

  const tabClick = (e) => {
    const val = e.currentTarget.getAttribute('name');
    setActiveTab(() => ({ ...configTabsTemp, [val]: true }));
  };

  return (
    <View
      style={{
        padding: '100px 5px 5px 5px',
        // paddingTop: '100px',
        borderRadius: '5px',
      }}
    >
      <Box
        style={{
          display: 'flex',
          padding: '0',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box id="back-to-list-button" onClick={() => navigate('/admin-config')}>
          <Text
            style={{
              fontSize: '1rem',
              fontWeight: 'bold',
              marginLeft: '-12px',
            }}
          >
            ← Orgs List
          </Text>
        </Box>
        <Text
          style={{ fontWeight: 'bold', fontSize: '2rem', color: '#FF6A00' }}
        >
          {orgID}
        </Text>
        <Box style={{ width: '100px' }} />
      </Box>
      <Box id="tabs-box">
        <Box
          className={`config-tab ${
            activeTab.general ? 'active' : 'non-active'
          }`}
          name="general"
          onClick={tabClick}
        >
          General
        </Box>
        <Box
          className={`config-tab ${activeTab.kpi ? 'active' : 'non-active'}`}
          name="kpi"
          onClick={tabClick}
        >
          KPI
        </Box>
        <Box
          className={`config-tab ${activeTab.crm ? 'active' : 'non-active'}`}
          name="crm"
          onClick={tabClick}
        >
          CRM
        </Box>
        <Box
          className={`config-tab ${activeTab.teams ? 'active' : 'non-active'}`}
          name="teams"
          onClick={tabClick}
        >
          TEAMS
        </Box>
      </Box>
      <Box
        style={{
          border: '1px solid black',
          padding: '15px',
          backgroundColor: 'white',
        }}
      >
        {activeTab.general && <GeneralConfig orgID={orgID} />}
        {activeTab.kpi && <KpisConfig orgID={orgID} />}
        {activeTab.crm && <CRMConfigV2 orgID={orgID} />}
        {activeTab.crm && (
          <>
            <View style={{ height: 150 }} />
            {/* <CRMConfig orgID={orgID} /> */}
          </>
        )}
        {activeTab.teams && <TeamsConfigurator orgID={orgID} />}
      </Box>
    </View>
  );
}
