import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { getAppointmentGrid } from '../../../../../graphql/queries';
import { convertAppointmentsToEvents } from './utils';
import { CalendarEvent } from '../types';

interface GetAppointmentGridResponse {
  getAppointmentGrid: string;
}

export const fetchAppointmentGrid = async (
  selectedDates: Date[],
  userIds: string[]
): Promise<CalendarEvent[]> => {
  try {
    // Format the request as expected by the server
    const request = {
      dates: selectedDates.map((date) => date.toISOString().split('T')[0]),
      users: userIds,
      dealDisplayFields: [
        // First name variations
        'First Name',
        'FirstName',
        'firstName',
        'first_name',
        'first name',
        'first Name',
        'First name',
        'name',
        'dealName',
        'Deal Name',

        // Last name variations
        'Last Name',
        'LastName',
        'lastName',
        'last_name',
        'last name',
        'last Name',
        'Last name',

        // Address variations
        'Address',
        'address',
        'ADDRESS',

        // City variations
        'City',
        'city',
        'CITY',

        // State variations
        'State',
        'state',
        'STATE',

        // Zip code variations
        'Zip',
        'ZIP',
        'zip',
        'Zip Code',
        'ZipCode',
        'zipCode',
        'zip_code',
        'ZIP_CODE',
        'Zipcode',

        // Phone number variations
        'Phone Number',
        'PhoneNumber',
        'phoneNumber',
        'phone_number',
        'phone number',
        'phone Number',
        'Phone number',
        'Phone',

        // Notes variations
        'Notes',
        'notes',
        'Appointment Notes',
        'AppointmentNotes',
        'appointment_notes',
        'appointment notes',
        'Comments',

        // Status variations
        'Status',
        'status',
        'IsDeleted',
        'isDeleted',
      ],
    };

    console.log('Appointment grid request:', JSON.stringify(request));
    console.log(
      'Fetching appointments for dates:',
      selectedDates.map((d) => d.toISOString())
    );
    console.log('Fetching appointments for users:', userIds);

    const response = (await API.graphql(
      graphqlOperation(getAppointmentGrid, {
        request: JSON.stringify(request),
      })
    )) as GraphQLResult<GetAppointmentGridResponse>;

    console.log('Appointment grid raw response:', response);

    if (response.data) {
      try {
        console.log(
          'Raw appointment grid data:',
          response.data.getAppointmentGrid
        );

        const parsedData = JSON.parse(response.data.getAppointmentGrid);
        console.log('Parsed appointment grid data:', parsedData);

        if (parsedData.appointments) {
          Object.entries(parsedData.appointments).forEach(
            ([date, userAppointments]: [string, any]) => {
              console.log(`Appointments for date ${date}:`, userAppointments);
              Object.entries(userAppointments).forEach(
                ([userId, appointments]: [string, any]) => {
                  console.log(
                    `User ${userId} has ${appointments.length} appointments on ${date}`
                  );
                  appointments.forEach((appointment: any, index: number) => {
                    console.log(`Appointment ${index + 1}:`, {
                      id: appointment.appointmentId,
                      dealId: appointment.dealId,
                      date: appointment.appointmentDate,
                      startTime: appointment.appointmentStartTime,
                      endTime: appointment.appointmentEndTime,
                    });
                  });
                }
              );
            }
          );
        } else {
          console.log('No appointments found in response');
        }
      } catch (parseError) {
        console.error('Error parsing appointment grid data:', parseError);
      }

      const events = convertAppointmentsToEvents(
        response.data.getAppointmentGrid
      );
      console.log('Converted calendar events:', events);
      return events;
    }
    return [];
  } catch (error) {
    console.error('Error fetching appointment grid:', error);
    return [];
  }
};
