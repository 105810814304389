import React from 'react';
import {
  startOfWeek,
  format,
  eachWeekOfInterval,
  eachMonthOfInterval,
  startOfYear,
  endOfMonth,
} from 'date-fns';
import {
  DimensionTimeframe,
  DimensionTimeframeValues,
  GetKPIValuesArgs,
  GetKPIValuesResponse,
  GetOrganizationsResponse,
  GetTeamsResponse,
  KPIList,
  KPIValues,
  Organization,
  Result,
  SortColumn,
  SortOrder,
} from './_types';
import { API } from 'aws-amplify';
import {
  GET_KPI_VALUES_QUERY,
  GET_MY_CIRCLE_LEVELS_QUERY,
  SEARCH_CIRCLES_QUERY,
} from './_query';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';

export const getColumns = ({
  kpiList,
  currentSortColumn,
  currentSortOrder,
}: {
  kpiList: KPIList;
  currentSortColumn: SortColumn;
  currentSortOrder: SortOrder;
}) => {
  const baseColumns = [
    {
      accessorKey: 'rk',
      header: () => (
        <span
          style={{
            marginRight: 20,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <span>Rank</span>
          <span
            style={{ height: shouldShowSortIcon(currentSortColumn) ? 10 : 0 }}
          />
        </span>
      ),
    },
    {
      accessorKey: 'name',
      header: () => (
        <span
          style={{
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <span>Name</span>
          <span
            style={{ height: shouldShowSortIcon(currentSortColumn) ? 10 : 0 }}
          >
            {currentSortColumn === 'name' ? (
              currentSortOrder === 'asc' ? (
                <ArrowDropDown />
              ) : (
                <ArrowDropUp />
              )
            ) : null}
          </span>
        </span>
      ),
    },
    {
      accessorKey: 'space',
      header: () => (
        <span
          style={{
            marginRight: 50,
          }}
        />
      ),
    },
  ];

  const kpiColumns = Object.entries(kpiList).map(([key, fullName]) => ({
    accessorKey: key,
    header: () => (
      <span
        style={{
          paddingRight: 3,
          paddingLeft: 3,
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <div style={{ marginLeft: '5px' }}>
          {fullName || capitalizeFirstLetter(key)}
        </div>
        <div style={{ height: shouldShowSortIcon(currentSortColumn) ? 10 : 0 }}>
          {currentSortColumn === key ? (
            currentSortOrder === 'asc' ? (
              <ArrowDropDown />
            ) : (
              <ArrowDropUp />
            )
          ) : null}
        </div>
      </span>
    ),
  }));

  return [...baseColumns, ...kpiColumns];
};

const shouldShowSortIcon = (currentSortColumn: SortColumn): boolean => {
  return currentSortColumn !== '--first--';
};

export const getData = ({
  kpiList,
  results,
}: {
  results: Result[];
  kpiList: KPIList;
}) => {
  return results.map((result) => ({
    id: result.id,
    rk: result.rank,
    name: result.name,
    space: '',
    ...Object.fromEntries(Object.entries(kpiList).map(([key]) => [key, 0])),
    ...Object.fromEntries(
      Object.entries(result.kpis).map(([key, value]) => [key, value])
    ),
  }));
};

export const getKpiValues = async (
  args: GetKPIValuesArgs
): Promise<KPIValues> => {
  const result = (await API.graphql({
    query: GET_KPI_VALUES_QUERY,
    variables: {
      dimensionType: args.dimensionType,
      dimensionTimeframe: args.dimensionTimeframe,
      dimensionValue: args.dimensionValue,
      nextToken: args.nextToken,
      limit: args.limit,
      sortColumn: args.sortColumn,
      sortOrder: args.sortOrder,
      circleLevelID: args.circleLevelID,
      circleID: args.circleID,
    },
  })) as GetKPIValuesResponse;

  // @ts-ignore
  const kpiValues = JSON.parse(result.data.getKPIValuesV4) as KPIValues;

  return kpiValues;
};

export const getOrganizations = async (
  orgID: string
): Promise<Organization[]> => {
  const result = (await API.graphql({
    query: GET_MY_CIRCLE_LEVELS_QUERY,
    variables: {
      orgID,
    },
  })) as GetOrganizationsResponse;

  const organizations = result.data.listCircleLevelByOrg.items;

  return organizations;
};

export const getTeams = async (args: { orgID: string; userID: string }) => {
  const { orgID, userID } = args;

  const result = (await API.graphql({
    query: SEARCH_CIRCLES_QUERY,
    variables: {
      orgID,
      userID,
    },
  })) as GetTeamsResponse;

  const teams = result.data.searchCircles.items;

  return teams;
};

export const getDimensionTimeframeTitle = (
  dimensionTimeframe: DimensionTimeframe
) => {
  if (dimensionTimeframe === DimensionTimeframeValues.Year) {
    return 'Year';
  } else if (dimensionTimeframe === DimensionTimeframeValues.Month) {
    return 'Month';
  } else if (dimensionTimeframe === DimensionTimeframeValues.Week) {
    return 'Week';
  } else if (dimensionTimeframe === DimensionTimeframeValues.Day) {
    return 'Day';
  } else {
    return '';
  }
};

export const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const generateYearDimensionValues = (): { label: string; value: string }[] => {
  // Sample returned values
  // [
  //   {label: '2019', value: '2019'}
  //   {label: '2020', value: '2020'}
  //   {label: '2021', value: '2021'}
  //   {label: 'Last Year', value: '2022'}
  //   {label: 'This Year', value: '2023'}
  // ]
  const currentYear = parseInt(format(new Date(), 'yyyy'));
  return Array.from({ length: currentYear - 2018 }, (_, i) => {
    const year = (i + 2019).toString();
    let label = year;
    if (year === (currentYear - 1).toString()) label = 'Last Year';
    if (year === currentYear.toString()) label = 'This Year';
    return { label, value: year };
  });
};

const generateMonthDimensionValues = (): { label: string; value: string }[] => {
  // Sample returned values
  // [
  //   {label: 'March 2023', value: '2023-03'}
  //   {label: 'Apr 2023', value: '2023-04'}
  //   {label: 'May 2023', value: '2023-05'}
  //   {label: 'Last Month', value: '2023-06'}
  //   {label: 'This Month', value: '2023-07'}
  // ]
  const now = new Date();
  return eachMonthOfInterval({
    start: startOfYear(new Date(2019, 0, 1)),
    end: endOfMonth(now),
  })
    .map((date, i, arr) => {
      let label = format(date, 'MMM yyyy');
      if (i === arr.length - 1) label = 'This Month';
      if (i === arr.length - 2) label = 'Last Month';
      return {
        label,
        value: format(date, 'yyyy-MM'),
      };
    })
    .slice(-5);
};

export const generateWeekDimensionValues = (): {
  label: string;
  value: string;
}[] => {
  // Sample return
  // {label: 'Week of 07/02/2023', value: '2023-07-02'}
  // {label: 'Week of 07/09/2023', value: '2023-07-09'}
  // {label: 'Week of 07/16/2023', value: '2023-07-16'}
  // {label: 'Last week', value: '2023-07-16'}
  // {label: 'This week', value: '2023-07-23'}
  const startDate = startOfWeek(new Date(2019, 0, 1));
  const endDate = new Date();
  return eachWeekOfInterval({ start: startDate, end: endDate })
    .map((date, i, arr) => {
      const value = format(date, 'yyyy-MM-dd');
      const label =
        i === arr.length - 1
          ? 'This week'
          : i === arr.length - 2
          ? 'Last week'
          : `Week of ${format(date, 'MM/dd/yyyy')}`;
      return { label, value };
    })
    .slice(-5);
};

export const getDimensionValues = (timeframe: DimensionTimeframe) => {
  if (timeframe === DimensionTimeframeValues.Year) {
    return generateYearDimensionValues();
  } else if (timeframe === DimensionTimeframeValues.Month) {
    return generateMonthDimensionValues();
  } else if (timeframe === DimensionTimeframeValues.Week) {
    return generateWeekDimensionValues();
  }

  return [];
};

// This will be use when user change the dimensionTimeframe
// Depending on the value of the dimensionTimeframe,
// this function will return either the CURRENT year, month, or day.
// For week, it should start on the date of the first day of the week (Sunday).
// Ex:
//  dimensionTimeframe = Y
//  returns 2023
//
//  dimensionTimeframe = M
//  returns 2023-07
//
//  dimensionTimeframe = W
//  returns 2023-07-23
//
//  dimensionTimeframe = D
//  returns 2023-07-27
export const getDimensionValue = (
  dimensionTimeframe: DimensionTimeframe
): string => {
  const currentDate = new Date();

  switch (dimensionTimeframe) {
    case DimensionTimeframeValues.Year:
      return format(currentDate, 'yyyy');

    case DimensionTimeframeValues.Month:
      return format(currentDate, 'yyyy-MM');

    case DimensionTimeframeValues.Week:
      const startOfWeekDate = startOfWeek(currentDate, { weekStartsOn: 0 });
      return format(startOfWeekDate, 'yyyy-MM-dd');

    case DimensionTimeframeValues.Day:
      return format(currentDate, 'yyyy-MM-dd');

    default:
      return '';
  }
};
