import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';
import { getUserRole } from '../../../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import {
  PermissionsContextType,
  GetUserRoleResponse,
  UserPermissions,
  Permission,
  OrgRole,
  PermissionKeys,
} from './permissionTypes';
import gql from 'graphql-tag';
import { useCurrentUserStore } from '../../store/zustand/useCurrentUserStore';

// Create the context with default values
const PermissionsContext = createContext<PermissionsContextType | undefined>(
  undefined
);

// Permissions provider component
export const PermissionsProvider = ({ children }: { children: ReactNode }) => {
  const [userPermissions, setUserPermissions] =
    useState<UserPermissions | null>(null);
  const [orgRoles, setOrgRoles] = useState<OrgRole[]>([]);
  const { isUserOrTeamMember, teams, userID } = useCurrentUserStore();
  // const {userID} = useCurrentUserStore();

  const isSuperAdmin = () => {
    return userPermissions?.isSuperAdmin;
  };

  const hasAssignedRole = () => {
    return userPermissions !== null;
  };

  // Function to check if the user has a specific permission
  const checkPermission = (
    permissionKey: PermissionKeys,
    defaultTo?: Permission
  ): Permission | boolean => {
    const permission = userPermissions?.permissions[permissionKey];

    // If the permission is null or undefined, return the default (if provided)
    if (permission === undefined || permission === null) {
      return defaultTo !== undefined ? defaultTo : false;
    }
    // @ts-ignore
    return userPermissions.permissions[permissionKey];
  };

  const hasPermission = (
    permissionKey: PermissionKeys,
    userIDs: string[]
  ): boolean => {
    const permission = checkPermission(permissionKey);
    return (
      permission === 'everyone' ||
      (permission === 'myTeam' && isUserOrTeamMember(...userIDs)) ||
      // @ts-ignore
      (permission === 'onlyMe' && userIDs.some((id) => id === userID))
    );
  };
  const hasTeamPermission = (
    permissionKey: PermissionKeys,
    teamIDs: string[]
  ): boolean => {
    const permission = checkPermission(permissionKey);
    return (
      permission === 'everyone' ||
      // @ts-ignore
      (permission === 'myTeam' && teamIDs.some((id) => teams[id]))
    );
  };

  // Function to fetch user permissions
  const fetchUserPermissions = async () => {
    // @ts-ignore
    const roleID = global.me.roleID;

    if (roleID && roleID.length) {
      try {
        const response = (await API.graphql(
          graphqlOperation(getUserRole, { id: roleID })
        )) as { data: GetUserRoleResponse };

        const userRole = response.data.getUserRole;

        if (userRole) {
          const { id, roleName, permissions, isSuper, iconName } = userRole;

          // Parse permissions (assuming permissions are stored as a JSON string)
          const parsedPermissions = JSON.parse(permissions);
          // Flatten the permissions object
          // const flattenedPermissions = flattenPermissions(parsedPermissions);

          // Update the userPermissions state
          setUserPermissions({
            id,
            name: roleName,
            iconName,
            isSuperAdmin: isSuper,
            permissions: parsedPermissions,
          });

          // console.log('Role Name:', roleName);
          // console.log('Permissions:', parsedPermissions);
          // console.log('Is Super Admin:', isSuper);
          // console.log('Icon Name:', iconName);
        } else {
          console.log('No role found for this roleID.');
          setUserPermissions(null); // Reset state if no role is found
        }
      } catch (error) {
        console.error('Error fetching user role:', error);
        setUserPermissions(null); // Reset state in case of error
      }
    } else {
      console.log('No roleID found.');
      setUserPermissions(null); // Reset state if no roleID
    }
  };

  const fetchOrgsRoles = async () => {
    // @ts-ignore
    const orgID = global.me.orgID;

    const rolesQuery = gql`
      query ListRolesByOrgMinimal($orgID: ID!, $nextToken: String) {
        listRolesByOrg(
          orgID: $orgID
          nextToken: $nextToken
          filter: { isDeleted: { eq: false } }
        ) {
          items {
            id
            roleName
            iconName
            isSuper
          }
          nextToken
        }
      }
    `;
    let nextToken = null;
    let allRoles: any[] = [];

    try {
      do {
        const response = (await API.graphql(
          graphqlOperation(rolesQuery, { orgID, nextToken })
        )) as {
          data: { listRolesByOrg: { items: any[]; nextToken: string | null } };
        };

        const roles = response.data.listRolesByOrg.items;
        nextToken = response.data.listRolesByOrg.nextToken;

        allRoles = [...allRoles, ...roles];
      } while (nextToken);

      // console.log('All roles:', allRoles);
      setOrgRoles(allRoles);
    } catch (error) {
      console.error('Error fetching organization roles:', error);
    }
  };
  // Fetch user role on mount and whenever user changes
  useEffect(() => {
    fetchUserPermissions();
    fetchOrgsRoles();
    // @ts-ignore
  }, [global?.me?.id]);

  return (
    <PermissionsContext.Provider
      value={{
        hasAssignedRole,
        // @ts-ignore
        checkPermission,
        hasTeamPermission,
        hasPermission,
        orgRoles,
        isSuperAdmin,
      }}
    >
      {children}
    </PermissionsContext.Provider>
  );
};

// Custom hook to use the permissions context
export const usePermissions = () => {
  const context = useContext(PermissionsContext);
  if (!context) {
    throw new Error('usePermissions must be used within a PermissionsProvider');
  }
  return context;
};
